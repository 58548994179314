import { useEffect } from 'react';
import useIsMobile from 'app/utils/hooks/useIsMobile';

const useShowHeadroom = (headerRef, setShowHeader) => {
  const isMobile = useIsMobile();
  const isClient = typeof window !== 'undefined';

  let prevScrollY = isClient ? window.scrollY : null;

  const handleScroll = e => {
    const window = e.currentTarget;
    if (headerRef.current) {
      if (window.scrollY < 200) {
        headerRef.current.style.transform = 'translateY(0)';
        setShowHeader(true);
      } else if (prevScrollY > window.scrollY) {
        headerRef.current.style.transform = 'translateY(0)';
        setShowHeader(true);
      } else if (prevScrollY < window.scrollY) {
        headerRef.current.style.transform = 'translateY(-56px)';
        setShowHeader(false);
      }
    }

    prevScrollY = window.scrollY;
  };

  useEffect(() => {
    if (isClient && isMobile) {
      window.addEventListener('scroll', handleScroll);
    }

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [isMobile]);
};

export default useShowHeadroom;

import paramsSerializer from 'app/utils/paramSerializer';
import api, { prodApi } from './base';

const getProductCategories = (params = {}) => {
  return api.get('v4/product/item/categories', { ...params, depth: 2 });
};

const getProductCategoriesFromProd = (params = {}) => {
  return prodApi.get('v4/product/item/categories', { ...params, depth: 2 });
};

const getProducts = (params = {}) => {
  return api.get('v4/product/items', params, {
    paramsSerializer,
  });
};

const getProductsFromProd = (params = {}) => {
  return prodApi.get('v4/product/items', params, {
    paramsSerializer,
  });
};

const getProduct = (params = {}) => {
  const { productSlug, categorySlug, ...otherParams } = params;
  return api.get(`/v4/product/items/${productSlug}`, {
    category_slug: categorySlug,
    ...otherParams,
  });
};

const getPopularProducts = (params = {}) => {
  return api.get('v4/product/items/popular_products', params);
};

const getCustomBanner = businessAccountId => {
  return api.get(`/v4/business_accounts/${businessAccountId}/banners`);
};

const getPlan = (params = {}) => {
  const {
    planSlug,
    planId,
    productSlug,
    categorySlug,
    ...otherParams
  } = params;

  return api.get(`v4/product/item/plans/${planSlug || planId}`, {
    product_slug: productSlug,
    category_slug: categorySlug,
    ...otherParams,
  });
};

const getSavingsAccounts = () => {
  return api.get('v4/product/items/savings_accounts');
};

const getTopQuestionReview = (params = {}) => {
  const { productSlug } = params;
  return api.get(`v4/product/items/${productSlug}/top_question_and_review`);
};

const getTopCategoriesByPageview = () => {
  return api.get(`v4/analytics/categories`);
};

const getTrendingProducts = () => {
  return api.get(`v4/product/item/topics`);
};

export default {
  getProductCategories,
  getProductCategoriesFromProd,
  getProducts,
  getProductsFromProd,
  getProduct,
  getPopularProducts,
  getPlan,
  getSavingsAccounts,
  getTopQuestionReview,
  getTopCategoriesByPageview,
  getCustomBanner,
  getTrendingProducts,
};

/* eslint-disable */
import mixpanel from 'mixpanel-browser';

const superProps = {
  SessionId: 'Session ID',
  LastEventTime: 'Last Event Time',
};

export const sessionConfig = {
  // thirty minutes in milliseconds
  timeout: 1800000,

  source: () => {
    if (document.referrer.search('https?://(.*)google.([^/?]*)') === 0) {
      return 'Google';
    }
    if (document.referrer.search('https?://(.*)bing.([^/?]*)') === 0) {
      return 'Bing';
    }
    if (document.referrer.search('https?://(.*)yahoo.([^/?]*)') === 0) {
      return 'Yahoo';
    }
    if (document.referrer.search('https?://(.*)facebook.([^/?]*)') === 0) {
      return 'Facebook';
    }
    if (document.referrer.search('https?://(.*)twitter.([^/?]*)') === 0) {
      return 'Twitter';
    }
    if (document.referrer.search('https?://(.*)seedly.([^/?]*)') === 0) {
      return 'Seedly';
    }
    return 'Other';
  },

  getSessionId: () => {
    const s4 = () => {
      return Math.floor((1 + Math.random()) * 0x10000)
        .toString(16)
        .substring(1);
    };
    return `${s4() + s4()}-${s4()}-${s4()}-${s4()}-${s4()}${s4()}${s4()}`;
  },

  setSessionId: () => {
    mixpanel.register_once({ Source: sessionConfig.source() });
    mixpanel.people.set_once({ Source: sessionConfig.source() });
    mixpanel.register({
      [superProps.SessionId]: sessionConfig.getSessionId(),
    });
  },

  checkSessionId: () => {
    if (!mixpanel.get_property(superProps.LastEventTime)) {
      sessionConfig.setSessionId();
    }

    if (!mixpanel.get_property(superProps.SessionId)) {
      sessionConfig.setSessionId();
    }

    if (
      Date.now() - mixpanel.get_property(superProps.LastEventTime) >
      sessionConfig.timeout
    ) {
      sessionConfig.setSessionId();
    }
  },
};

const initSessionConfig = callback => ({
  loaded: mixpanel => {
    const originalTrack = mixpanel.track;
    mixpanel.track = function () {
      sessionConfig.checkSessionId();
      mixpanel.register({ [superProps.LastEventTime]: Date.now() });
      originalTrack.apply(mixpanel, arguments);
    };
    callback(mixpanel);
  },
});

export default initSessionConfig;

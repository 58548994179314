import { createActions, createReducer } from 'reduxsauce';
import produce from 'immer';

const { Types, Creators } = createActions({
  saveOpinionDraftRequest: ['payload', 'meta'],
  saveOpinionDraftSuccess: ['payload'],
  saveOpinionDraftFailure: ['payload'],

  getOpinionDraftsRequest: ['payload', 'meta'],
  getOpinionDraftsSuccess: ['payload'],
  getOpinionDraftsFailure: null,

  getOpinionDraftRequest: ['payload', 'meta'],
  getOpinionDraftSuccess: ['payload'],
  getOpinionDraftFailure: null,

  updateOpinionDraftRequest: ['payload', 'meta'],
  updateOpinionDraftSuccess: ['payload'],
  updateOpinionDraftFailure: null,

  deleteOpinionDraftRequest: ['payload', 'meta'],
  deleteOpinionDraftSuccess: ['payload'],
  deleteOpinionDraftFailure: null,

  setEditOpinionDraftId: ['payload'],
});

const INITIAL_STATE = {
  ids: [],
  byId: {},
  api: {
    isFetching: false,
    isSaving: false,
    isError: false,
    errorMessage: '',
  },
  editOpinionDraftId: null,
};

export const DraftTypes = Types;
export default Creators;

const saveOpinionDraftRequest = state => {
  return produce(state, draft => {
    draft.api.isSaving = true;
  });
};

const saveOpinionDraftSuccess = (state, action) => {
  const { payload: opinion } = action;
  return produce(state, draft => {
    draft.ids.push(opinion.id);
    draft.byId[opinion.id] = opinion;
    draft.api.isSaving = false;
  });
};

const saveOpinionDraftFailure = (state, action) => {
  const { payload } = action;
  return produce(state, draft => {
    draft.api.isSaving = false;
    draft.api.profanityErrors = payload.profanityErrors;
  });
};

const getOpinionDraftsRequest = state => {
  return produce(state, draft => {
    draft.api.isFetching = true;
  });
};

const getOpinionDraftsSuccess = (state, action) => {
  const { payload: opinions } = action;

  return produce(state, draft => {
    draft.ids = [];
    opinions.forEach(opinion => {
      draft.ids.push(opinion.id);
      draft.byId[opinion.id] = opinion;
    });

    draft.api.isFetching = false;
  });
};

const getOpinionDraftSuccess = (state, action) => {
  const opinion = action.payload;

  return produce(state, draft => {
    draft.ids = [opinion.id];
    draft.byId[opinion.id] = opinion;
  });
};

const updateOpinionDraftRequest = state => {
  return produce(state, draft => {
    draft.api.isSaving = true;
  });
};

const updateOpinionDraftSuccess = (state, action) => {
  const { payload: opinion } = action;

  return produce(state, draft => {
    draft.byId[opinion.id] = opinion;
    draft.api.isSaving = false;
  });
};

const deleteOpinionDraftSuccess = (state, action) => {
  const { id: deletedId } = action.payload;
  const newIds = state.ids.filter(id => id !== deletedId);

  return produce(state, draft => {
    draft.ids = newIds;
  });
};

const setEditOpinionDraftId = (state, action) => {
  const opinionDraftId = action.payload;

  return produce(state, draft => {
    draft.editOpinionDraftId = opinionDraftId;
  });
};

export const reducer = createReducer(INITIAL_STATE, {
  [Types.SAVE_OPINION_DRAFT_REQUEST]: saveOpinionDraftRequest,
  [Types.SAVE_OPINION_DRAFT_SUCCESS]: saveOpinionDraftSuccess,
  [Types.SAVE_OPINION_DRAFT_FAILURE]: saveOpinionDraftFailure,
  [Types.GET_OPINION_DRAFT_REQUEST]: getOpinionDraftsRequest,
  [Types.GET_OPINION_DRAFTS_SUCCESS]: getOpinionDraftsSuccess,
  [Types.GET_OPINION_DRAFT_SUCCESS]: getOpinionDraftSuccess,
  [Types.UPDATE_OPINION_DRAFT_REQUEST]: updateOpinionDraftRequest,
  [Types.UPDATE_OPINION_DRAFT_SUCCESS]: updateOpinionDraftSuccess,
  [Types.DELETE_OPINION_DRAFT_SUCCESS]: deleteOpinionDraftSuccess,
  [Types.SET_EDIT_OPINION_DRAFT_ID]: setEditOpinionDraftId,
});

import { createGlobalStyle } from 'styled-components';
import buildConfig from 'utils/buildConfig';
import media from './media';
import { CREDENTIAL_LINK_TYPE } from '../constants/profile';

export { media };

const S3Link = buildConfig.s3BucketEndpoint;

export const navbarImage = {
  broadband: `${S3Link}/navbar/broadband.svg`,
  brokerageAcc: `${S3Link}/navbar/brokerage_acc.svg`,
  car: `${S3Link}/navbar/car.svg`,
  cashbackCard: `${S3Link}/navbar/cashback_card.svg`,
  creditCard: `${S3Link}/navbar/credit_card.svg`,
  debitCard: `${S3Link}/navbar/debit_card.svg`,
  electricity: `${S3Link}/navbar/electricity.svg`,
  electricRetail: `${S3Link}/navbar/electricity_retail.svg`,
  health: `${S3Link}/navbar/health.svg`,
  house: `${S3Link}/navbar/house.svg`,
  intCalculator: `${S3Link}/navbar/int_calculator.svg`,
  investCourse: `${S3Link}/navbar/invest_course.svg`,
  life: `${S3Link}/navbar/life.svg`,
  maid: `${S3Link}/navbar/maid.svg`,
  milesCard: `${S3Link}/navbar/miles_card.svg`,
  mobile: `${S3Link}/navbar/mobile.svg`,
  mortgageLoan: `${S3Link}/navbar/mortgage_loan.svg`,
  multiCurrencyCard: `${S3Link}/navbar/multi_currency_card.svg`,
  p2pLending: `${S3Link}/navbar/p2p_lending.svg`,
  personalAccident: `${S3Link}/navbar/personal_accident.svg`,
  personalLoan: `${S3Link}/navbar/personal_loan.svg`,
  remittance: `${S3Link}/navbar/remittance.svg`,
  roboAdvisor: `${S3Link}/navbar/robo_advisor.svg`,
  savingsAcc: `${S3Link}/navbar/savings_acc.svg`,
  simOnly: `${S3Link}/navbar/sim_only.svg`,
  travel: `${S3Link}/navbar/travel.svg`,
  insuranceSavings: `${S3Link}/navbar/insurance_savings.svg`,
  cancerInsurance: `${S3Link}/navbar/cancer_insurance.svg`,
  longTermEndowment: `${S3Link}/navbar/long_term_endowment.svg`,
  community: {
    stocksDiscussion: `${S3Link}/navbar/community/stocks-discussion.jpeg`,
    campaigns: `${S3Link}/navbar/community/campaigns.png`,
    youngMoney: `${S3Link}/navbar/community/young-money.png`,
    seedlyTv: `${S3Link}/navbar/community/seedly-tv.jpeg`,
    rewards: `${S3Link}/navbar/community/rewards.jpeg`,
    studentAmbassador: `${S3Link}/navbar/community/student-ambassador.jpeg`,
    opinions: `${S3Link}/navbar/community/opinions.jpeg`,
  },
};

export const navbarBlogImage = {
  banking: `${S3Link}/navbar/blog/banking.svg`,
  cards: `${S3Link}/navbar/blog/cards.svg`,
  insurance: `${S3Link}/navbar/blog/insurance.svg`,
  investments: `${S3Link}/navbar/blog/investments.svg`,
  lifeStages: `${S3Link}/navbar/blog/life_stages.svg`,
  lifestyle: `${S3Link}/navbar/blog/lifestyle.svg`,
  loans: `${S3Link}/navbar/blog/loans.svg`,
  more: `${S3Link}/navbar/blog/more.svg`,
  mostRecent: `${S3Link}/navbar/blog/most_recent.svg`,
  property: `${S3Link}/navbar/blog/property.svg`,
  utilitiesBills: `${S3Link}/navbar/blog/utilities_bills.svg`,
};

export const homeImage = {
  bankingIcon: `${S3Link}/home/banking.svg`,
  cardsIcon: `${S3Link}/home/cards.svg`,
  insuranceIcon: `${S3Link}/home/insurance.svg`,
  investmentsIcon: `${S3Link}/home/investments.svg`,
  moreIcon: `${S3Link}/home/more.svg`,
  utilitiesIcon: `${S3Link}/home/utilities-bills.svg`,
  contentIcon: `${S3Link}/home/content.svg`,
  communityIcon: `${S3Link}/home/community.svg`,
  defaultBackgroundDesktop: `${S3Link}/home/default_background_desktop.png`,
  defaultBackgroundMobile: `${S3Link}/home/default_background_mobile.png`,
  bannerMobile: `${S3Link}/home/home-banner-mobile.png`,
  bannerDesktop: `${S3Link}/home/home-banner-desktop.png`,
  blueWallet: `${S3Link}/home/blue_wallet.svg`,
  purpleProtect: `${S3Link}/home/purple_protect.svg`,
  pinkMoneyBag: `${S3Link}/home/pink_money_bag.svg`,
  tealCalculator: `${S3Link}/home/teal_calculator.svg`,
};

export const homeTabIcons = {
  communityPrimary: `${S3Link}/icon/homepage-tabs/community-seedlyBlue.svg`,
  communitySecondary: `${S3Link}/icon/homepage-tabs/community-neutral5.svg`,
  communityWhite: `${S3Link}/icon/homepage-tabs/community-neutral1.svg`,
  blogPrimary: `${S3Link}/icon/homepage-tabs/blog-seedlyBlue.svg`,
  blogSecondary: `${S3Link}/icon/homepage-tabs/blog-neutral5.svg`,
  blogWhite: `${S3Link}/icon/homepage-tabs/blog-neutral1.svg`,
  reviewsPrimary: `${S3Link}/icon/homepage-tabs/reviews-seedlyBlue.svg`,
  reviewsSecondary: `${S3Link}/icon/homepage-tabs/reviews-neutral5.svg`,
  reviewsWhite: `${S3Link}/icon/homepage-tabs/reviews-neutral1.svg`,
  giftPrimary: `${S3Link}/icon/homepage-tabs/gift-seedlyBlue.svg`,
  giftSecondary: `${S3Link}/icon/homepage-tabs/gift-neutral5.svg`,
  giftWhite: `${S3Link}/icon/homepage-tabs/gift-neutral1.svg`,
};

export const sideHeaderImage = {
  communityLogo: `${S3Link}/site_header/community_logo.svg`,
  comparisonLogo: `${S3Link}/site_header/comparison_logo.svg`,
  blogLogo: `${S3Link}/site_header/blog_logo.svg`,
  eventLogo: `${S3Link}/site_header/event_logo.svg`,
};

export const reviewHeaderImage = {
  defaultMobileHeader: `${S3Link}/review_header/default_mobile.png`,
  defaultDesktopHeader: `${S3Link}/review_header/default_desktop.png`,
};

export const profileImage = {
  defaultImage: `${S3Link}/profile_image/default_profile_image.png`,
  anonymousPoster: `${S3Link}/profile_image/anonymous_poster_profile_image.jpg`,
  anonymousAnswerer: `${S3Link}/profile_image/anonymous_answerer_profile_image.jpg`,
};

export const savingsAccountCalculatorImage = {
  backgroundLeft: `${S3Link}/savings_calculator/left.png`,
  backgroundRight: `${S3Link}/savings_calculator/right.png`,
};

export const imageUrl = {
  homeDesktopHero: `${S3Link}/home/desktop-hero.png`,
  notFoundImage: `${S3Link}/404/illustration.png`,
  appStore: `${S3Link}/site_footer/app-store.png`,
  googlePlay: `${S3Link}/site_footer/google-play.png`,
  seedlyLogo: `${S3Link}/logo/seedly_logo_4.svg`,
  seedlyProfile: `${S3Link}/icon/seedly-profile.png`,
  seedlyLogoWhite: `${S3Link}/logo/seedly-logo-white.png`,
  seedlyLogoSchema: `${S3Link}/logo/seedly-logo-schema.jpg`,
  defaultCardImage: `${S3Link}/misc/default-onboard-card-image.jpg`,
  onboarding: `${S3Link}/misc/onboarding.png`,
  joinGroupIcon: `${S3Link}/misc/join-groups.svg`,
  readMoreIcon: `${S3Link}/misc/read-more.svg`,
  binocular: `${S3Link}/misc/binoculars.png`,
  clapHand: `${S3Link}/misc/clap-hand.png`,
  errorBot: `${S3Link}/misc/error-bot.png`,
  homePageCoverDesktop: `${S3Link}/site_header/default_desktop.svg`,
  homePageCoverMobile: `${S3Link}/site_header/default_mobile.svg`,
  referFriendLogo: `${S3Link}/redirects/refer_friend_logo.png`,
  login: `${S3Link}/misc/login.png`,
  personalFeedEmpty: `${S3Link}/misc/empty-state.png`,
  notification: `${S3Link}/misc/notification.svg`,
  verifyEmail: `${S3Link}/misc/verify-email.svg`,
  articleBenefitOne: `${S3Link}/misc/article-benefit-one.png`,
  articleBenefitTwo: `${S3Link}/misc/article-benefit-two.png`,
  articleBenefitThree: `${S3Link}/misc/article-benefit-three.png`,
  facebook: `${S3Link}/icon/ic-fb-signup.png`,
  apple: `${S3Link}/icon/apple.svg`,
  appleWhite: `${S3Link}/icon/appleWhite.svg`,
  claimed: `${S3Link}/icon/claimed.svg`,
  unclaimed: `${S3Link}/icon/unclaimed.svg`,
  seedlyReadsLogo: `${S3Link}/logo/seedly-reads-logo.svg`,
  errorAlmond: `${S3Link}/misc/error-almond.png`,
  verified: `${S3Link}/icon/verified.svg`,
  verifiedInverted: `${S3Link}/icon/verified-inverted.svg`,
  pinSquare: `${S3Link}/icon/pin-square.svg`,
  opinion: `${S3Link}/icon/opinion.svg`,
  success: `${S3Link}/icon/success.svg`,
  shareButton: `${S3Link}/icon/share-3.svg`,
  loginPrompt: `${S3Link}/icon/login-prompt.svg`,
  noReward: `${S3Link}/icon/no-reward.svg`,
  rewardBanner: `${S3Link}/icon/rewards-page-banner.svg`,
  selectTopic: `${S3Link}/icon/select-topic.svg`,
  levelUpCalmond: `${S3Link}/icon/level-up-calmond.png`,
  levelUpModal: `${S3Link}/icon/level-up-modal.gif`,
  star: `${S3Link}/icon/star.svg`,
  paperPlane: `${S3Link}/icon/paperplane.svg`,
  community: `${S3Link}/icon/community.svg`,
  blog: `${S3Link}/icon/blog.svg`,
  reviews: `${S3Link}/icon/reviews.svg`,
  more: `${S3Link}/icon/more-navbar.svg`,
  google: `${S3Link}/icon/google.svg`,
  plant: `${S3Link}/icon/plant.svg`,
  checkbox: `${S3Link}/icon/checkbox.png`,
  dotPattern: `${S3Link}/misc/dot-pattern.png`,
  handphone: `${S3Link}/misc/handphone.png`,
  blogCarousel: `${S3Link}/misc/blog-carousel.png`,
  communityCarousel: `${S3Link}/misc/community-carousel.png`,
  reviewsCarousel: `${S3Link}/misc/reviews-carousel.png`,
  badge: `${S3Link}/misc/badge.svg`,
  yellowCoin: `${S3Link}/reward/yellow_coin.svg`,
  greenCoin: `${S3Link}/reward/green_coin.svg`,
};

export const bannedImage = {
  hiddenFees: `${S3Link}/banned/hidden-fees.svg`,
};

export const blogImage = {
  personalFinance: `${S3Link}/blog/personal-finance.svg`,
  freshGrad: `${S3Link}/blog/fresh-grad.svg`,
  investment: `${S3Link}/blog/investment.svg`,
  nsf: `${S3Link}/blog/nsf.svg`,
  subscriptionBackground: `${S3Link}/blog/subscribe-bg.svg`,
  postPlaceHolder: `${S3Link}/blog`,
};

export const devImage = {
  sampleTopicBanner: `${S3Link}/dev/milelion_banner.jpg`,
};

export const reitImage = {
  // Icon.
  tableSort: `${S3Link}/icon/table-sort.svg`,

  // Placeholder Image.
  agm_minutes: `${S3Link}/icon/agm-active.svg`,
  agm_minutes_disabled: `${S3Link}/icon/agm-disabled.svg`,
  analysis: `${S3Link}/icon/analysis.svg`,
  best_brokerages: `${S3Link}/icon/best-brokerages.svg`,
  company_announcements: `${S3Link}/icon/announcement.svg`,
  financial_statement_highlights: `${S3Link}/icon/highlights.svg`,
  investor_relations_website: `${S3Link}/icon/investor-web.svg`,
};

export const reviewGuidelineImage = `${S3Link}/banned/hidden-fees.svg`;

export const searchIcons = {
  comparison: `${S3Link}/icon/comparison.svg`,
  category: `${S3Link}/icon/category.svg`,
};

export const rewardOnboarding = {
  present: `${S3Link}/reward/present.svg`,
  surprise: `${S3Link}/reward/surprise.svg`,
  credit: `${S3Link}/reward/credit.svg`,
  tier: `${S3Link}/reward/tier.svg`,
  protect: `${S3Link}/reward/2fa.svg`,
  coin: `${S3Link}/reward/coin.svg`,
  star: `${S3Link}/reward/star.svg`,
  circleStar: `${S3Link}/reward/circle_star.svg`,
  singleCoin: `${S3Link}/reward/single_coin.svg`,
  redeemCredit: `${S3Link}/reward/redeem_credit.png`,
  rewardOverview: `${S3Link}/reward/reward_overview.png`,
  tierSeed: `${S3Link}/reward/tier_seed.png`,
  giftSmall: `${S3Link}/reward/gift-small.svg`,
  seeds: `${S3Link}/reward/seeds.svg`,
  billionaire: `${S3Link}/reward/billionaire_dollar.svg`,
  trillionaire: `${S3Link}/reward/trillionaire_dollar.svg`,
  error: `${S3Link}/reward/error.png`,
};

export const credentialLinkIcons = {
  [CREDENTIAL_LINK_TYPE.FACEBOOK]: `${S3Link}/icon/blue/ic-fb.svg`,
  [CREDENTIAL_LINK_TYPE.LINKEDIN]: `${S3Link}/icon/blue/ic-linkedin.svg`,
  [CREDENTIAL_LINK_TYPE.INSTAGRAM]: `${S3Link}/icon/blue/ic-instagram.svg`,
  [CREDENTIAL_LINK_TYPE.YOUTUBE]: `${S3Link}/icon/blue/ic-youtube.svg`,
  [CREDENTIAL_LINK_TYPE.TWITTER]: `${S3Link}/icon/blue/ic-twitter.svg`,
  [CREDENTIAL_LINK_TYPE.WEBSITE]: `${S3Link}/icon/blue/ic-link.svg`,
};

export const credentialDropdownIcons = {
  [CREDENTIAL_LINK_TYPE.FACEBOOK]: `${S3Link}/icon/black-white/ic-fb.svg`,
  [CREDENTIAL_LINK_TYPE.LINKEDIN]: `${S3Link}/icon/black-white/ic-linkedin.svg`,
  [CREDENTIAL_LINK_TYPE.INSTAGRAM]: `${S3Link}/icon/black-white/ic-instagram.svg`,
  [CREDENTIAL_LINK_TYPE.YOUTUBE]: `${S3Link}/icon/black-white/ic-youtube.svg`,
  [CREDENTIAL_LINK_TYPE.TWITTER]: `${S3Link}/icon/black-white/ic-twitter.svg`,
  [CREDENTIAL_LINK_TYPE.WEBSITE]: `${S3Link}/icon/black-white/ic-link.svg`,
};

export const styles = {
  outerBorderRadius: '4px',
  sidebarWidth: '354px',
  desktopContentWidth: '1024px',
  headerHeight: '56px',
};

export const GlobalStyle = createGlobalStyle`
  input::-ms-clear, input::-ms-reveal {
    display: none;
  }

  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }

  html {
    font-family: sans-serif;
    line-height: 1.15;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    -ms-overflow-style: scrollbar;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }

  article, aside, dialog, figcaption, figure, footer, header, hgroup, main, nav, section {
    display: block;
  }


  body {
    margin: 0;
    font-family: Open Sans, Helvetica, Arial;
    color: #40485A;
    font-size: 14px;
    line-height: 1.5;
  }

  hr {
    box-sizing: content-box;
    height: 0;
    overflow: visible;
  }

  h1, h2, h3, h4, h5, h6 {
    margin: 0;
  }

  p {
    margin: 0;
  }

  abbr[title],
  abbr[data-original-title] {
    text-decoration: underline;
    text-decoration: underline dotted;
    cursor: help;
    border-bottom: 0;
  }

  address {
    margin-bottom: 1em;
    font-style: normal;
    line-height: inherit;
  }

  input, select, textarea {
    color: #40485A;
  }

  input[type="text"],
  input[type="password"],
  input[type="number"],
  textarea {
    -webkit-appearance: none;
  }

  ol,
  ul,
  dl {
    margin: 0;
    padding: 0;
  }

  ol ol,
  ul ul,
  ol ul,
  ul ol {
    margin: 0;
    padding: 0;
  }

  li {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  dt {
    font-weight: 500;
  }

  dd {
    margin-bottom: .5em;
    margin-left: 0;
  }

  blockquote {
    margin: 0 0 1em;
  }

  dfn {
    font-style: italic;
  }

  b,
  strong {
    font-weight: bolder;
  }

  small {
    font-size: 80%;
  }

  sub,
  sup {
    position: relative;
    font-size: 75%;
    line-height: 0;
    vertical-align: baseline;
  }

  sub { bottom: -.25em; }
  sup { top: -.5em; }

  a {
    background-color: transparent;
    outline: none;
    cursor: pointer;
    transition: color .3s;
    -webkit-text-decoration-skip: objects;
    text-decoration: none;

    &:focus {
      text-decoration: underline;
      text-decoration-skip: ink;
    }

    &:active,
    &:hover {
      outline: 0;
    }

    &[disabled] {
      cursor: not-allowed;
      pointer-events: none;
    }
  }

  img {
    vertical-align: middle;
    border-style: none;
  }

  a,
  area,
  button,
  [role="button"],
  input:not([type=range]),
  label,
  select,
  summary,
  textarea {
    touch-action: manipulation;
  }

  a:link,
  a:visited,
  a:focus,
  a:active {
    text-decoration: none;
  }

  table {
    border-collapse: collapse;
  }

  caption {
    padding-top: .75em;
    padding-bottom: .3em;
    color: #99A1B3;
    text-align: left;
    caption-side: bottom;
  }

  th {
    text-align: inherit;
  }

  button,
  html [type="button"],
  [type="reset"],
  [type="submit"] {
    -webkit-appearance: button;
  }

  button::-moz-focus-inner,
  [type="button"]::-moz-focus-inner,
  [type="reset"]::-moz-focus-inner,
  [type="submit"]::-moz-focus-inner {
    padding: 0;
    border-style: none;
  }

  input[type="radio"],
  input[type="checkbox"] {
    box-sizing: border-box;
    padding: 0;
  }

  input[type="date"],
  input[type="time"],
  input[type="datetime-local"],
  input[type="month"] {
    -webkit-appearance: listbox;
  }

  textarea {
    overflow: auto;
    resize: vertical;
    font-size: 14px;
  }

  fieldset {
    min-width: 0;
    padding: 0;
    margin: 0;
    border: 0;
  }

  legend {
    display: block;
    width: 100%;
    max-width: 100%;
    padding: 0;
    margin-bottom: .5em;
    font-size: 1.5em;
    line-height: inherit;
    color: inherit;
    white-space: normal;
  }

  progress {
    vertical-align: baseline;
    [type="number"]::-webkit-inner-spin-button,
    [type="number"]::-webkit-outer-spin-button {
      height: auto;
    } 
  }

  [type="search"] {
    outline-offset: -2px;
    -webkit-appearance: none;
  }

  [type="search"]::-webkit-search-cancel-button,
  [type="search"]::-webkit-search-decoration {
    -webkit-appearance: none;
  }

  ::-webkit-file-upload-button {
    font: inherit;
    -webkit-appearance: button;
  }

  output {
    display: inline-block;
  }

  summary {
    display: list-item;
  }

  template {
    display: none;
  }

  mark {
    padding: .2em;
  }

  /* loading progress bar styles */
  #nprogress {
    pointer-events: none;
  }

  #nprogress .bar {
    background: #2dc1f6;
    position: fixed;
    z-index: 1031;
    top: 0;
    left: 0;
    width: 100%;
    height: 2px;
  }

  #nprogress .peg {
    display: block;
    position: absolute;
    right: 0px;
    width: 100px;
    height: 100%;
    box-shadow: 0 0 10px #2dc1f6, 0 0 5px #2dc1f6;
    opacity: 1.0;
    transform: rotate(3deg) translate(0px, -4px);
  }
`;

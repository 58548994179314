export const PRODUCT_FILTER_TYPE = {
  MOST_POPULAR: 'cached_ranking',
  MOST_REVIEWED: 'cached_reviews_count',
  HIGHEST_RATING: 'cached_rating',
  A_TO_Z: 'name',
};

export const PRODUCT_FILTER_TRACKING_MAP = {
  [PRODUCT_FILTER_TYPE.MOST_REVIEWED]: 'sort_most_reviewed',
  [PRODUCT_FILTER_TYPE.HIGHEST_RATING]: 'sort_highest_rating',
  [PRODUCT_FILTER_TYPE.A_TO_Z]: 'sort_a_z',
};

export const PRODUCT_FILTER_TYPE_MAP = {
  [PRODUCT_FILTER_TYPE.MOST_POPULAR]: 'Recommended',
  [PRODUCT_FILTER_TYPE.MOST_REVIEWED]: 'Most Reviewed',
  [PRODUCT_FILTER_TYPE.HIGHEST_RATING]: 'Highest Rating',
  [PRODUCT_FILTER_TYPE.A_TO_Z]: 'A-Z',
};

export const PRODUCT_FILTER_TYPE_LIST = [
  PRODUCT_FILTER_TYPE.MOST_POPULAR,
  PRODUCT_FILTER_TYPE.MOST_REVIEWED,
  PRODUCT_FILTER_TYPE.HIGHEST_RATING,
  PRODUCT_FILTER_TYPE.A_TO_Z,
];

export const PRODUCT_FILTER_TYPE_LIST_WITHOUT_POPULAR = [
  PRODUCT_FILTER_TYPE.MOST_REVIEWED,
  PRODUCT_FILTER_TYPE.HIGHEST_RATING,
  PRODUCT_FILTER_TYPE.A_TO_Z,
];

export const REVIEW_PAGE_TYPE = {
  PROFILE: 'profile',
  PRODUCT: 'product',
};

export const REVIEW_FILTER_TYPE = {
  MOST_UPVOTE: 'most-upvote',
  MOST_RECENT: 'most-recent',
  LOW_TO_HIGH: 'low-to-high',
  HIGH_TO_LOW: 'high-to-low',
};

export const REVIEW_FILTER_TYPE_MAP = {
  [REVIEW_FILTER_TYPE.MOST_UPVOTE]: 'Most Helpful',
  [REVIEW_FILTER_TYPE.MOST_RECENT]: 'Most Recent',
  [REVIEW_FILTER_TYPE.LOW_TO_HIGH]: 'Low to High',
  [REVIEW_FILTER_TYPE.HIGH_TO_LOW]: 'High to Low',
};

export const REVIEW_FILTER_TRACKING_MAP = {
  [REVIEW_FILTER_TYPE.MOST_UPVOTE]: 'sort_most_helpful',
  [REVIEW_FILTER_TYPE.MOST_RECENT]: 'sort_most_recent',
  [REVIEW_FILTER_TYPE.LOW_TO_HIGH]: 'sort_low_to_high',
  [REVIEW_FILTER_TYPE.HIGH_TO_LOW]: 'sort_high_to_low',
};

export const REVIEW_FILTER_TYPE_LIST = [
  REVIEW_FILTER_TYPE.HIGH_TO_LOW,
  REVIEW_FILTER_TYPE.MOST_UPVOTE,
  REVIEW_FILTER_TYPE.MOST_RECENT,
  REVIEW_FILTER_TYPE.LOW_TO_HIGH,
];

export const PRODUCT_LIST_TYPE = {
  REVIEW: 'review',
  SEARCH: 'search',
};

export const INFO_TYPE = {
  PRODUCT: 'product',
  PLAN: 'plan',
};

export const CREDIT_CARD_FILTER = {
  CARD_TYPES: 'Card Types',
  BANK_PROVIDERS: 'Bank Providers',
};

export const CREDIT_CARD_DEFAULT_META =
  'Compare and read real user reviews on the best cashback, air miles and rewards credit card in Singapore.Stay updated with the latest credit card promotions.';

export const getOnlineBrokerageMetaTitle = () => {
  const today = new Date();
  const year = today.toLocaleDateString('en-US', { year: 'numeric' });
  return `Best Online Brokerage Trading Accounts in Singapore ${year} - `;
};
export const ONLINE_BROKERAGES_DEFAULT_META =
  'Compare and read real user reviews on Online Brokerage to find out the best Online Investment Brokerage Accounts in Singapore. Learn about brokerage fees, trading platforms, investment options and how to open an online trading account.';

export const CAR_INSURANCE_DEFAULT_META =
  'What you need to know about car insurance in Singapore. Compare car insurance with real user reviews, from the cheapest car insurance premiums to the most comprehensive coverage';

export const PRIORITY_BANKING_DEFAULT_META =
  'Enjoy the best privileges and benefits priority banking brings. Compare between the various premium banking account requirements and benefits, complete with real user reviews to help you find the most suitable Priority Banking account for you.';

export const SUBSCRIPTION_TYPE = {
  LITE: 'lite',
  BASIC: 'basic',
  PRO: 'pro',
};

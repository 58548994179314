type Environment = 'development' | 'staging' | 'production' | 'beta';

export const config = {
  development: {
    domain: 'seedly.local',
    apiHost: 'http://seedly.local:3000',
    api: 'https://api.seedly.mhg-staging.com/api/',
    apiDomain: 'https://api.seedly.mhg-staging.com',
    facebookAppId: '178405999239190',
    facebookPageId: '460639650790741',
    cmsApiEndpoint: 'https://seedly.prismic.io/api/v2',
    s3BucketEndpoint: 'https://f-staging.seedly.sg',
    blogHost: 'http://blog-development.seedly.local:3000',
    blogApi: 'https://api-blog.seedly.sg/wp-json/',
  },
  staging: {
    domain: 'seedly.mhg-staging.com',
    apiHost: 'https://seedly.mhg-staging.com',
    api: 'https://api.seedly.mhg-staging.com/api/',
    apiDomain: 'https://api.seedly.mhg-staging.com',
    facebookAppId: '178405999239190',
    facebookPageId: '460639650790741',
    cmsApiEndpoint: 'https://seedly.prismic.io/api/v2',
    s3BucketEndpoint: 'https://f-staging.seedly.sg',
    blogHost: 'https://blog.seedly.sg',
    blogApi: 'https://api-blog.seedly.sg/wp-json/',
  },
  production: {
    domain: 'seedly.sg',
    apiHost: 'https://seedly.sg',
    api: 'https://api.seedly.sg/api/',
    apiDomain: 'https://api.seedly.sg',
    facebookAppId: '178405999239190',
    facebookPageId: '460639650790741',
    cmsApiEndpoint: 'https://seedly.prismic.io/api/v2',
    s3BucketEndpoint: 'https://f.seedly.sg',
    blogHost: 'https://blog.seedly.sg',
    blogApi: 'https://api-blog.seedly.sg/wp-json/',
  },
  beta: {
    domain: 'seedly.sg',
    apiHost: 'https://beta.seedly.sg',
    api: 'https://api-beta.seedly.sg/api/',
    apiDomain: 'https://api-beta.seedly.sg',
    facebookAppId: '178405999239190',
    facebookPageId: '460639650790741',
    cmsApiEndpoint: 'https://seedly.prismic.io/api/v2',
    s3BucketEndpoint: 'https://f.seedly.sg',
    blogHost: 'https://blog-beta.seedly.sg',
    blogApi: 'https://api-blog.seedly.sg/wp-json/',
  },
  new: {
    domain: 'new.seedly.sg',
    apiHost: 'https://new.seedly.sg',
    api: 'https://api.new.seedly.sg/api/',
    apiDomain: 'https://api.new.seedly.sg',
    facebookAppId: '178405999239190',
    facebookPageId: '460639650790741',
    cmsApiEndpoint: 'https://seedly.prismic.io/api/v2',
    s3BucketEndpoint: 'https://f.seedly.sg',
    blogHost: 'https://blog.new.seedly.sg',
    blogApi: 'https://api-blog.new.seedly.sg/wp-json/',
  },
};

const ENV = (process.env.NEXT_PUBLIC_BUILD_ENV || 'production') as Environment;
const buildConfig = config[ENV];

export default buildConfig;

import { rewardOnboarding } from 'theme';
import { Colors } from 'seedly-component-library';
import { useIsSignedIn, useMigratedRewardUser } from 'entity/user/userHooks';

type PageData = {
  backgroundColor: string;
  title: string;
  description: string;
  imageUrl: string;
  verifyMobile?: boolean;
};

const usePostlaunchData = (): PageData[] => {
  const isMigratedRewardUser = useMigratedRewardUser();
  const isSignedin = useIsSignedIn();

  if (!isMigratedRewardUser) {
    return [
      {
        backgroundColor: Colors.seedlyBlue,
        title: 'Introducing the new Seedly Rewards!',
        description: 'Get rewarded for being on Seedly',
        imageUrl: rewardOnboarding.present,
      },
      {
        backgroundColor: Colors.green8,
        title: 'Enjoy greater benefits with the new tier system',
        description:
          'You will be slotted into a tier once you join the rewards programme',
        imageUrl: rewardOnboarding.tier,
      },
      {
        backgroundColor: Colors.orange1,
        title: 'Cash out credits for popular vouchers or rewards',
        description: 'Get popular vouchers from various lifestyle brands',
        imageUrl: rewardOnboarding.surprise,
      },
      {
        backgroundColor: Colors.green9,
        title: 'Secure your account and earn bonus credits',
        description: isSignedin
          ? 'Verify your mobile number next to earn instant welcome bonus!'
          : 'Sign up now and verify your mobile number to earn instant welcome bonus!',
        imageUrl: rewardOnboarding.protect,
        verifyMobile: true,
      },
    ];
  }

  // existing user register before reward launch
  return [
    {
      backgroundColor: Colors.seedlyBlue,
      title: 'Introducing new SeedlyRewards to you!',
      description: 'Get rewarded for being on Seedly. Swipe to find out more!',
      imageUrl: rewardOnboarding.present,
    },
    {
      backgroundColor: Colors.green8,
      title: 'Enjoy greater benefits with the new tier system',
      description:
        'You will be slotted into a tier once you join the rewards programme',
      imageUrl: rewardOnboarding.tier,
    },
    {
      backgroundColor: Colors.purple2,
      title: 'Earn credits by contributing on Seedly just like before',
      description: 'Your existing points will also be converted to credits ',
      imageUrl: rewardOnboarding.credit,
    },
    {
      backgroundColor: Colors.orange1,
      title: 'Cash out credits for popular vouchers or rewards',
      description: 'Get popular vouchers from various lifestyle brands',
      imageUrl: rewardOnboarding.surprise,
    },
    {
      backgroundColor: Colors.green9,
      title: 'Secure your account and earn bonus credits',
      description:
        'Verify your mobile number next to earn instant welcome bonus!',
      imageUrl: rewardOnboarding.protect,
      verifyMobile: true,
    },
  ];
};

export default usePostlaunchData;

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FeedState, FeedFilter } from './feedTypes';
import { BaseParam, Pagination, Post } from '../post/postTypes';

const defaultPagination = {
  per: 0,
  currentPage: 0,
  totalCount: 0,
  totalPage: 0,
};

const initialState: FeedState = {
  idsWithType: [],
  pagination: defaultPagination,
  api: {
    isFetching: false,
    isFetchingMoreFeed: false,
    isFetchFeedsError: false,
  },
};

export const feedsSlice = createSlice({
  name: 'feeds',
  initialState,
  reducers: {
    getFeedsRequest(
      state,
      action: PayloadAction<BaseParam & { feedFilter: FeedFilter }>,
    ) {
      state.api.isFetching = action.payload.page && action.payload.page === 1;
      state.api.isFetchingMoreFeed =
        action.payload.page && action.payload.page > 1;
    },
    getFeedSuccess(
      state,
      action: PayloadAction<{ feeds: Post[]; pagination: Pagination }>,
    ) {
      const { feeds, pagination } = action.payload;

      if (pagination.currentPage === 1) {
        state.idsWithType = [];
      }

      feeds.forEach(post => {
        const postTypeWithId = {
          id: post.id,
          type: post.type,
        };
        state.idsWithType.push(postTypeWithId);
      });

      state.pagination = pagination;
      state.api.isFetching = false;
      state.api.isFetchingMoreFeed = false;
      state.api.isFetchFeedsError = false;
    },
    getFeedsFailure(state) {
      state.api.isFetching = false;
      state.api.isFetchingMoreFeed = false;
      state.api.isFetchFeedsError = true;
    },
    addPostToFeed(state, action: PayloadAction<Post>) {
      const post = action.payload;
      state.idsWithType.unshift({
        id: post.id,
        type: 'Community::Question::Entity',
      });
    },
    deletePostFromFeed(state, action: PayloadAction<number>) {
      const deletedId = action.payload;
      state.idsWithType = state.idsWithType.filter(post => {
        return !(
          post.id === deletedId && post.type === 'Community::Question::Entity'
        );
      });
    },
  },
});

// only use for dispatching purpose
export const { getFeedsRequest } = feedsSlice.actions;

export default feedsSlice.reducer;

import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { openNameModal } from 'app/dispatcher/modalDispatcher';
import get from 'lodash/get';

const useOpenNameModal = () => {
  const userName = useSelector(state => get(state.auth.user, 'name', null));
  const dispatch = useDispatch();

  useEffect(() => {
    if (userName === '' || userName?.match(/SeedlyUser \d{5}/)) {
      dispatch(openNameModal());
    }
  }, []);
};

export default useOpenNameModal;

/* eslint-disable no-unused-vars */

import { css, CSSObject, SimpleInterpolation } from 'styled-components';

// Responsive breakpoints ported from BootStrap 4 -
// https://v4-alpha.getbootstrap.com/layout/overview/

// const SCREEN_XXS = 400;
// const SCREEN_XS = 480;
const SCREEN_SM = 576;
const SCREEN_MD = 769;
const SCREEN_LG = 1024;
const SCREEN_XL = 1200;
const SCREEN_XXL = 1600;

// const SCREEN_XXS_MIN = SCREEN_XXS;
// const SCREEN_XS_MIN = SCREEN_XS;
const SCREEN_SM_MIN = SCREEN_SM;
const SCREEN_MD_MIN = SCREEN_MD;
const SCREEN_LG_MIN = SCREEN_LG;
const SCREEN_XL_MIN = SCREEN_XL;
const SCREEN_XXL_MIN = SCREEN_XXL;

// const SCREEN_XXS_MAX = SCREEN_XXS_MIN - 1;
// const SCREEN_XS_MAX = SCREEN_XS_MIN - 1;
const SCREEN_SM_MAX = SCREEN_SM_MIN - 1;
// const SCREEN_MD_MAX = SCREEN_MD_MIN - 1;
// const SCREEN_LG_MAX = SCREEN_LG_MIN - 1;
// const SCREEN_XL_MAX = SCREEN_XL_MIN - 1;
// const SCREEN_XXL_MAX = SCREEN_XXL_MIN - 1;

// Mobile First

// Extra small devices (portrait phones, less than 576px)
// No media query since this is the default in Bootstrap

type MediaArgs = [TemplateStringsArray | CSSObject, ...SimpleInterpolation[]];

// Small devices (landscape phones, 576px and up)
const smallAndUp = (...args: MediaArgs) => css`
  @media (min-width: ${SCREEN_SM_MIN}px) {
    ${css(...args)}
  }
`;
// Medium devices (tablets, 768px and up)
const mediumAndUp = (...args: MediaArgs) => css`
  @media (min-width: ${SCREEN_MD_MIN}px) {
    ${css(...args)}
  }
`;
// Large devices (desktops, 992px and up)
const largeAndUp = (...args: MediaArgs) => css`
  @media (min-width: ${SCREEN_LG_MIN}px) {
    ${css(...args)}
  }
`;

// Extra large devices (large desktops, 1200px and up)
const xLargeAndUp = (...args: MediaArgs) => css`
  @media (min-width: ${SCREEN_XL_MIN}px) {
    ${css(...args)}
  }
`;

// Extra large devices (large desktops, 1600px and up)
const xXLargeAndUp = (...args: MediaArgs) => css`
  @media (min-width: ${SCREEN_XXL_MIN}px) {
    ${css(...args)}
  }
`;

// Desktop First

// Extra small devices (portrait phones, less than 576px)
const xSmallAndDown = (...args: MediaArgs) => css`
  @media (max-width: ${SCREEN_SM_MAX}px) {
    ${css(...args)}
  }
`;

// Small devices (landscape phones, less than 768px)
const smallAndDown = (...args: MediaArgs) => css`
  @media (max-width: ${SCREEN_SM_MIN}px) {
    ${css(...args)}
  }
`;

// Medium devices (tablets, less than 992px)
const mediumAndDown = (...args: MediaArgs) => css`
  @media (max-width: ${SCREEN_MD_MIN}px) {
    ${css(...args)}
  }
`;

// Large devices (desktops, less than 1200px)
const largeAndDown = (...args: MediaArgs) => css`
  @media (max-width: ${SCREEN_LG_MIN}px) {
    ${css(...args)}
  }
`;

// Extra large devices (large desktops)
// No media query since the extra-large breakpoint has no upper bound on its width

const ie = (...args: MediaArgs) => css`
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    ${css(...args)}
  }
`;

export default {
  smallAndUp,
  mediumAndUp,
  largeAndUp,
  xLargeAndUp,
  xXLargeAndUp,
  xSmallAndDown,
  smallAndDown,
  mediumAndDown,
  largeAndDown,
  ie,
  SCREEN_SM,
  SCREEN_MD,
  SCREEN_LG,
  SCREEN_XL,
  SCREEN_XXL,
};

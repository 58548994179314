import { call, takeLatest } from 'redux-saga/effects';
import { handleResponse } from 'app/utils/sagaHelper';

import api from './navigationApi';
import { navigationSlice } from './navigationRedux';

function* getNavCampaigns() {
  const response = yield call(api.getCampaigns);

  const successAction = navigationSlice.actions.getNavCampaignsSuccess;
  const failureAction = navigationSlice.actions.getNavCampaignsFailure;

  yield call(handleResponse, {
    successAction,
    failureAction,
    response,
  });
}

export const navigationSagas = [
  takeLatest(navigationSlice.actions.getNavCampaignsRequest, getNavCampaigns),
];

import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import {
  destroyFormDataCache,
  getCachedFormData,
} from 'app/utils/sessionStorage';
import { postCommentRequest } from 'entity/comment/commentRedux';
import { followGroupRequest } from 'entity/group/groupRedux';
import { postReview } from 'app/dispatcher/reviewDispatcher';
import {
  openReportModal,
  openRewardLaunchModal,
} from 'app/dispatcher/modalDispatcher';
import { followPostRequest, createPostRequest } from 'entity/post/postRedux';
import { dailyCheckInRequest } from 'entity/reward/rewardRedux';
import { useIsOnboarded, useIsSignedIn } from 'entity/user/userHooks';
import getSessionStorage from 'utils/getSesssionStorage';

const usePostAfterSignIn = () => {
  const dispatch = useDispatch();
  const isSignedIn = useIsSignedIn();
  const isOnboarded = useIsOnboarded();

  useEffect(() => {
    const isCheckingIn = getSessionStorage('daily-checkin') === 'true';
    const hasSignedUp = getSessionStorage('user-signedup') === 'true';

    if (isSignedIn) {
      const {
        cachedQuestionData,
        cachedGroupData,
        cachedCommentData,
        cachedReviewData,
        cachedFollowQuestionData,
        cachedReportData,
        cachedChildCommentData,
      } = getCachedFormData();

      if (!isEmpty(cachedQuestionData)) {
        dispatch(
          createPostRequest({
            ...cachedQuestionData,
            onSuccess: destroyFormDataCache,
          }),
        );
      } else if (!isEmpty(cachedChildCommentData)) {
        dispatch(
          postCommentRequest(cachedChildCommentData, {
            onSuccess: destroyFormDataCache,
            onFailure: destroyFormDataCache,
          }),
        );
      } else if (!isEmpty(cachedCommentData)) {
        dispatch(
          postCommentRequest(cachedCommentData, {
            onSuccess: destroyFormDataCache,
            onFailure: destroyFormDataCache,
          }),
        );
      } else if (!isEmpty(cachedReviewData)) {
        dispatch(
          postReview(
            { formValues: cachedReviewData },
            {
              onSuccess: () => {
                destroyFormDataCache();
              },
              onFailure: destroyFormDataCache,
            },
          ),
        );
      } else if (!isEmpty(cachedFollowQuestionData)) {
        dispatch(followPostRequest(cachedFollowQuestionData));
        destroyFormDataCache();
      } else if (!isEmpty(cachedReportData)) {
        dispatch(openReportModal(cachedReportData));
        destroyFormDataCache();
      } else if (cachedGroupData) {
        dispatch(followGroupRequest(cachedGroupData));
        destroyFormDataCache();
      } else if (isCheckingIn && !hasSignedUp) {
        if (isOnboarded === true) {
          dispatch(dailyCheckInRequest({ openRewardModal: true }));
        }

        if (isOnboarded === false) {
          dispatch(openRewardLaunchModal());
        }

        destroyFormDataCache();
      }
    }
  }, [dispatch, isSignedIn]);
};

export default usePostAfterSignIn;

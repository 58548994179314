import React from 'react';

import {
  Facebook as FacebookIcon,
  Twitter as TwitterIcon,
  Instagram as InstagramIcon,
  Send as TelegramIcon,
  Youtube as YoutubeIcon,
} from 'react-feather';

import buildConfig from 'app/utils/buildConfig';

const footerItems = [
  {
    title: 'Company',
    items: [
      {
        title: 'About',
        url: `${buildConfig.apiHost}/about/`,
      },
      {
        title: 'Contact',
        url: 'mailto:support@seedly.sg',
      },
      {
        title: 'Careers',
        url: 'https://careers.seedly.com',
      },
      {
        title: 'Privacy',
        url: `${buildConfig.apiHost}/privacy/`,
      },
      {
        title: 'Terms',
        url: `${buildConfig.apiHost}/terms/`,
      },
    ],
  },
  {
    title: 'Product Reviews',
    items: [
      {
        title: 'Credit Cards',
        url: `${buildConfig.apiHost}/reviews/credit-cards/`,
      },
      {
        title: 'Online Brokerages',
        url: `${buildConfig.apiHost}/reviews/online-brokerages/`,
      },
      {
        title: 'Robo-Advisors',
        url: `${buildConfig.apiHost}/reviews/robo-advisors/`,
      },
      {
        title: 'Crypto Exchanges',
        url: `${buildConfig.apiHost}/reviews/crypto-exchange/`,
      },
      {
        title: 'Savings Accounts',
        url: `${buildConfig.apiHost}/reviews/savings-accounts/`,
      },
      {
        title: 'Broadband',
        url: `${buildConfig.apiHost}/reviews/broadband/`,
      },
      {
        title: 'Remittance  ',
        url: `${buildConfig.apiHost}/reviews/remittance/`,
      },
      {
        title: 'Product Sitemap',
        url: `${buildConfig.apiHost}/sitemap/products/1/`,
      },
    ],
  },
  {
    title: 'Business',
    items: [
      {
        title: 'SeedlyBusiness',
        url: 'https://www.business.seedly.sg',
      },
      {
        title: 'Advertise with Seedly',
        url: 'https://www.business.seedly.sg/advertise',
      },
    ],
  },
  {
    title: 'Popular Articles',
    items: [
      {
        title: 'Best Fixed Deposit Rate Singapore',
        url: 'https://blog.seedly.sg/best-fixed-deposit-rate-singapore/',
      },
      {
        title: 'Treasury Bills (T-Bills) Singapore Guide',
        url: 'https://blog.seedly.sg/treasury-bills-t-bill-singapore-guide/',
      },
      {
        title: 'Latest Singapore Savings Bonds (SSB) Guide',
        url:
          'https://blog.seedly.sg/guide-investing-singapore-savings-bond-ssb-interest-rate-how-to-buy/',
      },
      {
        title: 'Best Savings Accounts Singapore',
        url: 'https://blog.seedly.sg/best-savings-accounts-singapore/',
      },
      {
        title: 'Budget 2023 Singapore Summary',
        url:
          'https://blog.seedly.sg/singapore-budget-summary-ministry-of-finance/',
      },
    ],
  },
];

const socialFooterItems = [
  {
    name: 'Twitter',
    icon: <TwitterIcon />,
    url: 'https://twitter.com/seedlysg',
  },
  {
    name: 'Facebook',
    icon: <FacebookIcon />,
    url: 'https://www.facebook.com/seedlysg',
  },
  {
    name: 'Instagram',
    icon: <InstagramIcon />,
    url: 'https://www.instagram.com/seedlysg',
  },
  {
    name: 'Telegram',
    icon: <TelegramIcon />,
    url: 'https://t.me/personalfinancesg',
  },
  {
    name: 'Youtube',
    icon: <YoutubeIcon />,
    url: 'https://youtube.com/c/Seedly',
  },
];

const Footer = () => {
  return (
    <>
      <div className="flex flex-col items-center m-auto px-4 py-6 lg:px-0 w-full flex-wrap z-10 bg-neutral-600">
        <div className="w-full lg:w-[1024px] flex justify-between flex-wrap lg:flex-nowrap">
          {footerItems.map(footerItem => {
            return (
              <div
                className="flex flex-col gap-2 w-1/2 lg:w-1/4 pb-6 lg:pb-0"
                key={footerItem.title}
              >
                <p className="text-sm font-bold text-white">
                  {footerItem.title}
                </p>
                {footerItem.items.map(item => {
                  return (
                    <a
                      key={item.title}
                      className="text-white"
                      rel="noreferrer"
                      href={item.url}
                      target="_blank"
                    >
                      {item.title}
                    </a>
                  );
                })}
              </div>
            );
          })}
        </div>
      </div>
      <div className="flex flex-col items-center w-full z-10 bg-neutral-700 p-4 lg:py-4 lg:px-0">
        <div className="flex gap-6 w-full lg:w-[1024px] items-center bg-neutral-700">
          {socialFooterItems.map(socialFooterItem => {
            return (
              <a
                key={socialFooterItem.name}
                className="text-white flex items-center"
                href={socialFooterItem.url}
                target="_blank"
                aria-label={socialFooterItem.name}
                rel="noopener noreferrer"
              >
                {socialFooterItem.icon}
              </a>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default Footer;

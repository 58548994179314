import api from './base';

const postQuestionReport = (params = {}) => {
  const { id, ...otherParams } = params;
  return api.post(`v4/posts/${id}/report`, { ...otherParams });
};

const postCommentReport = (params = {}) => {
  const { id, ...otherParams } = params;
  return api.post(`v4/answers/${id}/report`, { ...otherParams });
};

const postReviewReport = (params = {}) => {
  const { id, ...otherParams } = params;
  return api.post(`v4/product/item/reviews/${id}/report`, { ...otherParams });
};

const postOpinionReport = (params = {}) => {
  const { id, ...otherParams } = params;
  return api.post(`v4/opinions/${id}/report`, { ...otherParams });
};

const postReviewCommentReport = (params = {}) => {
  const { id, commentId, ...otherParams } = params;
  return api.post(
    `v4/product/item/reviews/${id}/comments/${commentId}/report`,
    { ...otherParams },
  );
};

const postChildCommentReport = (params = {}) => {
  const { id, commentId, ...otherParams } = params;
  return api.post(`v4/answers/${id}/comments/${commentId}/report`, {
    ...otherParams,
  });
};

const postOpinionCommentReport = (params = {}) => {
  const { id, commentId, ...otherParams } = params;
  return api.post(`v4/opinions/${id}/comments/${commentId}/report`, {
    ...otherParams,
  });
};

export default {
  postQuestionReport,
  postCommentReport,
  postReviewReport,
  postOpinionReport,
  postOpinionCommentReport,
  postReviewCommentReport,
  postChildCommentReport,
};

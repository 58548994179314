const spacing = {
  /** 0px */
  none: '0px',
  /** 4px */
  xxs: '4px',
  /** 8px */
  xs: '8px',
  /** 12px */
  sm: '12px',
  /** md is the default, 16px */
  md: '16px',
  /** 24px */
  lg: '24px',
  /** 32px */
  xl: '32px',
  /** 40px */
  xxl: '40px',
  /** 64px */
  xxxl: '64px',
  /** 80px */
  xxxxl: '80px',
};

export default spacing;

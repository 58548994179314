import React from 'react';
import { imageUrl } from 'theme';
import buildConfig from 'app/utils/buildConfig';
import Page from 'app/components/misc/BasePage';

const UsefulLinks = () => {
  return (
    <div className="flex flex-col lg:flex-row items-center gap-4">
      <a
        className="text-lg text-blue-500 hover:underline"
        href="https://blog.seedly.sg"
      >
        Read Articles
      </a>
      <span className="hidden lg:block">|</span>
      <a
        className="text-lg text-blue-500 hover:underline"
        href={`${buildConfig.apiHost}/community`}
      >
        Post Discussions
      </a>
      <span className="hidden lg:block">|</span>
      <a
        className="text-lg text-blue-500 hover:underline"
        href={`${buildConfig.apiHost}/reviews`}
      >
        View Products
      </a>
    </div>
  );
};

const BackToAllPosts = () => {
  return (
    <>
      <img alt="calmond" height={255} width={175} src={imageUrl.errorAlmond} />
      <p className="text-3xl font-bold text-blue-500 text-center">
        Oops! The Post you’re looking for does not exist.
      </p>
      <a
        className="text-lg text-blue-500 hover:underline"
        href={`${buildConfig.apiHost}/community`}
      >
        Back To All Posts
      </a>
    </>
  );
};

const BackToAllProducts = () => {
  return (
    <>
      <img alt="calmond" height={255} width={175} src={imageUrl.errorAlmond} />
      <p className="text-3xl font-bold text-blue-500 text-center">
        Oops! The Product you’re looking for does not exist.
      </p>
      <a
        className="text-lg text-blue-500 hover:underline"
        href={`${buildConfig.apiHost}/reviews`}
      >
        Back To All Products
      </a>
    </>
  );
};

const BackToAll = () => {
  return (
    <>
      <img alt="calmond" height={255} width={175} src={imageUrl.errorAlmond} />
      <p className="text-3xl font-bold text-blue-500 text-center">
        Oops! The place you’re looking for does not exist.
      </p>
      <p className="text-lg">Here are some useful links</p>
      <UsefulLinks />
    </>
  );
};

const NotFoundError = ({ path }) => {
  let content;
  if (path === '/reviews' || path === '/productDetail') {
    content = <BackToAllProducts />;
  } else if (path === '/community' || path === '/questionDetail') {
    content = <BackToAllPosts />;
  } else {
    content = <BackToAll />;
  }

  return (
    <div className="flex flex-col gap-4 items-center justify-center w-full">
      {content}
    </div>
  );
};

const ServerError = () => {
  return (
    <div className="flex flex-col gap-4 items-center justify-center w-full">
      <img alt="calmond" height={255} width={175} src={imageUrl.errorAlmond} />
      <p className="text-3xl font-bold text-blue-500 text-center">
        Oops! Our server encountered an error.
      </p>
      <p className="text-lg">Here are some useful links :)</p>
      <UsefulLinks />
    </div>
  );
};

const ServerMaintenance = () => {
  return (
    <div className="flex flex-col gap-4 items-center justify-center w-full">
      <img alt="calmond" height={255} width={175} src={imageUrl.errorAlmond} />
      <p className="text-3xl font-bold text-blue-500 text-center">
        Seedly is under maintenance.
      </p>
      <p className="text-lg">
        We’re doing some spring cleaning for the new year, come back in a few
        hours!
      </p>
    </div>
  );
};

const ErrorPage = props => {
  let statusCode = 404;
  const { path } = props;
  if (props.statusCode) {
    statusCode = props.statusCode;
  } else if (props.apiError) {
    const { apiError } = props;
    statusCode = apiError.statusCode;
  }

  let errorMessage;

  switch (statusCode) {
    case 404:
      errorMessage = <NotFoundError path={path} />;
      break;
    case 400:
      errorMessage = <NotFoundError path={path} />;
      break;
    case 500:
      errorMessage = <ServerError />;
      break;
    case 503:
      errorMessage = <ServerMaintenance />;
      break;
    default:
      errorMessage = <ServerError />;
      break;
  }

  const ErrorPageContent = (
    <div className="flex flex-col w-full justify-center mt-28 lg:mt-0">
      {errorMessage}
    </div>
  );
  if (statusCode !== 404 && statusCode !== 400) {
    return ErrorPageContent;
  }
  return <Page>{ErrorPageContent}</Page>;
};

export default ErrorPage;

import { createReducer, createActions } from 'reduxsauce';
import { createSelector } from 'reselect';
import get from 'lodash/get';
import produce from 'immer';
import { PROFILE_FILTER_TYPE } from 'app/constants/profile';
import { POST_FILTER_TYPE } from 'app/entity/post/postConstant';
import { getLatestCredentials } from 'app/utils/formatCredentials';

const { Types, Creators } = createActions({
  getProfilesSuccess: ['payload', 'meta'],

  getUserProfileSuccess: ['payload', 'meta'],

  updateUserProfileRequest: ['payload', 'meta'],
  updateUserProfileSuccess: ['payload'],
  updateUserProfileFailure: null,

  getProfileRequest: ['payload', 'meta'],
  getProfileSuccess: ['payload'],
  getProfileFailure: null,

  postCredentialRequest: ['payload', 'meta'],
  postCredentialSuccess: ['payload'],
  postCredentialFailure: null,

  updateCredentialRequest: ['payload', 'meta'],
  updateCredentialSuccess: ['payload'],
  updateCredentialFailure: null,

  deleteCredentialRequest: ['payload', 'meta'],
  deleteCredentialSuccess: ['payload'],
  deleteCredentialFailure: null,

  bookmarkArticleProfileSuccess: ['payload'],

  updateProfileMeta: ['payload'],
  resetSearchProfiles: null,
  setBusinessProfile: ['payload'],
  updateProfilePoints: ['payload'],

  updateOnboardingStatus: ['payload'],

  updateRewardPrelaunchOnboarding: ['payload'],
  updateRewardPostlaunchOnboarding: ['payload'],

  onRegisterMobileSuccess: ['payload'],
  dailyCheckIn: ['payload'],
  updateUserMobileNumber: ['payload'],
});

const INITIAL_STATE = {
  meta: {
    id: null,
    ids: [],
    pagination: {},
    filter: PROFILE_FILTER_TYPE.OPINIONS,
  },
  api: {
    isFetching: false,
  },
  byId: {},
  user: {},
  selectedProfile: {},
  view: {},
};

export const ProfileTypes = Types;
export default Creators;

const resetSearchProfiles = state => {
  return produce(state, draft => {
    draft.meta.ids = [];
    draft.meta.pagination = {};
  });
};

const getProfileSuccess = (state, action) => {
  const { payload: profile } = action;

  return produce(state, draft => {
    draft.meta.id = profile.id;
    draft.meta.ids = [];
    draft.meta.pagination = {};

    draft.byId[profile.id] = profile;
    draft.view = profile;
  });
};

const getProfilesSuccess = (state, action) => {
  const { profiles, pagination, isFetchingMore } = action.payload;

  return produce(state, draft => {
    draft.meta.id = null;
    if (!isFetchingMore) {
      draft.meta.ids = [];
    }
    profiles.forEach(profile => {
      draft.meta.ids.push(profile.id);
      draft.byId[profile.id] = profile;
    });
    draft.meta.pagination = pagination;
  });
};

const getUserProfileSuccess = (state, action) => {
  const { payload: profile } = action;

  return produce(state, draft => {
    draft.user = profile;

    draft.meta.id = profile.id;
    draft.meta.ids = [];
    draft.meta.pagination = {};

    draft.byId[profile.id] = profile;

    // If have current plan means is business profile
    draft.selectedProfile = profile;
    draft.selectedProfile.isBusinessProfile = Boolean(profile.currentPlan);
    draft.selectedProfile.image = profile.currentPlan
      ? profile.picture
      : profile.image;
  });
};

const updateUserProfileSuccess = (state, action) => {
  const { payload: profile } = action;

  return produce(state, draft => {
    draft.user = profile;
    draft.selectedProfile = profile;
    draft.selectedProfile.isBusinessProfile = Boolean(profile.currentPlan);
    draft.selectedProfile.image = profile.currentPlan
      ? profile.picture
      : profile.image;
  });
};

const updateProfilePoints = (state, action) => {
  const { payload: profile } = action;
  return produce(state, draft => {
    // update credit and tier
    draft.user.tier = profile.tier;
    draft.user.creditsCount = profile.creditsCount;
    draft.user.lifetimeSeeds = profile.lifetimeSeeds;
    draft.user.commentsCount = profile.commentsCount;
    draft.user.questionsCount = profile.questionsCount;

    // when viewing own profile, update the counts as well
    // to be refactored
    if (state.view?.slug && state.view?.slug === profile.slug) {
      draft.view.tier = profile.tier;
      draft.view.creditsCount = profile.creditsCount;
      draft.view.lifetimeSeeds = profile.lifetimeSeeds;
    }
  });
};

const postCredentialSuccess = (state, action) => {
  const { payload: newCredential } = action;

  return produce(state, draft => {
    draft.user.credentials.push(newCredential);
  });
};

const updateCredentialSuccess = (state, action) => {
  const { payload: newCredential } = action;
  const credentials = state.user.credentials;
  const index = credentials.findIndex(cred => cred.id === newCredential.id);

  return produce(state, draft => {
    draft.user.credentials[index] = newCredential;
  });
};

const deleteCredentialSuccess = (state, action) => {
  const { id } = action.payload;
  const credentials = state.user.credentials;
  const newCredentials = [
    ...credentials.filter(credential => credential.id !== id),
  ];

  return produce(state, draft => {
    draft.user.credentials = newCredentials;
  });
};

const updateProfileMeta = (state, action) => {
  const { filter } = action.payload;

  return produce(state, draft => {
    draft.meta.filter = filter;
  });
};

const setBusinessProfile = (state, action) => {
  const profile = action.payload;
  return produce(state, draft => {
    draft.selectedProfile = profile;
    draft.selectedProfile.isBusinessProfile = Boolean(profile.currentPlan);
    draft.selectedProfile.image = profile.currentPlan
      ? profile.picture
      : profile.image;
  });
};

const bookmarkArticleProfileSuccess = (state, action) => {
  const { articleId: id } = action.payload;
  return produce(state, draft => {
    if (draft.user.bookmarkedArticleIds.includes(id)) {
      draft.user.bookmarkedArticleIds = draft.user.bookmarkedArticleIds.filter(
        value => value !== id,
      );
    } else {
      draft.user.bookmarkedArticleIds.push(id);
    }
  });
};

const updateOnboardingStatus = (state, action) => {
  return produce(state, draft => {
    draft.user.isOnboarded = action.payload;
  });
};

const updateRewardPrelaunchOnboarding = state => {
  return produce(state, draft => {
    draft.user.isPrelaunchRewardsOnboarded = true;
  });
};
const updateRewardPostlaunchOnboarding = state => {
  return produce(state, draft => {
    draft.user.isPostlaunchRewardsOnboarded = true;
  });
};

const dailyCheckIn = (state, action) => {
  const {
    checkedInToday,
    awardWeeklyLoginStreak,
    consecutiveLoginDays,
  } = action.payload;

  return produce(state, draft => {
    draft.user.checkedInToday = checkedInToday;
    draft.user.consecutiveLoginDays = consecutiveLoginDays;
    draft.user.awardWeeklyLoginStreak = awardWeeklyLoginStreak;
  });
};

const updateUserMobileNumber = (state, action) => {
  const phoneNumber = action.payload;
  return produce(state, draft => {
    draft.user.phone = phoneNumber;
  });
};

export const reducer = createReducer(INITIAL_STATE, {
  [Types.UPDATE_USER_PROFILE_SUCCESS]: updateUserProfileSuccess,
  [Types.GET_PROFILE_SUCCESS]: getProfileSuccess,
  [Types.GET_PROFILES_SUCCESS]: getProfilesSuccess,
  [Types.GET_USER_PROFILE_SUCCESS]: getUserProfileSuccess,
  [Types.POST_CREDENTIAL_SUCCESS]: postCredentialSuccess,
  [Types.UPDATE_CREDENTIAL_SUCCESS]: updateCredentialSuccess,
  [Types.DELETE_CREDENTIAL_SUCCESS]: deleteCredentialSuccess,
  [Types.UPDATE_PROFILE_META]: updateProfileMeta,
  [Types.RESET_SEARCH_PROFILES]: resetSearchProfiles,
  [Types.SET_BUSINESS_PROFILE]: setBusinessProfile,
  [Types.BOOKMARK_ARTICLE_PROFILE_SUCCESS]: bookmarkArticleProfileSuccess,
  [Types.UPDATE_PROFILE_POINTS]: updateProfilePoints,
  [Types.UPDATE_ONBOARDING_STATUS]: updateOnboardingStatus,
  [Types.UPDATE_REWARD_PRELAUNCH_ONBOARDING]: updateRewardPrelaunchOnboarding,
  [Types.UPDATE_REWARD_POSTLAUNCH_ONBOARDING]: updateRewardPostlaunchOnboarding,
  [Types.DAILY_CHECK_IN]: dailyCheckIn,
  [Types.UPDATE_USER_MOBILE_NUMBER]: updateUserMobileNumber,
});

export const getProfileId = state => state.profile.view.id;
export const getProfilebyId = state => state.profile.byId;
export const getUserProfile = state => state.profile.user;
export const getProfilePagination = state => state.profile.view.pagination;
export const getProfilesPagination = state => state.profile.meta.pagination;
export const getProfileToDisplay = state => state.profile.view;

export const hasMoreProfile = createSelector(
  [getProfilesPagination],
  pagination => {
    const { totalPage, currentPage } = pagination;
    if (totalPage === currentPage) {
      return false;
    }
    return true;
  },
);

export const getProfileForConfigurePageHead = createSelector(
  [getProfileId, getProfilebyId],
  (profileId, profileById) => {
    const profile = profileById[profileId];
    if (!profile) return { name: '', slug: '', image: '' };
    const { name, slug, image } = profile;
    return { name, slug, image };
  },
);

export const getProfileLatestCredentials = createSelector(
  [getProfileId, getProfilebyId],
  (profileId, profileById) => {
    const profile = profileById[profileId];
    return getLatestCredentials(get(profile, 'credentials', []));
  },
);

export const getDefaultProfileFilter = createSelector(
  [getProfileToDisplay],
  profile => {
    if (profile) {
      const {
        opinionsCount,
        answersCount,
        questionsCount,
        reviewsCount,
      } = profile;

      if (opinionsCount > 0) return PROFILE_FILTER_TYPE.OPINIONS;
      if (questionsCount > 0) return POST_FILTER_TYPE.ASKED_BY;
      if (answersCount > 0) return POST_FILTER_TYPE.ANSWERED_BY;
      if (reviewsCount > 0) return PROFILE_FILTER_TYPE.REVIEWS;
    }

    if (profile.isOpinionWriter) {
      return PROFILE_FILTER_TYPE.OPINIONS;
    }
    return PROFILE_FILTER_TYPE.ASKED_BY;
  },
);

export const getIsOpinionWriter = state => state.profile.user.isOpinionWriter;

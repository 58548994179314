/* eslint-disable @typescript-eslint/indent */

import { ApiResponse } from 'apisauce';
import { ResponseData } from 'api/base';

export const VOTE_TYPE = {
  UNVOTE: 0,
  UPVOTE: 1,
  DOWNVOTE: 2,
};

export interface Pagination {
  per: number;
  currentPage: number;
  totalPage: number;
  totalCount: number;
}

export type CommentSortBy = 'updated_at' | 'cached_votes_score';
export type CommentSortDir = 'asc' | 'desc';

// This is what recorded on BE
// only use this type in API calls
export type RecordType =
  | 'questions'
  | 'opinions'
  | 'reviews'
  | 'articles'
  | 'question_comments'
  | 'opinion_comments'
  | 'review_comments'
  | 'article_comments';

// type of comment store on redux.
// eg, post comment will have CommentableType of Post in FE
export type CommentableType =
  | 'Post'
  | 'Opinion'
  | 'Review'
  | 'Article'
  | 'PostComment'
  | 'OpinionComment'
  | 'ReviewComment'
  | 'ArticleComment';

export const COMMENTABLE_TYPES: CommentableType[] = [
  'Post',
  'Opinion',
  'Review',
  'Article',
  'PostComment',
  'OpinionComment',
  'ReviewComment',
  'ArticleComment',
];

// Use to map before passing into API
// This is because on BE the record is still under legacy naming
export const CommentableTypeToRecordType: Record<
  CommentableType,
  RecordType
> = {
  Post: 'questions',
  Opinion: 'opinions',
  Review: 'reviews',
  Article: 'articles',
  PostComment: 'question_comments',
  OpinionComment: 'opinion_comments',
  ReviewComment: 'review_comments',
  ArticleComment: 'article_comments',
};

export const RecordTypeToCommentableType: Record<string, CommentableType> = {
  question: 'Post',
  opinion: 'Opinion',
  review: 'Review',
  article: 'Article',
  questionComment: 'PostComment',
  opinionComment: 'OpinionComment',
  reviewComment: 'ReviewComment',
  articleComment: 'ArticleComment',
};

export const CommentRelationMap: Record<CommentableType, CommentableType> = {
  Post: 'PostComment',
  Opinion: 'OpinionComment',
  Review: 'ReviewComment',
  Article: 'ArticleComment',
  PostComment: 'Post',
  OpinionComment: 'Opinion',
  ReviewComment: 'Review',
  ArticleComment: 'Article',
};

export const ParentCommentTypes = ['Post', 'Opinion', 'Review', 'Article'];

export interface Comment {
  id: number;
  // this is parents pagination
  bookmarked: boolean;
  businessAccount: any;
  businessAccountId: number;
  commentText: string;
  // this is the parent id
  commentableId: number;
  commentsCount: number;
  // child comments
  comments: Comment[];
  createdAt: string;
  createdByCurrentBusinessAccount: boolean;
  createdByCurrentUser: boolean;
  downvoted: boolean;
  downvotesCount: number;
  followedByCurrentUser: boolean;
  isAnonymous: boolean;
  isChildQuestion: boolean;
  picture: string;
  pictureOriginal: string;
  updatedAt: string;
  upvoted: boolean;
  upvotesCount: number;
  // to use for merge post/question
  question?: {
    id: number;
    slug: string;
    title: string;
  };
  article?: {
    id: number;
    slug: string;
    title: string;
  };
  opinion?: {
    id: number;
    slug: string;
    title: string;
  };
  review?: {
    id: number;
    categorySlug: string;
    productSlug: string;
  };
  // TODO: add in once we have user type
  user: any;
  // TODO: currently no reward type yet, will add in once we refactor reward
  reward: any;
}

export interface CommentPagination {
  ids: number[];
  pagination: Pagination;
}

export interface CommentParentEntity {
  title?: string;
  slug?: string;
  category?: {
    id: number;
    slug: string;
  };
  product?: any;
  // if is child comment will have follow entity:
  commentText?: string;
  link?: string;
}

export type CommentParentEntityClient = CommentParentEntity & {
  commentableType: CommentableType;
};
export type EntityCommentTuple = [CommentParentEntityClient, number];

// Eg Comments with ids 1, 2, 3 under Post with id 100 will look like
// byType {
//   Post: {
//     100 : {
//       ids: [1, 2, 3]
//       pagination: {
//           per: 1;
//           currentPage: 1;
//           totalPage: 1;
//           totalCount: 3;
//       }
//     }
//   }
// }
export interface CommentState {
  byType: Record<CommentableType, Record<number, CommentPagination>>;
  byId: Record<number, Comment>;
  api: {
    isFetching: boolean;
    isFetchingMoreComment: boolean;
  };
  userComments: {
    entityCommentTuples: EntityCommentTuple[];
    pagination: Pagination;
  };
}

// *******************
// REDUX ACTION PAYLOAD TYPES
// *******************

export interface CommentParentMeta {
  commentableType: CommentableType;
  commentableId: number;
}

// use to identify specific comment on FE
export type CommentPayload = CommentParentMeta & { commentId: number };

// newly created comment which dont have commentId yet
export type CommentsSuccessPayload = CommentParentMeta & {
  comments: Comment[];
  pagination: Pagination;
  isRefresh?: boolean;
};
export type CommentsRequestPayload = CommentParentMeta & {
  page: number;
  per?: number;
  pcid?: string | string[];
  rcid?: string | string[];
  sort?: {
    by: CommentSortBy;
    dir: CommentSortDir;
  };
  shuffleComment?: boolean;
  isRefresh?: boolean;
};
export type PostCommentRequestPayload = CommentParentMeta &
  PostBody & {
    onSuccess?: () => void;
  };
export type PostCommentSuccessPayload = CommentParentMeta & {
  comment: Comment;
};
export type UpdateCommentRequestPayload = CommentPayload &
  PostBody & {
    onSuccess?: () => void;
  };

export interface FeaturedCommentsRequestPayload {
  commentsByCommentable: {
    pagination: Pagination;
    commentableId: number;
    comments: Comment[];
  }[];
  commentableType: CommentableType;
  isRefresh?: boolean;
}

export interface UserCommentPayload {
  parentChildComment: {
    entity: CommentParentEntityClient;
    featuredComment: Comment;
  }[];
  pagination: Pagination;
  isRefresh?: boolean;
}

// *******************
// API PARAMS TYPES
// *******************

export interface CommentApiParentMeta {
  recordType: RecordType;
  commentableId: number;
}

export type CommentParentEntityServer = CommentParentEntity & {
  recordType: RecordType;
};

// use to identify specific comment on BE
export type CommentApiIdentifier = CommentApiParentMeta & { commentId: number };

// params for GET comment request
export interface GetCommentBody {
  per: number;
  page: number;
  pcid?: string | string[];
  rcid?: string | string[];
  sort?: {
    by: CommentSortBy;
    dir: CommentSortDir;
  };
}

// body params for POST & PUT comment request
export interface PostBody {
  comment: string;
  picture?: string;
  business_account_id?: number;
  // only use in PostComments
  is_anonymous?: boolean;
}

export type VoteType = '0' | '1' | '2';

// *******************
// API RESPONSE TYPES
// *******************

export type GetCommentApiResponse = ApiResponse<
  ResponseData<Comment[], { pagination: Pagination }>,
  ResponseData<null>
>;

export type GetCommentWithEntityApiResponse = ApiResponse<
  ResponseData<
    { entity: CommentParentEntityServer; featuredComment: Comment }[],
    { pagination: Pagination }
  >,
  ResponseData<null>
>;

export type PostCommentApiResponse = ApiResponse<
  ResponseData<Comment>,
  ResponseData<null>
>;

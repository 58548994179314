import { call, takeLatest, takeEvery, select, put } from 'redux-saga/effects';
import api from 'app/api/profileApi';
import searchApi from 'app/api/searchApi';
import ProfileAction, { ProfileTypes } from 'app/reducer/profileRedux';
import { SearchTypes } from 'app/reducer/searchRedux';
import { rewardsSlice } from 'app/entity/reward/rewardRedux';
import { handleResponse } from 'app/utils/sagaHelper';
import { isApiSuccess } from 'app/api/helper';
import getSessionStorage from 'utils/getSesssionStorage';
import get from 'lodash/get';
import noop from 'lodash/noop';
import curry from 'lodash/curry';
import ModalActions from '../reducer/modalRedux';

const searchFormatter = (meta, response) => {
  return {
    profiles: response.data.data,
    pagination: response.data.meta.pagination,
    ...meta,
  };
};

const profileFormatter = curry(searchFormatter);

function* updateUserProfile(action) {
  const { payload, meta } = action;

  const response = yield call(api.updateUserProfile, payload);
  const successAction = ProfileAction.updateUserProfileSuccess;
  meta.failureMsg = 'An error occured. Failed to post profile.';

  if (isApiSuccess(response)) {
    const slug = get(response, 'data.data.slug', null);
    if (slug && meta.callbacks.onSuccess === noop) {
      meta.successMsg = 'Profile posted.';
      meta.callbacks.onSuccess = () => {
        window.location.href = `/profile/${slug}`;
      };
      meta.callbacks.onFailure = noop;
    }
  }

  yield call(handleResponse, { successAction, meta, response });
}

function* getProfile(action) {
  const { payload, meta } = action;

  const response = yield call(api.getProfile, payload);
  const successAction = meta.isUserProfile
    ? ProfileAction.getUserProfileSuccess
    : ProfileAction.getProfileSuccess;
  yield call(handleResponse, { successAction, meta, response });
}

function* searchProfile(action) {
  const { payload, meta } = action;

  const response = yield call(searchApi.searchProfileWithTerm, payload);
  const successAction = ProfileAction.getProfilesSuccess;
  const format = profileFormatter(meta);
  yield call(handleResponse, { successAction, meta, response, format });
}

function* postCredential(action) {
  const { payload, meta } = action;

  const response = yield call(api.postCredential, payload);
  const successAction = ProfileAction.postCredentialSuccess;
  meta.successMsg = 'Credential posted.';
  meta.failureMsg = 'An error occured. Failed to post credential.';
  yield call(handleResponse, { successAction, meta, response });
}

function* updateCredential(action) {
  const { payload, meta } = action;

  const response = yield call(api.updateCredential, payload);
  const successAction = ProfileAction.updateCredentialSuccess;
  meta.successMsg = 'Credential updated.';
  meta.failureMsg = 'An error occured. Failed to update credential.';
  yield call(handleResponse, { successAction, meta, response });
}

function* deleteCredential(action) {
  const { payload, meta } = action;

  const response = yield call(api.deleteCredential, payload);
  const successAction = ProfileAction.deleteCredentialSuccess;
  meta.successMsg = 'Credential deleted.';
  meta.failureMsg = 'An error occured. Failed to delete credential.';
  const format = () => payload;
  yield call(handleResponse, { successAction, meta, response, format });
}

// after 2FA success
function* onRegisterMobileSuccess(action) {
  const dailyCheckIn = getSessionStorage('daily-checkin');
  // update user profile with latest update
  const creditsEarned = action.payload?.creditsEarned;
  const user = yield select(state => state.auth.user);
  const shareLink = yield select(state => state.reward.shareLink);
  const response = yield call(api.getProfile, { slug: user.slug });
  const successAction = ProfileAction.getUserProfileSuccess;
  yield call(handleResponse, { successAction, response });

  // open credit earn modal after
  if (creditsEarned && shareLink) {
    yield put(
      ModalActions.setCreditEarnModal(true, {
        shareLink,
        creditsEarned,
        maxMonthlyCreditReached: false,
      }),
    );
  }

  // open reward welcome modal
  yield put(ModalActions.setRewardWelcomeModal(true));

  if (dailyCheckIn === 'true') {
    yield put(rewardsSlice.actions.dailyCheckInRequest());
  }
}

export const profileSagas = [
  takeLatest(ProfileTypes.UPDATE_USER_PROFILE_REQUEST, updateUserProfile),
  takeEvery(ProfileTypes.GET_PROFILE_REQUEST, getProfile),
  takeLatest(ProfileTypes.POST_CREDENTIAL_REQUEST, postCredential),
  takeLatest(ProfileTypes.UPDATE_CREDENTIAL_REQUEST, updateCredential),
  takeLatest(ProfileTypes.DELETE_CREDENTIAL_REQUEST, deleteCredential),
  takeLatest(SearchTypes.SEARCH_PROFILES_REQUEST, searchProfile),
  takeLatest(ProfileTypes.ON_REGISTER_MOBILE_SUCCESS, onRegisterMobileSuccess),
];

import React from 'react';
import reactStringReplace from 'react-string-replace';

interface OwnProps {
  searchText: string;
  searchTerm: string;
}

const SearchText = (props: OwnProps) => {
  const { searchText, searchTerm } = props;

  let regex;
  try {
    regex = new RegExp(
      `(${searchTerm.replace(/(\[|]|{|}|\)|\()/g, '[^a-z]+')})`,
      'i',
    );
  } catch (e) {
    regex = '';
  }

  return (
    <p>
      {reactStringReplace(searchText, regex, (match, i) => (
        <strong key={i}>{match}</strong>
      ))}
    </p>
  );
};

export default SearchText;

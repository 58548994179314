import React, { useMemo, useContext } from 'react';
import { PathContext } from 'context';
import { addLinksToArticle } from 'entity/blog/blogUtils';
import { imageUrl } from 'theme';
import buildConfig from 'utils/buildConfig';
import Avatar from 'app/seedly-component-library/avatar-new';
import { SearchContext } from '.';
import { ISearchResult } from '../SearchResult';
import SearchText from '../SearchResult/SearchText';

// format link such that it routed back to seedly domain if is searched on blog
const formatLink = (paths, isBlog) => {
  return isBlog ? buildConfig.apiHost + paths : paths;
};

const useSearchResults = (): ISearchResult[] => {
  const { searchTerm, searchState } = useContext(SearchContext);
  const { host } = useContext(PathContext);
  const isBlog = host === 'blog';

  const combineList = useMemo(() => {
    const products: ISearchResult[] = searchState.products.map(
      (product, index) => {
        return {
          searchItem: (
            <div className="flex gap-4 items-center">
              <img
                className="h-[30px] w-9 object-cover"
                alt="logo"
                src={product.logo}
              />
              <div className="flex gap-2 items-center">
                <SearchText searchText={product.name} searchTerm={searchTerm} />
                {product.claimed && (
                  <img
                    alt="verified icon"
                    height="16px"
                    width="16px"
                    src={imageUrl.verifiedInverted}
                  />
                )}
              </div>
            </div>
          ),
          link: formatLink(
            `/reviews/${product.categorySlug}/${product.slug}`,
            isBlog,
          ),
          title: index === 0 && 'Product',
          // the pupose for adding type props is to save as recent search
          item: { ...product, type: 'products' },
        };
      },
    );

    const blogs: ISearchResult[] = searchState.blogs.map(blog => {
      const blogWithLink = addLinksToArticle(blog);

      // when is blog domain, route within blog domain as /blog-slug
      // when is seedly domain, route to blog using anchor link eg. http://blog.seedly.sg/blog-slug
      const link = isBlog
        ? blogWithLink.link.as
        : `${buildConfig.blogHost}${blogWithLink.link.as}`;
      return {
        searchItem: (
          <div className="flex gap-4 items-center">
            <img
              className="h-[54px] w-[75px] object-cover"
              alt="article"
              src={blogWithLink.imageUrl}
            />
            <SearchText
              searchText={blogWithLink.title}
              searchTerm={searchTerm}
            />
          </div>
        ),
        link,
        withBottomDivider: true,
        item: { ...blog, type: 'blogs' },
      };
    });

    const posts: ISearchResult[] = searchState.posts.map(post => {
      return {
        searchItem: (
          <SearchText searchText={post.title} searchTerm={searchTerm} />
        ),
        link: formatLink(`/posts/${post.slug}`, isBlog),
        withBottomDivider: true,
        item: { ...post, type: 'posts' },
      };
    });

    const opinions: ISearchResult[] = searchState.opinions.map(opinion => {
      return {
        searchItem: (
          <SearchText searchText={opinion.title} searchTerm={searchTerm} />
        ),
        link: formatLink(`/opinions/${opinion.slug}`, isBlog),
        withBottomDivider: true,
        item: { ...opinion, type: 'opinions' },
      };
    });

    const groups: ISearchResult[] = searchState.groups.map((group, index) => {
      return {
        searchItem: (
          <div className="flex gap-4 items-center">
            <Avatar src={group.profileImage} name={group.name} />
            <SearchText searchText={group.name} searchTerm={searchTerm} />
          </div>
        ),
        link: formatLink(`/community/${group.slug}`, isBlog),
        title: index === 0 && 'Groups',
        item: { ...group, type: 'groups' },
      };
    });

    const profiles: ISearchResult[] = searchState.profiles.map(
      (profile, index) => {
        return {
          searchItem: (
            <div className="flex gap-4 items-center">
              <Avatar src={profile.image} name={profile.name} />
              <SearchText searchText={profile.name} searchTerm={searchTerm} />
            </div>
          ),
          link: formatLink(`/profile/${profile.slug}`, isBlog),
          title: index === 0 && 'Profiles',
          item: { ...profile, type: 'profiles' },
        };
      },
    );

    // This order determine the search list order
    return [
      ...products,
      ...blogs,
      ...posts,
      ...opinions,
      ...groups,
      ...profiles,
    ];
  }, [searchState]);

  if (searchTerm) {
    const displayTerm =
      searchTerm.length > 30 ? `${searchTerm.substring(0, 27)}...` : searchTerm;

    const searchAll: ISearchResult = {
      searchItem: (
        <a className="hover:underline  cursor-pointer text-blue-500">
          <span>{`See all results for "${displayTerm}"`}</span>
        </a>
      ),
      link: `/search?s=${searchTerm}`,
    };
    return [searchAll, ...combineList];
  }
  return [...combineList];
};

export default useSearchResults;

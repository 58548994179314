import { call, takeLatest } from 'redux-saga/effects';

import api from 'app/api/draftApi';
import get from 'lodash/get';
import DraftAction, { DraftTypes } from 'app/reducer/draftRedux';

import { handleResponse } from 'app/utils/sagaHelper';

function* getOpinionDrafts(action) {
  const { payload, meta } = action;

  const response = yield call(api.getOpinionDrafts, payload);
  const successAction = DraftAction.getOpinionDraftsSuccess;
  yield call(handleResponse, { successAction, meta, response });
}

function* getOpinionDraft(action) {
  const { payload, meta } = action;

  const response = yield call(api.getOpinionDraft, payload);
  const successAction = DraftAction.getOpinionDraftSuccess;
  yield call(handleResponse, { successAction, meta, response });
}

function* saveOpinionDraft(action) {
  const { payload, meta } = action;

  const response = yield call(api.saveOpinionDraft, payload);
  const successAction = DraftAction.saveOpinionDraftSuccess;
  const failureAction = DraftAction.saveOpinionDraftFailure;
  meta.successMsg = 'Opinion draft saved.';
  meta.failureMsg =
    response.data?.message || 'An error occured. Failed to save opinion draft.';

  meta.profanityErrors = get(response.data, 'errors.profanity[0]', []);

  yield call(handleResponse, { successAction, failureAction, meta, response });
}

function* updateOpinionDraft(action) {
  const { payload, meta } = action;

  const response = yield call(api.updateOpinionDraft, payload);
  const successAction = DraftAction.updateOpinionDraftSuccess;
  const failureAction = DraftAction.updateOpinionDraftFailure;
  meta.successMsg = 'Opinion draft updated.';
  meta.failureMsg =
    response.data?.message ||
    'An error occured. Failed to update opinion draft.';
  yield call(handleResponse, { successAction, failureAction, meta, response });
}

function* deleteOpinionDraft(action) {
  const { payload, meta } = action;

  const response = yield call(api.deleteOpinionDraft, payload);
  const successAction = DraftAction.deleteOpinionDraftSuccess;
  meta.successMsg = 'Opinion draft deleted.';
  meta.failureMsg = 'An error occured. Failed to delete opinion draft.';
  const format = () => payload;
  yield call(handleResponse, { successAction, meta, response, format });
}

export const draftSagas = [
  takeLatest(DraftTypes.GET_OPINION_DRAFTS_REQUEST, getOpinionDrafts),
  takeLatest(DraftTypes.GET_OPINION_DRAFT_REQUEST, getOpinionDraft),
  takeLatest(DraftTypes.SAVE_OPINION_DRAFT_REQUEST, saveOpinionDraft),
  takeLatest(DraftTypes.UPDATE_OPINION_DRAFT_REQUEST, updateOpinionDraft),
  takeLatest(DraftTypes.DELETE_OPINION_DRAFT_REQUEST, deleteOpinionDraft),
];

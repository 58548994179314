import mixpanel from 'mixpanel-browser';
import Cookies from 'js-cookie';

import initSessionConfig from './config';

const MIXPANEL_SESSION_KEY = process.env.MIXPANEL_SESSION_KEY;

let distinctId;

mixpanel.init(MIXPANEL_SESSION_KEY, {
  ...initSessionConfig(mp => {
    distinctId = mp.get_distinct_id();
  }),
  debug: process.env.NEXT_PUBLIC_BUILD_ENV !== 'production',
});

export const trackedEvents = {
  PageView: 'Page View',
  SignUp: 'Sign Up',
  OpenLoginModal: 'Open Login Modal',
  Search: 'Search',
  Outbound: 'Outbound',
  Share: 'Share',
  Report: 'Report',
  ViewNotification: 'View Notification',
  DiscussionClicked: 'Discussion Clicked',
  CommentThread: 'Comment Thread',
  ShareBar: 'Share Bar',
  LoadMorePostClicked: 'Load More Post Clicked',
  TrendingDiscussionsClicked: 'Trending Discussions Clicked',
  RecommendedArticlesClicked: 'Recommended Articles Clicked',
  RecommendedProductReviewsClicked: 'Recommended Product Reviews Clicked',
  NavbarClicked: 'Navbar Clicked',
  NavbarDropdownClicked: 'Navbar Dropdown Clicked',
  SidebarClicked: 'Sidebar Clicked',
  ArticleSidebarClicked: 'Article Sidebar Clicked',
  MobileArticleCarousel: 'Mobile Article Carousel',
  SmartFeedDuration: 'On Smartfeed',
  FeedScrollFocused: 'Feed Scroll Focused',
  ReadMoreClicked: 'Read More Clicked',
  ReviewHome: 'Review Home',
  ReviewPage: 'Review Page',
  NotificationClicked: 'Notification Clicked',
  NotificationSetting: 'Notification Setting',
  SurveyForm: 'Survey Form',
  LoginSignUpModal: 'Login Sign Up Modal',
  RewardPrelaunchOnboarding: 'Rewards Pre Launch Onboarding',
  CommunityTopBarClicked: 'Community Top Bar Clicked',
  BlogCommentButtonClicked: 'Blog Comment Button Clicked',
  JoinGroupCarousel: 'Join Group Carousel',
  SignUpBonus: 'Sign Up Bonus',
  RewardsCollection: 'Rewards Collection',
  HomePage: 'Homepage',
  CommunityFeed: 'Community Feed',
};

const track = (name, props) => {
  const isNativeApp = Cookies.get('isNativeApp');
  mixpanel.track(name, { ...props, Platform: isNativeApp ? 'App' : 'Web' });
};

export const trackTrendingDiscussionsClick = ({ groupId, postId, param }) => {
  mixpanel.track(trackedEvents.TrendingDiscussionsClicked, {
    group_id: groupId,
    post_id: postId,
    param,
  });
};

export const trackRecommendedReviewsClick = ({
  productId,
  reviewId,
  param,
}) => {
  mixpanel.track(trackedEvents.RecommendedProductReviewsClicked, {
    product_id: productId,
    review_id: reviewId,
    param,
  });
};

export default {
  track,
  alias: (alias, original) => {
    if (distinctId) {
      mixpanel.alias(alias, original);
    }
  },
  identify: uniqueId => {
    if (distinctId) {
      mixpanel.identify(uniqueId);
    }
  },
  getDistinctId: () => distinctId,
};

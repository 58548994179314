import { call, takeLatest, put, select } from 'redux-saga/effects';
import isEmpty from 'lodash/isEmpty';
import { Message } from 'seedly-component-library';
import get from 'lodash/get';
import ProfileAction from 'reducer/profileRedux';
import { postsSlice } from 'entity/post/postRedux';
import { rewardsSlice } from 'entity/reward/rewardRedux';
import { PROFILE_FILTER_TYPE } from 'constants/profile';
import {
  generateArticleLink,
  generateCommentLink,
  generateOpinionLink,
  generateReviewLink,
} from 'utils/generateShareLink';
import { shuffle } from 'lodash';
import { commentsSlice } from './commentRedux';
import {
  CommentableTypeToRecordType,
  CommentsRequestPayload,
  CommentsSuccessPayload,
  PostCommentRequestPayload,
  PostCommentSuccessPayload,
  UpdateCommentRequestPayload,
  CommentPayload,
  VoteType,
  GetCommentApiResponse,
  PostCommentApiResponse,
  GetCommentWithEntityApiResponse,
  PostBody,
  GetCommentBody,
  RecordTypeToCommentableType,
  CommentableType,
  Comment,
  ParentCommentTypes,
} from './commentTypes';
import { constructRewardCreditPayload } from '../reward/rewardUtils';
import api from './commentApi';

const mapToGetApiParams = (payload: CommentsRequestPayload) => {
  return {
    commentableId: payload.commentableId,
    recordType: CommentableTypeToRecordType[payload.commentableType],
    commentParams: {
      per: payload.per || 10,
      page: payload.page,
      pcid: payload.pcid,
      rcid: payload.rcid,
      sort: {
        by: payload.sort?.by || 'updated_at',
        dir: payload.sort?.dir || 'desc',
      },
    },
  };
};

const makePostBody = (payload: PostCommentRequestPayload): PostBody => {
  const postBody: PostBody = { comment: payload.comment };

  // process picture by removing prefix
  const BASE64_PREFIX = 'base64,';
  if (payload.picture && payload.picture.indexOf(BASE64_PREFIX) !== -1) {
    const base64Index =
      payload.picture.indexOf(BASE64_PREFIX) + BASE64_PREFIX.length;
    postBody.picture = payload.picture.substring(base64Index);
  }

  if (payload.business_account_id) {
    postBody.business_account_id = payload.business_account_id;
  }

  if (payload.is_anonymous) {
    postBody.is_anonymous = payload.is_anonymous;
  }

  return postBody;
};

function* getComments(action: { payload: CommentsRequestPayload; meta: any }) {
  const { payload, meta } = action;
  const commentParams = mapToGetApiParams(payload);
  const response: GetCommentApiResponse = yield call(
    api.getComments,
    commentParams,
  );

  if (response.ok) {
    const comments = payload.shuffleComment
      ? shuffle(response.data.data)
      : response.data.data;
    const commentPayload: CommentsSuccessPayload = {
      comments,
      commentableType: payload.commentableType,
      commentableId: payload.commentableId,
      pagination: response.data.meta.pagination,
      isRefresh: payload.isRefresh || false,
    };
    const resolve = get(meta, 'resolve', () => {});
    yield call(resolve);
    yield put(commentsSlice.actions.getCommentsSuccess(commentPayload));
  } else {
    yield put(commentsSlice.actions.getCommentsFailure());
  }
}

function* getUserComments(action: {
  payload: GetCommentBody & { slug: string };
  type: string;
}) {
  const { payload, type } = action;
  let getApi;
  if (
    type === commentsSlice.actions.getUserBookmarkedParentCommentsRequest.type
  ) {
    getApi = api.getBookmarkedParentComments;
  }
  if (
    type === commentsSlice.actions.getUserBookmarkedChildCommentsRequest.type
  ) {
    getApi = api.getBookmarkedChildComments;
  }
  if (type === commentsSlice.actions.getUserParentCommentsRequest.type) {
    yield put(
      ProfileAction.updateProfileMeta({
        filter: PROFILE_FILTER_TYPE.ANSWERED_BY,
      }),
    );
    getApi = api.getUserParentComments;
  }
  if (type === commentsSlice.actions.getUserChildCommentsRequest.type) {
    yield put(
      ProfileAction.updateProfileMeta({
        filter: PROFILE_FILTER_TYPE.REPLIED_BY,
      }),
    );
    getApi = api.getUserChildComments;
  }
  if (!getApi) return;

  const response: GetCommentWithEntityApiResponse = yield call(getApi, payload);
  if (response.ok) {
    const commentPayload = response.data.data.map(item => {
      return {
        entity: {
          ...item.entity,
          commentableType: RecordTypeToCommentableType[item.entity.recordType],
        },
        featuredComment: item.featuredComment,
      };
    });
    const pagination = response.data.meta.pagination;
    const isRefresh = pagination && pagination.currentPage === 1;

    yield put(
      commentsSlice.actions.getUserCommentsSuccess({
        parentChildComment: commentPayload,
        pagination,
        isRefresh,
      }),
    );
  } else {
    yield put(commentsSlice.actions.getCommentsFailure());
  }
}

function* postCommentsSuccess(action: { payload: PostCommentSuccessPayload }) {
  const { payload } = action;
  const { comment } = payload;

  yield put(
    postsSlice.actions.followPostSuccess({
      postId: comment.commentableId,
      followed: true,
    }),
  );
}

function* postComment(action: { payload: PostCommentRequestPayload }) {
  const { payload } = action;
  const postBody = makePostBody(payload);
  const postParam = {
    commentableId: payload.commentableId,
    recordType: CommentableTypeToRecordType[payload.commentableType],
    postBody,
  };
  const response: PostCommentApiResponse = yield call(
    api.postComment,
    postParam,
  );

  if (payload.onSuccess) payload.onSuccess();

  if (response.ok) {
    const commentPayload: PostCommentSuccessPayload = {
      comment: response.data.data,
      commentableId: payload.commentableId,
      commentableType: payload.commentableType,
    };

    const getShareLinkByType = (
      commentType: CommentableType,
      comment: Comment,
    ) => {
      let shareLink = '';
      if (commentType === 'Post' || commentType === 'PostComment') {
        shareLink = generateCommentLink(comment.question.slug);
      }
      if (commentType === 'Article' || commentType === 'ArticleComment') {
        shareLink = generateArticleLink(comment.article.slug);
      }
      if (commentType === 'Opinion' || commentType === 'OpinionComment') {
        shareLink = generateOpinionLink(comment.opinion.slug);
      }
      if (commentType === 'Review' || commentType === 'ReviewComment') {
        shareLink = generateReviewLink(
          comment.review.categorySlug,
          comment.review.productSlug,
          comment.review.id,
        );
      }
      const shareLinkAppend = ParentCommentTypes.includes(commentType)
        ? `&pcid=${comment.id}`
        : `&rcid=${comment.id}`;

      return shareLink + shareLinkAppend;
    };

    const shareLink = getShareLinkByType(
      payload.commentableType,
      response.data.data,
    );
    const successPayload = constructRewardCreditPayload(response, shareLink);
    yield put(rewardsSlice.actions.handleEarnCredit(successPayload));
    yield put(commentsSlice.actions.postCommentSuccess(commentPayload));

    Message.success(response.data.message);
  } else {
    Message.error(response.data.message);
  }
}

function* updateComment(action: { payload: UpdateCommentRequestPayload }) {
  const { payload } = action;
  const postBody = makePostBody(payload);
  // to remove picture in for BE
  if (payload.picture === null) {
    postBody.picture = '';
  }
  const postParam = {
    commentId: payload.commentId,
    commentableId: payload.commentableId,
    recordType: CommentableTypeToRecordType[payload.commentableType],
    postBody,
  };
  const response: PostCommentApiResponse = yield call(
    api.updateComment,
    postParam,
  );

  if (payload.onSuccess) payload.onSuccess();

  if (response.ok) {
    const commentPayload: PostCommentSuccessPayload = {
      comment: response.data.data,
      commentableId: payload.commentableId,
      commentableType: payload.commentableType,
    };
    yield put(commentsSlice.actions.updateCommentSuccess(commentPayload));
    Message.success(response.data.message);
  } else {
    Message.error(response.data.message);
  }
}

function* deleteComment(action: { payload: CommentPayload }) {
  const { payload } = action;
  const deleteCommentParam = {
    commentId: payload.commentId,
    commentableId: payload.commentableId,
    recordType: CommentableTypeToRecordType[payload.commentableType],
  };
  const response = yield call(api.deleteComment, deleteCommentParam);
  if (response.ok) {
    yield put(commentsSlice.actions.deleteCommentSuccess(payload));
    Message.success(response.data.message);
  } else {
    Message.error(response.data.message);
  }
}

function* voteComment(action: {
  payload: CommentPayload & { voteType: VoteType };
}) {
  const { payload } = action;
  const voteCommentParam = {
    commentId: payload.commentId,
    commentableId: payload.commentableId,
    recordType: CommentableTypeToRecordType[payload.commentableType],
    vote_type: payload.voteType,
  };
  const response = yield call(api.voteComment, voteCommentParam);
  if (response.ok) {
    const comment = response.data.data;
    Message.success(response.data.message);
    yield put(commentsSlice.actions.voteCommentSuccess({ comment }));
  } else {
    Message.error(response.data.message);
  }
}

function* bookmarkComment(action: { payload: CommentPayload }) {
  const { payload } = action;
  const bookmarkCommentParam = {
    commentId: payload.commentId,
    commentableId: payload.commentableId,
    recordType: CommentableTypeToRecordType[payload.commentableType],
  };
  const response: PostCommentApiResponse = yield call(
    api.bookmarkComment,
    bookmarkCommentParam,
  );
  if (response.ok) {
    const comment = response.data.data;
    Message.success(response.data.message);
    yield put(commentsSlice.actions.bookmarkCommentSuccess({ comment }));
  } else {
    Message.error(response.data.message);
  }
}

function* getCommentsSuccess() {
  const isSignedIn = yield select(state => state.auth.session.isSignedIn);
  if (
    typeof window !== 'undefined' &&
    !isEmpty(JSON.parse(sessionStorage.getItem('save-comment'))) &&
    isSignedIn
  ) {
    yield put(
      commentsSlice.actions.bookmarkCommentRequest(
        JSON.parse(sessionStorage.getItem('save-comment')),
      ),
    );
    sessionStorage.clear();
  }
}

export const commentSagas = [
  takeLatest(commentsSlice.actions.getCommentsRequest, getComments),
  takeLatest(
    commentsSlice.actions.getUserParentCommentsRequest,
    getUserComments,
  ),
  takeLatest(
    commentsSlice.actions.getUserChildCommentsRequest,
    getUserComments,
  ),
  takeLatest(
    commentsSlice.actions.getUserBookmarkedParentCommentsRequest,
    getUserComments,
  ),
  takeLatest(
    commentsSlice.actions.getUserBookmarkedChildCommentsRequest,
    getUserComments,
  ),
  takeLatest(commentsSlice.actions.getCommentsSuccess, getCommentsSuccess),
  takeLatest(commentsSlice.actions.postCommentRequest, postComment),
  takeLatest(commentsSlice.actions.postCommentSuccess, postCommentsSuccess),
  takeLatest(commentsSlice.actions.updateCommentRequest, updateComment),
  takeLatest(commentsSlice.actions.deleteCommentRequest, deleteComment),
  takeLatest(commentsSlice.actions.voteCommentRequest, voteComment),
  takeLatest(commentsSlice.actions.bookmarkCommentRequest, bookmarkComment),
];

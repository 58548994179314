import api from './base';

const getOpinionDrafts = (params = {}) => {
  const { id } = params;
  return api.get(`v4/users/${id}/drafts`, params);
};

const getOpinionDraft = (params = {}) => {
  const { slug } = params;
  const published = false;
  return api.get(`v4/opinions/${slug}`, { published, ...params });
};

const saveOpinionDraft = (params = {}) => {
  const published = false;
  return api.post('v4/opinions', { published, ...params });
};

const updateOpinionDraft = (params = {}) => {
  const { id } = params;
  const published = false;
  return api.put(`v4/opinions/${id}`, { published, ...params });
};

const deleteOpinionDraft = (params = {}) => {
  const { id } = params;
  const published = false;
  return api.delete(`v4/opinions/${id}`, { published, ...params });
};

export default {
  getOpinionDrafts,
  getOpinionDraft,
  saveOpinionDraft,
  updateOpinionDraft,
  deleteOpinionDraft,
};

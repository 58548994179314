import { call, put } from 'redux-saga/effects';
import api from '../api/searchApi';
import SearchAction from '../reducer/searchRedux';
import { isApiSuccess } from '../api/helper';

export function* getSearch(action) {
  const { payload } = action;
  const response = yield call(api.searchWithTerm, payload);
  if (isApiSuccess(response)) {
    const searchResult = response.data.data;
    yield put(SearchAction.searchSuccess(searchResult));
  }
}

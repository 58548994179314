import React from 'react';
import styled from 'styled-components';
import {
  XCircle as CloseCircleIcon,
  CheckCircle as CheckCircleIcon,
} from 'react-feather';
import { Colors } from 'app/seedly-component-library';
import Notification from './Notification';

let messageInstance = null;
let key = 1;

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 16px;
`;

const getMessageInstance = callback => {
  if (messageInstance) {
    callback(messageInstance);
    return;
  }

  Notification.newInstance({}, instance => {
    if (messageInstance) {
      callback(messageInstance);
      return;
    }
    messageInstance = instance;
    callback(messageInstance);
  });
};

const notice = (content, duration, type) => {
  const msgTypes = {
    success: { element: CheckCircleIcon, background: Colors.green4 },
    error: { element: CloseCircleIcon, background: Colors.red4 },
  };

  const msgConfig = msgTypes[type];
  const { element: Icon, background } = msgConfig;

  const target = key;
  key += 1;
  getMessageInstance(instance => {
    instance.notice({
      key: target,
      duration,
      content: (
        <Container style={{ background }}>
          <Icon style={{ marginRight: '10px', color: 'white' }} />
          <span>{content}</span>
        </Container>
      ),
    });

    return () => {
      if (messageInstance) {
        messageInstance.removeNotice(target);
      }
    };
  });
};

export default {
  success: (content, duration) => {
    return notice(content, duration, 'success');
  },
  error: (content, duration) => {
    return notice(content, duration, 'error');
  },
  destroy: () => {
    if (messageInstance) {
      messageInstance.destroy();
      messageInstance = null;
    }
  },
};

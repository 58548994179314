import { call, put, select, takeLatest, debounce } from 'redux-saga/effects';
import api from 'app/api/productApi';
import searchApi from 'app/api/searchApi';
import prismicApi from 'app/api/prismicApi';
import ApiError from 'app/api/error';
import get from 'lodash/get';
import curry from 'lodash/curry';
import noop from 'lodash/noop';
import { SearchTypes } from 'app/reducer/searchRedux';
import ProductAction, { ProductTypes } from 'app/reducer/productRedux';
import { getFilterParams, getProductMeta } from 'app/selectors/productSelector';
import { postsSlice } from 'entity/post/postRedux';
import { handleResponse } from 'app/utils/sagaHelper';
import { makeProductParams } from 'app/dispatcher/productDispatcher';
import Router from 'next/router';
import slugify from 'slugify';
import { CREDIT_CARD_FILTER } from 'app/constants/product';

const formatter = (meta, response) => {
  return {
    ...meta,
    products: response.data.data,
    pagination: response.data.meta.pagination,
  };
};

const productsformatter = (meta, response) => {
  return {
    ...meta,
    products: response.data.data,
    pagination: response.data.meta.pagination,
    categorySlug: response.data.meta.categorySlug,
  };
};

const productFormatter = (meta, response) => {
  const { data } = response;
  return {
    product: data.data,
    meta: data.meta,
  };
};

const planFormatter = (meta, response) => {
  const { data } = response;

  return {
    plan: data.data,
    meta: data.meta,
  };
};

function* getProductCategories(action) {
  const { payload, meta } = action;

  const response = yield call(api.getProductCategories, payload);
  const successAction = ProductAction.getProductCategoriesSuccess;
  const failureAction = ProductAction.getProductCategoriesFailure;
  yield call(handleResponse, { successAction, failureAction, meta, response });
}

function* getProducts(action) {
  const { payload, meta } = action;

  const response = yield call(api.getProducts, payload);
  const successAction = ProductAction.getProductsSuccess;
  const failureAction = ProductAction.getProductsFailure;
  const format = curry(productsformatter)(meta);
  yield call(handleResponse, {
    successAction,
    failureAction,
    meta,
    response,
    format,
  });
}

function* searchProducts(action) {
  const { payload, meta } = action;

  const response = yield call(searchApi.searchProductWithTerm, payload);
  const successAction = ProductAction.getProductsSuccess;
  const failureAction = ProductAction.getProductsFailure;
  const format = curry(formatter)(meta);
  yield call(handleResponse, {
    successAction,
    failureAction,
    meta,
    response,
    format,
  });
}

function* getProduct(action) {
  const { payload, meta } = action;

  const response = yield call(api.getProduct, payload);
  const successAction = ProductAction.getProductSuccess;
  const failureAction = ProductAction.getProductFailure;
  const format = curry(productFormatter)(meta);
  yield call(handleResponse, {
    successAction,
    failureAction,
    meta,
    response,
    format,
  });
}

function* getPlan(action) {
  const { payload, meta } = action;

  const response = yield call(api.getPlan, payload);
  const successAction = ProductAction.getPlanSuccess;
  const failureAction = ProductAction.getPlanFailure;
  const format = curry(planFormatter)(meta);
  yield call(handleResponse, {
    successAction,
    failureAction,
    meta,
    response,
    format,
  });
}

function* getSavingsAccounts(action) {
  const { meta } = action;

  const response = yield call(api.getSavingsAccounts, null);
  const successAction = ProductAction.getProductsSuccess;
  const failureAction = ProductAction.getProductsFailure;
  const format = curry(formatter)(meta);
  yield call(handleResponse, {
    successAction,
    failureAction,
    meta,
    response,
    format,
  });
}

function* getProductsWithFilter() {
  const productFilter = yield select(getFilterParams);
  const productMeta = yield select(getProductMeta);

  const meta = { ...productMeta, productFilter };
  const params = makeProductParams({ ...meta });

  if (productMeta.categorySlug === 'credit-cards') {
    const cardTypes = get(productFilter, CREDIT_CARD_FILTER.CARD_TYPES, []);
    const bankProviders = get(
      productFilter,
      CREDIT_CARD_FILTER.BANK_PROVIDERS,
      [],
    );

    let filter;
    const query = {};
    query.categorySlug = 'credit-cards';
    if (cardTypes.length === 1 && bankProviders.length === 0) {
      query.cardTypes = cardTypes[0];
      filter = cardTypes[0];
    }
    if (cardTypes.length === 0 && bankProviders.length === 1) {
      query.bankProviders = bankProviders[0];
      filter = bankProviders[0];
    }
    if (cardTypes.length === 1 && bankProviders.length === 1) {
      query.bankProviders = bankProviders[0];
      query.cardTypes = cardTypes[0];
      filter = bankProviders[0];
    }
    if (filter) {
      const filterSlug = slugify(filter, { lower: true });
      const href = `/reviews/credit-cards/${filterSlug}`;

      yield call(() =>
        Router.push(
          {
            pathname: '/reviews',
            query,
          },
          href,
        ),
      );
    } else {
      const response = yield call(api.getProducts, params);
      const successAction = ProductAction.getProductsSuccess;
      const failureAction = ProductAction.getProductsFailure;
      const format = curry(formatter)(meta);
      const as = `/reviews/credit-cards`;

      yield call(handleResponse, {
        successAction,
        failureAction,
        meta,
        response,
        format,
      });
      yield call(() =>
        Router.push(
          {
            pathname: '/reviews',
            query: { categorySlug: 'credit-cards' },
          },
          as,
          { shallow: true },
        ),
      );
    }
  }
}

function* getTopQuestionReview(action) {
  const { payload, meta } = action;

  const response = yield call(api.getTopQuestionReview, payload);
  const successAction = ProductAction.getTopQuestionReviewSuccess;
  const failureAction = ProductAction.getTopQuestionReviewFailure;

  const format = responseParam => {
    return {
      review: responseParam.data.data.review,
      question: responseParam.data.data.question,
      itemId: responseParam.data.meta.itemId,
    };
  };

  yield call(handleResponse, {
    successAction,
    failureAction,
    meta,
    response,
    format,
  });
}

function* getTopQuestionReviewSuccess(action) {
  const { question } = action.payload;
  if (question) {
    const questionPayload = {
      posts: [question],
    };
    yield put(postsSlice.actions.getPostsSuccess(questionPayload));
  }
}

// Prismic API Section

function* getProductCategoryPrismic(action) {
  const { payload, meta = {} } = action;
  const resolve = get(meta, 'resolve', noop);
  const reject = get(meta, 'reject', noop);

  const url = meta.isSignUpBonus
    ? 'my.seo_story_sign_up_bonus_product_category.uid'
    : 'my.seo_story_product_category.uid';

  const response = yield call(() => prismicApi(url, payload));
  if (response) {
    const results = get(response, 'results[0].data', {});
    yield put(ProductAction.getCategoryPrismicSuccess(results));
    yield call(resolve);
  } else {
    const apiError = new ApiError(response);
    yield call(() => reject(apiError));
  }
}

function* getPlanFaqPrismic(action) {
  const { payload, meta } = action;
  const resolve = get(meta, 'resolve', noop);
  const reject = get(meta, 'reject', noop);
  const response = yield call(() => prismicApi('my.plan_faq.uid', payload));

  if (response) {
    const results = get(response, 'results[0].data', {});
    yield put(ProductAction.getPlanFaqPrismicSuccess(results));
    yield call(resolve);
  } else {
    const apiError = new ApiError(response);
    yield call(() => reject(apiError));
  }
}

export const productSagas = [
  takeLatest(ProductTypes.GET_PRODUCT_CATEGORIES_REQUEST, getProductCategories),
  takeLatest(ProductTypes.GET_PRODUCTS_REQUEST, getProducts),
  debounce(800, ProductTypes.GET_PRODUCTS_WITH_TERM_REQUEST, getProducts),
  takeLatest(ProductTypes.GET_PRODUCT_REQUEST, getProduct),
  takeLatest(
    ProductTypes.GET_CATEGORY_PRISMIC_REQUEST,
    getProductCategoryPrismic,
  ),
  takeLatest(ProductTypes.GET_SAVINGS_ACCOUNTS_REQUEST, getSavingsAccounts),
  takeLatest(
    ProductTypes.GET_TOP_QUESTION_REVIEW_REQUEST,
    getTopQuestionReview,
  ),
  takeLatest(ProductTypes.SET_PRODUCT_FILTER_PARAMS, getProductsWithFilter),
  takeLatest(
    ProductTypes.GET_TOP_QUESTION_REVIEW_SUCCESS,
    getTopQuestionReviewSuccess,
  ),
  takeLatest(ProductTypes.GET_PLAN_REQUEST, getPlan),
  takeLatest(ProductTypes.GET_PLAN_FAQ_PRISMIC_REQUEST, getPlanFaqPrismic),
  takeLatest(SearchTypes.SEARCH_PRODUCTS_REQUEST, searchProducts),
];

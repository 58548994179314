import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface NavigationCampaign {
  id: number;
  title: string;
  category: string;
  link: string;
  isFeatured: boolean;
}

interface NavigationState {
  campaigns: {
    ids: number[];
    byId: Record<number, NavigationCampaign>;
    api: {
      isFetching: boolean;
      isFetchError: boolean;
    };
  };
}

const initialState: NavigationState = {
  campaigns: {
    ids: [],
    byId: {},
    api: {
      isFetching: false,
      isFetchError: false,
    },
  },
};

export const navigationSlice = createSlice({
  name: 'navigation',
  initialState,
  reducers: {
    getNavCampaignsRequest(state) {
      state.campaigns.api.isFetching = true;
    },
    getNavCampaignsSuccess(state, action: PayloadAction<NavigationCampaign[]>) {
      state.campaigns.ids = [];
      action.payload.forEach(campaign => {
        state.campaigns.ids.push(campaign.id);
        state.campaigns.byId[campaign.id] = campaign;
      });
      state.campaigns.api.isFetching = true;
      state.campaigns.api.isFetchError = true;
    },
    getNavCampaignsFailure(state) {
      state.campaigns.api.isFetching = false;
      state.campaigns.api.isFetchError = true;
    },
  },
});

// only use for dispatching purpose
export const {
  getNavCampaignsRequest,
  getNavCampaignsSuccess,
  getNavCampaignsFailure,
} = navigationSlice.actions;

export default navigationSlice.reducer;

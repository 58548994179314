import styled, { createGlobalStyle } from 'styled-components';
import { styles, media } from 'theme';

export const PageBody = styled.div`
  background-color: ${props => props.theme.colors.neutral2};
  font-family: Open Sans;
  display: flex;
  min-height: 100vh;
  width: 100%;
  flex-direction: column;

  ${media.largeAndDown`
    overflow-y: ${props => (props.scrollable ? 'scroll' : 'hidden')};
    position: ${props => (props.scrollable ? 'relative' : 'fixed')};
  `};
`;

export const PageContent = styled.div`
  margin-top: ${({ hideMarginTop }) =>
    hideMarginTop ? '0' : styles.headerHeight};
  padding-bottom: ${({ hidePaddingBottom, theme }) =>
    hidePaddingBottom ? '0' : theme.spacing.lg};
  display: flex;
  justify-content: center;
  flex: 1;
`;

export const VerifyEmailCard = styled.div`
  width: 100%;
  height: auto;
  background-color: ${props => props.theme.colors.red5};
  padding: ${props => props.theme.spacing.md};
  position: absolute;
  top: 63px;
  cursor: pointer;
  z-index: 2;
`;

export const ProgressBarStyle = createGlobalStyle`
/* loading progress bar styles */
#nprogress {
  pointer-events: none;
}

#nprogress .bar {
  background: #2dc1f6;
  position: fixed;
  z-index: 1031;
  top: 0;
  left: 0;
  width: 100%;
  height: 2px;
}

#nprogress .peg {
  display: block;
  position: absolute;
  right: 0px;
  width: 100px;
  height: 100%;
  box-shadow: 0 0 10px #2dc1f6, 0 0 5px #2dc1f6;
  opacity: 1.0;
  transform: rotate(3deg) translate(0px, -4px);
}
`;

/* eslint-disable @typescript-eslint/indent */
import { ApiResponse } from 'apisauce';
import { ResponseData } from 'api/base';
import { Colors } from 'seedly-component-library';
import { Pagination } from '../comment/commentTypes';

export interface RewardClaim {
  content: string;
  created_at: string;
  id: number;
  reward_id: number;
  reward_type: string;
  updated_at: string;
  used_at: string;
  pin?: string;
}

export interface RewardVoucher {
  claimId: number;
  content: string;
  createdAt: string;
  expiresAt: string;
  id: number;
  rewardId: number;
  rewardType: string;
  signature: string;
  status: string;
  pin?: string;
}
export interface Reward {
  banner: string;
  category: string;
  ctaCopy: string;
  ctaLink: string;
  ctaPromoCode: string;
  description: string;
  endedAt: string;
  isFullyRedeemed: boolean;
  id: number;
  instructions: string;
  isActive: boolean;
  isFeatured: boolean;
  minCredits: number;
  minLevel: number;
  minTier: number;
  rewardType: string;
  rewardsCriteriaDescription: string;
  startedAt: string;
  termsAndConditions: string;
  title: string;
  claims: RewardClaim[];
}

export interface RewardState {
  ids: number[];
  levelUpRewardId: number;
  claimedReward: {
    reward: Reward;
    claimId: number;
    rewardId: number;
  };
  userRewardIds: number[];
  shareLink: string;
  byId: Record<number, Reward>;
  pagination: Pagination;
  api: {
    isFetching: boolean;
    isClaiming: boolean;
  };
}

export type GetRewardApiResponse = ApiResponse<
  ResponseData<Reward[]>,
  ResponseData<null>
>;

export type ClaimRewardApiResponse = ApiResponse<
  ResponseData<Reward>,
  ResponseData<null>
>;

export type GetRewardParams = {
  page: number;
  per?: number;
  sort_by_featured?: boolean;
  sort_by_recency?: boolean;
  sort_by_credits?: string;
  redeemable_only?: boolean;
  tier?: number[];
  category?: string;
  expired?: boolean;
};

export type CreditHistory = {
  amount: number;
  createdAt: string;
  description: string;
  id: number;
  // this is reward title
  subdescription?: string;
};

export const TierMap = {
  0: 'Tier 0',
  1: 'Millionaire',
  2: 'Billionaire',
  3: 'Trillionaire',
};

export const TierColorMap = {
  0: Colors.purple2,
  1: Colors.purple2,
  2: Colors.orange1,
  3: Colors.seedlyBlue,
};

export type RewardSort = 'featured' | 'latest' | 'creditAsc' | 'creditDesc';

export type RewardFilter = 'browse-reward' | 'my-reward';

export type TagType =
  | 'featured'
  | 'redeemed'
  | 'fully_redeemed'
  | 'used'
  | 'expired'
  | 'lock';

export type RewardBenefit = {
  description: string;
  displayOrder: number;
  id: number;
  image: string;
  title: string;
};

export type RewardBenefitByTier = {
  billionaire: RewardBenefit[];
  millionaire: RewardBenefit[];
  trillionaire: RewardBenefit[];
};

// hardcoded cycle here until further refactor to put on backend
export const RewardCycleDate = [
  [new Date(2022, 7, 15), new Date(2023, 0, 31)],
  [new Date(2023, 1, 1), new Date(2023, 6, 31)],
  [new Date(2023, 7, 1), new Date(2024, 0, 31)],
  [new Date(2024, 1, 1), new Date(2024, 6, 31)],
];

export const RewardSeedRequirement = {
  1: 0,
  2: 1201,
  3: 5001,
};

// get current reward cycle
export const getCurrentCycle = () => {
  const currDate = new Date();
  let currCycleDate = RewardCycleDate[0];

  RewardCycleDate.forEach(date => {
    if (currDate >= date[0] && currDate <= date[1]) {
      currCycleDate = date;
    }
  });

  return currCycleDate;
};

// get next reward cycle
export const getNextCycle = () => {
  const currDate = new Date();
  let cycleIndex = 0;

  RewardCycleDate.forEach((date, index) => {
    if (currDate >= date[0] && currDate <= date[1]) {
      cycleIndex = index + 1;
    }
  });

  // use the last reward as failback
  if (cycleIndex > RewardCycleDate.length - 1) {
    cycleIndex = RewardCycleDate.length - 1;
  }

  return RewardCycleDate[cycleIndex];
};

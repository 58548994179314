import { all, takeLatest } from 'redux-saga/effects';

// Sagas
import { SearchTypes } from 'app/reducer/searchRedux';
import { ReportTypes } from 'app/reducer/reportRedux';
import { authSagas } from '../entity/auth/authSaga';
import { reviewSagas } from './reviewSaga';
import { productSagas } from './productSaga';
import { groupSagas } from '../entity/group/groupSaga';
import { feedSagas } from '../entity/feed/feedSaga';
import { postSagas } from '../entity/post/postSaga';
import { commentSagas } from '../entity/comment/commentSaga';
import { navigationSagas } from '../entity/navigation/navigationSaga';
import { rewardSagas } from '../entity/reward/rewardSaga';
import { opinionSagas } from './opinionSaga';
import { draftSagas } from './draftSaga';
import { setShareForm } from './shareSaga';
import { getSearch } from './searchSaga';
import { profileSagas } from './profileSaga';
import { postReport } from './reportSaga';

// Redux
import { ModalTypes } from '../reducer/modalRedux';

export default function* rootSaga() {
  yield all([
    ...authSagas,
    ...commentSagas,
    ...groupSagas,
    ...navigationSagas,
    ...productSagas,
    ...profileSagas,
    ...reviewSagas,
    ...feedSagas,
    ...postSagas,
    ...opinionSagas,
    ...draftSagas,
    ...rewardSagas,
    takeLatest(ModalTypes.SET_SHARE_MODAL, setShareForm),
    takeLatest(ModalTypes.SET_LEVEL_MODAL, setShareForm),
    takeLatest(SearchTypes.SEARCH_REQUEST, getSearch),
    takeLatest(ReportTypes.POST_REPORT_REQUEST, postReport),
  ]);
}

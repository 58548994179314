import { call, put, take, takeLatest } from 'redux-saga/effects';
import AuthAction, { AuthTypes } from 'app/entity/auth/authRedux';
import get from 'lodash/get';
import noop from 'lodash/noop';
import Cookies from 'js-cookie';
import { Message } from 'app/seedly-component-library';
import mixpanel, { trackedEvents } from 'app/utils/mixpanel';
import buildConfig from 'app/utils/buildConfig';
import getSessionStorage from 'utils/getSesssionStorage';
import { sessionIdentifier } from 'app/constants/auth';
import { postsSlice } from 'entity/post/postRedux';
import OpinionActions, { OpinionTypes } from 'app/reducer/opinionRedux';
import { commentsSlice } from 'entity/comment/commentRedux';

import authApi from './authApi';
import baseApi from '../../api/base';

const getErrorMessage = response => get(response, 'data.errors[0]');
const getUpdateErrorMessage = response => {
  const errors = get(response, 'data.errors');
  if (!errors) return;
  const errorList = Object.values(errors);
  return errorList[0];
};

function signOut({ reload = true }) {
  baseApi.setHeaders({});
  Cookies.remove(sessionIdentifier, {
    domain: buildConfig.domain,
  });

  if (reload) {
    window.location.reload();
  }
}

function* authUserToken(action) {
  const { payload, meta } = action;
  const headers = payload.headers;
  const resolve = get(meta, 'resolve', noop);
  const response = yield call(authApi.authUserToken, { headers });

  if (response.data && response.data.success) {
    baseApi.setHeaders({
      'access-token': response.headers['access-token'],
      client: response.headers.client,
      uid: response.headers.uid,
    });

    const tokenResponse = response.data.data;
    const filteredResponse = {
      id: tokenResponse.id,
      name: tokenResponse.name,
      slug: tokenResponse.slug,
      image: tokenResponse.image,
      banned: tokenResponse.banned,
      ...(tokenResponse.businessAccounts && {
        businessAccounts: tokenResponse.businessAccounts,
      }),
      ...(tokenResponse.ban && { ban: tokenResponse.ban }),
      confirmedAt: tokenResponse.confirmed_at,
      createdAt: tokenResponse.created_at,
    };
    const { confirmedAt, createdAt } = filteredResponse;

    const createdAtDate = new Date(createdAt);
    const currentDate = new Date();

    const timeDiff = Math.abs(createdAtDate.getTime() - currentDate.getTime());
    const diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));

    if (!confirmedAt && diffDays > 3) {
      yield put(AuthAction.setUnverifiedEmail(tokenResponse.email));
      yield call(resolve, null);
    } else {
      yield put(AuthAction.authUserTokenSuccess(filteredResponse));
      yield put(AuthAction.setSessionStorageVote());
      yield call(resolve, filteredResponse);
    }
  } else {
    yield call(signOut, { reload: false });
  }
}

function* signIn(action) {
  const response = yield call(authApi.signIn, action.payload);

  if (response.ok) {
    const headers = response.headers;

    const authHeaders = {
      'access-token': headers['access-token'],
      uid: headers.uid,
      client: headers.client,
    };

    Cookies.set(sessionIdentifier, JSON.stringify(authHeaders), {
      domain: buildConfig.domain,
      secure: process.env.NEXT_PUBLIC_BUILD_ENV !== 'development',
      expires: 30,
    });
    window.location.reload();
  } else {
    const errorMessage = get(response, 'data.errors[0]', '');
    yield put(AuthAction.signInFailure({ errorMessage }));
  }
}

function* signUp(action) {
  const response = yield call(authApi.signUp, action.payload);

  if (response.ok) {
    const { email, password } = action.payload;
    sessionStorage.setItem('user-signedup', 'true');
    mixpanel.alias(`${response.data.data.id}`, mixpanel.getDistinctId());
    mixpanel.track(trackedEvents.SignUp);

    yield call(signIn, { payload: { email, password } });
  } else {
    const errorMessage = getErrorMessage(response);
    Message.error(errorMessage);
    yield put(AuthAction.signUpFailure({ errorMessage }));
  }
}

function* forgetPassword(action) {
  const { onFailure = () => {}, onComplete = () => {} } = action.meta;
  const response = yield call(authApi.forgetPassword, action.payload);
  if (response.ok) {
    Message.success('Reset password email sent');
  } else if (response.status === 404) {
    onFailure();
  } else {
    Message.error('Oops, something went wrong');
  }
  onComplete();
}

function* updateEmail(action) {
  const { email, password } = action.payload;

  const response = yield call(authApi.updateUser, {
    email,
    current_password: password,
  });
  if (response.ok) {
    sessionStorage.setItem('showEmailUpdateSuccess', true);
    window.location.reload();
  } else {
    const onFailure = get(action, 'meta.onFailure', noop);
    onFailure();
    const errorMessage = getUpdateErrorMessage(response);
    if (errorMessage) Message.error(errorMessage);
  }
}

function* updatePassword(action) {
  const { newPassword, currentPassword, confirmPassword } = action.payload;

  const response = yield call(authApi.updateUser, {
    current_password: currentPassword,
    password: newPassword,
    password_confirmation: confirmPassword,
  });

  if (response.ok) {
    sessionStorage.setItem('showPasswordUpdateSuccess', true);
    window.location.reload();
  } else {
    const onFailure = get(action, 'meta.onFailure', noop);
    onFailure();
    const errorMessage = getUpdateErrorMessage(response);
    if (errorMessage) Message.error(errorMessage);
  }
}

function* sessionStorageVote() {
  const savedVote = getSessionStorage('new-vote');

  if (savedVote) {
    const parseVote = JSON.parse(savedVote);
    parseVote.vote_type = parseVote.voteType;

    const actions = {
      post: {
        // we use comments because it gets called after fetching all the post/posts
        success: [
          commentsSlice.actions.getCommentsSuccess,
          commentsSlice.actions.getFeaturedCommentsSuccess,
        ],
        request: postsSlice.actions.votePostRequest(parseVote),
      },
      comment: {
        success: [
          commentsSlice.actions.getCommentsSuccess,
          commentsSlice.actions.getFeaturedCommentsSuccess,
        ],
        request: commentsSlice.actions.voteCommentRequest(parseVote),
      },
      opinion: {
        success: [
          OpinionTypes.GET_OPINIONS_SUCCESS,
          OpinionTypes.GET_OPINION_SUCCESS,
        ],
        request: OpinionActions.voteOpinionRequest(parseVote),
      },
    };

    yield take(actions[parseVote.type].success);
    yield put(actions[parseVote.type].request);
    sessionStorage.clear();
  }
}

export const authSagas = [
  takeLatest(AuthTypes.SIGN_OUT_REQUEST, signOut),
  takeLatest(AuthTypes.SIGN_UP_REQUEST, signUp),
  takeLatest(AuthTypes.SIGN_IN_REQUEST, signIn),
  takeLatest(AuthTypes.FORGET_PASSWORD, forgetPassword),
  takeLatest(AuthTypes.AUTH_USER_TOKEN_REQUEST, authUserToken),
  takeLatest(AuthTypes.UPDATE_EMAIL_REQUEST, updateEmail),
  takeLatest(AuthTypes.UPDATE_PASSWORD_REQUEST, updatePassword),
  takeLatest(AuthTypes.SET_SESSION_STORAGE_VOTE, sessionStorageVote),
];

import { DefaultTheme } from 'styled-components';

import colors from './colors';
import spacing from './spacing';
import borderRadius from './borderRadius';
import shadow from './shadow';
import sizing from './sizing';
import fonts from './fonts';
import inset from './inset';

export const themes: DefaultTheme = {
  colors,
  spacing,
  borderRadius,
  shadow,
  sizing,
  fonts,
  inset,
};

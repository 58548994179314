import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { signOut } from 'app/entity/auth/authDispatch';
import { imageUrl, bannedImage } from 'theme';
import { Button, Modal } from 'app/seedly-component-library';
import * as S from './styles';

const getHeaderMessage = remainingDays => {
  if (!remainingDays || remainingDays > 7) {
    return 'You are banned from logging in.';
  }
  const dayWord = remainingDays > 1 ? 'days' : 'day';
  return `You are banned from logging in for ${remainingDays} more ${dayWord}.`;
};

const subHeaderMessage =
  'You have been temporarily banned for violating our community guidelines';

const BannedDetail = () => {
  // Selectors
  const remainingDays = useSelector(state => state.auth.user.ban.remainingDays);
  const reason = useSelector(state => state.auth.user.ban.reason);
  const isBanned = useSelector(state => state.auth.user.banned);

  const dispatch = useDispatch();

  const headerMessage = getHeaderMessage(remainingDays);

  const handleClick = () => {
    dispatch(signOut());
  };

  const logo = () => (
    <img src={imageUrl.seedlyLogo} alt="Seedly logo" height={28} width="auto" />
  );

  return (
    <Modal isOpen={isBanned} renderHeaderLeft={logo} label="banned">
      <S.Content>
        <S.IllustrationWrapper>
          <img alt="banned-img" src={bannedImage.hiddenFees} />
        </S.IllustrationWrapper>
        <S.Header>{headerMessage}</S.Header>
        <S.SubHeader>{subHeaderMessage}</S.SubHeader>
        <S.ReasonWrapper>
          <div>Reason:</div>
          <div>{reason}</div>
        </S.ReasonWrapper>
        <Button onClick={handleClick}>Continue without logging in</Button>
        <S.SupportMessage>
          View the community guidelines{' '}
          <a
            href="https://seedlyhelp.zendesk.com/hc/en-us"
            target="_blank"
            rel="noopener noreferrer"
          >
            here
          </a>{' '}
          or reach out to us at
          <a href="mailto:support@seedly.sg"> support@seedly.sg</a>.
        </S.SupportMessage>
      </S.Content>
    </Modal>
  );
};

export default BannedDetail;

import React from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';
import Notice from './Notice';

let count = 0;
const now = Date.now();

const getUuid = () => {
  count += 1;
  return `rcNotification_${now}_${count}`;
};

const Container = styled.div`
  font-size: 14px;
  line-height: 1.5;
  color: rgba(0, 0, 0, 0.65);
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  list-style: none;
  position: fixed;
  z-index: 1010;
  width: 100%;
  top: 16px;
  left: 0;
  pointer-events: none;
`;

class Notification extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      notices: [],
    };
  }

  add(notice) {
    const key = notice.key || getUuid();
    this.setState(previousState => {
      const notices = previousState.notices;
      if (!notices.filter(v => v.key === key).length) {
        return {
          notices: notices.concat(notice),
        };
      }
    });
  }

  remove(key) {
    this.setState(previousState => {
      return {
        notices: previousState.notices.filter(notice => notice.key !== key),
      };
    });
  }

  render() {
    const noticeNodes = this.state.notices.map(notice => {
      const onClose = () => {
        this.remove(notice.key);
      };

      return (
        <Notice key={notice.key} {...notice} onClose={onClose}>
          {notice.content}
        </Notice>
      );
    });
    return (
      <Container data-testid="banner">
        <span>{noticeNodes}</span>
      </Container>
    );
  }
}

Notification.newInstance = function newNotificationInstance(
  properties,
  callback,
) {
  if (typeof document === 'undefined') return;

  const { ...props } = properties || {};

  const div = document.createElement('div');
  document.body.appendChild(div);

  let called = false;

  const ref = notification => {
    if (called) return;

    called = true;

    callback({
      notice(noticeProps) {
        notification.add(noticeProps);
      },
      removeNotice(key) {
        notification.remove(key);
      },
      component: notification,
      destroy() {
        ReactDOM.unmountComponentAtNode(div);
        div.parentNode.removeChild(div);
      },
    });
  };

  ReactDOM.render(<Notification {...props} ref={ref} />, div);
};

export default Notification;

import get from 'lodash/get';

export function isApiSuccess(response) {
  if (
    get(response, 'data.code') === 'ok' ||
    get(response, 'data.code') === 'created'
  ) {
    return true;
  }

  return false;
}

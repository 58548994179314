import { useSelector } from 'react-redux';
import isEmpty from 'lodash/isEmpty';

const useIsSignedIn = () => {
  return useSelector(state => state.auth.session.isSignedIn);
};

const useIsSigningIn = () => {
  return useSelector(state => state.auth.api.isFetching);
};

const useIsAuthSettled = () => {
  return useSelector(
    state => !state.auth.api.isIdle && !state.auth.api.isFetching,
  );
};

const useIsUserProfileLoaded = () => {
  return useSelector(state => !isEmpty(state.profile.user));
};

const useIsOnboarded = () => {
  return useSelector(state => state.profile.user.isOnboarded);
};

const useShowPrelaunchOnboarded = () => {
  const profileLoaded = useSelector(state => !isEmpty(state.profile.user));
  const profile = useSelector(state => state.profile.user);
  return profileLoaded && !profile?.isPrelaunchRewardsOnboarded;
};

const useIsPrelaunchOnboarded = () => {
  const isPrelaunchRewardsOnboarded = useSelector(
    state => state.profile.user.isPrelaunchRewardsOnboarded,
  );
  return isPrelaunchRewardsOnboarded;
};

const useShowPostlaunchOnboarded = () => {
  const profileLoaded = useSelector(state => !isEmpty(state.profile.user));
  const profile = useSelector(state => state.profile.user);
  return profileLoaded && !profile?.isPostlaunchRewardsOnboarded;
};

const useIsPostlaunchOnboarded = () => {
  const isPostlaunchRewardsOnboarded = useSelector(
    state => state.profile.user.isPostlaunchRewardsOnboarded,
  );
  return isPostlaunchRewardsOnboarded;
};

const useMigratedRewardUser = () => {
  const user = useSelector(state => state.profile.user);
  return !!user?.migratedToNewRewards;
};

const usePhoneNumber = () => {
  const user = useSelector(state => state.profile.user);
  return user?.phone;
};

// user who enrolled with 2FA
const useIsEnrolledUser = () => {
  const profile = useSelector(state => state.profile.user);
  if (isEmpty(profile)) return false;
  return !!profile?.isTwoFactorAuthCompleted;
};

const useDailyCheckInData = () => {
  const {
    consecutiveLoginDays,
    checkedInToday,
    awardWeeklyLoginStreak,
  } = useSelector(state => state.profile.user);

  return {
    consecutiveLoginDays,
    checkedInToday,
    awardWeeklyLoginStreak,
  };
};

export {
  useIsSignedIn,
  useIsSigningIn,
  useIsAuthSettled,
  useIsUserProfileLoaded,
  useIsOnboarded,
  useShowPrelaunchOnboarded,
  useIsPrelaunchOnboarded,
  useShowPostlaunchOnboarded,
  useIsPostlaunchOnboarded,
  useMigratedRewardUser,
  usePhoneNumber,
  useIsEnrolledUser,
  useDailyCheckInData,
};

import { createActions, createReducer } from 'reduxsauce';
import { COMMENT_TYPE } from 'app/constants/app';
import { createSelector } from 'reselect';
import produce from 'immer';

const { Types, Creators } = createActions({
  getOpinionRequest: ['payload', 'meta'],
  getOpinionSuccess: ['payload'],
  getOpinionFailure: null,

  getOpinionsRequest: ['payload', 'meta'],
  getOpinionsSuccess: ['payload', 'meta'],
  getOpinionsFailure: null,

  getUserOpinionsRequest: ['payload', 'meta'],
  getUserOpinionsFailure: null,

  getBookmarkedOpinionsRequest: ['payload', 'meta'],
  getBookmarkedOpinionsFailure: null,

  postOpinionRequest: ['payload', 'meta'],
  postOpinionSuccess: ['payload'],
  postOpinionFailure: ['payload'],

  updateOpinionRequest: ['payload', 'meta'],
  updateOpinionSuccess: ['payload'],
  updateOpinionFailure: null,

  deleteOpinionRequest: ['payload', 'meta'],
  deleteOpinionSuccess: ['payload'],
  deleteOpinionFailure: null,

  voteOpinionRequest: ['payload', 'meta'],
  voteOpinionSuccess: ['payload'],
  voteOpinionFailure: null,

  bookmarkOpinionRequest: ['payload', 'metadata'],
  bookmarkOpinionSuccess: ['payload'],
  bookmarkOpinionFailure: null,

  updateOpinionPageViewRequest: ['payload', 'meta'],
  updateOpinionPageViewFailure: null,

  updateOpinionCommentCount: ['payload'],
  setEditOpinionId: ['payload'],
  resetSearchOpinion: null,
});

const INITIAL_STATE = {
  ids: [],
  byId: {},
  editOpinionId: null,
  pagination: {},
  api: {
    isFetching: false,
    isFetchingMoreOpinions: false,
    isPosting: false,
    isError: false,
    errorMessage: '',
    profanityErrors: [],
  },
};

export const OpinionTypes = Types;
export default Creators;

const postOpinionRequest = state => {
  return produce(state, draft => {
    draft.api.isPosting = true;
  });
};

const postOpinionSuccess = (state, action) => {
  const { payload: opinion } = action;

  return produce(state, draft => {
    draft.ids.unshift(opinion.id);
    draft.byId[opinion.id] = opinion;
    draft.api.isPosting = false;
  });
};

const postOpinionFailure = (state, action) => {
  const { payload } = action;
  return produce(state, draft => {
    draft.api.isPosting = false;
    draft.api.profanityErrors = payload.profanityErrors;
  });
};

const updateOpinionRequest = state => {
  return produce(state, draft => {
    draft.api.isPosting = true;
  });
};

const updateOpinionSuccess = (state, action) => {
  const { payload: opinion } = action;

  return produce(state, draft => {
    draft.byId[opinion.id] = opinion;
    draft.api.isPosting = false;
  });
};

const updateOpinionFailure = state => {
  return produce(state, draft => {
    draft.api.isPosting = false;
  });
};

const deleteOpinionSuccess = (state, action) => {
  const { id: deletedId } = action.payload;
  const newIds = state.ids.filter(id => id !== deletedId);

  return produce(state, draft => {
    draft.ids = newIds;
  });
};

const getOpinionSuccess = (state, action) => {
  const opinion = action.payload;

  return produce(state, draft => {
    draft.ids = [opinion.id];
    draft.byId[opinion.id] = opinion;
  });
};

const getOpinionsSuccess = (state, action) => {
  const { opinions, pagination } = action.payload;
  const isFetchingMoreOpinions = action.meta?.isFetchingMoreOpinions;

  return produce(state, draft => {
    if (!isFetchingMoreOpinions) {
      draft.ids = [];
    }

    opinions.forEach(opinion => {
      draft.ids.push(opinion.id);
      draft.byId[opinion.id] = opinion;
    });

    draft.api.isFetching = false;
    draft.api.isFetchingMoreOpinions = false;

    if (pagination) {
      draft.pagination = pagination;
    }
  });
};

const getOpinionsRequest = (state, action) => {
  const isFetchingMoreOpinions = action.meta.isFetchingMoreOpinions;

  return produce(state, draft => {
    draft.api.isFetching = true;
    draft.api.isFetchingMoreOpinions = isFetchingMoreOpinions;
  });
};

const getBookmarkedOpinionsRequest = state => {
  return produce(state, draft => {
    draft.api.isFetching = true;
  });
};

const voteOpinionSuccess = (state, action) => {
  const opinion = action.payload;
  return produce(state, draft => {
    draft.byId[opinion.id] = opinion;
  });
};

const updateOpinionCommentCount = (state, action) => {
  const { commentableId, type } = action.payload;

  return produce(state, draft => {
    if (draft.byId[commentableId]) {
      const { commentsCount } = draft.byId[commentableId];
      const newCommentsCount =
        type === COMMENT_TYPE.POST ? commentsCount + 1 : commentsCount - 1;

      draft.byId[commentableId].commentsCount = newCommentsCount;
    }
  });
};

const setEditOpinionId = (state, action) => {
  const opinionId = action.payload;

  return produce(state, draft => {
    draft.editOpinionId = opinionId;
  });
};

const bookmarkOpinionRequest = (state, action) => {
  const { id } = action.payload;
  return produce(state, draft => {
    draft.byId[id].post.bookmarked = !draft.byId[id].post.bookmarked;
  });
};

const bookmarkOpinionSuccess = (state, action) => {
  const opinion = action.payload;
  return produce(state, draft => {
    draft.byId[opinion.id] = opinion;
  });
};

const resetSearchOpinion = state => {
  return produce(state, draft => {
    draft.byId = {};
    draft.ids = [];
  });
};

export const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_USER_OPINIONS_REQUEST]: getOpinionsRequest,
  [Types.GET_OPINION_SUCCESS]: getOpinionSuccess,
  [Types.GET_OPINIONS_SUCCESS]: getOpinionsSuccess,
  [Types.GET_OPINIONS_REQUEST]: getOpinionsRequest,
  [Types.GET_BOOKMARKED_OPINIONS_REQUEST]: getBookmarkedOpinionsRequest,
  [Types.POST_OPINION_REQUEST]: postOpinionRequest,
  [Types.POST_OPINION_SUCCESS]: postOpinionSuccess,
  [Types.POST_OPINION_FAILURE]: postOpinionFailure,
  [Types.UPDATE_OPINION_REQUEST]: updateOpinionRequest,
  [Types.UPDATE_OPINION_SUCCESS]: updateOpinionSuccess,
  [Types.UPDATE_OPINION_FAILURE]: updateOpinionFailure,
  [Types.DELETE_OPINION_SUCCESS]: deleteOpinionSuccess,
  [Types.VOTE_OPINION_SUCCESS]: voteOpinionSuccess,
  [Types.UPDATE_OPINION_COMMENT_COUNT]: updateOpinionCommentCount,
  [Types.SET_EDIT_OPINION_ID]: setEditOpinionId,
  [Types.BOOKMARK_OPINION_REQUEST]: bookmarkOpinionRequest,
  [Types.BOOKMARK_OPINION_SUCCESS]: bookmarkOpinionSuccess,
  [Types.RESET_SEARCH_OPINION]: resetSearchOpinion,
});

export const getOpinionPagination = state => state.opinion.pagination;

export const getHasMoreOpinion = createSelector(
  [getOpinionPagination],
  pagination => {
    const { totalPage, currentPage } = pagination;
    if (totalPage === currentPage) {
      return false;
    }
    return true;
  },
);

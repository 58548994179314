import {
  useIsSignedIn,
  useShowPostlaunchOnboarded,
  useIsPostlaunchOnboarded,
} from 'entity/user/userHooks';
import { RootState } from 'reducer';
import {
  openRewardLaunchModal,
  openVerifyNumberModal,
} from 'dispatcher/modalDispatcher';
import { useEffect } from 'react';
import api from 'api/profileApi';
import ProfileActions from 'reducer/profileRedux';
import getSessionStorage from 'utils/getSesssionStorage';
import { useDispatch, useSelector } from 'react-redux';

const usePostlaunchOnboard = () => {
  const isSignedin = useIsSignedIn();
  const showOnboard = useShowPostlaunchOnboarded();
  const isOnboarded = useIsPostlaunchOnboarded();

  const dispatch = useDispatch();

  const isModalOpen = useSelector(
    (state: RootState) => state.modal.isRewardLaunchModalOpen,
  );

  useEffect(() => {
    const userSignedUp = getSessionStorage('user-signedup');

    if (isSignedin && showOnboard && userSignedUp !== 'true') {
      dispatch(openRewardLaunchModal());
    }
  }, [isSignedin, dispatch, showOnboard]);

  // update onboard user status here
  useEffect(() => {
    const updateOnboard = async () => {
      const response = await api.updateUserPostlaunchOnboardStatus();
      if (response.ok) {
        dispatch(ProfileActions.updateRewardPostlaunchOnboarding());
      }
    };
    if (isModalOpen && !isOnboarded) {
      updateOnboard();
    }
  }, [isModalOpen, isOnboarded]);

  // Register postlaunch 2FA
  useEffect(() => {
    const isInterest = getSessionStorage('postlaunch-2fa');
    if (isInterest && isSignedin) {
      dispatch(openVerifyNumberModal());
      sessionStorage.removeItem('postlaunch-2fa');
    }
  }, [isSignedin, dispatch]);
};

export default usePostlaunchOnboard;

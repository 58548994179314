import { CREDENTIAL_TYPE } from 'app/constants/profile';
import groupBy from 'lodash/groupBy';
import isEmpty from 'lodash/isEmpty';

export const getLatestCredentials = credentials => {
  const credentialResult = {
    [CREDENTIAL_TYPE.EMPLOYMENT]: {},
    [CREDENTIAL_TYPE.EDUCATION]: {},
    default: {},
    overall: {},
  };

  if (!credentials || credentials.length === 0) return credentialResult;

  // Get Latest Credential by Type
  const credentialsByType = groupBy(credentials, 'credentialType');

  if (credentialsByType[CREDENTIAL_TYPE.EMPLOYMENT]) {
    const latestEmployment = credentialsByType[CREDENTIAL_TYPE.EMPLOYMENT][0];
    credentialResult[CREDENTIAL_TYPE.EMPLOYMENT] = latestEmployment || {};
  }

  if (credentialsByType[CREDENTIAL_TYPE.EDUCATION]) {
    const latestEducation = credentialsByType[CREDENTIAL_TYPE.EDUCATION][0];
    credentialResult[CREDENTIAL_TYPE.EDUCATION] = latestEducation || {};
  }

  // Get Latest Default Credential
  const defaultCredential = credentials.filter(credential => {
    return credential.isDefault === true;
  });
  if (defaultCredential.length > 0) {
    credentialResult.default = defaultCredential[0];
    credentialResult[defaultCredential[0].credentialType] =
      defaultCredential[0];
  } else {
    credentialResult.default = credentials[0];
  }

  // Get Overall Latest Credential
  const credentialsByDate = credentials
    .slice()
    .sort((a, b) => b.endYear - a.endYear)
    .sort((a, b) => b.startYear - a.startYear);
  credentialResult.overall =
    credentialsByDate.length > 0 ? credentialsByDate[0] : {};

  return credentialResult;
};

export const generateCredentialText = (credential, prefix = '') => {
  if (isEmpty(credential)) return '';
  const { desc, name } = credential;
  return `${prefix}${desc} at ${name}`;
};

import { PostForm } from './postTypes';
import { postsSlice } from './postRedux';
import api from './postApi';

export const getPostsApiByAction = type => {
  switch (type) {
    case postsSlice.actions.getGroupPostsRequest.type:
      return api.getGroupPosts;
    case postsSlice.actions.getProductPostsRequest.type:
      return api.getProductPosts;
    case postsSlice.actions.getUserPostsRequest.type:
      return api.getUserPosts;
    case postsSlice.actions.getBookmarkedPostsRequest.type:
      return api.getBookmarkedPosts;
    case postsSlice.actions.getTrendingPostsRequest.type:
      return api.getTrendingPosts;
    default:
      return api.getPosts;
  }
};

export const makePostParams = data => {
  const {
    id,
    title,
    isAnonymous,
    products,
    description,
    picture,
    group,
    tags = [],
  } = data;

  const params: PostForm = {
    title,
    description,
    is_anonymous: isAnonymous,
    picture,
    group_id: group.id,
  };

  if (id) {
    params.id = id;
  }
  if (products) {
    params.tags = [...products, ...tags];
  }

  return params;
};

import React from 'react';
import clsx from 'clsx';

interface OwnProps {
  totalPage: number;
  currentIndex: number;
  setPage: (params: number) => void;
}

const Pagination = (props: OwnProps) => {
  const { totalPage, currentIndex, setPage } = props;

  if (!totalPage) return null;

  const paginations = [...Array(totalPage)].map((i, index) => {
    return (
      <div
        className={clsx(
          'rounded-full w-2.5 h-2.5 cursor-pointer',
          currentIndex === index ? 'bg-neutral-700' : 'bg-neutral-100',
        )}
        onClick={() => setPage(index)}
        key={i}
      />
    );
  });

  return <div className="flex gap-3 items-center">{paginations}</div>;
};

export default Pagination;

const borderRadius = {
  /** 0px */
  none: '0px',
  /** 2px */
  sm: '2px',
  /** 4px. default */
  md: '4px',
  /** 8px */
  lg: '8px',
  /** 12px */
  xl: '12px',
  /** 16px */
  xxl: '16px',
  /** 40px, used for rounded corner */
  xxxl: '40px',
};

export default borderRadius;

import { useEffect, useState, useContext } from 'react';
import { useMediaQuery } from 'react-responsive';
import { SystemInfoContext } from 'app/context';

const useIsMobile = () => {
  const systemInfo = useContext(SystemInfoContext);

  // Note: we stick to 1 breakpoint for now which is 1024
  const isMobileViewPort = useMediaQuery({ maxWidth: 1024 });
  const [isMobile, setIsMobile] = useState(
    systemInfo ? systemInfo.isMobile : false,
  );

  // Server Side isMobile is false for deskstop mobile viewport,
  // Hence, we need to sync with clientside mediaQuery
  const isHydration = isMobile !== isMobileViewPort;

  useEffect(() => {
    setIsMobile(isMobileViewPort);
  }, [isMobileViewPort, isHydration]);

  return isMobile;
};

export default useIsMobile;

import { call, put } from 'redux-saga/effects';
import get from 'lodash/get';
import noop from 'lodash/noop';
import { Message } from 'app/seedly-component-library';
import ApiError from '../api/error';
import { isApiSuccess } from '../api/helper';

const defaultFormatter = response => {
  return response.data.data;
};

const noopAction = () => {
  return { type: 'NOOP' };
};

export const handleResponse = function* ({
  successAction = noopAction,
  failureAction = noopAction,
  meta = {},
  response = {},
  format = defaultFormatter,
}) {
  const callbacks = get(meta, 'callbacks', {
    onSuccess: noop,
    onFailure: noop,
  });
  const successMsg = get(meta, 'successMsg', null);
  const failureMsg = get(meta, 'failureMsg', null);
  const { onSuccess, onFailure } = callbacks;
  if (isApiSuccess(response)) {
    const resolve = get(meta, 'resolve', noop);
    const successPayload = format(response);
    yield put(successAction(successPayload, meta));
    if (successMsg) Message.success(successMsg);
    onSuccess(successPayload);
    yield call(resolve, successPayload);
  } else {
    const reject = get(meta, 'reject', noop);
    const apiError = new ApiError(response);
    yield put(failureAction(meta));
    if (failureMsg) Message.error(failureMsg);
    onFailure(apiError);
    yield call(reject, apiError);
  }

  if (meta && meta.onComplete) {
    meta.onComplete();
  }
};

import ProfileActions from '../reducer/profileRedux';
import { makeCallbacks } from './helper';

const getProfileWithSlug = ({ slug }, meta = {}) => {
  return ProfileActions.getProfileRequest({ slug }, meta);
};

const getUserProfileWithSlug = ({ slug }, meta = {}) => {
  meta.isUserProfile = true;
  return ProfileActions.getProfileRequest({ slug }, meta);
};

const updateUserProfile = (
  { name, description, links },
  { onSuccess, onFailure } = {},
) => {
  const meta = {};

  const linkIds = Object.keys(links || {});
  const filteredEmptyLinks = {};
  linkIds.forEach(id => {
    if (links[id].value !== '') {
      filteredEmptyLinks[id] = links[id];
    }
  });

  const params = { name, description, links: filteredEmptyLinks };
  meta.callbacks = makeCallbacks({ onSuccess, onFailure });

  return ProfileActions.updateUserProfileRequest(params, meta);
};

const updateUserProfileImage = (
  { tempImageFileIds },
  { onSuccess, onFailure } = {},
) => {
  const meta = {};
  const params = { temp_image_ids: tempImageFileIds };
  meta.callbacks = makeCallbacks({ onSuccess, onFailure });

  return ProfileActions.updateUserProfileRequest(params, meta);
};

export {
  getProfileWithSlug,
  getUserProfileWithSlug,
  updateUserProfile,
  updateUserProfileImage,
};

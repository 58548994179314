import styled from 'styled-components';

interface FlexSpaceProps {
  directionProp?: string;
  align?: string;
  justifyContent?: string;
  widthProp?: string;
  heightProp?: string;
  margin?: string;
}

export const FlexSpace = styled.div<FlexSpaceProps>`
  display: inline-flex;
  flex-direction: ${({ directionProp }) => directionProp};
  align-items: ${({ align }) => align};
  justify-content: ${({ justifyContent }) => justifyContent};
  width: ${({ widthProp }) => widthProp};
  height: ${({ heightProp }) => heightProp};

  >*: nth-child(n + 2) {
    margin: ${({ margin }) => margin};
  }
`;

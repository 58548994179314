// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;
const ENVIRONMENT = process.env.NEXT_PUBLIC_BUILD_ENV || 'development';

Sentry.init({
  // Do not set a 'release' value here - use the environment variable `SENTRY_RELEASE`
  dsn:
    SENTRY_DSN ||
    'https://cf131d7ae3094c47b9ab20fd38d7d0f9@o78623.ingest.sentry.io/1491715',
  ignoreErrors: [
    // Random plugins/extensions
    'top.GLOBALS',
    'originalCreateNotification',
    'canvas.contentDocument',
    'MyApp_RemoveAllHighlights',
    'http://tt.epicplay.com',
    "Can't find variable: ZiteReader",
    'jigsaw is not defined',
    'ComboSearch is not defined',
    'http://loading.retry.widdit.com/',
    'atomicFindClose',
    'fb_xd_fragment',
    'bmi_SafeAddOnload',
    'EBCallBackMessageReceived',
    'conduitPage',
    'ResizeObserver loop limit exceeded',
    'Illegal invocation',
    // https://forum.sentry.io/t/unhandledrejection-non-error-promise-rejection-captured-with-value/14062/17
    'Non-Error promise rejection captured',
  ],
  denyUrls: [
    // Facebook flakiness
    /graph\.facebook\.com/i,
    // Facebook blocked
    /connect\.facebook\.net\/en_US\/all\.js/i,
    // Woopra flakiness
    /eatdifferent\.com\.woopra-ns\.com/i,
    /static\.woopra\.com\/js\/woopra\.js/i,
    // Chrome extensions
    /extensions\//i,
    /^chrome:\/\//i,
    // Other plugins
    /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
    /webappstoolbarba\.texthelp\.com\//i,
    /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
    'https://blog.seedly.sg/widget/product/',
    'https://blog.seedly.sg/widget/carousel/',
  ],
  integrations: [new Sentry.Integrations.BrowserTracing({})],
  environment: ENVIRONMENT,
  normalizeDepth: 10,
  beforeSend(event) {
    // Check if it is an exception, and if so, show the report dialog
    // Show repot modal only in staging
    if (event.exception && ENVIRONMENT === 'staging') {
      Sentry.showReportDialog({ eventId: event.event_id });
    }
    return event;
  },
  initialScope: {
    tags: { from_server: 'no', from_client: 'yes' },
  },
});

import React from 'react';
import { useDispatch } from 'react-redux';
import { useRouter } from 'next/router';
import { Gift, ChevronRight } from 'react-feather';
import { closeDailyCheckInRewardModal } from 'app/dispatcher/modalDispatcher';
import buildConfig from 'app/utils/buildConfig';
import mixpanel, { trackedEvents } from 'utils/mixpanel';

const DiscoverRewards = () => {
  const dispatch = useDispatch();
  const router = useRouter();

  return (
    <div className="p-4 bg-white rounded w-full cursor-pointer">
      <div
        className="flex justify-between w-full items-center align-middle"
        onClick={() => {
          dispatch(closeDailyCheckInRewardModal());
          router.push(`${buildConfig.apiHost}/rewards`);
          mixpanel.track(trackedEvents.RewardsCollection, {
            param: 'discover_rewards',
          });
        }}
      >
        <div className="flex item-center gap-1">
          <Gift />
          <p>Discover Seedly Rewards</p>
        </div>
        <ChevronRight className="cursor-pointer" />
      </div>
    </div>
  );
};

export default DiscoverRewards;

import React from 'react';
import { useDispatch } from 'react-redux';
import {
  useDailyCheckInData,
  useIsSignedIn,
  useIsEnrolledUser,
} from 'entity/user/userHooks';
import {
  openLoginModal,
  openRewardLaunchModal,
} from 'dispatcher/modalDispatcher';
import Button from 'app/seedly-component-library/button-new';
import { Tooltip } from '@component-lib';
import { dailyCheckInRequest } from 'entity/reward/rewardRedux';
import { HelpCircle } from 'react-feather';
import clsx from 'clsx';
import CircleGreyIcon from './icons/CircleGreyIcon.svg';
import CircleGreenIcon from './icons/CircleGreenIcon.svg';
import GiftSmallIcon from './icons/GiftSmall.svg';

const CheckInDays = props => {
  const { variant } = props;
  const days = ['D1', 'D2', 'D3', 'D4', 'D5', 'D6'];
  const checkedDays = [];
  const uncheckedDays = [];
  const {
    consecutiveLoginDays,
    awardWeeklyLoginStreak,
    checkedInToday,
  } = useDailyCheckInData();

  if (consecutiveLoginDays === 0 && !awardWeeklyLoginStreak) {
    return (
      <div className="flex justify-between w-full items-center">
        {days.map(day => (
          <div className="flex flex-col gap-1 items-center" key={day}>
            <CircleGreyIcon
              className={clsx(
                variant === 'modal' && 'h-6 w-6',
                variant === 'sidebar' && 'h-5 w-5',
              )}
            />
            {variant === 'modal' && <p>{day}</p>}
          </div>
        ))}
        <div className="flex flex-col gap-1 items-center">
          <GiftSmallIcon className="h-6 w-6" />
          {variant === 'modal' && <p>D7</p>}
        </div>
      </div>
    );
  }

  const loginDays =
    awardWeeklyLoginStreak && checkedInToday ? 7 : consecutiveLoginDays;

  for (let i = 1; i <= loginDays; i += 1) {
    checkedDays.push(`D${i}`);
  }

  for (let j = checkedDays.length + 1; j < 7; j += 1) {
    uncheckedDays.push(`D${j}`);
  }

  return (
    <div className="flex justify-between w-full items-center">
      {checkedDays.length > 0 &&
        checkedDays.map(day => (
          <div key={day} className="flex flex-col gap-1 items-center">
            <CircleGreenIcon
              className={clsx(
                variant === 'modal' && 'h-6 w-6',
                variant === 'sidebar' && 'h-5 w-5',
              )}
            />
            {variant === 'modal' && <p>{day}</p>}
          </div>
        ))}
      {uncheckedDays.length > 0 &&
        uncheckedDays.map(day => (
          <div key={day} className="flex flex-col gap-1 items-center">
            <CircleGreyIcon
              className={clsx(
                variant === 'modal' && 'h-6 w-6',
                variant === 'sidebar' && 'h-5 w-5',
              )}
            />
            {variant === 'modal' && <p>{day}</p>}
          </div>
        ))}
      {checkedDays.length < 7 && !awardWeeklyLoginStreak && (
        <div className="flex flex-col gap-1 items-center">
          <GiftSmallIcon className="h-6 w-6" />
          {variant === 'modal' && <p>D7</p>}
        </div>
      )}
    </div>
  );
};

const CheckInDate = props => {
  const { variant } = props;

  const dispatch = useDispatch();
  const isSignedin = useIsSignedIn();
  const {
    consecutiveLoginDays,
    checkedInToday,
    awardWeeklyLoginStreak,
  } = useDailyCheckInData();
  const isEnrolled = useIsEnrolledUser();

  const days = isSignedin ? consecutiveLoginDays : 1;

  return (
    <div className={clsx('card ', variant === 'modal' && 'z-10')}>
      <div className="flex flex-col w-full items-center gap-2">
        <div className="flex justify-between w-full items-center">
          <div className="flex items-center gap-1">
            <p
              className={clsx(
                variant === 'modal' && 'text-2xl',
                variant === 'sidebar' && 'text-base',
                'font-bold',
              )}
            >
              <span role="img" aria-label="calendar">
                📅
              </span>{' '}
              Day {awardWeeklyLoginStreak ? 7 : days}
            </p>
            <Tooltip
              showToolTip
              placement="top"
              renderTriggerButton={() => {
                return <HelpCircle size={14} />;
              }}
              renderToolTipBody={() => (
                <div className="w-[150px]">
                  Receive 200 credits when you check-in for 7 consecutive days
                </div>
              )}
            />
          </div>
          <Button
            size={variant === 'modal' ? 'lg' : 'sm'}
            onClick={event => {
              event.currentTarget.disabled = true;
              sessionStorage.setItem('daily-checkin', 'true');
              if (!isSignedin) {
                dispatch(openLoginModal());
                return;
              }
              if (!isEnrolled) {
                dispatch(openRewardLaunchModal());
                return;
              }
              sessionStorage.removeItem('daily-checkin');
              dispatch(dailyCheckInRequest());
            }}
            disabled={checkedInToday}
          >
            Check In
          </Button>
        </div>
        <div className="horizontal-divider" />
        <CheckInDays variant={variant} />
      </div>
    </div>
  );
};

export default CheckInDate;

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import noop from 'lodash/noop';
import { media } from 'theme';

const propTypes = {
  onClose: PropTypes.func,
  duration: PropTypes.number,
  children: PropTypes.node,
};
const defaultProps = {
  onClose: noop,
  duration: 3,
};

const Container = styled.div`
  padding: 8px;
  text-align: center;

  &:first-child {
    margin-top: -8px;
  }

  ${media.largeAndDown`
    padding: 0;
    margin-top: 0px;

    &:first-child {
      margin-top: -16px;
    }
  `}
`;

const Content = styled.div`
  border-radius: 4px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  display: inline-block;
  pointer-events: all;
  color: white;
  font-weight: bold;

  ${media.largeAndDown`
    width: 100%;
  `}
`;

const Notice = props => {
  const { onClose, duration, children } = props;

  useEffect(() => {
    let closeTimer = setTimeout(() => {
      if (closeTimer) {
        clearTimeout(closeTimer);
        closeTimer = null;
      }
      onClose();
    }, duration * 1000);
    return () => {
      if (closeTimer) {
        clearTimeout(closeTimer);
        closeTimer = null;
      }
      onClose();
    };
  });

  return (
    <Container>
      <Content>{children}</Content>
    </Container>
  );
};

Notice.propTypes = propTypes;
Notice.defaultProps = defaultProps;

export default Notice;

import ProductActions from 'app/reducer/productRedux';
import { PRODUCT_FILTER_TYPE } from 'app/constants/product';
import isEmpty from 'lodash/isEmpty';

// For Api Passing
export const makeProductParams = ({
  categorySlug,
  sortType,
  page,
  term,
  productFilter,
  per = 10,
  isSignUpBonus,
}) => {
  const params = {};

  if (!sortType) {
    params.sort = {
      by: PRODUCT_FILTER_TYPE.MOST_POPULAR,
      dir: 'asc',
    };
  } else if (
    [
      PRODUCT_FILTER_TYPE.MOST_REVIEWED,
      PRODUCT_FILTER_TYPE.HIGHEST_RATING,
    ].includes(sortType)
  ) {
    params.sort = {
      by: sortType,
      dir: 'desc',
    };
  } else if (
    [PRODUCT_FILTER_TYPE.A_TO_Z, PRODUCT_FILTER_TYPE.MOST_POPULAR].includes(
      sortType,
    )
  ) {
    params.sort = {
      by: sortType,
      dir: 'asc',
    };
  }

  params.per = per;
  if (categorySlug) params.category_slug = categorySlug;
  if (productFilter && !isEmpty(productFilter)) params.filter = productFilter;
  if (page) params.page = page;
  if (term) params.term = term.trim();
  if (isSignUpBonus) {
    params.sign_up_bonus = true;
    params.per = 30;
  }

  return params;
};

const getProductCategories = (params, meta) => {
  return ProductActions.getProductCategoriesRequest(params, meta);
};

const getProducts = (
  {
    categorySlug = null,
    sortType = PRODUCT_FILTER_TYPE.MOST_POPULAR,
    term,
    productFilter,
    per,
    isSignUpBonus,
  },
  meta = {},
) => {
  const params = makeProductParams({
    categorySlug,
    sortType,
    term,
    productFilter,
    per,
    isSignUpBonus,
  });
  meta.sortType = sortType;

  if (categorySlug) meta.categorySlug = categorySlug;
  if (term) meta.term = term;
  if (productFilter && !isEmpty(productFilter)) {
    meta.productFilter = productFilter;
  }
  if (per) meta.per = per;
  if (isSignUpBonus) meta.isSignUpBonus = isSignUpBonus;

  if (meta.isWithTerm) {
    return ProductActions.getProductsWithTermRequest(params, meta);
  }

  return ProductActions.getProductsRequest(params, meta);
};

const getProductsNextPage = (
  {
    categorySlug = null,
    sortType = PRODUCT_FILTER_TYPE.MOST_POPULAR,
    page,
    term,
    productFilter,
    isSignUpBonus,
  },
  meta = {},
) => {
  const params = makeProductParams({
    categorySlug,
    sortType,
    page,
    term,
    productFilter,
    isSignUpBonus,
  });
  meta.sortType = sortType;
  if (categorySlug) meta.categorySlug = categorySlug;
  if (term) meta.term = term;
  if (productFilter && !isEmpty(productFilter)) {
    meta.productFilter = productFilter;
  }
  if (isSignUpBonus) meta.isSignUpBonus = isSignUpBonus;

  return ProductActions.getProductsRequest(params, meta);
};

const getProduct = (payload, meta) => {
  return ProductActions.getProductRequest(payload, meta);
};

const getPrismic = (params, meta) => {
  return ProductActions.getCategoryPrismicRequest(params, meta);
};

const getPlan = (payload, meta) => {
  return ProductActions.getPlanRequest(payload, meta);
};

const getPlanFaq = (payload, meta) => {
  return ProductActions.getPlanFaqPrismicRequest(payload, meta);
};

const getSavingsAccounts = (payload, meta) => {
  return ProductActions.getSavingsAccountsRequest(payload, meta);
};

const getTopQuestionReview = (params, meta = {}) => {
  return ProductActions.getTopQuestionReviewRequest(params, meta);
};

const setProductFilterParams = (type, value) => {
  return ProductActions.setProductFilterParams({ type, value });
};

export {
  getProductCategories,
  getProducts,
  getProductsNextPage,
  getProduct,
  getPrismic,
  getPlan,
  getPlanFaq,
  getSavingsAccounts,
  getTopQuestionReview,
  setProductFilterParams,
};

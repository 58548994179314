import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import Cookies from 'js-cookie';

import buildConfig from 'utils/buildConfig';

const useIsNativeApp = (): boolean => {
  const router = useRouter();

  const [isNativeApp, setIsNativeApp] = useState(false);
  const isAppPlatform = router.query.platform === 'app';
  const cookieValue = Cookies.get('isNativeApp');

  useEffect(() => {
    if (isAppPlatform) {
      Cookies.set('isNativeApp', 'true', {
        domain: buildConfig.domain,
        secure: process.env.NEXT_PUBLIC_BUILD_ENV !== 'development',
        expires: 30,
      });
      setIsNativeApp(true);
    }
  }, [isAppPlatform]);

  useEffect(() => {
    if (cookieValue) {
      setIsNativeApp(true);
    }
  }, [cookieValue]);

  return isNativeApp;
};

export default useIsNativeApp;

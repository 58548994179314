import capitalize from 'lodash/capitalize';
import mixpanel, { trackedEvents } from 'app/utils/mixpanel';
import ModalActions from '../reducer/modalRedux';

const openPostModal = () => {
  return ModalActions.setPostModal({
    isOpen: true,
    isEdit: false,
    presetProducts: [],
  });
};

const openPostModalWithValues = (questionText = '', presetProducts = []) => {
  return ModalActions.setPostModal({
    isOpen: true,
    presetPost: questionText,
    presetProducts,
  });
};

const openPostModalWithProduct = (presetProducts = []) => {
  return ModalActions.setPostModal({
    isOpen: true,
    isEdit: false,
    presetProducts,
  });
};

const openPostModalForEdit = post => {
  return ModalActions.setPostModal({
    isOpen: true,
    isEdit: true,
    post,
  });
};

const openPostModalForSharing = url => {
  return ModalActions.setPostModal({
    isOpen: true,
    isEdit: false,
    presetDescription: url,
  });
};

const closePostModal = () => {
  return ModalActions.setPostModal({ isOpen: false, isEdit: false });
};

const openLoginModal = meta => {
  return ModalActions.setLoginModal(true, meta);
};

const closeLoginModal = () => {
  return ModalActions.setLoginModal(false);
};

const openRewardModal = (rewardId, claimId) => {
  return ModalActions.setRewardModal(true, { rewardId, claimId });
};

const closeRewardModal = () => {
  return ModalActions.setRewardModal(false);
};

const openShareModal = (type, link, name = null, data = {}) => {
  mixpanel.track(trackedEvents.Share, { 'Content Type': capitalize(type) });
  return ModalActions.setShareModal(true, { type, link, name, data });
};

const closeShareModal = () => {
  return ModalActions.setShareModal(false, {
    type: null,
    link: '',
    name: null,
    data: {},
  });
};

const closeLevelModal = () => {
  return ModalActions.setLevelModal(false, {
    type: null,
    link: '',
    name: null,
  });
};

const openNameModal = meta => {
  return ModalActions.setNameModal(true, meta);
};

const closeNameModal = () => {
  return ModalActions.setNameModal(false);
};

const openPostAnswerModalForEdit = comment => {
  return ModalActions.setPostAnswerModal(true, { isEdit: true, comment });
};

const closePostAnswerModal = () => {
  return ModalActions.setPostAnswerModal(false, { isEdit: false });
};

const openPostCredentialModal = () => {
  return ModalActions.setPostCredentialModal(true);
};

const closePostCredentialModal = () => {
  return ModalActions.setPostCredentialModal(false);
};

const openPostReviewModal = ({ productId, categorySlug }) => {
  return ModalActions.setPostReviewModal(true, {
    isEdit: false,
    productId,
    categorySlug,
  });
};

const openPostReviewModalForEdit = ({ productId, review }) => {
  return ModalActions.setPostReviewModal(true, {
    isEdit: true,
    productId,
    review,
  });
};

const closePostReviewModal = () => {
  return ModalActions.setPostReviewModal(false, { isEdit: false });
};

const openPlanReqModal = () => {
  return ModalActions.setPlanReqModal(true);
};

const closePlanReqModal = () => {
  return ModalActions.setPlanReqModal(false);
};

const openDeletedPostNotifModal = ({ post, message }) => {
  return ModalActions.setDeletedPostNotifModal(true, { post, message });
};

const closeDeletedPostNotifModal = () => {
  return ModalActions.setDeletedPostNotifModal(false);
};

const openReportModal = meta => {
  return ModalActions.setReportModal(true, meta);
};

const closeReportModal = () => {
  return ModalActions.setReportModal(false);
};

const openDeleteOpinionModal = ({ id }) => {
  return ModalActions.setDeleteOpinionModal(true, { id });
};

const closeDeleteOpinionModal = () => {
  return ModalActions.setDeleteOpinionModal(false, { id: null });
};

const openDeleteDraftModal = ({ id }) => {
  return ModalActions.setDeleteDraftModal(true, { id });
};

const closeDeleteDraftModal = () => {
  return ModalActions.setDeleteDraftModal(false, { id: null });
};

const openVerifyNumberModal = meta => {
  return ModalActions.setVerifyNumberModal(true, meta);
};

const closeVerifyNumberModal = () => {
  return ModalActions.setVerifyNumberModal(false, { isUpdating: false });
};

const openRewardLaunchModal = () => {
  return ModalActions.setRewardLaunchModal(true);
};

const closeRewardLaunchModal = () => {
  return ModalActions.setRewardLaunchModal(false);
};

const openRewardWelcomeModal = () => {
  return ModalActions.setRewardWelcomeModal(true);
};

const closeRewardWelcomeModal = () => {
  return ModalActions.setRewardWelcomeModal(false);
};

const openJoinGroupModal = ({ isSigningUp = false }) => {
  return ModalActions.setJoinGroupModal(true, { isSigningUp });
};

const closeJoinGroupModal = ({ isSigningUp = false }) => {
  return ModalActions.setJoinGroupModal(false, { isSigningUp });
};

const openSignUpCompleteModal = () => {
  return ModalActions.setSignUpCompleteModal(true);
};

const closeSignUpCompleteModal = () => {
  return ModalActions.setSignUpCompleteModal(false);
};

const openDailyCheckInRewardModal = () => {
  return ModalActions.setDailyCheckInRewardModal(true);
};

const closeDailyCheckInRewardModal = () => {
  return ModalActions.setDailyCheckInRewardModal(false);
};

const openDailyCheckInSuccessModal = () => {
  return ModalActions.setDailyCheckInSuccessModal(true);
};

const closeDailyCheckInSuccessModal = () => {
  return ModalActions.setDailyCheckInSuccessModal(false);
};

const openDailyCheckInPointsModal = () => {
  return ModalActions.setDailyCheckInPointsModal(true);
};

const closeDailyCheckInPointsModal = () => {
  return ModalActions.setDailyCheckInPointsModal(false);
};

const openCreditHistoryModal = () => {
  return ModalActions.setCreditHistoryModal(true);
};

const closeCreditHistoryModal = () => {
  return ModalActions.setCreditHistoryModal(false);
};

export {
  openPostModal,
  openPostModalWithValues,
  openPostModalWithProduct,
  openPostModalForEdit,
  openPostModalForSharing,
  closePostModal,
  openPostAnswerModalForEdit,
  closePostAnswerModal,
  openLoginModal,
  closeLoginModal,
  openShareModal,
  closeShareModal,
  openNameModal,
  closeNameModal,
  openPostCredentialModal,
  closePostCredentialModal,
  openPostReviewModal,
  openPostReviewModalForEdit,
  closePostReviewModal,
  openPlanReqModal,
  closePlanReqModal,
  openDeletedPostNotifModal,
  closeDeletedPostNotifModal,
  openReportModal,
  closeReportModal,
  openDeleteOpinionModal,
  closeDeleteOpinionModal,
  openDeleteDraftModal,
  closeDeleteDraftModal,
  closeLevelModal,
  openRewardModal,
  closeRewardModal,
  openVerifyNumberModal,
  closeVerifyNumberModal,
  openRewardLaunchModal,
  closeRewardLaunchModal,
  openRewardWelcomeModal,
  closeRewardWelcomeModal,
  openJoinGroupModal,
  closeJoinGroupModal,
  openSignUpCompleteModal,
  closeSignUpCompleteModal,
  openCreditHistoryModal,
  closeCreditHistoryModal,
  openDailyCheckInRewardModal,
  closeDailyCheckInRewardModal,
  openDailyCheckInSuccessModal,
  closeDailyCheckInSuccessModal,
  openDailyCheckInPointsModal,
  closeDailyCheckInPointsModal,
};

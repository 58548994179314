/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit';
import { COMMENT_TYPE } from 'constants/app';
import {
  PostState,
  Post,
  PostForm,
  PostFormPreset,
  BaseParam,
  Pagination,
  ProductPostParams,
  UserPostsParams,
  GroupPostsParams,
} from './postTypes';

const defaultPagination = {
  per: 0,
  currentPage: 0,
  totalCount: 0,
  totalPage: 0,
};

const initialState: PostState = {
  ids: [],
  byId: {},
  pagination: defaultPagination,
  similarPosts: [],
  api: {
    isFetching: false,
    isFetchingMorePosts: false,
    isFetchPostsError: false,
    errorMessage: '',
  },
  form: {
    isEdit: false,
    post: {},
    presetPost: '',
    presetProducts: [],
    presetGroup: {},
    presetDescription: '',
  },
};

const setIsFetching = (
  state: Draft<PostState>,
  action: PayloadAction<BaseParam>,
) => {
  const { payload } = action;
  state.api.isFetching = payload.page && payload.page === 1;
  state.api.isFetchingMorePosts = payload.page && payload.page > 1;
};

export const postsSlice = createSlice({
  name: 'posts',
  initialState,
  reducers: {
    getPostRequest: {
      reducer(state, action: PayloadAction<String>) {},
      prepare(payload: string, meta: any) {
        return { payload, meta };
      },
    },
    getPinnedPostRequest(state) {},
    getPostsRequest(state, action: PayloadAction<BaseParam>) {
      setIsFetching(state, action);
    },
    getGroupPostsRequest(state, action: PayloadAction<GroupPostsParams>) {
      setIsFetching(state, action);
    },
    getProductPostsRequest(state, action: PayloadAction<ProductPostParams>) {
      setIsFetching(state, action);
    },
    getUserPostsRequest(state, action: PayloadAction<UserPostsParams>) {
      setIsFetching(state, action);
    },
    getTrendingPostsRequest(state, action: PayloadAction<BaseParam>) {
      setIsFetching(state, action);
    },
    getBookmarkedPostsRequest(state, action: PayloadAction<UserPostsParams>) {
      setIsFetching(state, action);
    },

    getPostSuccess(
      state,
      action: PayloadAction<{ post: Post; similarPosts: Post[] }>,
    ) {
      const { post, similarPosts } = action.payload;

      state.ids = [post.id];
      if (post.isMerged && post.isParent) {
        post.childQuestions.forEach(childQuestion => {
          state.ids.push(childQuestion.id);
          state.byId[childQuestion.id] = childQuestion;
        });
      }
      state.similarPosts = similarPosts;
      state.byId[post.id] = post;
    },
    getPinnedPostSuccess(
      state,
      action: PayloadAction<{ posts: Post[]; pagination: Pagination }>,
    ) {
      const { posts } = action.payload;
      if (posts.length > 0) {
        const post = posts[0];
        if (!state.ids.find(id => id === post.id)) {
          state.ids.push(post.id);
        }
        state.byId[post.id] = post;
      }
    },
    getPostsSuccess(
      state,
      action: PayloadAction<{ posts: Post[]; pagination: Pagination }>,
    ) {
      const { posts, pagination } = action.payload;
      if (pagination?.currentPage === 1) {
        state.ids = [];
      }
      posts.forEach(post => {
        if (state.ids.indexOf(post.id) === -1) {
          state.ids.push(post.id);
        }
        state.byId[post.id] = post;
      });
      state.pagination = pagination || defaultPagination;
      state.api.isFetching = false;
      state.api.isFetchingMorePosts = false;
      state.api.isFetchPostsError = false;
    },
    getPostsFailure(state) {
      state.api.isFetching = false;
      state.api.isFetchingMorePosts = false;
      state.api.isFetchPostsError = true;
    },

    votePostRequest(
      state,
      action: PayloadAction<{ id: number; voteType: number }>,
    ) {},
    votePostSuccess(state, action: PayloadAction<Post>) {
      if (state.byId[action.payload.id]) {
        state.byId[action.payload.id] = action.payload;
      }
    },
    pinPostRequest(
      state,
      action: PayloadAction<{ id: number; pinType: 'pin' | 'unpin' }>,
    ) {},
    pinPostSuccess(
      state,
      action: PayloadAction<{ post: Post; pinType: 'pin' | 'unpin' }>,
    ) {
      const { post, pinType } = action.payload;

      if (pinType === 'pin') {
        state.ids.forEach(id => {
          if (state.byId[id].campaignPostState === 'live') {
            state.byId[id].campaignPostState = 'closed';
          }
        });
      }

      if (state.byId[post.id]) {
        state.byId[post.id] = post;
      }
    },
    bookmarkPostRequest(state, action: PayloadAction<{ id: number }>) {
      const postId = action.payload.id;
      if (state.byId[postId]) {
        state.byId[postId].bookmarked = !state.byId[postId].bookmarked;
      }
    },
    bookmarkPostSuccess(state, action: PayloadAction<Post>) {
      if (state.byId[action.payload.id]) {
        state.byId[action.payload.id] = action.payload;
      }
    },
    followPostRequest(
      state,
      action: PayloadAction<{ followableId: number }>,
    ) {},
    followPostSuccess(
      state,
      action: PayloadAction<{ postId: number; followed: boolean }>,
    ) {
      const { postId, followed } = action.payload;

      if (state.byId[postId]) {
        state.byId[postId].followedByCurrentUser = followed;

        const followersCount = state.byId[postId].followersCount;
        state.byId[postId].followersCount = followed
          ? followersCount + 1
          : followersCount - 1;
      }
    },
    createPostRequest(
      state,
      action: PayloadAction<PostForm & { onSuccess: () => void }>,
    ) {},
    createPostSuccess(state, action: PayloadAction<Post>) {
      state.ids.unshift(action.payload.id);
      state.byId[action.payload.id] = action.payload;
    },
    updatePostRequest(
      state,
      action: PayloadAction<PostForm & { onSuccess: () => void }>,
    ) {},
    updatePostSuccess(state, action: PayloadAction<Post>) {
      const post = action.payload;
      state.byId[post.id] = post;
    },
    deletePostRequest(state, action: PayloadAction<number>) {},
    deletePostSuccess(state, action: PayloadAction<number>) {
      const postId = action.payload;
      state.ids = state.ids.filter(id => id !== postId);
    },

    updatePostCommentCount(
      state,
      action: PayloadAction<{ commentableId: number; type: string }>,
    ) {
      const { commentableId, type } = action.payload;
      const commentsCount = state.byId[commentableId].commentsCount;
      const newCommentsCount =
        type === COMMENT_TYPE.POST ? commentsCount + 1 : commentsCount - 1;

      state.byId[commentableId].commentsCount = newCommentsCount;
    },
    setPostForm(state, action: PayloadAction<PostFormPreset>) {
      const {
        isEdit,
        post,
        presetPost,
        presetProducts,
        presetGroup,
        presetDescription,
      } = action.payload;

      state.form.isEdit = isEdit || false;
      state.form.post = post || {};
      state.form.presetPost = presetPost || '';
      state.form.presetProducts = presetProducts || [];
      state.form.presetGroup = presetGroup || {};
      state.form.presetDescription = presetDescription || '';
    },
    resetSearchPosts(state) {
      state.byId = {};
      state.ids = [];
      state.pagination = defaultPagination;
    },
    bumpPinAnnouncementPost(state) {
      let pinnedId;
      state.ids.forEach(id => {
        if (state.byId[id].campaignPostState === 'live') {
          pinnedId = id;
        }
      });
      state.ids = state.ids.filter(id => id !== pinnedId);
      state.ids.unshift(pinnedId);
    },
  },
});

// only use for dispatching purpose
export const {
  getPostRequest,
  getPinnedPostRequest,
  getPostsRequest,
  getGroupPostsRequest,
  getProductPostsRequest,
  getUserPostsRequest,
  getTrendingPostsRequest,
  getBookmarkedPostsRequest,
  createPostRequest,
  updatePostRequest,
  deletePostRequest,
  votePostRequest,
  bookmarkPostRequest,
  followPostRequest,
  pinPostRequest,
  resetSearchPosts,
} = postsSlice.actions;

export default postsSlice.reducer;

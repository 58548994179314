import { profileImage } from 'theme';

const defaultColors = [
  '#2ecc71', // emerald
  '#3498db', // peter river
  '#8e44ad', // wisteria
  '#e67e22', // carrot
  '#e74c3c', // alizarin
  '#1abc9c', // turquoise
  '#2c3e50', // midnight blue
];

const sumChars = name => {
  let sum = 0;
  for (let i = 0; i < name.length; i += 1) {
    sum += name.charCodeAt(i);
  }

  return sum;
};

export const generateBackgroundColor = name => {
  if (name && name.length > 0) {
    return defaultColors[sumChars(name) % defaultColors.length];
  }
};

export const generateAcronym = name => {
  if (name) {
    return name
      .split(/\s/)
      .reduce((response, word) => {
        const addition = response + word.slice(0, 1);
        return addition;
      }, '')
      .slice(0, 2)
      .toUpperCase();
  }
};

export const profileImages = {
  question: { img: profileImage.anonymousPoster, name: 'Question Poster' },
  answer: { img: profileImage.anonymousAnswerer, name: 'Question Poster' },
  default: { img: profileImage.defaultImage, name: 'Default Profile' },
};

export const badgeSizes = {
  xs: [14, 20],
  sm: [14, 20],
  md: [14, 20],
  lg: [14, 20],
  xl: [18, 24],
  xxl: [18, 24],
  xxxl: [40, 48],
};

import React, { useEffect, useState } from 'react';
import { X as CloseButton } from 'react-feather';
import Link from 'components/misc/Link';
import {
  closeRewardLaunchModal,
  openLoginModal,
  openVerifyNumberModal,
} from 'dispatcher/modalDispatcher';
import Dialog from 'seedly-component-library/dialog';
import Button from 'seedly-component-library/button-new';
import { useSelector, useDispatch } from 'react-redux';
import Image from 'next/image';
import { RootState } from 'reducer';
import { useIsSignedIn } from 'entity/user/userHooks';
import mixpanel, { trackedEvents } from 'utils/mixpanel';
import clsx from 'clsx';
import usePostlaunchData from './usePostlaunchData';
import Pagination from './pagination';

const RewardOnboardModal = () => {
  const [pageIndex, setPageIndex] = useState(0);
  const dispatch = useDispatch();
  const isSignedIn = useIsSignedIn();
  const isModalOpen = useSelector(
    (state: RootState) => state.modal.isRewardLaunchModalOpen,
  );

  const rewardPages = usePostlaunchData();

  const totalPage = rewardPages.length;
  const pageData = rewardPages[pageIndex];

  useEffect(() => {
    setPageIndex(0);
  }, [isModalOpen]);

  useEffect(() => {
    if (isModalOpen) {
      mixpanel.track(trackedEvents.RewardPrelaunchOnboarding, {
        param: 'modal_viewed',
      });
    }
  }, [isModalOpen]);

  return (
    <Dialog isOpen={isModalOpen} widthSize="sm" contentClassName="p-0">
      <div
        className={clsx(
          'w-full h-full ease-in-out lg:rounded-2xl duration-150 lg:h-[640px]',
          {
            'bg-blue-500': pageIndex === 0 || pageIndex === 4,
            'bg-green-800': pageIndex === 1,
            'bg-orange-100': pageIndex === 2,
            'bg-green-900': pageIndex === 3,
          },
        )}
      >
        <div
          className="absolute top-6 right-6 cursor-pointer"
          onClick={() => {
            mixpanel.track(trackedEvents.RewardPrelaunchOnboarding, {
              param: `close_${pageIndex + 1}`,
            });
            dispatch(closeRewardLaunchModal());
          }}
        >
          <CloseButton color="white" />
        </div>
        <div className="absolute top-[20vh] lg:top-32 flex flex-col w-full items-center">
          <div className="flex flex-col w-[300px] gap-6 items-center">
            <Image src={pageData.imageUrl} width={240} height={240} />
            <div className="flex flex-col w-[300px] gap-3 items-center">
              <p className="text-xl text-center text-white font-bold">
                {pageData.title}
              </p>
              <p className="text-center text-white ">{pageData.description}</p>
            </div>
            {/* Only on PostLaunch */}
            {pageData.verifyMobile && isSignedIn && (
              <Button
                onClick={() => {
                  dispatch(openVerifyNumberModal());
                  dispatch(closeRewardLaunchModal());
                  mixpanel.track(trackedEvents.RewardsCollection, {
                    param: 'verify_mobile_number',
                  });
                }}
                variant="primaryWhite"
                isBlock
                size="lg"
              >
                Verify Mobile Number
              </Button>
            )}
            {pageData.verifyMobile && !isSignedIn && (
              <div className="flex flex-col gap-2 items-center">
                <Button
                  onClick={() => {
                    dispatch(openLoginModal());
                    sessionStorage.setItem('postlaunch-2fa', 'true');
                  }}
                  variant="primaryWhite"
                  isBlock
                  size="lg"
                >
                  Sign Up
                </Button>
                <Link href="/rewards" as="/rewards" passHref>
                  <a
                    className="text-white cursor-pointer hover:text-blue-500"
                    onClick={() => {
                      dispatch(closeRewardLaunchModal());
                    }}
                  >
                    Discover Rewards
                  </a>
                </Link>
              </div>
            )}
          </div>
        </div>
        {pageIndex > 0 && (
          <div
            className="absolute cursor-pointer bottom-6 left-6"
            onClick={() => setPageIndex(pageIndex - 1)}
          >
            <p className="text-sm font-semibold text-white">Previous</p>
          </div>
        )}
        <div className="absolute bottom-6 left-1/2 translate-x-[-50%]">
          <Pagination
            setPage={index => setPageIndex(index)}
            totalPage={totalPage}
            currentIndex={pageIndex}
          />
        </div>
        {pageIndex < totalPage - 1 && (
          <div
            className="absolute cursor-pointer bottom-6 right-6"
            onClick={() => setPageIndex(pageIndex + 1)}
          >
            <p className="text-sm font-semibold text-white">Next</p>
          </div>
        )}
      </div>
    </Dialog>
  );
};

export default RewardOnboardModal;

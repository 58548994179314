export const ALL_CONTENTS = 'All Content';
export const DISCOVERY_TOPIC = 'Discover Topics';
export const UNIVERSAL = 'universal';

export const POST_FILTER_TYPE = {
  MOST_RECENT: 'most-recent',
  NEW: 'new',
  TRENDING: 'trending',
  FOLLOWED: 'followed',
  ANSWERED_BY: 'answered-by',
  REPLIED_BY: 'replied-by',
  ASKED_BY: 'asked-by',
  SEARCH: 'search',
  HOME: 'home',
  ARTICLE: 'editoral',
  ABOUT: ' about',
  PRODUCT: 'product',
};

export const POST_FILTER_TYPE_MAP = {
  [POST_FILTER_TYPE.PRODUCT]: 'Recent Activity',
  [POST_FILTER_TYPE.NEW]: 'New',
  [POST_FILTER_TYPE.TRENDING]: 'Trending',
  [POST_FILTER_TYPE.ARTICLE]: 'Editoral',
};

export const POST_FILTER_TYPE_LIST = [
  POST_FILTER_TYPE.MOST_RECENT,
  POST_FILTER_TYPE.NEW,
  POST_FILTER_TYPE.TRENDING,
  POST_FILTER_TYPE.ARTICLE,
];

export const POST_ONLY_FILTER_TYPE_LIST = [
  POST_FILTER_TYPE.PRODUCT,
  POST_FILTER_TYPE.NEW,
  POST_FILTER_TYPE.TRENDING,
];

export const POST_FILTER = [
  {
    title: 'My Feed',
    value: POST_FILTER_TYPE.MOST_RECENT,
  },
  {
    title: 'New',
    value: POST_FILTER_TYPE.NEW,
  },
  {
    title: 'Trending',
    value: POST_FILTER_TYPE.TRENDING,
  },
  {
    title: 'Editorial',
    value: POST_FILTER_TYPE.ARTICLE,
  },
];

export const VOTE_TYPE = {
  UNVOTE: 0,
  UPVOTE: 1,
  DOWNVOTE: 2,
};

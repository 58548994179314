import api from '../../api/base';

const signUp = payload => {
  return api.post('v1/auth', {
    email: payload.email,
    password: payload.password,
    name: payload.name,
    gcaptchaResponse: payload.gcaptchaResponse,
    password_confirmation: payload.passwordConfirmation,
  });
};

const signIn = payload => {
  return api.post('v1/auth/sign_in', {
    email: payload.email,
    password: payload.password,
  });
};

const updateUser = (params = {}) => {
  return api.put('v1/auth', params);
};

const signOut = () => {
  return api.delete('v1/auth/sign_out', null);
};

const authUserToken = (params = {}) => {
  return api.get('auth/validate_token', {}, { headers: params.headers });
};

const forgetPassword = payload => {
  return api.post('auth/password', payload);
};

const resendVerificationEmail = email => {
  return api.post('auth/confirmation', { email });
};

const mobileRequestCode = (id, number) => {
  return api.post(`v4/users/${id}/request_code`, { phone: number });
};

const mobileVerifyCode = (id, number, code) => {
  return api.post(`v4/users/${id}/verify_code`, { phone: number, code });
};

export default {
  signUp,
  signIn,
  signOut,
  updateUser,
  authUserToken,
  forgetPassword,
  resendVerificationEmail,
  mobileRequestCode,
  mobileVerifyCode,
};

import React from 'react';

const ChallengesConfirmation = () => {
  return (
    <svg
      width="240"
      height="240"
      viewBox="0 0 240 240"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M110.142 212.988C118.361 209.936 118.98 191.151 111.525 171.03C104.069 150.909 91.3629 137.071 83.144 140.123C74.925 143.175 74.3059 161.96 81.7612 182.081C89.2164 202.202 101.923 216.039 110.142 212.988Z"
        fill="white"
      />
      <path
        d="M107.739 215.277C106.184 215.277 104.539 214.888 102.823 214.113C94.696 210.439 85.7654 198.176 80.0684 182.871C76.3639 172.924 74.4611 162.937 74.7078 154.753C74.9732 146 77.7096 140.189 82.4197 138.385C84.8458 137.457 87.5523 137.659 90.4681 138.976C98.595 142.651 107.526 154.914 113.223 170.219C116.927 180.165 118.83 190.153 118.583 198.337C118.318 207.089 115.581 212.901 110.871 214.705C109.873 215.086 108.826 215.277 107.739 215.277V215.277ZM85.5524 141.562C84.9131 141.562 84.3113 141.671 83.7543 141.884C80.5918 143.093 78.6554 147.826 78.4423 154.869C78.2105 162.581 80.031 172.064 83.5711 181.565C88.8569 195.759 97.2118 207.467 104.363 210.704C106.341 211.599 108.083 211.767 109.537 211.21C112.699 210.001 114.636 205.267 114.849 198.225C115.08 190.512 113.26 181.03 109.72 171.529C104.434 157.335 96.0791 145.626 88.928 142.389C87.7093 141.839 86.5804 141.562 85.5524 141.562V141.562Z"
        fill="#0A379B"
      />
      <path
        d="M172.072 92.2898C172.562 84.2892 172.132 75.4018 169.844 66.7801C167.736 58.8507 164.734 52.549 161.041 48.0511C156.955 43.0778 152.122 39.1262 146.047 41.5099C140.806 43.5643 138.989 50.3674 139.438 55.9842C139.883 61.6011 143.183 69.6353 144.671 75.0688C146.159 80.5023 146.907 87.4438 144.002 92.2673C141.243 96.8439 136.84 101.125 133.09 104.93C127.356 110.753 123.311 119.266 123.793 127.922C123.793 127.922 100.587 142.729 78.7593 144.398C75.0472 151.71 76.0005 166.577 81.8209 182.212C85.5852 192.319 93.2037 204.507 100.011 210.217C100.175 210.113 127.292 205.547 151.471 188.678C195.645 188.678 217.723 172.718 221.94 163.352C224.407 157.873 222.938 153.072 218.736 149.618C219.862 148.391 220.684 147.171 221.121 146.03C223.618 139.511 222.138 134.478 215.903 130.897C216.658 129.924 217.226 128.951 217.585 128.019C220.116 121.411 218.329 115.94 211.869 112.355C212.826 111.329 213.533 110.304 213.91 109.32C217.163 100.829 213.637 94.5463 201.69 91.2121C192.782 88.7236 181.414 91.0325 172.256 92.301L172.076 92.2898H172.072Z"
        fill="#FFD552"
      />
      <g opacity="0.7">
        <path
          d="M170.51 104.935C171.213 101.152 171.792 96.8675 172.073 92.2909C172.562 84.2904 172.132 75.403 169.845 66.7812C169.845 66.7812 170.331 90.8877 166.147 106.384"
          fill="#999999"
        />
      </g>
      <g opacity="0.7">
        <path
          d="M100.003 210.217C100.168 210.112 127.285 205.547 151.463 188.678C195.638 188.678 217.716 172.718 221.932 163.352C185.634 183.166 148.151 176.546 148.151 176.546C148.151 176.546 115.375 193.475 101.872 194.392C88.2055 195.32 81.8169 182.219 81.8169 182.219C86.4971 194.789 93.2184 204.877 99.5958 209.91"
          fill="#999999"
        />
      </g>
      <g opacity="0.7">
        <path
          d="M187.235 123.234C196.12 123.234 211.529 115.522 213.907 109.317C214.628 107.431 215.017 105.654 215.054 103.992C204.453 116.588 194.034 117.142 186.595 118.833C185.511 119.08 185.085 120.79 187.235 123.234Z"
          fill="#999999"
        />
      </g>
      <g opacity="0.7">
        <path
          d="M218.68 122.781C218.654 124.428 218.284 126.179 217.577 128.02C215.2 134.224 203.484 142.023 190.221 142.023C190.221 142.023 188.098 139.056 190.221 137.622C190.221 137.622 207.035 135.987 218.68 122.781Z"
          fill="#999999"
        />
      </g>
      <g opacity="0.7">
        <path
          d="M215.054 103.992C215.028 105.639 214.658 107.39 213.951 109.231Z"
          fill="#999999"
        />
      </g>
      <g opacity="0.7">
        <path
          d="M222.261 140.771C219.884 146.975 204.419 156.139 192.449 156.139C192.449 156.139 190.711 158.108 192.965 160.742C204.935 160.742 218.736 152.236 221.114 146.032C221.854 144.097 222.247 142.294 222.265 140.617"
          fill="#999999"
        />
      </g>
      <g opacity="0.7">
        <path
          d="M170.51 106.589C175.74 106.518 183.418 105.298 191.096 100.07C188.431 101.159 184.988 101.432 181.724 101.346C176.285 101.204 171.343 100.07 171.343 100.07"
          fill="#999999"
        />
      </g>
      <path
        d="M46.2704 216.98C33.6615 215.06 26.585 211.071 19.8563 193.663C12.4995 174.627 17.1199 145.955 21.7254 141.345C42.1733 145.413 83.0843 140.133 83.0843 140.133C74.8939 143.265 74.3294 162.106 81.817 182.216C89.3046 202.326 102.015 216.086 110.201 212.953C110.201 212.953 105.222 216.198 87.3645 217.246C72.6248 218.11 57.586 218.701 46.2667 216.98H46.2704Z"
        fill="#FF8D10"
      />
      <path
        d="M170.51 106.808C170.398 106.808 170.282 106.797 170.166 106.778C169.153 106.591 168.48 105.61 168.67 104.596C169.684 99.1441 170.775 90.702 170.237 81.4403C170.177 80.4075 170.962 79.5244 171.994 79.4645C173.025 79.4009 173.908 80.1905 173.968 81.2233C174.528 90.8591 173.396 99.623 172.345 105.281C172.177 106.183 171.392 106.812 170.51 106.812V106.808Z"
        fill="#0A379A"
      />
      <path
        d="M187.234 125.104C186.203 125.104 185.365 124.266 185.365 123.233C185.365 122.201 186.203 121.362 187.234 121.362C195.481 121.362 210.157 113.874 212.161 108.647C214.531 102.457 212.822 98.154 206.774 95.1042C205.851 94.6402 205.481 93.5138 205.944 92.5933C206.408 91.669 207.533 91.2985 208.452 91.7625C216.224 95.6805 218.714 101.982 215.645 109.986C213.828 114.731 206.796 118.758 203.858 120.266C197.963 123.293 191.746 125.104 187.227 125.104H187.234Z"
        fill="#0A379A"
      />
      <path
        d="M190.221 143.894C189.189 143.894 188.352 143.056 188.352 142.023C188.352 140.99 189.189 140.152 190.221 140.152C198.662 140.152 212.837 135.164 215.832 127.35C217.211 123.746 217.129 120.641 215.585 118.126C215.043 117.246 215.319 116.094 216.198 115.551C217.076 115.009 218.228 115.286 218.77 116.165C220.957 119.727 221.144 123.941 219.323 128.69C216.568 135.882 203.94 143.894 190.221 143.894V143.894Z"
        fill="#0A379A"
      />
      <path
        d="M192.965 162.611C191.934 162.611 191.096 161.773 191.096 160.74C191.096 159.708 191.934 158.869 192.965 158.869C204.479 158.869 217.372 150.573 219.368 145.36C220.684 141.925 220.74 139.145 219.536 136.851C219.058 135.938 219.409 134.804 220.321 134.325C221.234 133.842 222.366 134.198 222.845 135.111C224.553 138.367 224.557 142.266 222.856 146.7C220.168 153.713 205.556 162.611 192.965 162.611V162.611Z"
        fill="#0A379A"
      />
      <path
        d="M186.532 186.559C185.694 186.559 184.935 185.994 184.722 185.145C184.468 184.142 185.077 183.124 186.076 182.873C208.355 177.245 218.026 167.471 220.228 162.58C221.304 160.185 221.544 157.925 220.931 155.863C220.639 154.871 221.203 153.831 222.19 153.539C223.185 153.244 224.22 153.812 224.512 154.8C225.383 157.749 225.087 160.881 223.633 164.118C220.557 170.951 209.114 180.912 186.988 186.499C186.834 186.537 186.681 186.555 186.528 186.555L186.532 186.559Z"
        fill="#0A379A"
      />
      <path
        d="M170.05 108.481C166.211 108.481 163.71 107.897 163.471 107.841C162.469 107.597 161.852 106.587 162.095 105.58C162.338 104.578 163.347 103.96 164.349 104.203C164.558 104.252 177.388 107.141 190.045 98.5267C190.902 97.9467 192.06 98.1675 192.644 99.0207C193.223 99.8738 193.002 101.038 192.15 101.621C183.833 107.283 175.564 108.481 170.054 108.481H170.05Z"
        fill="#0A379A"
      />
      <g opacity="0.7">
        <path
          d="M22.3984 199.578C35.8597 203.23 52.805 203.399 67.5672 203.215C85.4545 202.991 90.5758 199.979 90.5758 199.979L90.7253 199.877C97.3083 209.644 104.759 215.04 110.205 212.956C110.205 212.956 105.226 216.2 87.3684 217.248C72.6287 218.113 57.5899 218.704 46.2706 216.982C35.2354 215.302 28.4394 212.039 22.4059 199.586"
          fill="#999999"
        />
      </g>
      <path
        d="M107.738 215.274C106.183 215.274 104.538 214.885 102.822 214.11C94.695 210.436 85.7645 198.173 80.0674 182.868C76.3629 172.921 74.4601 162.934 74.7069 154.75C74.9723 145.997 77.7086 140.186 82.4188 138.382C83.3832 138.012 84.4636 138.498 84.8336 139.464C85.2037 140.429 84.7178 141.51 83.7533 141.881C80.5908 143.09 78.6544 147.823 78.4413 154.866C78.2095 162.578 80.0301 172.061 83.5701 181.562C88.856 195.755 97.2108 207.464 104.362 210.701C106.34 211.596 108.078 211.764 109.536 211.206C110.5 210.84 111.58 211.322 111.951 212.288C112.321 213.253 111.835 214.335 110.87 214.705C109.872 215.087 108.825 215.278 107.738 215.278V215.274Z"
        fill="#0A379B"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M99.0885 67.8673L99.0878 67.8675L99.0924 67.866C99.0978 67.8642 99.1076 67.861 99.1217 67.8566C99.1499 67.8476 99.1954 67.8334 99.2575 67.815C99.3816 67.7781 99.5721 67.724 99.8233 67.6601C100.326 67.5321 101.071 67.3648 102.015 67.2158C103.905 66.9175 106.581 66.6944 109.702 67C115.917 67.6087 123.944 70.3152 131.11 78.8303C131.576 79.3835 132.401 79.4541 132.954 78.9881C133.506 78.522 133.577 77.6958 133.111 77.1426C125.444 68.0325 116.752 65.0585 109.957 64.393C106.572 64.0616 103.67 64.3029 101.608 64.6284C100.576 64.7912 99.7512 64.9757 99.1785 65.1214C98.892 65.1943 98.6683 65.2576 98.5127 65.3039C98.4348 65.327 98.374 65.3459 98.3309 65.3596C98.3094 65.3664 98.2923 65.3719 98.2796 65.3761L98.2641 65.3812L98.2589 65.3829L98.2569 65.3836C98.2561 65.3839 98.2554 65.3841 98.6698 66.6176L98.2554 65.3841C97.5705 65.6147 97.202 66.3574 97.4324 67.043C97.6625 67.728 98.4034 68.0968 99.0877 67.8675C99.088 67.8674 99.0882 67.8673 99.0885 67.8673Z"
        fill="#0A379A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M110.313 79.5045C110.935 78.3524 110.935 78.352 110.934 78.3516Z"
        fill="#0A379A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M207.477 52.8051C207.748 51.5238 207.747 51.5236 207.746 51.5234Z"
        fill="#0A379A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M74.6846 43.5703C75.1628 43.5705 75.6028 43.8317 75.8322 44.2517L78.2912 48.7535L82.7883 51.215C83.208 51.4447 83.469 51.8854 83.469 52.3642C83.469 52.843 83.208 53.2836 82.7883 53.5133L78.2912 55.9749L75.8322 60.4767C75.6028 60.8966 75.1628 61.1579 74.6846 61.158C74.2064 61.1582 73.7663 60.8972 73.5366 60.4773L71.0738 55.9749L66.5797 53.5131C66.1603 53.2834 65.8994 52.8428 65.8994 52.3642C65.8994 51.8855 66.1603 51.445 66.5797 51.2152L71.0738 48.7535L73.5366 44.251C73.7663 43.8312 74.2064 43.5702 74.6846 43.5703ZM74.6834 47.6085L73.1851 50.3476C73.0651 50.567 72.8848 50.7474 72.6656 50.8675L69.9333 52.3642L72.6656 53.8609C72.8848 53.981 73.0651 54.1614 73.1851 54.3808L74.6834 57.1198L76.1791 54.3815C76.2992 54.1616 76.4798 53.9809 76.6994 53.8607L79.4334 52.3642L76.6994 50.8677C76.4798 50.7475 76.2992 50.5667 76.1791 50.3469L74.6834 47.6085Z"
        fill="#0A379A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M180.453 21C180.932 21 181.372 21.2613 181.601 21.6814L184.06 26.1832L188.557 28.6447C188.977 28.8744 189.238 29.3151 189.238 29.7939C189.238 30.2727 188.977 30.7133 188.557 30.943L184.06 33.4046L181.601 37.9064C181.372 38.3265 180.932 38.5877 180.453 38.5877C179.975 38.5877 179.535 38.3265 179.305 37.9064L176.846 33.4046L172.349 30.943C171.929 30.7133 171.668 30.2727 171.668 29.7939C171.668 29.3151 171.929 28.8744 172.349 28.6447L176.846 26.1832L179.305 21.6814C179.535 21.2613 179.975 21 180.453 21ZM180.453 25.0397L178.958 27.7766C178.838 27.9964 178.658 28.1772 178.438 28.2974L175.704 29.7939L178.438 31.2904C178.658 31.4106 178.838 31.5913 178.958 31.8112L180.453 34.548L181.948 31.8112C182.068 31.5913 182.249 31.4106 182.468 31.2904L185.202 29.7939L182.468 28.2974C182.249 28.1772 182.068 27.9964 181.948 27.7766L180.453 25.0397Z"
        fill="#0A379A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M64.0529 91.7054C62.711 91.7054 61.6231 92.7944 61.6231 94.1377C61.6231 95.4811 62.711 96.5701 64.0529 96.5701C65.3949 96.5701 66.4828 95.4811 66.4828 94.1377C66.4828 92.7944 65.3949 91.7054 64.0529 91.7054ZM59.0063 94.1377C59.0063 91.3477 61.2658 89.0859 64.0529 89.0859C66.8401 89.0859 69.0995 91.3477 69.0995 94.1377C69.0995 96.9278 66.8401 99.1895 64.0529 99.1895C61.2658 99.1895 59.0063 96.9278 59.0063 94.1377Z"
        fill="#0A379A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M211.974 72.6116C210.632 72.6116 209.544 73.7006 209.544 75.044C209.544 76.3873 210.632 77.4763 211.974 77.4763C213.316 77.4763 214.404 76.3873 214.404 75.044C214.404 73.7006 213.316 72.6116 211.974 72.6116ZM206.927 75.044C206.927 72.254 209.187 69.9922 211.974 69.9922C214.761 69.9922 217.02 72.254 217.02 75.044C217.02 77.834 214.761 80.0958 211.974 80.0958C209.187 80.0958 206.927 77.834 206.927 75.044Z"
        fill="#0A379A"
      />
      <path
        d="M110.209 52.367C112.273 52.367 113.947 50.6916 113.947 48.6249C113.947 46.5582 112.273 44.8828 110.209 44.8828C108.144 44.8828 106.471 46.5582 106.471 48.6249C106.471 50.6916 108.144 52.367 110.209 52.367Z"
        fill="#0A379A"
      />
      <path
        d="M210.213 46.1951C212.278 46.1951 213.951 44.5197 213.951 42.453C213.951 40.3863 212.278 38.7109 210.213 38.7109C208.149 38.7109 206.475 40.3863 206.475 42.453C206.475 44.5197 208.149 46.1951 210.213 46.1951Z"
        fill="#0A379A"
      />
      <path
        d="M107.865 110.328C109.929 110.328 111.603 108.653 111.603 106.586C111.603 104.519 109.929 102.844 107.865 102.844C105.8 102.844 104.126 104.519 104.126 106.586C104.126 108.653 105.8 110.328 107.865 110.328Z"
        fill="#0A379A"
      />
    </svg>
  );
};

export default ChallengesConfirmation;

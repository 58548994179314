import React, { createContext, useState } from 'react';
import useSearch, { INITIAL_STATE } from './useSearch';

interface ISearchContext {
  setSearchTerm: React.Dispatch<React.SetStateAction<string>>;
  searchTerm: string;
  setIsInputFocus: React.Dispatch<React.SetStateAction<boolean>>;
  isInputFocus: boolean;
  setShowMobileResult: React.Dispatch<React.SetStateAction<boolean>>;
  showMobileResult: boolean;
  searchState: typeof INITIAL_STATE;
}

export const SearchContext = createContext<ISearchContext>({
  setSearchTerm: () => {},
  searchTerm: '',
  setIsInputFocus: () => {},
  isInputFocus: false,
  setShowMobileResult: () => {},
  showMobileResult: false,
  searchState: INITIAL_STATE,
});

interface OwnProps {
  children: React.ReactNode;
}

const SearchProvider = (props: OwnProps) => {
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [isInputFocus, setIsInputFocus] = useState<boolean>(false);

  // This control the state of results page in mobile search.
  const [showMobileResult, setShowMobileResult] = useState<boolean>(false);

  const searchState = useSearch(searchTerm);

  return (
    <SearchContext.Provider
      value={{
        searchTerm,
        setSearchTerm,
        searchState,
        setIsInputFocus,
        isInputFocus,
        showMobileResult,
        setShowMobileResult,
      }}
    >
      {props.children}
    </SearchContext.Provider>
  );
};

export default SearchProvider;

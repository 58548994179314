import api from './base';

const updateUserProfile = (params = {}) => {
  return api.put(`v4/user/profile`, params);
};

const getProfile = (params = {}) => {
  const { slug } = params;
  return api.get(`v4/users/${slug}/profile`, {});
};

const postCredential = (params = {}) => {
  return api.post(`v4/user/credentials`, params);
};

const getSavedCount = id => {
  return api.get(`v4/users/${id}/bookmark_counters`);
};

const updateCredential = (params = {}) => {
  const { id } = params;
  return api.patch(`v4/user/credentials/${id}`, params);
};

const deleteCredential = (params = {}) => {
  const { id } = params;
  return api.delete(`v4/user/credentials/${id}`, params);
};

const updateUserOnboardStatus = () => {
  return api.patch(`v4/users/onboard`, {});
};

const updateUserPrelaunchOnboardStatus = () => {
  return api.put(`v4/users/rewards_onboard?state=prelaunch_rewards_onboarded`);
};

const updateUserPostlaunchOnboardStatus = () => {
  return api.put(`v4/users/rewards_onboard?state=postlaunch_rewards_onboarded`);
};

const updateUserNotificationReminder = () => {
  return api.put(`v4/users/open_reward_notif`);
};

export default {
  getSavedCount,
  updateUserProfile,
  getProfile,
  postCredential,
  updateCredential,
  deleteCredential,
  updateUserOnboardStatus,
  updateUserPrelaunchOnboardStatus,
  updateUserPostlaunchOnboardStatus,
  updateUserNotificationReminder,
};

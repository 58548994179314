import api from './base';

const searchWithTerm = params => {
  return api.get(`v4/search`, params);
};

const searchQuestionWithTerm = params => {
  return api.get(`v4/search/questions`, params);
};

const searchProfileWithTerm = params => {
  return api.get(`v4/search/profiles`, params);
};

const searchProductWithTerm = params => {
  return api.get(`v4/search/products`, params);
};

const searchOpinionWithTerm = params => {
  return api.get(`v4/search/opinions`, params);
};

const getTopSearchedProducts = () => {
  return api.get(`v4/search/products/trending`);
};

export default {
  searchWithTerm,
  searchQuestionWithTerm,
  searchProfileWithTerm,
  searchProductWithTerm,
  searchOpinionWithTerm,
  getTopSearchedProducts,
};

import React, { useState, useRef, useEffect, useContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import dynamic from 'next/dynamic';
import isEmpty from 'lodash/isEmpty';

import { PageContext } from 'app/context';
import { Text, Colors } from 'app/seedly-component-library';
import { useAmp } from 'next/amp';
import { getGroupsRequest } from 'entity/group/groupRedux';
import { getNavCampaignsRequest } from 'entity/navigation/navigationRedux';
import { getProductCategories } from 'app/dispatcher/productDispatcher';
import { SearchContext } from 'components/search/SearchProvider';
import useSearchResults from 'components/search/SearchProvider/useSearchResults';
import useIsNativeApp from 'utils/hooks/useIsNativeApp';
import LoginContextProvider from 'app/components/auth/LoginModal/LoginContext';
import RewardWelcomeModal from 'app/components/reward/RewardWelcomeModal';
import DailyCheckInRewardModal from 'app/components/reward/DailyCheckInRewardModal';

import Footer from './Footer';
import RewardOnboardModal from './RewardOnboardModal';
import usePostlaunchOnboard from './RewardOnboardModal/usePostlaunchOnboard';

import usePostAfterSignIn from './hooks/usePostAfterSignIn';
import useShowHeadroom from './hooks/useShowHeadroom';
import useOpenNameModal from './hooks/useOpenNameModal';
import useShowResendEmail from './hooks/useShowResendEmail';
import usePageViewTracker from './hooks/usePageViewTracker';
import useAuth from './hooks/useAuth';
import useInitialiseApp from './hooks/useInitialiseApp';

import * as S from './styles';

const DeleteOpinionModal = dynamic(() =>
  import('app/components/opinion/DeleteOpinionModal'),
);
const LoginModal = dynamic(() => import('app/components/auth/LoginModal'));

const DeletedPostNotifModal = dynamic(() =>
  import('app/components/post/DeletedPostNotifModal'),
);
const ReportModal = dynamic(() => import('app/components/post/ReportModal'));

const NameModal = dynamic(() => import('app/components/auth/NameModal'));
const EmailVerifiedModal = dynamic(() =>
  import('app/components/auth/EmailVerifiedModal'),
);
const EmailUnverifiedModal = dynamic(() =>
  import('app/components/auth/EmailUnverifiedModal'),
);
const VerifyMobileModal = dynamic(() =>
  import('app/components/auth/VerifyMobileModal'),
);
const SignUpOnboardingModal = dynamic(() =>
  import('app/components/auth/SignUpOnboardingModal'),
);
const JoinGroupModal = dynamic(() =>
  import('app/components/group/JoinGroupModal'),
);
const EarnCreditModal = dynamic(() =>
  import('app/components/reward/EarnCreditModal'),
);
const TierUpModal = dynamic(() => import('app/components/reward/TierUpModal'));
const SignUpCompleteModal = dynamic(() =>
  import('app/components/auth/SignUpOnboardingModal/SignUpCompleteModal'),
);

const CreatePostForm = dynamic(() =>
  import('app/components/post/CreatePostForm'),
);

const PostReviewForm = dynamic(() =>
  import('app/components/review/PostReviewForm'),
);
const ShareForm = dynamic(() => import('app/components/post/ShareForm'));

const SearchResult = dynamic(() => import('components/search/SearchResult'));

const AmpHeader = dynamic(() =>
  import('app/components/misc/Header').then(mod => mod.AmpHeader),
);

const ImportedHeader = dynamic(() =>
  import('app/components/misc/Header').then(mod => mod.Header),
);

const Header = React.forwardRef((props, ref) => (
  <ImportedHeader {...props} forwardRef={ref} />
));

const blueHeaderBackgroundStyles = {
  background: `linear-gradient(180deg, ${Colors.neutral4} 381px, ${Colors.neutral2} 0%)`,
  display: 'flex',
  flexDirection: 'column',
};

const BasePage = props => {
  const {
    hideDefaultNav = false,
    pageContentStyle,
    withBackground,
    renderSubHeader,
    hidePaddingBottom = false,
  } = props;

  const dispatch = useDispatch();

  const categoriesById = useSelector(
    state => state.product.productCategoriesById,
  );
  const unverifiedEmail = useSelector(state => state.auth.unverifiedEmail);

  const isNativeApp = useIsNativeApp();
  const [scrollable, setScrollable] = useState(true);
  const [isOpen, toggleMenu] = useState(false);
  const [scrollTo, setScrollTo] = useState(null);
  const [isEmailVerifiedModalOpen, toggleEmailVerifiedModal] = useState(false);
  const [showHeader, setShowHeader] = useState(false);
  const [isEmailUnverifiedModalOpen, toggleEmailUnverifiedModal] = useState(
    !!unverifiedEmail,
  );

  const headerRef = useRef(null);
  const isAmp = useAmp();

  const { setShowMobileResult, showMobileResult, searchTerm } = useContext(
    SearchContext,
  );
  const resultList = useSearchResults();

  // Effects
  const [showResendEmail, onResendEmail] = useShowResendEmail(
    toggleEmailVerifiedModal,
  );

  usePostlaunchOnboard();
  useInitialiseApp();
  useAuth();
  usePageViewTracker();
  useShowHeadroom(headerRef, setShowHeader);
  usePostAfterSignIn();
  useOpenNameModal();
  useEffect(() => {
    if (isEmpty(categoriesById)) {
      dispatch(getProductCategories());
    }
  }, [categoriesById, dispatch]);

  useEffect(() => {
    dispatch(getGroupsRequest());
    dispatch(getNavCampaignsRequest());
  }, [dispatch]);

  let renderHeader = (
    <Header
      hideDefaultNav={hideDefaultNav}
      renderSubHeader={renderSubHeader}
      ref={headerRef}
    />
  );

  if (isAmp) {
    renderHeader = <AmpHeader />;
  } else if (isNativeApp) {
    renderHeader = <div ref={headerRef} />;
  }

  return (
    <PageContext.Provider
      value={{
        scrollable,
        setScrollable,
        scrollTo,
        setScrollTo,
        isOpen,
        toggleMenu,
        showHeader,
      }}
    >
      <S.PageBody scrollable={scrollable}>
        <S.ProgressBarStyle />
        {renderHeader}
        {showResendEmail && (
          <S.VerifyEmailCard onClick={onResendEmail}>
            <Text intent="inverted" textAlign="center">
              Please verify your email within the next 3 days or you will not be
              able to log in. Didn’t see the email? Click{' '}
              <span style={{ textDecoration: 'underline' }}>here</span> to
              resend it.
            </Text>
          </S.VerifyEmailCard>
        )}
        <S.PageContent
          hideMarginTop={isAmp || isNativeApp}
          hidePaddingBottom={hidePaddingBottom}
          style={{
            ...pageContentStyle,
            ...(withBackground && blueHeaderBackgroundStyles),
          }}
        >
          {showMobileResult ? (
            <SearchResult
              searchTerm={searchTerm}
              resultList={resultList}
              onResultClick={() => setShowMobileResult(false)}
            />
          ) : (
            props.children
          )}
        </S.PageContent>
        <DailyCheckInRewardModal />
        <DeleteOpinionModal />
        <DeletedPostNotifModal />
        <ReportModal />
        <PostReviewForm />
        <CreatePostForm />
        <RewardOnboardModal />
        <EarnCreditModal />
        <RewardWelcomeModal />
        <TierUpModal />
        <EmailVerifiedModal
          isEmailVerifiedModalOpen={isEmailVerifiedModalOpen}
          toggleEmailVerifiedModal={toggleEmailVerifiedModal}
        />
        <EmailUnverifiedModal
          isEmailUnverifiedModalOpen={isEmailUnverifiedModalOpen}
          toggleEmailUnverifiedModal={toggleEmailUnverifiedModal}
        />
        <ShareForm />
        <NameModal />
        <VerifyMobileModal />
        <SignUpOnboardingModal />
        <SignUpCompleteModal />
        <JoinGroupModal />
        <LoginContextProvider>
          <LoginModal />
        </LoginContextProvider>
        {!isNativeApp && <Footer />}
      </S.PageBody>
    </PageContext.Provider>
  );
};

export default BasePage;

/* eslint-disable */
import { combineReducers, createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';
import * as Sentry from '@sentry/react';
import { composeWithDevTools } from '@redux-devtools/extension';
import rootSaga from '../sagas';
import { reducer as modalReducer } from './modalRedux';
import { reducer as authReducer } from '../entity/auth/authRedux';
import { reducer as profileReducer } from './profileRedux';
import { reducer as shareReducer } from './shareRedux';
import feedReducer from '../entity/feed/feedRedux';
import groupReducer from '../entity/group/groupRedux';
import navigationReducer from '../entity/navigation/navigationRedux';
import postReducer from '../entity/post/postRedux';
import commentReducer from '../entity/comment/commentRedux';
import rewardReducer from '../entity/reward/rewardRedux';
import { reducer as searchReducer } from './searchRedux';
import { reducer as productReducer } from './productRedux';
import { reducer as reviewReducer } from './reviewRedux';
import { reducer as reportReducer } from './reportRedux';
import { reducer as opinionReducer } from './opinionRedux';
import { reducer as draftReducer } from './draftRedux';

const rootReducer = combineReducers({
  auth: authReducer,
  modal: modalReducer,
  profile: profileReducer,
  feed: feedReducer,
  group: groupReducer,
  post: postReducer,
  comment: commentReducer,
  navigation: navigationReducer,
  share: shareReducer,
  product: productReducer,
  review: reviewReducer,
  search: searchReducer,
  report: reportReducer,
  opinion: opinionReducer,
  draft: draftReducer,
  reward: rewardReducer,
});

const sentryReduxEnhancer = Sentry.createReduxEnhancer({});

export const configureStore = initialState => {
  let store;
  const middleware = [];
  const enhancers = [];
  const sagaMiddleware = createSagaMiddleware();

  middleware.push(sagaMiddleware);
  enhancers.push(applyMiddleware(...middleware));

  if (process.env.NODE_ENV === 'development') {
    store = createStore(
      rootReducer,
      initialState,
      composeWithDevTools(...enhancers),
    );
  } else {
    store = createStore(
      rootReducer,
      initialState,
      compose(...enhancers, sentryReduxEnhancer),
    );
  }

  store.sagaTask = sagaMiddleware.run(rootSaga);

  return store;
};

export type RootState = ReturnType<typeof rootReducer>;

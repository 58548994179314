/* eslint-disable @typescript-eslint/indent */
import * as AvatarPrimitive from '@radix-ui/react-avatar';
import clsx from 'clsx';
import React from 'react';

import { generateAcronym, generateBackgroundColor } from './helper';

interface AvatarProps {
  src: string;
  sizeClass?:
    | 'avatar-2'
    | 'avatar-4'
    | 'avatar-6'
    | 'avatar-8'
    | 'avatar-10'
    | 'avatar-12'
    | 'avatar-14'
    | 'avatar-16'
    | 'avatar-40';
  name?: string;
  badge?: React.ReactNode;
  className?: string;
  badgePositionClass?: string;
}

const Avatar = (props: AvatarProps) => {
  const { src, sizeClass, name, badge, className, badgePositionClass } = props;

  return (
    <AvatarPrimitive.Root
      className={clsx(
        'relative inline-flex flex-none rounded-full border border-neutral-400',
        sizeClass || 'avatar-8',
        className,
      )}
    >
      <AvatarPrimitive.Image
        src={src}
        alt="Avatar"
        className="h-full w-full object-cover rounded-full "
      />
      {badge && (
        <div
          className={clsx(badgePositionClass || 'absolute -bottom-1 right-0')}
        >
          {badge}
        </div>
      )}
      <AvatarPrimitive.Fallback
        className={clsx(
          'flex h-full w-full items-center justify-center rounded-full',
        )}
        style={{ backgroundColor: generateBackgroundColor(name) }}
        delayMs={600}
      >
        <span
          className={clsx(
            'text-sm font-medium uppercase text-white',
            sizeClass === 'avatar-40' && 'text-5xl',
          )}
        >
          {generateAcronym(name)}
        </span>
      </AvatarPrimitive.Fallback>
    </AvatarPrimitive.Root>
  );
};

export default Avatar;

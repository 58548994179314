import { call, put, takeLatest, delay } from 'redux-saga/effects';
import { handleResponse } from 'app/utils/sagaHelper';
import Router from 'next/router';
import ProfileActions from '../../reducer/profileRedux';

import api from './groupApi';
import { feedsSlice } from '../feed/feedRedux';
import { groupsSlice } from './groupRedux';

function* getGroups() {
  const response = yield call(api.getGroups);

  const successAction = groupsSlice.actions.getGroupsSuccess;
  const failureAction = groupsSlice.actions.getGroupsFailure;

  yield call(handleResponse, {
    successAction,
    failureAction,
    response,
  });
}

function* followGroup(action) {
  const { payload: groupId } = action;

  const response = yield call(api.followGroup, groupId);
  const meta = {};
  const successAction = groupsSlice.actions.followGroupSuccess;

  // format function will format response to pass into redux
  const format = responseProp => {
    const { followed } = responseProp.data.meta;
    return { groupId, followed };
  };

  if (response.ok) {
    const { followed } = response.data.meta;
    meta.successMsg = followed ? 'Joined Group' : 'Left Group';

    // update profile
    const profile = response.data.data;
    const isOnboarded = profile?.isOnboarded;
    if (isOnboarded !== undefined)
      yield put(ProfileActions.updateOnboardingStatus(!!isOnboarded));
  }

  yield call(handleResponse, {
    successAction,
    response,
    meta,
    format,
  });
}

function* followGroups(action) {
  yield delay(400);

  const { payload: groupIds } = action;

  const response = yield call(api.followGroups, groupIds);
  const successAction = groupsSlice.actions.followGroupsSuccess;

  if (response.ok) {
    // update profile
    const profile = response.data.data.profile;
    const isOnboarded = profile?.isOnboarded;

    if (isOnboarded !== undefined) {
      yield put(ProfileActions.updateOnboardingStatus(!!isOnboarded));
    }
  }

  yield call(handleResponse, {
    successAction,
    response,
  });
}

function* updateGroupUnread(action) {
  const { payload: groupId } = action;

  const response = yield call(api.updateReadCount, groupId);

  const format = () => groupId;

  const successAction = groupsSlice.actions.updateGroupUnreadSuccess;

  yield call(handleResponse, {
    successAction,
    format,
    response,
  });
}

function* followGroupsSuccess() {
  const isTrendingFeed = Router.query?.trending === 'true';

  if (!isTrendingFeed) {
    yield put(
      feedsSlice.actions.getFeedsRequest({
        page: 1,
        per_page: 10,
        feedFilter: 'recent',
      }),
    );
  }
}

export const groupSagas = [
  takeLatest(groupsSlice.actions.getGroupsRequest, getGroups),
  takeLatest(groupsSlice.actions.followGroupRequest, followGroup),
  takeLatest(groupsSlice.actions.followGroupsRequest, followGroups),
  takeLatest(groupsSlice.actions.followGroupsSuccess, followGroupsSuccess),
  takeLatest(groupsSlice.actions.updateGroupUnreadRequest, updateGroupUnread),
];

export const SAVE_TYPE = {
  ARTICLE: 'article',
  OPINION: 'opinion',
  POST: 'post',
  ANSWER: 'comment',
  REPLY: 'replie',
};

export const SHARE_TYPE = {
  QUESTION: 'question',
  ANSWER: 'answer',
  REVIEW: 'review',
  TOOL: 'tool',
  PRODUCT: 'product',
  OPINION: 'opinion',
  AFTERPOSTQUESTION: 'afterPostQuestion',
  AFTERPOSTANSWER: 'afterPostAnswer',
  AFTERPOSTREVIEW: 'afterPostReview',
  AFTERPOSTOPINION: 'afterPostOpinion',
  ARTICLE: 'article',
};

export const SHARE_TITLE = {
  [SHARE_TYPE.QUESTION]: 'Post',
  [SHARE_TYPE.ANSWER]: 'Comment',
  [SHARE_TYPE.REVIEW]: 'Review',
  [SHARE_TYPE.OPINION]: 'Opinion',
  [SHARE_TYPE.TOOL]: 'Tool',
  [SHARE_TYPE.PRODUCT]: 'Product',
  [SHARE_TYPE.ARTICLE]: 'Share article',
  [SHARE_TYPE.AFTERPOSTQUESTION]: 'Your Post',
  [SHARE_TYPE.AFTERPOSTANSWER]: 'Your Comment',
  [SHARE_TYPE.AFTERPOSTREVIEW]: 'Your Review',
  [SHARE_TYPE.AFTERPOSTOPINION]: '', // ? No title for this type
};

export const ANON_TYPE = {
  QUESTION: 'question',
  ANSWER: 'answer',
  COMMENT: 'comment',
};

export const UPVOTER_TYPE = {
  REVIEW: 'review',
  OPINION: 'opinion',
  POST: 'post',
  COMMENT: 'comment',
};

export const COMMENT_TYPE = {
  POST: 'post',
  DELETE: 'delete',
};

export const COMMENTABLE_TYPE = {
  QUESTION: 'question',
  COMMENT: 'comment',
  REVIEW: 'review',
  OPINION: 'opinion',
};

export const FOLLOWABLE_TYPE = {
  TOPIC: 'topic',
  QUESTION: 'question',
};

export const PAGE_TYPE = {
  ABOUT: 'about',
  FAQ: 'faq',
  PRIVACY: 'privacy',
  SECURITY: 'security',
  TERMS: 'terms',
  SEARCH: 'search',
  SAVINGS_ACCOUNT_CALCULATOR: 'savingsAccountCalculator',
};

export const PRISMIC_BANNER_TYPE = {
  PRODUCT: 'product',
  PRODUCT_DETAILS_ABOUT: 'productDetailsAbout',
  PRODUCT_DETAILS_SEO: 'productDetailsSeo',
  COMPARISON: 'comparison',
};

export const BADGES_TYPE = {
  ICON: 'icon',
  RECENT: 'recent',
  GROUP: 'group',
};

export const MARKETING_BANNER_TYPE = {
  DESKTOP: 'desktop',
  MOBILE: 'mobile',
};

export const OEM_SLUG = 'open-electricity-market';
export const MOBILE_SLUG = 'sim-only-mobile-plans';

export const COMMUNITY_LOCKED_QNS_URL =
  'https://support.seedly.sg/hc/en-us/articles/900000136506-Questions-Answers-QnA-';
export const COMMUNITY_GUIDELINE_URL =
  'https://support.seedly.sg/hc/en-us/articles/360000338461-Complete-Seedly-Community-Guidelines';

import { call, put, takeLatest } from 'redux-saga/effects';
import ApiError from 'app/api/error';
import api from 'app/api/reviewApi';
import prismicApi from 'app/api/prismicApi';
import ProfileAction from 'app/reducer/profileRedux';
import ReviewAction, { ReviewTypes } from 'app/reducer/reviewRedux';
import { ModalTypes } from 'app/reducer/modalRedux';
import { rewardsSlice } from 'entity/reward/rewardRedux';

import { generateReviewLink } from 'app/utils/generateShareLink';
import { commentsSlice } from 'app/entity/comment/commentRedux';
import { PROFILE_FILTER_TYPE } from 'app/constants/profile';
import get from 'lodash/get';
import curry from 'lodash/curry';
import noop from 'lodash/noop';
import { handleResponse } from 'app/utils/sagaHelper';
import { constructRewardCreditPayload } from 'app/entity/reward/rewardUtils';

const formatter = (meta, response) => {
  return {
    reviews: response.data.data,
    pagination: response.data.meta.pagination,
    ...meta,
  };
};

function* getUserReviews(action) {
  const { payload, meta } = action;
  const isInit = get(meta, 'isInit', false);
  const isFetchingMoreReviews = get(meta, 'isFetchingMoreReviews', false);

  yield put(
    ReviewAction.updateReviewMeta({
      isFetching: !isInit,
      isFetchingMoreReviews,
    }),
  );

  yield put(
    ProfileAction.updateProfileMeta({
      filter: PROFILE_FILTER_TYPE.REVIEWS,
    }),
  );

  const response = yield call(api.getUserReviews, payload);
  const successAction = ReviewAction.getUserReviewsSuccess;
  const failureAction = ReviewAction.getUserReviewsFailure;
  const format = curry(formatter)(meta);
  yield call(handleResponse, {
    successAction,
    failureAction,
    meta,
    response,
    format,
  });
}

function* voteReview(action) {
  const { payload, meta } = action;

  const response = yield call(api.voteReview, payload);
  const successAction = ReviewAction.voteReviewSuccess;
  const failureAction = ReviewAction.voteReviewFailure;
  meta.failureMsg = get(response, 'data.message', null);
  yield call(handleResponse, { successAction, failureAction, meta, response });
}

function* getProductReviews(action) {
  const { payload, meta } = action;
  const isInit = get(meta, 'isInit', false);
  const isFetchingMoreReviews = get(meta, 'isFetchingMoreReviews', false);

  yield put(
    ReviewAction.updateReviewMeta({
      isFetching: !isInit,
      isFetchingMoreReviews,
    }),
  );

  const response = yield call(api.getProductReviews, payload);
  const successAction = ReviewAction.getProductReviewsSuccess;
  const failureAction = ReviewAction.getProductReviewsFailure;
  const format = curry(formatter)(meta);
  yield call(handleResponse, {
    successAction,
    failureAction,
    meta,
    response,
    format,
  });
}

function* getReviewsSuccess(action) {
  const { includeLatestComment, reviews } = action.payload;
  if (includeLatestComment) {
    const reviewComments = reviews
      .filter(review => !!review.featuredComment)
      .map(review => {
        return {
          pagination: {
            currentPage: 0, // trivia because initial
            per: 1,
            totalPage: review.commentsCount,
            totalCount: review.commentsCount,
          },
          commentableId: review.id,
          comments: [review.featuredComment],
        };
      });

    yield put(
      commentsSlice.actions.getFeaturedCommentsSuccess({
        commentsByCommentable: reviewComments,
        commentableType: 'Review',
      }),
    );
  }
}

function* getReviewFilter(action) {
  const { payload, meta } = action;

  const response = yield call(api.getReviewFilterTag, payload);
  const successAction = ReviewAction.getReviewFilterSuccess;
  const failureAction = ReviewAction.getReviewFilterFailure;
  yield call(handleResponse, { successAction, failureAction, meta, response });
}

function* postReview(action) {
  const { payload, meta } = action;

  const response = yield call(api.postReview, payload);
  const successAction = ReviewAction.postReviewSuccess;
  const failureAction = ReviewAction.postReviewFailure;
  meta.successMsg = 'Review posted.';

  yield call(handleResponse, { successAction, failureAction, meta, response });

  if (response.ok) {
    const { id, product } = response.data.data;
    const { slug, categorySlug } = product;

    const shareLink = generateReviewLink(categorySlug, slug, id);
    const successPayload = constructRewardCreditPayload(response, shareLink);

    yield put(rewardsSlice.actions.handleEarnCredit(successPayload));
  }
}

function* updateReview(action) {
  const { payload, meta } = action;

  const response = yield call(api.updateReview, payload);
  const successAction = ReviewAction.updateReviewSuccess;
  const failureAction = ReviewAction.updateReviewFailure;
  meta.successMsg = 'Review updated.';

  yield call(handleResponse, { successAction, failureAction, meta, response });
}

function* deleteReview(action) {
  const { payload, meta } = action;

  const response = yield call(api.deleteReview, payload);
  const successAction = ReviewAction.deleteReviewSuccess;
  const failureAction = ReviewAction.deleteReviewFailure;
  meta.successMsg = 'Review deleted.';
  meta.failureMsg = 'An error occured. Failed to delete review.';
  const format = () => payload.reviewId;

  yield call(handleResponse, {
    successAction,
    failureAction,
    meta,
    response,
    format,
  });
}

function* setReviewForm(action) {
  const { meta } = action;
  yield put(ReviewAction.setReviewForm(meta));
}

function* setReviewListModalProduct(action) {
  const { meta } = action;
  yield put(ReviewAction.setReviewListModalProduct(meta));
}

// Prismic API Section

function* getProductDetailPrismic(action) {
  const { payload, meta } = action;
  const resolve = get(meta, 'resolve', noop);
  const reject = get(meta, 'reject', noop);
  const response = yield call(() =>
    prismicApi('my.seo_story_product_details.uid', payload),
  );
  if (response) {
    const results = get(response, 'results[0].data', {});
    yield put(ReviewAction.getProductPrismicSuccess(results));
    yield call(resolve);
  } else {
    const apiError = new ApiError(response);
    yield call(() => reject(apiError));
  }
}

export const reviewSagas = [
  takeLatest(ReviewTypes.GET_USER_REVIEWS_REQUEST, getUserReviews),
  takeLatest(ReviewTypes.GET_USER_REVIEWS_SUCCESS, getReviewsSuccess),
  takeLatest(ReviewTypes.GET_REVIEW_FILTER_REQUEST, getReviewFilter),
  takeLatest(ReviewTypes.VOTE_REVIEW_REQUEST, voteReview),
  takeLatest(ReviewTypes.GET_PRODUCT_PRISMIC_REQUEST, getProductDetailPrismic),
  takeLatest(ReviewTypes.GET_PRODUCT_REVIEWS_REQUEST, getProductReviews),
  takeLatest(ReviewTypes.GET_PRODUCT_REVIEWS_SUCCESS, getReviewsSuccess),
  takeLatest(ReviewTypes.POST_REVIEW_REQUEST, postReview),
  takeLatest(ReviewTypes.UPDATE_REVIEW_REQUEST, updateReview),
  takeLatest(ReviewTypes.DELETE_REVIEW_REQUEST, deleteReview),
  takeLatest(ModalTypes.SET_POST_REVIEW_MODAL, setReviewForm),
  takeLatest(ModalTypes.SET_REVIEW_LIST_MODAL, setReviewListModalProduct),
];

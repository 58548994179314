import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  FileText,
  MessageSquare,
  Users,
  X as CloseButton,
} from 'react-feather';
import Button from 'seedly-component-library/button-new';
import Dialog from 'seedly-component-library/dialog';
import Link from 'components/misc/Link';
import { closeRewardWelcomeModal } from 'dispatcher/modalDispatcher';
import { rewardOnboarding } from 'theme';
import { getIsOpinionWriter } from 'reducer/profileRedux';
import { useMigratedRewardUser } from 'entity/user/userHooks';
import { TierMap } from 'entity/reward/rewardTypes';
import { useRouter } from 'next/router';
import { RootState } from 'reducer';
import Image from 'next/image';
import RewardCtaCard from '../RewardCtaCard';
import BackgroundLottie from './BackgroundLottie';

const RewardWelcomeModal = () => {
  const isOpinionWriter = useSelector(getIsOpinionWriter);
  const isMigratedRewardUser = useMigratedRewardUser();
  const router = useRouter();
  const dispatch = useDispatch();
  const profile = useSelector((state: RootState) => state.profile.user);
  const isModalOpen = useSelector(
    (state: RootState) => state.modal.isRewardWelcomeModalOpen,
  );

  const CTAData = [
    {
      icon: <Users />,
      actionText: 'Start a Discussion',
      creditEarn: 100,
      route: '/community?openPostForm=true',
    },
    {
      icon: <FileText />,
      actionText: 'Publish an Opinion',
      creditEarn: 100,
      route: isOpinionWriter ? '/post-opinion' : '/c/seedly-opinions ',
    },
    {
      icon: <MessageSquare />,
      actionText: 'Comment/Reply',
      creditEarn: 100,
      route: '/community',
    },
    {
      icon: <Image src={rewardOnboarding.star} height={24} width={24} />,
      actionText: 'Write a Review',
      creditEarn: 200,
      route: '/reviews',
    },
  ];

  return (
    <Dialog
      isOpen={isModalOpen}
      widthSize="sm"
      contentClassName="p-0  overflow-y-scroll"
    >
      <div
        className="absolute top-4 right-4 z-10 cursor-pointer"
        onClick={() => dispatch(closeRewardWelcomeModal())}
      >
        <CloseButton color="white" />
      </div>
      <div className="flex flex-col justify-center gap-4 items-center w-full h-[210px] bg-purple-200 rounded-t-2xl">
        <div className="absolute z-0 top-2">
          <BackgroundLottie />
        </div>
        {!isMigratedRewardUser ? (
          <div className="flex flex-col items-center">
            <p className="text-white">Welcome Bonus Credits</p>
            <p className="text-4xl font-extrabold text-white">+1000</p>
          </div>
        ) : (
          <div className="flex items-center gap-10">
            <div className="flex flex-col items-center">
              <p className="text-white">New Tier</p>
              <p className="text-4xl font-extrabold text-white">
                {TierMap[profile.tier || 0]}
              </p>
            </div>
            <div className="flex flex-col items-center">
              <p className="text-white">Your New Credits</p>
              <p className="text-4xl font-extrabold text-white">
                +{profile.creditsCount || 0}
              </p>
            </div>
          </div>
        )}
      </div>
      <div className="flex flex-col items-center w-full gap-4 p-4 relative z-10 bg-transparent pb-12">
        <div className="flex flex-col items-center gap-2">
          <p className="text-xl font-bold">Welcome to SeedlyRewards!</p>
          {!isMigratedRewardUser ? (
            <p className="text-center text-neutral-500">
              You’ve earned 1,000 credits as a welcome bonus. Earn more credits
              with these actions!
            </p>
          ) : (
            <p className="text-center text-neutral-500">
              You’ve earned{' '}
              <span className="font-bold">+{profile.creditsCount} credits</span>{' '}
              based on your past contributions plus the welcome bonus. Earn more
              credits by completing these actions.
            </p>
          )}
        </div>
        <div className="grid grid-cols-2 gap-4 w-full lg:w-auto">
          {CTAData.map((data, index) => (
            <Link key={index} href={data.route} passHref>
              <a
                onClick={() => {
                  dispatch(closeRewardWelcomeModal());
                }}
              >
                <RewardCtaCard
                  icon={data.icon}
                  actionText={data.actionText}
                  creditEarn={data.creditEarn}
                />
              </a>
            </Link>
          ))}
        </div>
        <Button
          onClick={() => {
            router.push('/rewards');
            dispatch(closeRewardWelcomeModal());
          }}
          isBlock
          size="lg"
        >
          Discover Reward
        </Button>
      </div>
    </Dialog>
  );
};

export default RewardWelcomeModal;

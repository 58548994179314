import { createReducer, createActions } from 'reduxsauce';
import produce from 'immer';

const { Types, Creators } = createActions({
  signInRequest: ['payload', 'meta'],
  signInFailure: ['payload'],

  authUserTokenRequest: ['payload', 'meta'],
  authUserTokenSuccess: ['payload'],
  authUserTokenFailure: ['payload'],

  signUpRequest: ['payload', 'meta'],
  signUpFailure: ['payload'],

  signOutRequest: ['payload'],
  signOutSuccess: ['payload'],

  forgetPassword: ['payload', 'meta'],
  updateUserProfileSuccess: ['payload'],
  updateEmailRequest: ['payload', 'meta'],
  updatePasswordRequest: ['payload', 'meta'],
  setUnverifiedEmail: ['payload'],
  setAuthErrorMessage: ['payload'],
  setSessionStorageVote: ['payload'],
  setAuthStatus: ['payload'],
});

const INITIAL_STATE = {
  api: {
    isIdle: true,
    isFetching: false,
    errorMessage: '',
  },
  session: { isSignedIn: false },
  user: {},
  unverifiedEmail: null,
};

export const AuthTypes = Types;
export default Creators;

const setApiRequest = state => {
  return produce(state, draft => {
    draft.api.isFetching = true;
    draft.api.isIdle = false;
    draft.api.errorMessage = '';
  });
};

const setApiFailure = (state, action) => {
  const { payload } = action;

  return produce(state, draft => {
    draft.api.isFetching = false;
    draft.api.errorMessage = payload.errorMessage;
  });
};

const setAuthErrorMessage = (state, action) => {
  return produce(state, draft => {
    draft.api.errorMessage = action.payload;
  });
};

const authUserTokenSuccess = (state, action) => {
  const { payload } = action;
  return produce(state, draft => {
    draft.user = payload;
    draft.session = { isSignedIn: true };
    draft.api.isFetching = false;
  });
};

const signOutSuccess = state => {
  return produce(state, draft => {
    draft.session = { isSignedIn: false };
    draft.user = {};
  });
};

const updateUserProfileSuccess = (state, action) => {
  const { payload: profile } = action;

  return produce(state, draft => {
    draft.user.image = profile.image;
  });
};

const setUnverifiedEmail = (state, action) => {
  const { payload } = action;

  return produce(state, draft => {
    draft.unverifiedEmail = payload;
  });
};

const setAuthStatus = (state, action) => {
  const { payload } = action;

  return produce(state, draft => {
    draft.api.isIdle = payload.isIdle;
  });
};

export const reducer = createReducer(INITIAL_STATE, {
  [Types.SIGN_IN_REQUEST]: setApiRequest,
  [Types.SIGN_IN_FAILURE]: setApiFailure,
  [Types.SIGN_UP_REQUEST]: setApiRequest,
  [Types.SIGN_UP_FAILURE]: setApiFailure,
  [Types.AUTH_USER_TOKEN_REQUEST]: setApiRequest,
  [Types.AUTH_USER_TOKEN_SUCCESS]: authUserTokenSuccess,
  [Types.AUTH_USER_TOKEN_FAILURE]: setApiFailure,
  [Types.SET_AUTH_ERROR_MESSAGE]: setAuthErrorMessage,
  [Types.SIGN_OUT_SUCCESS]: signOutSuccess,
  [Types.UPDATE_USER_PROFILE_SUCCESS]: updateUserProfileSuccess,
  [Types.SET_UNVERIFIED_EMAIL]: setUnverifiedEmail,
  [Types.SET_AUTH_STATUS]: setAuthStatus,
});

import { createReducer, createActions } from 'reduxsauce';
import produce from 'immer';
import isEmpty from 'lodash/isEmpty';
import { LOGIN_MODAL, TYPES as LOGIN_MODAL_TYPES } from 'app/constants/login';
import {
  REPORT_MODAL,
  TYPES as REPORT_MODAL_TYPES,
} from 'app/constants/report';

const { Types, Creators } = createActions({
  setPostModal: ['payload', 'meta'],
  setPostAnswerModal: ['payload', 'meta'],
  setPostChildCommentModal: ['payload', 'meta'],
  setLoginModal: ['payload', 'meta'],
  setShareModal: ['payload', 'meta'],
  setNameModal: ['payload', 'meta'],
  setLevelModal: ['payload', 'meta'],
  setPostCredentialModal: ['payload', 'meta'],
  setPostReviewModal: ['payload', 'meta'],
  setReviewListModal: ['payload', 'meta'],
  setPlanReqModal: ['payload', 'meta'],
  setDeletedPostNotifModal: ['payload', 'meta'],
  setReportModal: ['payload', 'meta'],
  setDeleteOpinionModal: ['payload', 'meta'],
  setDeleteDraftModal: ['payload', 'meta'],
  setRewardModal: ['payload', 'meta'],
  setVerifyNumberModal: ['payload', 'meta'],
  setRewardLaunchModal: ['payload'],
  setRewardWelcomeModal: ['payload'],
  setJoinGroupModal: ['payload', 'meta'],
  setSignUpCompleteModal: ['payload'],
  setCreditHistoryModal: ['payload'],
  setCreditEarnModal: ['payload', 'meta'],
  setTierUpModal: ['payload'],
  setDailyCheckInRewardModal: ['payload'],
  setDailyCheckInSuccessModal: ['payload'],
  setDailyCheckInPointsModal: ['payload', 'meta'],
  setClaimRewardSuccessModal: ['payload'],
  setClaimRewardFailureModal: ['payload'],
});

const INITIAL_STATE = {
  isPostQuestionModalOpen: false,
  isLoginModalOpen: false,
  isShareModalOpen: false,
  isNameModalOpen: false,
  isPostAnswerModalOpen: false,
  isPostCommentModalOpen: false,
  isPostCredentialModalOpen: false,
  isPostReviewModalOpen: false,
  isReviewListModalOpen: false,
  isPlanReqModal: false,
  isLevelModalOpen: false,
  isDeletedPostNotifModalOpen: false,
  deletedPostNotifModalMeta: {},
  isReportModalOpen: false,
  loginModalMeta: {
    ...LOGIN_MODAL[LOGIN_MODAL_TYPES.DEFAULT],
  },
  reportModalMeta: {
    data: REPORT_MODAL[REPORT_MODAL_TYPES.POST],
    commentableType: null,
    id: null,
    commentId: null,
  },
  isDeleteOpinionModalOpen: false,
  isDeleteDraftModalOpen: false,
  deleteOpinionModalMeta: {
    id: null,
  },
  isRewardModalOpen: false,
  rewardModalMeta: {
    rewardId: null,
    claimId: null,
  },
  isVerifyNumberModalOpen: false,
  verifyNumberModalMeta: {
    isUpdating: false,
  },
  isRewardLaunchModalOpen: false,
  isRewardWelcomeModalOpen: false,
  isJoinGroupModalOpen: false,
  joinGroupModalMeta: {
    isSigningUp: false,
  },
  isSignUpCompleteModalOpen: false,
  isCreditHistoryModalOpen: false,
  isCreditEarnModalOpen: false,
  creditEarnModalMeta: {
    shareLink: '',
    maxMonthlyCreditReached: false,
    creditsEarned: 0,
  },
  isTierUpModalOpen: false,
  isDailyCheckInRewardModalOpen: false,
  isDailyCheckInSuccessModalOpen: false,
  isDailyCheckInPointsModalOpen: false,
  dailyCheckInModalMeta: {
    maxMonthlyCreditReached: false,
  },
  isClaimRewardSuccessModalOpen: false,
  isClaimRewardFailureModalOpen: false,
};

export const ModalTypes = Types;
export default Creators;

const setPostModal = (state, action) => {
  const { isOpen } = action.payload;

  return produce(state, draft => {
    draft.isPostQuestionModalOpen = isOpen;
  });
};

const setLoginModal = (state, action) => {
  const { payload, meta } = action;

  return produce(state, draft => {
    draft.isLoginModalOpen = payload;
    draft.loginModalMeta = meta || LOGIN_MODAL[LOGIN_MODAL_TYPES.DEFAULT];
  });
};

const setShareModal = (state, action) => {
  const { payload } = action;
  return produce(state, draft => {
    draft.isShareModalOpen = payload;
  });
};

const setRewardModal = (state, action) => {
  const { payload, meta = {} } = action;
  return produce(state, draft => {
    draft.isRewardModalOpen = payload;
    if (meta.rewardId) {
      draft.rewardModalMeta.rewardId = meta.rewardId;
    }
    if (meta.claimId) {
      draft.rewardModalMeta.claimId = meta.claimId;
    }
  });
};

const setLevelModal = (state, action) => {
  const { payload } = action;
  return produce(state, draft => {
    draft.isLevelModalOpen = payload;
  });
};

const setNameModal = (state, action) => {
  const { payload } = action;
  return produce(state, draft => {
    draft.isNameModalOpen = payload;
  });
};

const setPostAnswerModal = (state, action) => {
  const { payload } = action;
  return produce(state, draft => {
    draft.isPostAnswerModalOpen = payload;
  });
};

const setPostCredentialModal = (state, action) => {
  const { payload } = action;
  return produce(state, draft => {
    draft.isPostCredentialModalOpen = payload;
  });
};

const setPostReviewModal = (state, action) => {
  const { payload } = action;
  return produce(state, draft => {
    draft.isPostReviewModalOpen = payload;
  });
};

const setReviewListModal = (state, action) => {
  const { payload } = action;
  return produce(state, draft => {
    draft.isReviewListModalOpen = payload;
  });
};

const setPlanReqModal = (state, action) => {
  const { payload } = action;
  return produce(state, draft => {
    draft.isPlanReqModalOpen = payload;
  });
};

const setDeletedPostNotifModal = (state, action) => {
  const { payload, meta } = action;
  return produce(state, draft => {
    draft.isDeletedPostNotifModalOpen = payload;
    draft.deletedPostNotifModalMeta = meta || {};
  });
};

const setReportModal = (state, action) => {
  const { payload, meta } = action;
  return produce(state, draft => {
    draft.isReportModalOpen = payload;
    draft.reportModalMeta = isEmpty(meta)
      ? {
          data: REPORT_MODAL[REPORT_MODAL_TYPES.POST],
          commentableType: null,
          commentId: null,
          id: null,
        }
      : meta;
  });
};

const setDeleteOpinionModal = (state, action) => {
  const { payload, meta } = action;
  return produce(state, draft => {
    draft.isDeleteOpinionModalOpen = payload;
    draft.deleteOpinionModalMeta = meta;
  });
};

const setDeleteDraftModal = (state, action) => {
  const { payload, meta } = action;
  return produce(state, draft => {
    draft.isDeleteDraftModalOpen = payload;
    draft.deleteOpinionModalMeta = meta;
  });
};

const setVerifyNumberModal = (state, action) => {
  const { payload, meta } = action;
  return produce(state, draft => {
    draft.isVerifyNumberModalOpen = payload;
    draft.verifyNumberModalMeta = meta;
  });
};

const setRewardLaunchModal = (state, action) => {
  const { payload } = action;
  return produce(state, draft => {
    draft.isRewardLaunchModalOpen = payload;
  });
};

const setRewardWelcomeModal = (state, action) => {
  const { payload } = action;
  return produce(state, draft => {
    draft.isRewardWelcomeModalOpen = payload;
  });
};

const setJoinGroupModal = (state, action) => {
  const { payload, meta } = action;
  return produce(state, draft => {
    draft.isJoinGroupModalOpen = payload;
    draft.joinGroupModalMeta = meta;
  });
};

const setSignUpCompleteModal = (state, action) => {
  const { payload } = action;
  return produce(state, draft => {
    draft.isSignUpCompleteModalOpen = payload;
  });
};

const setCreditHistoryModal = (state, action) => {
  const { payload } = action;
  return produce(state, draft => {
    draft.isCreditHistoryModalOpen = payload;
  });
};

const setCreditEarnModal = (state, action) => {
  const { payload, meta } = action;
  return produce(state, draft => {
    draft.isCreditEarnModalOpen = payload;
    draft.creditEarnModalMeta.shareLink = meta?.shareLink || '';
    draft.creditEarnModalMeta.creditsEarned = meta?.creditsEarned || 0;
    draft.creditEarnModalMeta.maxMonthlyCreditReached =
      meta?.maxMonthlyCreditReached || false;
  });
};

const setTierUpModal = (state, action) => {
  const { payload } = action;
  return produce(state, draft => {
    draft.isTierUpModalOpen = payload;
  });
};

const setDailyCheckInRewardModal = (state, action) => {
  const { payload } = action;
  return produce(state, draft => {
    draft.isDailyCheckInRewardModalOpen = payload;
  });
};

const setDailyCheckInSuccessModal = (state, action) => {
  const { payload } = action;
  return produce(state, draft => {
    draft.isDailyCheckInSuccessModalOpen = payload;
  });
};

const setDailyCheckInPointsModal = (state, action) => {
  const { payload, meta } = action;
  return produce(state, draft => {
    draft.isDailyCheckInPointsModalOpen = payload;
    draft.dailyCheckInModalMeta.maxMonthlyCreditReached =
      meta?.maxMonthlyCreditReached || false;
  });
};

const setClaimRewardSuccessModal = (state, action) => {
  const { payload } = action;
  return produce(state, draft => {
    draft.isClaimRewardSuccessModalOpen = payload;
  });
};

const setClaimRewardFailureModal = (state, action) => {
  const { payload } = action;
  return produce(state, draft => {
    draft.isClaimRewardFailureModalOpen = payload;
  });
};

export const reducer = createReducer(INITIAL_STATE, {
  [Types.SET_POST_MODAL]: setPostModal,
  [Types.SET_LOGIN_MODAL]: setLoginModal,
  [Types.SET_SHARE_MODAL]: setShareModal,
  [Types.SET_LEVEL_MODAL]: setLevelModal,
  [Types.SET_NAME_MODAL]: setNameModal,
  [Types.SET_REWARD_MODAL]: setRewardModal,
  [Types.SET_POST_ANSWER_MODAL]: setPostAnswerModal,
  [Types.SET_POST_CREDENTIAL_MODAL]: setPostCredentialModal,
  [Types.SET_POST_REVIEW_MODAL]: setPostReviewModal,
  [Types.SET_REVIEW_LIST_MODAL]: setReviewListModal,
  [Types.SET_PLAN_REQ_MODAL]: setPlanReqModal,
  [Types.SET_DELETED_POST_NOTIF_MODAL]: setDeletedPostNotifModal,
  [Types.SET_REPORT_MODAL]: setReportModal,
  [Types.SET_DELETE_OPINION_MODAL]: setDeleteOpinionModal,
  [Types.SET_DELETE_DRAFT_MODAL]: setDeleteDraftModal,
  [Types.SET_VERIFY_NUMBER_MODAL]: setVerifyNumberModal,
  [Types.SET_REWARD_LAUNCH_MODAL]: setRewardLaunchModal,
  [Types.SET_REWARD_WELCOME_MODAL]: setRewardWelcomeModal,
  [Types.SET_JOIN_GROUP_MODAL]: setJoinGroupModal,
  [Types.SET_SIGN_UP_COMPLETE_MODAL]: setSignUpCompleteModal,
  [Types.SET_CREDIT_HISTORY_MODAL]: setCreditHistoryModal,
  [Types.SET_CREDIT_EARN_MODAL]: setCreditEarnModal,
  [Types.SET_TIER_UP_MODAL]: setTierUpModal,
  [Types.SET_DAILY_CHECK_IN_REWARD_MODAL]: setDailyCheckInRewardModal,
  [Types.SET_DAILY_CHECK_IN_SUCCESS_MODAL]: setDailyCheckInSuccessModal,
  [Types.SET_DAILY_CHECK_IN_POINTS_MODAL]: setDailyCheckInPointsModal,
  [Types.SET_CLAIM_REWARD_SUCCESS_MODAL]: setClaimRewardSuccessModal,
  [Types.SET_CLAIM_REWARD_FAILURE_MODAL]: setClaimRewardFailureModal,
});

import apisauce from 'apisauce';

import buildConfig, { config } from 'utils/buildConfig';
import getAuthHeaders from 'utils/getAuthHeaders';

export interface ResponseData<DataType, MetaType = any> {
  code: 'ok' | 'created' | 'accepted';
  data: DataType;
  message: string;
  meta: MetaType;
}

const api = apisauce.create({
  baseURL: buildConfig.api,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    ...getAuthHeaders(),
  },
  timeout: 15000,
});

export const prodApi = apisauce.create({
  baseURL: config.production.api,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    ...getAuthHeaders(),
  },
  timeout: 15000,
});

// This is to prevent bugs on production where it set access token header to undefined.
// Therefore, it will delete the header if it is undefined.
api.addRequestTransform(request => {
  if (!request.headers['access-token']) {
    delete request.headers['access-token'];
  }
});

export default api;

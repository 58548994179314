import React from 'react';
import { useRouter } from 'next/router';
import { useDispatch } from 'react-redux';
import Button from 'seedly-component-library/button-new';
import buildConfig from 'utils/buildConfig';
import {
  closeDailyCheckInRewardModal,
  openPostModal,
} from 'dispatcher/modalDispatcher';
import mixpanel, { trackedEvents } from 'utils/mixpanel';

const EarnMore = () => {
  const router = useRouter();
  const dispatch = useDispatch();
  return (
    <div className="p-4 bg-white rounded w-full">
      <div className="flex flex-col gap-4 w-full">
        <p className="text-sm font-bold">Earn More Credits</p>
        <div className="flex items-center justify-between w-full">
          <div className="flex flex-col gap-1">
            <p>Start a discussion</p>
            <p className="text-xs text-neutral-500">Credits +100</p>
          </div>
          <Button
            variant="outline"
            onClick={() => {
              dispatch(closeDailyCheckInRewardModal());
              if (router.asPath.indexOf('/community/') === 0) {
                dispatch(openPostModal());
              } else {
                router.push(
                  `${buildConfig.apiHost}/community?openPostForm=true`,
                );
              }

              mixpanel.track(trackedEvents.RewardsCollection, {
                param: 'start_a_discussion',
              });
            }}
          >
            Go
          </Button>
        </div>
        <div className="flex items-center justify-between w-full">
          <div className="flex flex-col gap-1">
            <p>Leave a comment</p>
            <p className="text-xs text-neutral-500">Credits +100</p>
          </div>
          <Button
            variant="outline"
            onClick={() => {
              dispatch(closeDailyCheckInRewardModal());
              router.push(`${buildConfig.apiHost}/community`);
              mixpanel.track(trackedEvents.RewardsCollection, {
                param: 'leave_a_comment',
              });
            }}
          >
            Go
          </Button>
        </div>
        <div className="flex items-center justify-between w-full">
          <div className="flex flex-col gap-1">
            <p>Write a product review</p>
            <p className="text-xs text-neutral-500">Credits +200</p>
          </div>
          <Button
            variant="outline"
            onClick={() => {
              dispatch(closeDailyCheckInRewardModal());
              router.push(`${buildConfig.apiHost}/reviews`);
              mixpanel.track(trackedEvents.RewardsCollection, {
                param: 'write_a_review',
              });
            }}
          >
            Go
          </Button>
        </div>
      </div>
    </div>
  );
};

export default EarnMore;

import colors from '../themes/colors';

export const SpinnerTheme = {
  default: {
    fgColor: colors.seedlyBlue,
    bgColor: colors.blue2,
  },
  primary: {
    fgColor: colors.neutral1,
    bgColor: colors.blue4,
  },
};

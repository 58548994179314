import React from 'react';
import { Provider } from 'react-redux';
import { useRouter } from 'next/router';
import withRedux from 'next-redux-wrapper';
import withReduxSaga from 'next-redux-saga';
import * as Sentry from '@sentry/react';
import { ThemeProvider } from 'styled-components';
import get from 'lodash/get';

import { configureStore } from 'reducer';

import BannedDetail from 'app/components/misc/BannedDetail';
import ErrorPage from 'app/components/misc/ErrorPage';
import Scripts from 'app/components/misc/Scripts';

import { themes } from 'seedly-component-library/themes';

import { SystemInfoContext } from 'app/context';
import useProgressBar from 'app/utils/hooks/useProgressBar';

import '../styles/global.css';
import { GlobalStyle } from '../theme';

const SeedlyApp = ({ Component, pageProps, systemInfo, store, error }) => {
  const router = useRouter();
  useProgressBar();

  const isBanned = store.getState().auth.user.banned;
  const FallbackErrorPage = () => <ErrorPage statusCode={500} />;

  const InternalComponent = error ? (
    <ErrorPage path={router.pathname} apiError={error} />
  ) : (
    <Component {...pageProps} />
  );

  return (
    <>
      <Scripts />
      <GlobalStyle />
      <Sentry.ErrorBoundary fallback={FallbackErrorPage}>
        <Provider store={store}>
          <ThemeProvider theme={themes}>
            <SystemInfoContext.Provider value={systemInfo}>
              {!isBanned ? InternalComponent : <BannedDetail />}
            </SystemInfoContext.Provider>
          </ThemeProvider>
        </Provider>
      </Sentry.ErrorBoundary>
    </>
  );
};

SeedlyApp.getInitialProps = async appContext => {
  const { Component, ctx } = appContext;

  try {
    let pageProps = {};
    let systemInfo = {};

    if (ctx.req?.useragent) {
      const ua = ctx.req.useragent;
      systemInfo = { isMobile: ua.isMobile };
    }

    if (Component.getInitialProps) {
      pageProps = await Component.getInitialProps(ctx);
      return { pageProps, systemInfo };
    }

    return { ...pageProps, systemInfo };
  } catch (error) {
    let code = get(error, 'statusCode', 500);
    if (ctx.res) {
      if (code === 400) code = 404;
      if (!code) code = 500;
      ctx.res.statusCode = code;
    }

    return { error };
  }
};

export default withRedux(configureStore)(withReduxSaga(SeedlyApp));

import buildConfig from 'app/utils/buildConfig';

export const generatePostLink = slug => {
  return `${buildConfig.apiHost}/posts/${slug}?utm_source=question_share&utm_medium=internal`;
};

export const generateCommentLink = slug => {
  return `${buildConfig.apiHost}/posts/${slug}?&utm_source=answer_share&utm_medium=internal`;
};

export const generateReviewLink = (category, slug, reviewId) => {
  return `${buildConfig.apiHost}/reviews/${category}/${slug}?rid=${reviewId}&utm_source=review_share&utm_medium=internal`;
};

export const generateOpinionLink = slug => {
  return `${buildConfig.apiHost}/opinions/${slug}?`;
};

export const generateArticleLink = slug => {
  return `${buildConfig.blogHost}/${slug}?`;
};

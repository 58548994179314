import React from 'react';
import { useLottie } from 'lottie-react';
import { cloneDeep } from 'lodash';
import confettiAnimation from './confetti.json';

const BackgroundLottie = () => {
  const options = {
    animationData: confettiAnimation,
    loop: true,
  };

  const { View } = useLottie(cloneDeep(options));

  return <>{View}</>;
};

export default BackgroundLottie;

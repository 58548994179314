export interface TextVariantTheme {
  fontSize: string;
  fontWeight: number;
  lineHeight: string;
  fontFamily: 'Open Sans' | 'Montserrat';
  textTransform: 'none' | 'uppercase';
}

interface ITextTheme {
  [key: string]: TextVariantTheme;
}

const TextTheme: ITextTheme = {
  displaylg: {
    fontSize: '45px',
    fontWeight: 800,
    lineHeight: '60px',
    fontFamily: 'Montserrat',
    textTransform: 'none',
  },
  displaymd: {
    fontSize: '36px',
    fontWeight: 800,
    lineHeight: '54px',
    fontFamily: 'Open Sans',
    textTransform: 'none',
  },
  titlexxxl: {
    fontSize: '32px',
    fontWeight: 700,
    lineHeight: '44px',
    fontFamily: 'Open Sans',
    textTransform: 'none',
  },
  titlexxl: {
    fontSize: '24px',
    fontWeight: 700,
    lineHeight: '32px',
    fontFamily: 'Open Sans',
    textTransform: 'none',
  },
  titlexl: {
    fontSize: '20px',
    fontWeight: 700,
    lineHeight: '28px',
    fontFamily: 'Open Sans',
    textTransform: 'none',
  },
  titlelg: {
    fontSize: '16px',
    fontWeight: 700,
    lineHeight: '24px',
    fontFamily: 'Open Sans',
    textTransform: 'none',
  },
  titlemd: {
    fontSize: '14px',
    fontWeight: 700,
    lineHeight: '20px',
    fontFamily: 'Open Sans',
    textTransform: 'none',
  },
  titlesm: {
    fontSize: '12px',
    fontWeight: 700,
    lineHeight: '16px',
    fontFamily: 'Open Sans',
    textTransform: 'none',
  },
  buttonmd: {
    fontSize: '14px',
    fontWeight: 600,
    lineHeight: '20px',
    fontFamily: 'Open Sans',
    textTransform: 'none',
  },
  buttonsm: {
    fontSize: '12px',
    fontWeight: 600,
    lineHeight: '14px',
    fontFamily: 'Open Sans',
    textTransform: 'none',
  },
  // default
  body: {
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '150%',
    fontFamily: 'Open Sans',
    textTransform: 'none',
  },
  caption: {
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '16px',
    fontFamily: 'Open Sans',
    textTransform: 'none',
  },
  overlinesm: {
    fontSize: '12px',
    fontWeight: 700,
    lineHeight: '16px',
    fontFamily: 'Open Sans',
    textTransform: 'uppercase',
  },
  overlinexs: {
    fontSize: '10px',
    fontWeight: 700,
    lineHeight: '15px',
    fontFamily: 'Open Sans',
    textTransform: 'uppercase',
  },
};

export default TextTheme;

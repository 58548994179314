import Prismic from 'prismic-javascript';
import buildConfig from 'app/utils/buildConfig';

const prismicApi = async (documentType, uid) => {
  try {
    const apiUrlValidity = await fetch(buildConfig.cmsApiEndpoint);
    const API =
      apiUrlValidity && apiUrlValidity.status === 200
        ? await Prismic.api(buildConfig.cmsApiEndpoint)
        : [];
    const response = await API.query(Prismic.Predicates.at(documentType, uid));
    return response;
  } catch (error) {
    return error;
  }
};

export default prismicApi;

import { call } from 'redux-saga/effects';
import { Message } from 'app/seedly-component-library';
import api from 'app/api/reportApi';
import { TYPES } from 'app/constants/report';
import { COMMENTABLE_TYPE } from 'app/constants/app';
import { isApiSuccess } from '../api/helper';

export function* postReport(action) {
  const { payload, meta } = action;
  let response = {};
  if (meta.reportType === TYPES.POST) {
    response = yield call(api.postQuestionReport, payload);
  } else if (meta.reportType === TYPES.ANSWER) {
    response = yield call(api.postCommentReport, payload);
  } else if (meta.reportType === TYPES.REVIEW) {
    response = yield call(api.postReviewReport, payload);
  } else if (meta.reportType === TYPES.OPINION) {
    response = yield call(api.postOpinionReport, payload);
  } else if (meta.reportType === TYPES.COMMENT) {
    if (meta.commentableType === COMMENTABLE_TYPE.COMMENT) {
      response = yield call(api.postChildCommentReport, payload);
    } else if (meta.commentableType === COMMENTABLE_TYPE.REVIEW) {
      response = yield call(api.postReviewCommentReport, payload);
    } else if (meta.commentableType === COMMENTABLE_TYPE.OPINION) {
      response = yield call(api.postOpinionCommentReport, payload);
    }
  }
  if (isApiSuccess(response)) {
    Message.success('Thank you, your report is submitted!');
  } else {
    Message.error('Failed to submit report');
  }

  if (meta.onComplete) {
    meta.onComplete();
  }
}

import * as React from 'react';
import { X } from 'react-feather';
import * as DialogPrimitive from '@radix-ui/react-dialog';
import clsx from 'clsx';

interface OwnProps {
  children: React.ReactNode;
  isOpen: boolean;
  onCloseClick?: () => void;
  headerTitle?: string;
  widthSize?: 'xs' | 'sm' | 'md' | 'lg';
  contentClassName?: string;
  headerClassName?: string;
  // non-full size in mobile view
  isMobileFullHeight?: boolean;
}

const Dialog = (props: OwnProps) => {
  const {
    children,
    isOpen,
    headerTitle,
    onCloseClick,
    widthSize = 'md',
    headerClassName,
    contentClassName,
    isMobileFullHeight = true,
  } = props;

  return (
    <DialogPrimitive.Root open={isOpen}>
      <DialogPrimitive.Portal>
        <div
          className={clsx(
            'fixed inset-0 z-50 flex items-start justify-center lg:items-center',
            !isMobileFullHeight && 'items-center',
          )}
        >
          <DialogPrimitive.Overlay
            className={clsx(
              'fixed inset-0 z-50 bg-black opacity-40 transition-opacity animate-in fade-in',
            )}
            onClick={onCloseClick}
          />
          <DialogPrimitive.Content
            className={clsx(
              'fixed z-50 lg:h-fit bg-white p-4 opacity-100 lg:max-h-[95vh] lg:rounded-2xl focus:outline-none',
              {
                'lg:w-[343px]': widthSize === 'xs',
                'lg:w-[480px]': widthSize === 'sm',
                'lg:w-[576px]': widthSize === 'md',
              },
              contentClassName,
              !isMobileFullHeight
                ? 'h-auto w-[340px] rounded-2xl'
                : 'w-full h-full',
            )}
            {...props}
          >
            {headerTitle && (
              <DialogPrimitive.Title
                className={clsx('text-base font-bold pb-4', headerClassName)}
              >
                {headerTitle}
              </DialogPrimitive.Title>
            )}
            {children}
            {onCloseClick && (
              <DialogPrimitive.Close
                onClick={onCloseClick}
                className="absolute top-4 right-4 focus:outline-none"
              >
                <X data-testid="button-close" size={24} color="black" />
              </DialogPrimitive.Close>
            )}
          </DialogPrimitive.Content>
        </div>
      </DialogPrimitive.Portal>
    </DialogPrimitive.Root>
  );
};

export default Dialog;

import { call, put, takeLatest } from 'redux-saga/effects';
import searchApi from 'app/api/searchApi';
import get from 'lodash/get';
import curry from 'lodash/curry';
import Router from 'next/router';
import isEmpty from 'lodash/isEmpty';
import { destroyFormDataCache } from 'app/utils/sessionStorage';

import { SearchTypes } from 'app/reducer/searchRedux';
import ProfileAction from 'app/reducer/profileRedux';
import { ModalTypes } from 'app/reducer/modalRedux';
import { rewardsSlice } from 'entity/reward/rewardRedux';
import { PROFILE_FILTER_TYPE } from 'app/constants/profile';

import { generatePostLink } from 'app/utils/generateShareLink';
import { handleResponse } from 'app/utils/sagaHelper';
import { Message } from 'seedly-component-library';

import api from './postApi';
import { followGroupRequest } from '../group/groupRedux';
import { commentsSlice } from '../comment/commentRedux';
import { postsSlice } from './postRedux';
import { feedsSlice } from '../feed/feedRedux';
import { getPostsApiByAction, makePostParams } from './postHelpers';
import { constructRewardCreditPayload } from '../reward/rewardUtils';

const postFormat = response => {
  const { similarQuestions, ...otherData } = response.data.data;
  return {
    post: { ...otherData },
    similarPosts: similarQuestions,
  };
};

const formatter = (meta, response) => {
  return {
    posts: response.data.data,
    pagination: response.data.meta.pagination,
    ...meta,
  };
};

const postsFormat = curry(formatter);

function* getPost(action) {
  const { payload, meta } = action;

  const response = yield call(api.getPost, payload);
  const successAction = postsSlice.actions.getPostSuccess;
  yield call(handleResponse, {
    successAction,
    response,
    meta,
    format: postFormat,
  });
}

function* getPosts(action) {
  const { payload, type } = action;
  const meta = {};
  const getPostsApi = getPostsApiByAction(type);

  if (type === postsSlice.actions.getUserPostsRequest.type) {
    yield put(
      ProfileAction.updateProfileMeta({ filter: PROFILE_FILTER_TYPE.ASKED_BY }),
    );
  }

  const response = yield call(getPostsApi, payload);
  const successAction = postsSlice.actions.getPostsSuccess;
  const failureAction = postsSlice.actions.getPostsFailure;

  const format = postsFormat({ meta });
  yield call(handleResponse, {
    successAction,
    failureAction,
    meta,
    response,
    format,
  });
}

function* getGroupPosts(action) {
  const { payload, meta = {} } = action;

  const response = yield call(api.getGroupPosts, payload);

  const successAction = postsSlice.actions.getPostsSuccess;
  const failureAction = postsSlice.actions.getPostsFailure;

  const format = postsFormat(meta);
  yield call(handleResponse, {
    successAction,
    failureAction,
    response,
    format,
  });

  // move pinned post to top when in announcement group.
  if (response.ok) {
    const posts = response.data.data;
    if (
      posts.length > 0 &&
      posts[0].group?.slug === 'announcements' &&
      payload?.page === 1
    ) {
      yield put(postsSlice.actions.bumpPinAnnouncementPost());
    }
  }
}

function* getPostsSuccess(action) {
  const { payload } = action;
  const { posts, pagination } = payload;
  const childComments = [];
  const featuredComments = posts
    .filter(post => !!post.featuredComment)
    .map(post => {
      childComments.push(post.featuredComment);
      return {
        pagination: {
          currentPage: 1, // Trivial because only 1 comment there
          per: 1,
          totalPage: post.answersCount,
          totalCount: post.answersCount,
        },
        commentableId: post.id,
        comments: [{ ...post.featuredComment }],
      };
    });

  const isRefresh = pagination && pagination.currentPage === 1;

  yield put(
    commentsSlice.actions.getFeaturedCommentsSuccess({
      commentsByCommentable: featuredComments,
      commentableType: 'Post',
      isRefresh,
    }),
  );

  if (!isEmpty(JSON.parse(sessionStorage.getItem('save-post')))) {
    yield put(
      postsSlice.actions.bookmarkPostRequest(
        JSON.parse(sessionStorage.getItem('save-post')),
      ),
    );
    sessionStorage.clear();
  }
}

function* createPost(action) {
  const { payload } = action;

  const postFormParam = makePostParams(payload);
  const response = yield call(api.createPost, postFormParam);
  const successAction = postsSlice.actions.createPostSuccess;

  const meta = {
    successMsg: 'Discussion posted.',
    failureMsg: 'An error occured. Failed to post discussion.',
  };

  if (payload.onSuccess) payload.onSuccess();

  yield call(handleResponse, { successAction, meta, response });

  if (response.ok) {
    const shareLink = generatePostLink(response.data.data.slug);
    const successPayload = constructRewardCreditPayload(response, shareLink);
    yield put(rewardsSlice.actions.handleEarnCredit(successPayload));
  }
}

function* createPostSuccess(action) {
  const { payload: post } = action;
  const { group } = post;

  const { post: postDetail } = post;
  const seedlyEvent = get(postDetail, 'seedlyeventList[0]', undefined);

  // remove from home feed
  yield put(feedsSlice.actions.addPostToFeed(post));

  if (seedlyEvent) {
    yield call(
      Router.push,
      `/community/${group.slug}?filter=${seedlyEvent.slug}`,
    );
  } else {
    yield call(Router.push, `/community/${group.slug}`);
  }

  if (post.group.followedByCurrentUser === false) {
    yield put(followGroupRequest(post.group.id));
  }
}

function* updatePost(action) {
  const { payload } = action;

  const postFormParam = makePostParams(payload);
  const response = yield call(api.updatePost, postFormParam);
  const successAction = postsSlice.actions.updatePostSuccess;
  const meta = {
    successMsg: 'Post updated.',
    failureMsg: 'An error occured. Failed to update post.',
  };

  if (payload.onSuccess) payload.onSuccess();

  yield call(handleResponse, { successAction, meta, response });
}

function* deletePost(action) {
  const { payload } = action;

  const response = yield call(api.deletePost, payload);
  const successAction = postsSlice.actions.deletePostSuccess;
  const meta = {
    successMsg: 'Post deleted.',
    failureMsg: 'An error occured. Failed to delete post.',
  };
  const format = () => payload;

  yield call(handleResponse, { successAction, meta, response, format });
}

function* deletePostSuccess(action) {
  const { payload: postId } = action;
  yield put(feedsSlice.actions.deletePostFromFeed(postId));
}

function* searchQuestions(action) {
  const { payload, meta } = action;

  const response = yield call(searchApi.searchQuestionWithTerm, payload, {});
  const successAction = postsSlice.actions.getPostsSuccess;
  const format = postsFormat(meta);
  yield call(handleResponse, { successAction, meta, response, format });
}

function* setPostForm(action) {
  yield put(postsSlice.actions.setPostForm(action.payload));
}

function* followPost(action) {
  const { payload } = action;
  const meta = {};

  const response = yield call(api.followPost, payload);
  const successAction = postsSlice.actions.followPostSuccess;
  const { followed } = get(response, 'data.meta');

  meta.successMsg = followed
    ? 'Discussion followed.'
    : 'Discussion unfollowed.';
  meta.failureMsg = get(response, 'data.message', null);
  meta.followed = followed;

  const followFormat = () => {
    return {
      postId: payload.followableId,
      followed,
    };
  };

  yield call(handleResponse, {
    successAction,
    meta,
    response,
    format: followFormat,
  });
}

function* followPostSuccess() {
  yield call(destroyFormDataCache);
}

function* votePost(action) {
  const { payload } = action;
  const meta = {};
  const param = {
    id: payload.id,
    vote_type: payload.voteType,
  };
  const response = yield call(api.votePost, param);
  const successAction = postsSlice.actions.votePostSuccess;
  meta.failureMsg = get(response, 'data.message', null);
  yield call(handleResponse, { successAction, meta, response });
}

function* pinPost(action) {
  const { payload } = action;
  const apiCalled = payload.pinType === 'pin' ? api.pinPost : api.unPinPost;
  const response = yield call(apiCalled, payload.id);

  if (response.ok) {
    yield put(
      postsSlice.actions.pinPostSuccess({
        post: response.data.data,
        pinType: payload.pinType,
      }),
    );
    if (payload.pinType === 'pin') {
      Message.success('Discussion pinned.');
    } else {
      Message.success('Discussion unpinned.');
    }
  }
}

function* bookmarkPost(action) {
  const { payload } = action;
  const meta = {};

  const response = yield call(api.bookmarkPost, payload);
  const successAction = postsSlice.actions.bookmarkPostSuccess;
  if (response.ok) {
    meta.successMsg = get(response, 'data.message', null);
  } else {
    meta.failureMsg = get(response, 'data.message', null);
  }

  yield call(handleResponse, { successAction, meta, response });
}

function* getPostSuccess() {
  if (
    typeof window !== 'undefined' &&
    !isEmpty(JSON.parse(sessionStorage.getItem('new-vote')))
  ) {
    yield put(
      postsSlice.actions.votePostRequest(
        JSON.parse(sessionStorage.getItem('new-vote')),
      ),
    );
    sessionStorage.clear();
  }

  if (
    typeof window !== 'undefined' &&
    !isEmpty(JSON.parse(sessionStorage.getItem('save-post')))
  ) {
    yield put(
      postsSlice.actions.bookmarkPostRequest(
        JSON.parse(sessionStorage.getItem('save-post')),
      ),
    );
    sessionStorage.clear();
  }
}

export const postSagas = [
  // Retrieve Posts
  takeLatest(postsSlice.actions.getPostRequest, getPost),
  takeLatest(postsSlice.actions.getPinnedPostSuccess, getPostsSuccess),
  takeLatest(postsSlice.actions.getPostsRequest, getPosts),
  takeLatest(postsSlice.actions.getGroupPostsRequest, getGroupPosts),
  takeLatest(postsSlice.actions.getUserPostsRequest, getPosts),
  takeLatest(postsSlice.actions.getProductPostsRequest, getPosts),
  takeLatest(postsSlice.actions.getBookmarkedPostsRequest, getPosts),
  takeLatest(postsSlice.actions.getPostSuccess, getPostSuccess),
  takeLatest(postsSlice.actions.getPostsSuccess, getPostsSuccess),

  // Create Update Delete Post
  takeLatest(postsSlice.actions.createPostRequest, createPost),
  takeLatest(postsSlice.actions.createPostSuccess, createPostSuccess),
  takeLatest(postsSlice.actions.updatePostRequest, updatePost),
  takeLatest(postsSlice.actions.deletePostRequest, deletePost),
  takeLatest(postsSlice.actions.deletePostSuccess, deletePostSuccess),

  // Vote + Bookmark + Follow
  takeLatest(postsSlice.actions.votePostRequest, votePost),
  takeLatest(postsSlice.actions.bookmarkPostRequest, bookmarkPost),
  takeLatest(postsSlice.actions.followPostRequest, followPost),
  takeLatest(postsSlice.actions.followPostSuccess, followPostSuccess),
  takeLatest(postsSlice.actions.pinPostRequest, pinPost),

  // From other redux action
  takeLatest(SearchTypes.SEARCH_QUESTIONS_REQUEST, searchQuestions),
  takeLatest(ModalTypes.SET_POST_MODAL, setPostForm),
];

import colors from '../themes/colors';
import inset from '../themes/inset';
import shadow from '../themes/shadow';
import {
  VariantKey,
  VariantTheme,
  SizeKey,
  SizeTheme,
  ShapeKey,
} from './ButtonTypes';

export const ButtonVariantTheme: Record<VariantKey, Partial<VariantTheme>> = {
  primary: {
    backgroundColor: colors.seedlyBlue,
    color: colors.neutral1,
    border: 'none',
    backgroundHoveredColor: colors.blue6,
    backgroundPressedColor: colors.blue7,
    backgroundDisabledColor: colors.neutral2,
    borderDisabled: 'none',
  },
  primaryWhite: {
    backgroundColor: colors.neutral1,
    color: colors.seedlyBlue,
    border: 'none',
    backgroundHoveredColor: colors.blue1,
    backgroundPressedColor: colors.blue2,
    borderDisabled: 'none',
  },
  primaryGreen: {
    backgroundColor: colors.green6,
    color: colors.neutral1,
    border: 'none',
    backgroundHoveredColor: colors.green6,
    backgroundPressedColor: colors.green6,
    borderDisabled: 'none',
  },
  outline: {
    backgroundColor: 'transparent',
    color: colors.seedlyBlue,
    border: `1px solid ${colors.seedlyBlue}`,
    hoverColor: colors.blue7,
    backgroundHoveredColor: colors.blue1,
    backgroundPressedColor: colors.blue2,
    backgroundDisabledColor: 'transparent',
    borderDisabled: `1px solid ${colors.blue1}`,
    colorDisabled: colors.blue1,
  },
  outlineWhite: {
    backgroundColor: 'transparent',
    color: colors.neutral1,
    pressedColor: colors.neutral7,
    border: `1px solid ${colors.neutral1}`,
    backgroundPressedColor: colors.neutral1,
  },
  outlineBlack: {
    backgroundColor: 'transparent',
    color: colors.neutral7,
    pressedColor: colors.neutral4,
    border: `1px solid ${colors.neutral7}`,
    backgroundHoveredColor: colors.neutral3,
    backgroundPressedColor: colors.neutral4,
  },
  outlineGrey: {
    backgroundColor: 'transparent',
    color: colors.neutral7,
    border: `1px solid ${colors.neutral4}`,
    backgroundHoveredColor: colors.neutral3,
    backgroundPressedColor: colors.neutral4,
  },
  outlineGreySelected: {
    backgroundColor: colors.neutral7,
    color: colors.neutral1,
    border: '1px solid',
    pressedColor: colors.neutral7,
    backgroundHoveredColor: colors.neutral6,
    backgroundPressedColor: colors.neutral4,
  },
  ghost: {
    backgroundColor: 'unset',
    color: colors.seedlyBlue,
    border: 'none',
    backgroundHoveredColor: colors.blue1,
    backgroundPressedColor: colors.blue2,
    backgroundDisabledColor: 'transparent',
    borderDisabled: 'none',
  },
  ghostGrey: {
    backgroundColor: 'unset',
    color: colors.neutral7,
    border: 'none',
    backgroundHoveredColor: colors.neutral3,
    backgroundPressedColor: colors.neutral4,
    backgroundDisabledColor: colors.neutral2,
    borderDisabled: 'none',
  },
  icon: {
    backgroundColor: 'unset',
    color: colors.neutral7,
    border: 'none',
    hoveredColor: colors.seedlyBlue,
    backgroundHoveredColor: colors.blue1,
    backgroundPressedColor: colors.blue2,
    backgroundDisabledColor: 'unset',
    borderDisabled: 'none',
  },
  danger: {
    backgroundColor: colors.red6,
    color: colors.neutral1,
    border: 'none',
    backgroundHoveredColor: colors.red7,
    backgroundPressedColor: colors.red7,
    backgroundDisabledColor: colors.neutral2,
    borderDisabled: 'none',
  },
};

export const likeButtonTheme = (isSelected: boolean): VariantTheme => {
  return {
    backgroundColor: isSelected ? colors.green1 : colors.neutral1,
    color: colors.neutral5,
    border: isSelected
      ? `1px solid ${colors.green7}`
      : `1px solid ${colors.neutral4}`,
    boderHovered: `1px solid ${colors.green7}`,
    boxShadow: shadow.likeButton,
    backgroundHoveredColor: colors.green1,
    backgroundPressedColor: colors.green2,
    backgroundDisabledColor: colors.neutral2,
    borderDisabled: 'none',
  };
};

// border radius
export const ButtonShapeTheme: Record<ShapeKey, string> = {
  rounded: '4px',
  pill: '40px',
  circle: '50%',
};

export const ButtonIconSizeTheme: Record<'sm' | 'md', SizeTheme> = {
  sm: {
    padding: '0px 0px',
    fontSize: '14px',
    iconSize: 14,
    fontWeight: 600,
    lineHeight: '20px',
    minWidth: '24px',
  },
  md: {
    padding: '4px 0px',
    fontSize: '14px',
    iconSize: 20,
    fontWeight: 600,
    lineHeight: '20px',
    minWidth: '38px',
  },
};

export const ButtonSizeTheme: Record<SizeKey, SizeTheme> = {
  xs: {
    padding: inset.sm,
    fontSize: '12px',
    fontWeight: 600,
    lineHeight: '16px',
  },
  sm: {
    padding: inset.sm,
    fontSize: '14px',
    fontWeight: 600,
    lineHeight: '20px',
  },
  md: {
    padding: inset.md,
    fontSize: '14px',
    fontWeight: 600,
    lineHeight: '20px',
  },
  lg: {
    padding: inset.lg,
    fontSize: '14px',
    fontWeight: 600,
    lineHeight: '20px',
  },
  // padding-less
  none: {
    padding: '0px 0px',
    fontSize: '14px',
    fontWeight: 600,
    lineHeight: '20px',
  },
};

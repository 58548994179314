import paramsSerializer from 'utils/paramSerializer';
import api, { ResponseData } from '../../api/base';
import {
  CommentApiParentMeta,
  CommentParentEntityServer,
  GetCommentBody,
  PostBody,
  VoteType,
  CommentApiIdentifier,
} from './commentTypes';

const getComments = (
  params: CommentApiParentMeta & {
    commentParams: GetCommentBody;
  },
) => {
  const { recordType, commentableId, commentParams } = params;

  return api.get<ResponseData<Comment[]>>(
    `/v4/${recordType}/${commentableId}/comments`,
    commentParams,
    {
      paramsSerializer,
    },
  );
};

const postComment = (
  params: CommentApiParentMeta & {
    postBody: PostBody;
  },
) => {
  const { recordType, commentableId, postBody } = params;

  return api.post<ResponseData<Comment>>(
    `/v4/${recordType}/${commentableId}/comments`,
    postBody,
  );
};

const updateComment = (
  params: CommentApiIdentifier & {
    postBody: PostBody;
  },
) => {
  const { commentId, recordType, commentableId, postBody } = params;

  return api.put<ResponseData<Comment>>(
    `/v4/${recordType}/${commentableId}/comments/${commentId}`,
    postBody,
  );
};

const deleteComment = (params: CommentApiIdentifier) => {
  const { commentId, recordType, commentableId } = params;

  return api.delete<ResponseData<Comment>>(
    `/v4/${recordType}/${commentableId}/comments/${commentId}`,
  );
};

const voteComment = (
  params: CommentApiIdentifier & {
    vote_type: VoteType;
  },
) => {
  const { commentId, recordType, commentableId } = params;

  return api.put<ResponseData<Comment>>(
    `/v4/${recordType}/${commentableId}/comments/${commentId}/vote`,
    { vote_type: params.vote_type },
  );
};

const bookmarkComment = (params: CommentApiIdentifier) => {
  const { commentId, recordType, commentableId } = params;

  return api.put<ResponseData<Comment>>(
    `/v4/${recordType}/${commentableId}/comments/${commentId}/bookmark`,
  );
};

const reportComment = (params: CommentApiIdentifier & { reason: string }) => {
  const { commentId, recordType, commentableId, reason } = params;

  return api.put<ResponseData<any>>(
    `v4/${recordType}/${commentableId}/comments/${commentId}/report`,
    { reason },
  );
};

const getUserParentComments = (params: GetCommentBody & { slug: string }) => {
  const { slug } = params;

  return api.get<ResponseData<CommentParentEntityServer[]>>(
    `/v4/users/${slug}/parent_comments`,
    params,
    {
      paramsSerializer,
    },
  );
};

const getUserChildComments = (params: GetCommentBody & { slug: string }) => {
  const { slug } = params;

  return api.get<ResponseData<CommentParentEntityServer[]>>(
    `/v4/users/${slug}/child_comments`,
    params,
    {
      paramsSerializer,
    },
  );
};

const getBookmarkedParentComments = (
  params: GetCommentBody & { slug: string },
) => {
  const { slug } = params;
  return api.get<ResponseData<CommentParentEntityServer[]>>(
    `v4/users/${slug}/bookmarked_parent_comments`,
    params,
    {
      paramsSerializer,
    },
  );
};

const getBookmarkedChildComments = (
  params: GetCommentBody & { slug: string },
) => {
  const { slug } = params;
  return api.get<ResponseData<CommentParentEntityServer[]>>(
    `v4/users/${slug}/bookmarked_child_comments`,
    params,
    {
      paramsSerializer,
    },
  );
};

export default {
  getComments,
  getUserParentComments,
  getUserChildComments,
  getBookmarkedParentComments,
  getBookmarkedChildComments,
  postComment,
  updateComment,
  deleteComment,
  voteComment,
  bookmarkComment,
  reportComment,
};

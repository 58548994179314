import React from 'react';

const RewardCtaCard = props => {
  const { icon, actionText, creditEarn } = props;

  return (
    <div className="flex items-center justify-center w-full h-[150px] lg:w-[218px] lg:h-[120px] bg-transparent rounded-lg border border-neutral-400 px-3 py-4">
      <div className="flex flex-col items-center gap-4">
        {icon}
        <div className="flex flex-col items-center">
          <p className="text-sm font-bold text-center">{actionText}</p>
          <p className="text-center text-neutral-500">
            +{creditEarn} Seeds and Credits
          </p>
        </div>
      </div>
    </div>
  );
};

export default RewardCtaCard;

import { createReducer, createActions } from 'reduxsauce';
import produce from 'immer';

const { Types, Creators } = createActions({
  searchRequest: ['payload', 'meta'],
  searchSuccess: ['payload'],
  searchFailure: null,
  searchComparison: ['payload'],
  searchCategories: ['payload'],
  searchGroups: ['payload'],
  searchRecent: ['payload'],

  searchQuestionsRequest: ['payload', 'meta'],
  searchProfilesRequest: ['payload', 'meta'],
  searchProductsRequest: ['payload', 'meta'],
  searchOpinionRequest: ['payload', 'meta'],

  setMobileSearchPage: ['payload'],
  setSearchTerm: ['payload'],
});

const INITIAL_STATE = {
  isMobileSearchPageOpen: false,
  searchTerm: '',
  api: {
    isFetching: false,
    isError: false,
  },
  result: {
    products: [],
    profiles: [],
    questions: [],
    opinions: [],
    groups: [],
    comparisons: [],
    categories: [],
  },
};

const searchRequest = state => {
  return produce(state, draft => {
    draft.api.isFetching = true;
    draft.api.isError = false;
  });
};

const searchSuccess = (state, action) => {
  const { payload: result } = action;

  if (result.products) {
    result.products.forEach(product => {
      if (!product.link || typeof product.link === 'string') {
        product.link = {
          href: `/productDetail?categorySlug=${product.categorySlug}&productSlug=${product.slug}`,
          as: `/reviews/${product.categorySlug}/${product.slug}`,
        };
      }
      product.type = 'products';
    });
  }

  if (result.questions) {
    result.questions.forEach(question => {
      if (!question.link || typeof question.link === 'string') {
        question.link = {
          href: `/posts/${question.slug}`,
          as: `/posts/${question.slug}`,
        };
      }
      question.type = 'questions';
    });
  }

  if (result.opinions) {
    result.opinions.forEach(opinion => {
      if (!opinion.link || typeof opinion.link === 'string') {
        opinion.link = {
          href: `/opinion/${opinion.slug}`,
          as: `/opinion/${opinion.slug}`,
        };
      }
      opinion.type = 'opinions';
    });
  }

  if (result.profiles) {
    result.profiles.forEach(profile => {
      if (!profile.link || typeof profile.link === 'string') {
        profile.link = {
          href: `/profile?slug=${profile.slug}`,
          as: `/profile/${profile.slug}`,
        };
      }
      profile.type = 'profiles';
    });
  }

  return produce(state, draft => {
    const { categories, comparisons, groups } = draft.result;
    draft.result = {
      ...result,
      comparisons,
      categories,
      groups,
    };

    draft.api.isFetching = false;
    draft.api.isError = false;
  });
};

const setMobileSearchPage = (state, action) => {
  const { payload } = action;

  return produce(state, draft => {
    draft.isMobileSearchPageOpen = payload;
  });
};

const setSearchTerm = (state, action) => {
  const { payload } = action;

  return produce(state, draft => {
    draft.searchTerm = payload;
  });
};

const searchComparison = (state, action) => {
  const { payload: comparisons } = action;
  return produce(state, draft => {
    draft.result.comparisons = comparisons;
  });
};

const searchGroups = (state, action) => {
  const { payload: groups } = action;
  return produce(state, draft => {
    draft.result.groups = groups.map(group => {
      return {
        ...group,
        link: {
          href: `/community/${group.slug}`,
          as: `/community/${group.slug}`,
        },
      };
    });
  });
};

const searchRecent = (state, action) => {
  const { payload: result } = action;

  return produce(state, draft => {
    draft.result = { ...state.result, ...result };
    draft.api.isFetching = false;
    draft.api.isError = false;
  });
};

const searchCategories = (state, action) => {
  const { payload: categories } = action;
  if (categories) {
    categories.forEach(category => {
      category.link = {
        href: `/reviews?categorySlug=${category.slug}`,
        as: `/reviews/${category.slug}`,
      };
    });
  }

  return produce(state, draft => {
    draft.result.categories = categories;
  });
};

export const SearchTypes = Types;
export default Creators;

export const reducer = createReducer(INITIAL_STATE, {
  [Types.SEARCH_SUCCESS]: searchSuccess,
  [Types.SEARCH_REQUEST]: searchRequest,
  [Types.SET_MOBILE_SEARCH_PAGE]: setMobileSearchPage,
  [Types.SET_SEARCH_TERM]: setSearchTerm,
  [Types.SEARCH_COMPARISON]: searchComparison,
  [Types.SEARCH_GROUPS]: searchGroups,
  [Types.SEARCH_CATEGORIES]: searchCategories,
  [Types.SEARCH_RECENT]: searchRecent,
});

export const destroyFormDataCache = () => {
  sessionStorage.clear();
};

export const getCachedFormData = () => {
  let cachedFormData = {};

  try {
    cachedFormData = {
      cachedQuestionData:
        JSON.parse(sessionStorage.getItem('new-question')) || {},
      cachedGroupData: JSON.parse(sessionStorage.getItem('new-group')) || null,
      cachedCommentData:
        JSON.parse(sessionStorage.getItem('new-comment')) || {},
      cachedReviewData: JSON.parse(sessionStorage.getItem('new-review')) || {},
      cachedFollowQuestionData:
        JSON.parse(sessionStorage.getItem('follow-question')) || {},
      cachedReportData: JSON.parse(sessionStorage.getItem('new-report')) || {},
      cachedChildCommentData:
        JSON.parse(sessionStorage.getItem('new-childcomment')) || {},
    };
  } catch (error) {
    cachedFormData = {};
  }

  return cachedFormData;
};

import React from 'react';
import spacing from '../themes/spacing';
import * as S from './styles';

export interface SpaceProps extends React.HTMLAttributes<HTMLDivElement> {
  // md is 16px
  size?: keyof typeof spacing;
  align?: 'start' | 'end' | 'center' | 'baseline';
  justifyContent?: string;
  direction?: 'row' | 'row-reverse' | 'column' | 'column-reverse';
  height?: string;
  width?: string;
  children: React.ReactNode;
  style?: Object;
}

const generateMargin = (direction: string, spacingParam: string) => {
  switch (direction) {
    case 'row':
      return `0 0 0 ${spacingParam}`;
    case 'row-reverse':
      return `0 ${spacingParam} 0 0`;
    case 'column':
      return `${spacingParam} 0 0 0`;
    case 'column-reverse':
      return `0 0 ${spacingParam} 0`;
    default:
      return '0 0 0 0';
  }
};

const Space = React.forwardRef<HTMLDivElement, SpaceProps>((props, ref) => {
  const {
    size = 'md',
    align = 'center',
    justifyContent = 'start',
    direction = 'row',
    height = 'auto',
    width = 'auto',
    ...otherProps
  } = props;

  const space = spacing[size] || spacing.md;
  const margin = React.useMemo(() => generateMargin(direction, space), [
    space,
    direction,
  ]);

  return (
    <S.FlexSpace
      ref={ref}
      align={align}
      justifyContent={justifyContent}
      margin={margin}
      heightProp={height}
      widthProp={width}
      directionProp={direction}
      {...otherProps}
    >
      {props.children}
    </S.FlexSpace>
  );
});

export default Space;

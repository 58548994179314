import noop from 'lodash/noop';

export const makeCallbacks = ({ onSuccess, onFailure, onRequest }) => {
  return {
    onSuccess: onSuccess || noop,
    onFailure: onFailure || noop,
    onRequest: onRequest || noop,
  };
};

export const makeOpinionParams = ({
  title,
  description,
  content,
  credentialId,
  topics,
  tempImageIds,
  tempBgIds,
  draftId,
  image,
}) => {
  const params = {
    title,
    description,
    content,
    credential_id: credentialId,
    image: null,
  };

  if (topics) {
    params.tags = topics;
  }

  if (tempImageIds && tempImageIds.length > 0) {
    params.temp_image_ids = tempImageIds;
  }

  if (tempBgIds && tempBgIds.length > 0) {
    params.temp_bg_image_ids = tempBgIds;
  }

  if (draftId) {
    params.draft_id = draftId;
  }

  if (image) {
    params.image = image;
  }

  return params;
};

import { imageUrl } from 'theme';

export const TYPES = {
  DEFAULT: 'default',
  CREATE_POST: 'create-post',
  CREATE_COMMENT: 'create-comment',
  CREATE_CHILD_COMMENT: 'create-child-comment',
  CREATE_REVIEW: 'create-review',
  SAVE_OPINION: 'save-opinion',
  SAVE_COMMENT: 'save-comment',
  SAVE_ARTICLE: 'save-article',
  SAVE_POST: 'save-post',
  LOGIN: 'login',
  SIGNUP: 'signup',
  JOIN_GROUP: 'JOIN_GROUP',
  FOLLOW_GROUPS: 'follow-groups',
  FOLLOW_POST: 'follow-post',
  VOTE: 'vote',
  ARTICLE_FOOTER: 'article-footer',
  UNLOCK_ANSWER: 'unlock-answer',
  REWARD: 'reward',
};

export const LOGIN_MODAL = {
  [TYPES.DEFAULT]: {
    title: 'Join Seedly',
    description:
      'Learn, participate and earn rewards on the largest Personal Finance community in Singapore!',
  },

  [TYPES.CREATE_POST]: {
    title: 'You are one click away from starting a discussion!',
    description: 'Join our community to learn and level up your finances.',
    ac: 'start_a_discussion',
  },
  [TYPES.CREATE_COMMENT]: {
    title: 'Before we post your comment, please log in!',
    description:
      'Thank you for helping our community make smarter financial decisions!',
    ac: 'post_comment',
  },
  [TYPES.CREATE_CHILD_COMMENT]: {
    title: 'Before we post your comment, please log in!',
    description:
      'Thank you for helping our community make smarter financial decisions!',
    ac: 'post_child_comment',
  },
  [TYPES.CREATE_REVIEW]: {
    title: 'Before we post your review, please log in!',
    description:
      'Thank you for helping our community make smarter financial decisions!',
  },
  [TYPES.SAVE_OPINION]: {
    title: 'Login to save opinions to read it again in the future!',
  },
  [TYPES.SAVE_COMMENT]: {
    title: 'Login to save comments to read them again in the future!',
    ac: 'bookmark_comment',
  },
  [TYPES.SAVE_ARTICLE]: {
    title: 'Login to save articles to read it again in the future!',
  },
  [TYPES.SAVE_POST]: {
    title: 'Login to save posts to read it again in the future!',
    ac: 'bookmark_post',
  },
  [TYPES.LOGIN]: {
    title: 'Join Seedly',
    description:
      'Learn, participate and earn rewards on the largest Personal Finance community in Singapore!',
    scene: 'main-log-in',
  },
  [TYPES.SIGNUP]: {
    title: 'Join Seedly',
    description:
      'Learn, participate and earn rewards on the largest Personal Finance community in Singapore!',
    scene: 'main-sign-up',
  },
  [TYPES.JOIN_GROUP]: {
    ac: 'join_group',
  },
  [TYPES.FOLLOW_GROUPS]: {
    ac: 'onboarding_group_selection',
    additionalMixpanelProps: ['group_selection'],
  },
  [TYPES.FOLLOW_POST]: {
    image: imageUrl.notification,
    title: 'Login or Sign up to be notified of updates on this discussion!',
    description: '',
    ac: 'follow_post',
  },
  [TYPES.VOTE]: {},
  [TYPES.ARTICLE_FOOTER]: {
    title: 'Login to claim your reward',
  },
  [TYPES.UNLOCK_ANSWER]: {},
  [TYPES.REWARD]: {
    title: 'Login or Sign Up to get your exclusive rewards',
  },
};

const sizing = {
  // Modal
  modalMaxWidth: '600px',

  // Modal Fixed Width
  xs: '343px',
  sm: '480px',
  md: '576px',
  lg: '768px',
  xl: '1024px',

  // Padding
  default: '16px 16px 32px 16px',
};

export default sizing;

import React from 'react';
import {
  ButtonVariantTheme,
  ButtonShapeTheme,
  ButtonSizeTheme,
  ButtonIconSizeTheme,
} from './ButtonTheme';
import { VariantKey, SizeKey, ShapeKey } from './ButtonTypes';
import * as S from './styles';
import Space from '../space';
import Spinner from '../spinner';
import spacing from '../themes/spacing';

export interface ButtonProps extends React.HTMLAttributes<HTMLButtonElement> {
  variant?: VariantKey;
  shape?: ShapeKey;
  size?: SizeKey;
  renderAs?: any;
  isBlock?: boolean;
  icon?: any;
  iconSpacing?: keyof typeof spacing;
  disabled?: boolean;
  isLoading?: boolean;
  children?: React.ReactNode;
  spaceStyle?: Object;
}

const Button = React.forwardRef<HTMLDivElement, ButtonProps>((props, ref) => {
  const {
    variant = 'primary',
    shape = 'pill',
    size = 'md',
    onClick,
    renderAs = 'button',
    isBlock = false,
    icon = null,
    iconSpacing = 'xs',
    disabled = false,
    isLoading = false,
    spaceStyle = {},
    ...otherProps
  } = props;

  const variantTheme = props.children
    ? ButtonVariantTheme[variant]
    : ButtonVariantTheme.icon;
  const borderRadius = props.children
    ? ButtonShapeTheme[shape]
    : ButtonShapeTheme.circle;
  const sizeTheme = props.children
    ? ButtonSizeTheme[size]
    : ButtonIconSizeTheme[size];

  // Inject Props into Icon
  let styledIcon;
  if (React.isValidElement(icon)) {
    // Handling Icon Button
    const marginRight = props.children ? spacing[iconSpacing] : spacing.none;
    const marginTop = props.children ? '0px' : '4px';
    const iconSize = props.children ? 16 : sizeTheme.iconSize;
    styledIcon = React.cloneElement(icon as React.ReactElement<any>, {
      color: variantTheme.color,
      size: iconSize,
      style: { marginRight, marginTop },
    });
  }

  return (
    <S.StyledButton
      variant={variantTheme}
      size={sizeTheme}
      borderRadius={borderRadius}
      disabled={disabled}
      isBlock={isBlock}
      as={renderAs}
      onClick={isLoading ? () => {} : onClick}
      ref={ref}
      {...otherProps}
    >
      <Space style={{ ...spaceStyle }} size="none">
        {isLoading && (
          <Spinner size={12} variant="primary" margin="0px 4px 0px 0px" />
        )}
        {styledIcon}
        {props.children}
      </Space>
    </S.StyledButton>
  );
});

export default Button;

import { createReducer, createActions } from 'reduxsauce';
import { createSelector } from 'reselect';
import { COMMENT_TYPE } from 'app/constants/app';
import { REVIEW_FILTER_TYPE } from 'app/constants/product';
import produce from 'immer';
import get from 'lodash/get';

const { Types, Creators } = createActions({
  getUserReviewsRequest: ['payload', 'meta'],
  getUserReviewsSuccess: ['payload'],
  getUserReviewsFailure: null,

  getProductReviewsRequest: ['payload', 'meta'],
  getProductReviewsSuccess: ['payload', 'meta'],
  getProductReviewsFailure: null,

  getReviewFilterRequest: ['payload', 'meta'],
  getReviewFilterSuccess: ['payload', 'meta'],
  getReviewFilterFailure: null,

  postReviewRequest: ['payload', 'meta'],
  postReviewSuccess: ['payload', 'meta'],
  postReviewFailure: null,

  updateReviewRequest: ['payload', 'meta'],
  updateReviewSuccess: ['payload', 'meta'],
  updateReviewFailure: null,

  deleteReviewRequest: ['payload', 'meta'],
  deleteReviewSuccess: ['payload', 'meta'],
  deleteReviewFailure: null,

  setReviewForm: ['payload'],

  voteReviewRequest: ['payload', 'meta'],
  voteReviewSuccess: ['payload'],
  voteReviewFailure: null,

  getProductPrismicRequest: ['payload', 'meta'],
  getProductPrismicSuccess: ['payload'],
  getProductPrismicFailure: null,

  updateReviewMeta: ['payload'],
  setReviewListModalProduct: ['payload'],
  updateReviewCommentCount: ['payload'],
});

const INITIAL_STATE = {
  meta: {
    ids: [],
    sortType: REVIEW_FILTER_TYPE.MOST_UPVOTE,
    filterTag: null,
    filterText: [],
    pagination: {},
    product: null,
  },
  byId: {},
  api: {
    isFetching: false,
    isFetchingMoreReviews: false,
    hasError: false,
    errorMessage: '',
  },
  form: {
    isEdit: false,
    review: 0,
    productId: 0,
    categorySlug: '',
  },
  reviewFilter: [],
  prismicBanner: {},
};

export const ReviewTypes = Types;
export default Creators;

const getUserReviewsSuccess = (state, action) => {
  const { reviews, isFetchingMoreReviews, pagination } = action.payload;

  return produce(state, draft => {
    if (!isFetchingMoreReviews) {
      draft.meta.ids = [];
    }

    draft.meta.ids = reviews.map(review => {
      draft.byId[review.id] = review;
      return review.id;
    });

    draft.meta.pagination = pagination;
    draft.api.isFetching = false;
    draft.api.hasError = false;
    draft.api.isFetchingMoreReviews = false;
  });
};

const getProductReviewsRequest = (state, action) => {
  const sortType = get(action, 'meta.sortType');
  const tag = get(action, 'meta.tag');
  const filterText = get(action, 'meta.filterText', []);
  return produce(state, draft => {
    if (sortType) {
      draft.meta.sortType = sortType;
    }
    draft.meta.filterTag = tag;
    draft.meta.filterText = filterText.length === 0 ? [tag] : filterText;
  });
};

const getProductReviewsSuccess = (state, action) => {
  const { reviews, pagination } = action.payload;

  return produce(state, draft => {
    draft.meta.ids = [];

    reviews.forEach(review => {
      draft.meta.ids.push(review.id);
      draft.byId[review.id] = review;
    });

    draft.meta.pagination = pagination;
    draft.api.isFetching = false;
    draft.api.hasError = false;
    draft.api.isFetchingMoreReviews = false;
  });
};

const voteReviewSuccess = (state, action) => {
  const review = action.payload;
  return produce(state, draft => {
    draft.byId[review.id] = review;
    draft.api.hasError = false;
  });
};

const updateReviewMeta = (state, action) => {
  const { isFetching, isFetchingMoreReviews } = action.payload;

  return produce(state, draft => {
    draft.api.isFetching = isFetching;
    draft.api.isFetchingMoreReviews = isFetchingMoreReviews;
  });
};

export const postReviewSuccess = (state, action) => {
  const review = action.payload;

  return produce(state, draft => {
    if (!draft.meta.ids.find(id => id === review.id)) {
      draft.meta.ids.unshift(review.id);
    }
    draft.byId[review.id] = review;
    draft.api.hasError = false;
  });
};

export const updateReviewSuccess = (state, action) => {
  const review = action.payload;
  return produce(state, draft => {
    draft.byId[review.id] = review;
    draft.api.hasError = false;
  });
};

export const deleteReviewSuccess = (state, action) => {
  const reviewId = action.payload;
  const newIds = state.meta.ids.filter(id => id !== reviewId);

  return produce(state, draft => {
    delete draft.byId[reviewId];
    draft.meta.ids = newIds;
    draft.api.hasError = false;
  });
};

export const setReviewForm = (state, action) => {
  const { isEdit, productId, categorySlug, review } = action.payload;

  return produce(state, draft => {
    draft.form.isEdit = isEdit || false;
    draft.form.review = review || 0;
    draft.form.productId = productId || 0;
    draft.form.categorySlug = categorySlug || '';
  });
};

const getProductPrismicSuccess = (state, action) => {
  const { payload } = action;

  return produce(state, draft => {
    draft.prismicBanner = payload;
  });
};

export const setReviewListModalProduct = (state, action) => {
  const product = get(action, 'payload.product');

  return produce(state, draft => {
    if (product) {
      draft.meta.product = product;
    }
  });
};

export const getReviewFilterSuccess = (state, action) => {
  const { payload } = action;

  return produce(state, draft => {
    if (payload) {
      draft.reviewFilter = payload;
    }
    draft.api.hasError = false;
  });
};

export const setApiFailure = state => {
  return produce(state, draft => {
    draft.api.isFetching = false;
    draft.api.hasError = true;
  });
};

const updateReviewCommentCount = (state, action) => {
  const { commentableId, type } = action.payload;

  return produce(state, draft => {
    const commentsCount = draft.byId[commentableId].commentsCount;
    const newCommentsCount =
      type === COMMENT_TYPE.POST ? commentsCount + 1 : commentsCount - 1;

    draft.byId[commentableId].commentsCount = newCommentsCount;
  });
};

export const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_USER_REVIEWS_SUCCESS]: getUserReviewsSuccess,
  [Types.GET_USER_REVIEWS_FAILURE]: setApiFailure,
  [Types.VOTE_REVIEW_SUCCESS]: voteReviewSuccess,
  [Types.VOTE_REVIEW_FAILURE]: setApiFailure,
  [Types.UPDATE_REVIEW_META]: updateReviewMeta,
  [Types.GET_PRODUCT_REVIEWS_REQUEST]: getProductReviewsRequest,
  [Types.GET_PRODUCT_REVIEWS_SUCCESS]: getProductReviewsSuccess,
  [Types.GET_PRODUCT_REVIEWS_FAILURE]: setApiFailure,
  [Types.GET_REVIEW_FILTER_SUCCESS]: getReviewFilterSuccess,
  [Types.GET_REVIEW_FILTER_FAILURE]: setApiFailure,
  [Types.SET_REVIEW_FORM]: setReviewForm,
  [Types.POST_REVIEW_SUCCESS]: postReviewSuccess,
  [Types.POST_REVIEW_FAILURE]: setApiFailure,
  [Types.UPDATE_REVIEW_SUCCESS]: updateReviewSuccess,
  [Types.UPDATE_REVIEW_FAILURE]: setApiFailure,
  [Types.DELETE_REVIEW_SUCCESS]: deleteReviewSuccess,
  [Types.DELETE_REVIEW_FAILURE]: setApiFailure,
  [Types.GET_PRODUCT_PRISMIC_SUCCESS]: getProductPrismicSuccess,
  [Types.SET_REVIEW_LIST_MODAL_PRODUCT]: setReviewListModalProduct,
  [Types.UPDATE_REVIEW_COMMENT_COUNT]: updateReviewCommentCount,
});

export const getReviews = state => state.review.byId;
export const getReviewIds = state => state.review.meta.ids;

export const getReviewsById = createSelector(
  [getReviews, getReviewIds],
  (reviews, reviewIds) => {
    return reviewIds.map(id => reviews[id]);
  },
);

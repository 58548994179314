import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from 'reducer';
import { useIsOnboarded, useIsUserProfileLoaded } from 'entity/user/userHooks';
import orderBy from 'lodash/orderBy';
import { Selector, createSelector } from 'reselect';
import getSessionStorage from 'utils/getSesssionStorage';
import { Group } from './groupRedux';
import { openJoinGroupModal } from '../../dispatcher/modalDispatcher';

const getIds: Selector<RootState, number[]> = (state: RootState) =>
  state.group.ids;

const getById: Selector<RootState, Record<number, Group>> = (
  state: RootState,
) => state.group.byId;

const groupsSelector = createSelector([getIds, getById], (ids, byId) => {
  return ids.map(id => byId[id]);
});

const groupsSelectorByName = createSelector([getIds, getById], (ids, byId) => {
  return orderBy(
    ids.map(id => byId[id]),
    ['name'],
    ['asc'],
  );
});

const followedGroups = createSelector([getIds, getById], (ids, byId) => {
  return ids.map(id => byId[id]).filter(group => group.followedByCurrentUser);
});

const followedGroupsByUnreadCount = createSelector(
  [getIds, getById],
  (ids, byId) => {
    let announceId;
    const orderGroups = orderBy(
      ids
        .map(id => {
          if (byId[id].slug === 'announcements') {
            announceId = id;
          }
          return byId[id];
        })
        .filter(
          group =>
            group.followedByCurrentUser && group.slug !== 'announcements',
        ),
      ['unreadCount', 'name'],
      ['desc', 'asc'],
    );
    if (announceId) {
      orderGroups.unshift(byId[announceId]);
    }
    return orderGroups;
  },
);

const followedGroupsByJoinedAt = createSelector(
  [getIds, getById],
  (ids, byId) => {
    return orderBy(
      ids.map(id => byId[id]).filter(group => group.followedByCurrentUser),
      group => (group.joinedAt ? new Date(group.joinedAt) : new Date()),
      ['desc'],
    );
  },
);

const notFollowedGroups = createSelector([getIds, getById], (ids, byId) => {
  return ids.map(id => byId[id]).filter(group => !group.followedByCurrentUser);
});

const notFollowedGroupsByName = createSelector(
  [getIds, getById],
  (ids, byId) => {
    return orderBy(
      ids.map(id => byId[id]).filter(group => !group.followedByCurrentUser),
      ['name'],
      ['asc'],
    );
  },
);

const useIsFetchingGroups = () => {
  return useSelector((state: RootState) => state.group.api.isFetchingGroups);
};

const useIsFetchGroupsError = () => {
  return useSelector((state: RootState) => state.group.api.isFetchGroupsError);
};

const useGroupById = () => {
  return useSelector((state: RootState) => state.group.byId);
};

const useSelectGroupList = (): Group[] => {
  return useSelector(groupsSelector);
};

const useSelectGroupListByName = () => {
  return useSelector(groupsSelectorByName);
};

const useSelectFollowedGroupList = () => {
  return useSelector(followedGroups);
};

const useSelectFollowedGroupListByJoinedAt = () => {
  return useSelector(followedGroupsByJoinedAt);
};

const useSelectFollowedGroupListByUnreadCount = () => {
  return useSelector(followedGroupsByUnreadCount);
};

const useSelectNotFollowedGroupList = () => {
  return useSelector(notFollowedGroups);
};

const useSelectNotFollowedGroupListByName = () => {
  return useSelector(notFollowedGroupsByName);
};

const useSelectGroupById = (groupId: number) => {
  return useSelector((state: RootState) => {
    const byId = state.group.byId;
    return byId[groupId];
  });
};

// prompt user join group modal if logged in but not onboarded
const usePromptGroupModal = () => {
  const isOnboarded = useIsOnboarded();
  const isUserProfileLoaded = useIsUserProfileLoaded();
  const dispatch = useDispatch();

  useEffect(() => {
    const openedOnboardModal = getSessionStorage('opened-onboard-modal');
    const userSignedUp = getSessionStorage('user-signedup');

    if (
      !isOnboarded &&
      isUserProfileLoaded &&
      openedOnboardModal !== 'true' &&
      userSignedUp !== 'true'
    ) {
      dispatch(openJoinGroupModal({ isSigningUp: false }));
      sessionStorage.setItem('opened-onboard-modal', 'true');
    }
  }, [isOnboarded, isUserProfileLoaded, dispatch]);
};

export {
  useGroupById,
  useSelectGroupList,
  useSelectGroupListByName,
  useSelectFollowedGroupList,
  useSelectFollowedGroupListByJoinedAt,
  useSelectFollowedGroupListByUnreadCount,
  useSelectNotFollowedGroupList,
  useSelectNotFollowedGroupListByName,
  useSelectGroupById,
  useIsFetchingGroups,
  useIsFetchGroupsError,
  usePromptGroupModal,
};

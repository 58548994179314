import isEmpty from 'lodash/isEmpty';
import { ResponseData } from 'api/base';
import { ApiOkResponse } from 'apisauce';
import { Reward, RewardClaim, TagType } from './rewardTypes';

export const checkIsRedeemable = (profile, reward: Reward) => {
  if (isEmpty(profile) || isEmpty(reward)) return false;

  if (
    profile.creditsCount >= reward.minCredits &&
    profile.tier >= reward.minTier
  ) {
    return true;
  }

  return false;
};

export const mapTagType = (
  reward: Reward,
  canRedeem: boolean,
  claim: RewardClaim,
): TagType => {
  if (!reward) return 'lock';
  if (claim && claim.used_at) return 'used';
  if (claim && !claim.used_at) return 'redeemed';
  if (reward.isFullyRedeemed) return 'fully_redeemed';
  if (!canRedeem) return 'lock';
  if (reward.endedAt && new Date(reward.endedAt) < new Date()) return 'expired';
  if (reward.isFeatured) return 'featured';
};

export const constructRewardCreditPayload = (
  response: ApiOkResponse<ResponseData<any, any>>,
  shareLink: string,
) => {
  return {
    creditsEarned: response.data.meta?.creditsEarned || 0,
    userLeveledUp: response.data.meta?.userLeveledUp || false,
    maxMonthlyCreditReached:
      response.data.meta?.maxMonthlyCreditReached || false,
    user: response.data.data.user,
    shareLink,
  };
};

import { useEffect, useRef, useReducer } from 'react';
import searchApi from 'api/searchApi';
import blogApi from 'entity/blog/blogApi';
import useDebounce from 'utils/hooks/useDebounce';
import { createLocalSearch } from 'constants/searchComparison';
import { useSelectGroupList } from 'entity/group/groupHooks';

export const INITIAL_STATE = {
  products: [],
  profiles: [],
  opinions: [],
  groups: [],
  posts: [],
  blogs: [],
};

type ACTIONTYPE =
  | { type: 'set_search_result'; payload: any }
  | { type: 'set_recent_search_result'; payload: any }
  | { type: 'set_blog_search_result'; payload: any }
  | { type: 'set_group_search_result'; payload: any }
  | { type: 'reset_search_result' };

const reducer = (
  state: typeof INITIAL_STATE,
  action: ACTIONTYPE,
): typeof INITIAL_STATE => {
  switch (action.type) {
    case 'set_recent_search_result':
      return { ...INITIAL_STATE, ...action.payload };
    case 'set_search_result':
      return { ...state, ...action.payload };
    case 'set_group_search_result':
      return { ...state, groups: action.payload };
    case 'set_blog_search_result':
      return { ...state, blogs: action.payload };
    case 'reset_search_result':
      return { ...INITIAL_STATE };
    default:
      return state;
  }
};

const useSearch = (term: string) => {
  const debounceSearch: string = useDebounce(term, 500);
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE);
  const localSearch = useRef(createLocalSearch());
  const groupList = useSelectGroupList();

  useEffect(() => {
    if (debounceSearch.length > 2) {
      // reset search result before fetching new results
      dispatch({ type: 'reset_search_result' });

      // General search with API
      searchApi.searchWithTerm({ term: debounceSearch }).then(response => {
        if (response?.data.code === 'ok') {
          // currently posts is named as questions in BE
          const transformedData = {
            ...response.data.data,
            posts: response.data.data.questions,
          };

          dispatch({
            type: 'set_search_result',
            payload: transformedData,
          });
        }
      });

      // Blog search with wordpress API
      blogApi
        .searchArticles({ searchTerm: debounceSearch, page: 1, perPage: 5 })
        .then(response => {
          if (response.ok) {
            dispatch({
              type: 'set_blog_search_result',
              payload: response.data.posts.slice(0, 2),
            });
          }
        });

      // Search for group from redux store
      const groupResult = groupList
        .filter(group => {
          return group.name
            .toLowerCase()
            .includes(debounceSearch.toLowerCase());
        })
        .slice(0, 4);
      dispatch({ type: 'set_group_search_result', payload: groupResult });
    }
  }, [debounceSearch, groupList]);

  // use recent search result if there isnt any input
  useEffect(() => {
    if (!debounceSearch) {
      dispatch({
        type: 'set_recent_search_result',
        payload: localSearch.current.load(),
      });
    }
  }, [debounceSearch, localSearch]);

  return state;
};

export default useSearch;

import paramsSerializer from 'app/utils/paramSerializer';
import api from './base';

const getUserReviews = (params = {}) => {
  const { slug } = params;
  return api.get(`v4/users/${slug}/reviews`, params, {
    paramsSerializer,
  });
};

const getProductReviews = (params = {}) => {
  const { productId, ...otherParams } = params;
  return api.get(`v4/product/items/${productId}/reviews`, otherParams, {
    paramsSerializer,
  });
};

const postReview = (params = {}) => {
  const { formValues } = params;
  return api.post(`v4/product/item/reviews`, formValues);
};

const updateReview = (params = {}) => {
  const { formValues, reviewId } = params;
  return api.patch(`v4/product/item/reviews/${reviewId}`, formValues);
};

const deleteReview = (params = {}) => {
  const { reviewId } = params;
  return api.delete(`v4/product/item/reviews/${reviewId}`, {});
};

const voteReview = (params = {}) => {
  const { id, type } = params;
  return api.patch(`v4/product/item/reviews/${id}/vote`, { id, type });
};

const getReviewFilterTag = (params = {}) => {
  const { id } = params;
  return api.get(`v4/product/items/${id}/review_tags?limit=10`, {});
};

export default {
  getUserReviews,
  getProductReviews,
  getReviewFilterTag,
  postReview,
  updateReview,
  deleteReview,
  voteReview,
};

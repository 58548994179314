/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface Group {
  id: number;
  about: string;
  coverImage: string;
  description: string;
  discussionsCount: number;
  followedByCurrentUser: boolean;
  isToggling: boolean;
  isActive: true;
  joinedAt: string;
  membersCount: number;
  name: string;
  profileImage: string;
  slug: string;
  unreadCount: number;
}

interface FollowGroupPayload {
  groupId: number;
  followed: boolean;
}

interface GroupState {
  ids: number[];
  byId: Record<number, Group>;
  api: {
    isFetchingGroups: boolean;
    isFetchGroupsError: boolean;
  };
}

const initialState: GroupState = {
  ids: [],
  byId: {},
  api: {
    isFetchingGroups: false,
    isFetchGroupsError: false,
  },
};

export const groupsSlice = createSlice({
  name: 'groups',
  initialState,
  reducers: {
    getGroupsRequest(state) {
      state.api.isFetchingGroups = true;
    },
    getGroupsSuccess(state, action: PayloadAction<Group[]>) {
      state.ids = [];
      action.payload.forEach(group => {
        state.ids.push(group.id);
        state.byId[group.id] = group;
      });
      state.api.isFetchingGroups = false;
      state.api.isFetchGroupsError = false;
    },
    getGroupsFailure(state) {
      state.api.isFetchingGroups = false;
      state.api.isFetchGroupsError = true;
    },
    followGroupRequest(state, action: PayloadAction<number>) {
      const groupId = action.payload;
      if (state.byId[groupId]) {
        state.byId[groupId].isToggling = true;
      }
    },
    followGroupSuccess(state, action: PayloadAction<FollowGroupPayload>) {
      const { groupId, followed } = action.payload;
      if (state.byId[groupId]) {
        state.byId[groupId].followedByCurrentUser = followed;
        state.byId[groupId].isToggling = false;
        state.byId[groupId].membersCount += followed ? 1 : -1;
        if (followed) {
          state.byId[groupId].joinedAt = new Date().getTime().toString();
        }
      }
    },
    followGroupsRequest(state, action: PayloadAction<number[]>) {},
    followGroupsSuccess(state, action: PayloadAction<{ groups: Group[] }>) {
      const groups = action.payload.groups;
      groups.forEach(group => {
        state.byId[group.id].followedByCurrentUser = true;
      });
    },
    updateGroupUnreadRequest(state, action: PayloadAction<number>) {},
    updateGroupUnreadSuccess(state, action: PayloadAction<number>) {
      const groupId = action.payload;
      state.byId[groupId].unreadCount = 0;
    },
  },
});

// only use for dispatching purpose
export const {
  getGroupsRequest,
  followGroupRequest,
  followGroupsRequest,
  updateGroupUnreadRequest,
} = groupsSlice.actions;

export default groupsSlice.reducer;

/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit';
import {
  Comment,
  CommentState,
  CommentsRequestPayload,
  CommentsSuccessPayload,
  PostCommentRequestPayload,
  UpdateCommentRequestPayload,
  FeaturedCommentsRequestPayload,
  PostCommentSuccessPayload,
  CommentPayload,
  GetCommentBody,
  VoteType,
  UserCommentPayload,
} from './commentTypes';

const defaultPagination = {
  per: 0,
  currentPage: 0,
  totalCount: 0,
  totalPage: 0,
};

const initialState: CommentState = {
  byType: {
    Post: {},
    Opinion: {},
    Review: {},
    Article: {},
    PostComment: {},
    ReviewComment: {},
    OpinionComment: {},
    ArticleComment: {},
  },
  byId: {},
  api: {
    isFetching: false,
    isFetchingMoreComment: false,
  },
  userComments: {
    entityCommentTuples: [],
    pagination: {
      per: 0,
      currentPage: 0,
      totalCount: 0,
      totalPage: 0,
    },
  },
};

const setIsFetching = (
  state: Draft<CommentState>,
  action: PayloadAction<GetCommentBody | CommentsRequestPayload>,
) => {
  const { payload } = action;
  state.api.isFetching = payload.page && payload.page === 1;
  state.api.isFetchingMoreComment = payload.page && payload.page > 1;
};

export const commentsSlice = createSlice({
  name: 'comments',
  initialState,
  reducers: {
    getCommentsRequest: {
      reducer(state, action: PayloadAction<CommentsRequestPayload>) {},
      prepare(payload: CommentsRequestPayload, meta?: any) {
        return { payload, meta };
      },
    },
    getUserParentCommentsRequest(
      state,
      action: PayloadAction<GetCommentBody & { slug: string }>,
    ) {
      setIsFetching(state, action);
    },
    getUserChildCommentsRequest(
      state,
      action: PayloadAction<GetCommentBody & { slug: string }>,
    ) {
      setIsFetching(state, action);
    },
    getUserBookmarkedParentCommentsRequest(
      state,
      action: PayloadAction<GetCommentBody & { slug: string }>,
    ) {
      setIsFetching(state, action);
    },
    getUserBookmarkedChildCommentsRequest(
      state,
      action: PayloadAction<GetCommentBody & { slug: string }>,
    ) {
      setIsFetching(state, action);
    },
    getCommentsSuccess(state, action: PayloadAction<CommentsSuccessPayload>) {
      const {
        comments,
        commentableType,
        commentableId,
        pagination,
        isRefresh,
      } = action.payload;

      state.api.isFetching = false;
      state.api.isFetchingMoreComment = false;

      if (!state.byType[commentableType][commentableId] || isRefresh) {
        // if no comment yet, initialize array
        state.byType[commentableType][commentableId] = {
          ids: [],
          pagination: {
            ...defaultPagination,
          },
        };
      }

      if (pagination) {
        state.byType[commentableType][commentableId].pagination = pagination;
      }

      comments.forEach(comment => {
        const { id } = comment;
        state.byId[id] = comment;

        // push comment into the array if is not inside
        if (
          state.byType[commentableType][commentableId].ids.indexOf(id) === -1
        ) {
          state.byType[commentableType][commentableId].ids.push(comment.id);
        }
      });
    },
    getFeaturedCommentsSuccess(
      state,
      action: PayloadAction<FeaturedCommentsRequestPayload>,
    ) {
      const {
        commentsByCommentable,
        commentableType,
        isRefresh,
      } = action.payload;

      state.api.isFetching = false;
      state.api.isFetchingMoreComment = false;

      // refresh the list when navigating
      if (isRefresh) {
        state.byType[commentableType] = {};
      }

      commentsByCommentable.forEach(commentable => {
        const { commentableId, comments } = commentable;

        comments.forEach(comment => {
          state.byId[comment.id] = comment;
        });

        state.byType[commentableType][commentableId] = {
          ids: comments.map(comment => comment.id),
          pagination: commentable.pagination,
        };
      });
    },
    getUserCommentsSuccess(state, action: PayloadAction<UserCommentPayload>) {
      const { parentChildComment, pagination, isRefresh } = action.payload;
      state.api.isFetching = false;
      state.api.isFetchingMoreComment = false;

      // refresh the list when navigating
      if (isRefresh) {
        state.userComments.entityCommentTuples = [];
      }

      state.userComments.pagination = pagination;
      parentChildComment.forEach(item => {
        const { featuredComment, entity } = item;
        state.byId[featuredComment.id] = featuredComment;
        state.userComments.entityCommentTuples.push([
          entity,
          featuredComment.id,
        ]);
      });
    },
    getCommentsFailure(state) {
      state.api.isFetching = false;
      state.api.isFetchingMoreComment = false;
    },
    postCommentRequest(
      state,
      action: PayloadAction<PostCommentRequestPayload>,
    ) {},
    postCommentSuccess(
      state,
      action: PayloadAction<PostCommentSuccessPayload>,
    ) {
      const { comment, commentableType, commentableId } = action.payload;
      const commentByType = state.byType[commentableType][commentableId];

      // if no comment yet, initialize array
      if (!commentByType) {
        state.byType[commentableType][commentableId] = {
          ids: [comment.id],
          pagination: {
            ...defaultPagination,
          },
        };
      } else if (
        commentableType === 'Post' ||
        commentableType === 'Review' ||
        commentableType === 'Opinion' ||
        commentableType === 'Article'
      ) {
        state.byType[commentableType][commentableId].ids.unshift(comment.id);
      } else {
        state.byType[commentableType][commentableId].ids.push(comment.id);
      }

      state.byId[comment.id] = comment;

      // update pagination
      if (state.byType[commentableType][commentableId].pagination) {
        state.byType[commentableType][commentableId].pagination.totalCount += 1;
      }
    },
    updateCommentRequest(
      state,
      action: PayloadAction<UpdateCommentRequestPayload>,
    ) {},
    updateCommentSuccess(
      state,
      action: PayloadAction<PostCommentSuccessPayload>,
    ) {
      const { comment } = action.payload;

      // only update neccessary state to avoid weird behavior like comment count not tally.
      if (state.byId[comment.id]) {
        state.byId[comment.id].commentText = comment.commentText;
        state.byId[comment.id].updatedAt = comment.updatedAt;
        state.byId[comment.id].picture = comment.picture;
        state.byId[comment.id].pictureOriginal = comment.pictureOriginal;
        state.byId[comment.id].isAnonymous = comment.isAnonymous;
      }
    },
    deleteCommentRequest(state, action: PayloadAction<CommentPayload>) {},
    deleteCommentSuccess(state, action: PayloadAction<CommentPayload>) {
      const { commentId, commentableType, commentableId } = action.payload;
      const commentByType = state.byType[commentableType][commentableId];

      if (commentByType) {
        commentByType.ids = commentByType.ids.filter(id => id !== commentId);
        if (commentByType.pagination) {
          commentByType.pagination.totalCount -= 1;
        }
      }

      state.userComments.entityCommentTuples = state.userComments.entityCommentTuples.filter(
        entity => {
          return entity[1] !== commentId;
        },
      );

      delete state.byId[commentId];
    },
    voteCommentRequest(
      state,
      action: PayloadAction<CommentPayload & { voteType: VoteType }>,
    ) {},
    voteCommentSuccess(state, action: PayloadAction<{ comment: Comment }>) {
      const { comment } = action.payload;
      if (state.byId[comment.id]) {
        state.byId[comment.id].upvoted = comment.upvoted;
        state.byId[comment.id].upvotesCount = comment.upvotesCount;
      }
    },
    bookmarkCommentRequest(state, action: PayloadAction<CommentPayload>) {
      const { commentId } = action.payload;
      if (state.byId[commentId]) {
        state.byId[commentId].bookmarked = !state.byId[commentId].bookmarked;
      }
    },
    bookmarkCommentSuccess(state, action: PayloadAction<{ comment: Comment }>) {
      const { comment } = action.payload;
      if (state.byId[comment.id]) {
        state.byId[comment.id].bookmarked = comment.bookmarked;
      }
    },
    resetPostComment(state) {
      state.byType.Post = {};
    },
  },
});

// only use for dispatching purpose
export const {
  getCommentsRequest,
  getUserParentCommentsRequest,
  getUserChildCommentsRequest,
  getUserBookmarkedParentCommentsRequest,
  getUserBookmarkedChildCommentsRequest,
  postCommentRequest,
  updateCommentRequest,
  deleteCommentRequest,
  voteCommentRequest,
  bookmarkCommentRequest,
  resetPostComment,
  getFeaturedCommentsSuccess,
} = commentsSlice.actions;

export default commentsSlice.reducer;

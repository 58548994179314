import React from 'react';
import { SpinnerTheme } from './SpinnerTheme';
import { SpinnerWrapper, Loader } from './styles';

interface SpinnerProps {
  size?: number;
  isCenter?: boolean;
  margin?: string;
  variant?: keyof typeof SpinnerTheme;
}

const Spinner = (props: SpinnerProps) => {
  const {
    size = 28,
    isCenter = false,
    margin = '0px',
    variant = 'default',
  } = props;
  const { fgColor, bgColor } = SpinnerTheme[variant] || SpinnerTheme.default;

  return (
    <SpinnerWrapper data-testid="spinner" margin={margin} isCenter={isCenter}>
      <Loader size={size} fgColor={fgColor} bgColor={bgColor} />
    </SpinnerWrapper>
  );
};

export default Spinner;

import { call, takeLatest, put } from 'redux-saga/effects';
import { postsSlice } from 'entity/post/postRedux';
import OpinionAction from 'app/reducer/opinionRedux';

import api from './feedApi';
import postApi from '../post/postApi';

import { feedsSlice } from './feedRedux';

const formatter = response => {
  const posts = response.data.data.filter(feed => {
    return feed.type === 'Community::Question::Entity';
  });

  const opinions = response.data.data.filter(feed => {
    return feed.type === 'Community::Opinion::Entity';
  });

  return {
    posts,
    opinions,
    pagination: response.data.meta.pagination,
  };
};

const getFeedApiByParam = feedFilter => {
  if (feedFilter === 'recent') {
    return api.getRecentFeeds;
  }

  if (feedFilter === 'trending') {
    return api.getTrendingFeeds;
  }

  if (feedFilter === 'new') {
    return api.getNewFeeds;
  }
};

function* getFeeds(action) {
  const { payload } = action;

  const feedApi = getFeedApiByParam(payload.feedFilter);
  const response = yield call(feedApi, { page: payload.page, per_page: 10 });

  const feedSuccessAction = feedsSlice.actions.getFeedSuccess;
  const feedFailureAction = feedsSlice.actions.getFeedsFailure;
  const postSuccessAction = postsSlice.actions.getPostsSuccess;
  const opinonsSuccessAction = OpinionAction.getOpinionsSuccess;
  if (response.ok) {
    const successPayload = formatter(response);
    yield put(
      feedSuccessAction({
        feeds: response.data.data,
        pagination: response.data.meta.pagination,
      }),
    );
    yield put(opinonsSuccessAction({ opinions: successPayload.opinions }));
    yield put(postSuccessAction(successPayload));

    // fetch pinned post
    if (payload.page === 1 && payload.feedFilter === 'recent') {
      yield put(postsSlice.actions.getPinnedPostRequest());
    }
  } else {
    yield put(feedFailureAction());
  }
}

function* getPinnedPost() {
  const response = yield call(postApi.getPinnedPost);
  if (response.ok) {
    // put in post redux
    const posts = response.data.data;

    yield put(
      postsSlice.actions.getPinnedPostSuccess({
        posts,
        pagination: response.data.meta.pagination,
      }),
    );

    // put in feed;
    if (posts.length > 0) {
      yield put(feedsSlice.actions.addPostToFeed(posts[0]));
    }
  }
}

export const feedSagas = [
  takeLatest(feedsSlice.actions.getFeedsRequest, getFeeds),
  takeLatest(postsSlice.actions.getPinnedPostRequest, getPinnedPost),
];

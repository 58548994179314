import React from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import useIsNativeApp from 'app/utils/hooks/useIsNativeApp';
import buildConfig from 'app/utils/buildConfig';

/*
    Check href if it has `blog` to know if link is for blog or community/product

      <Link href="/blog/author?authorSlug=guest-contributor" as="/blog/author/guest-contributor" />

      Output:

      If user is on seedly.sg
        <a href="blog.seedly.sg/author/guest-contributor" />

      If user is on blog.seedly.sg
        <a href="/author/guest-contributor" />
  ---

    <Link href={`/profile?slug=${slug}`} as={`/profile/${slug}`} />

      If user is on seedly.sg
        <a href="/profile/:slug" />

      If user is on blog.seedly.sg
        <a href="seedly.sg/profile/:slug" />
*/

const getLinkProps = props => {
  const router = useRouter();
  const isOnBlogDomain = router.route.startsWith('/blog');
  const redirectToHomePage = props.homepage;
  const linkIsForBlog = (
    (typeof props.href === 'string' && props.href) ||
    ''
  ).includes('/blog/');

  const isOnBlogDomainAndLinkIsForBlog = isOnBlogDomain && linkIsForBlog;
  const isOnCommunityDomainAndLinkIsForCommunity =
    !isOnBlogDomain && !linkIsForBlog;

  if (redirectToHomePage) {
    return {
      ...props,
      href: `${buildConfig.apiHost}`,
      passHref: true,
      as: `${buildConfig.apiHost}`,
      prefetch: false,
    };
  }

  // handle of passing url instead of path in
  // to prevent return href of duplicate domain
  if (
    isOnBlogDomainAndLinkIsForBlog ||
    isOnCommunityDomainAndLinkIsForCommunity ||
    props.href?.indexOf('http') === 0
  ) {
    return props;
  }

  if (isOnBlogDomain && !linkIsForBlog) {
    return {
      ...props,
      href: `${buildConfig.apiHost}${props.as || props.href}`,
      passHref: true,
      as: null,
      prefetch: false,
    };
  }
  if (!isOnBlogDomain && linkIsForBlog) {
    return {
      ...props,
      href: `${buildConfig.blogHost}${props.as || props.href}`,
      passHref: true,
      as: null,
      prefetch: false,
    };
  }
};

const SeedlyLink = props => {
  const linkProps = getLinkProps(props);
  const isNativeApp = useIsNativeApp();

  if (isNativeApp) {
    return React.cloneElement(props.children, {
      href: linkProps.href,
    });
  }

  return <Link {...linkProps}>{props.children}</Link>;
};

export default SeedlyLink;

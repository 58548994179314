const colors = {
  // Blue
  seedlyBlue: '#2955DE',
  blue1: '#E9EEFC',
  blue2: '#B9C7F6',
  blue3: '#89A1EE',
  blue4: '#597BE6',
  blue5: '#2955DE',
  blue6: '#254DC8',
  blue7: '#2144B2',

  // Grey
  neutral1: '#FFFFFF',
  neutral2: '#F4F5F6',
  neutral3: '#E9ECF3',
  neutral4: '#DEE3F0',
  neutral5: '#99A1B3',
  neutral6: '#6D7587',
  neutral7: '#40485A',

  // Green
  green1: '#EBF9F2',
  green2: '#C6F0D8',
  green3: '#A1E5BF',
  green4: '#7CDAA6',
  green5: '#57CF8D',
  green6: '#4EBA7F',
  green7: '#46A671',
  green8: '#48B5A3',
  green9: '#66AE4B',

  // Red
  red1: '#FCE9EB',
  red2: '#F6C8CD',
  red3: '#F1A5AD',
  red4: '#EC828D',
  red5: '#E75F6D',
  red6: '#D05662',
  red7: '#B94C57',

  // Teal
  teal1: '#2DC1F6',

  // Purple
  purple1: '#715FF2',
  purple2: '#7E6EBA',

  // Orange
  orange1: '#FFAA00',

  // Yellow
  yellow1: '#FFD552',

  // Background color
  bgDarkGrey: 'rgba(0, 0, 0, 0.4)',
  bgWhiteMinorTranslucent: 'rgba(255, 255, 255, 0.9)',

  // Modal color
  modalBgNeutral: 'rgba(64, 72, 90, 0.5)',

  // Rewards
  forestGreen4: '#66AE4B',
  sunflowerYellow4: '#FFAA00',
  lilacPurple4: '#7E6EBA',
  oceanTeal4: '#48B5A3',
  coralPink4: '#FF8589',
};

export default colors;

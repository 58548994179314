import values from 'lodash/values';
import {
  Smile,
  Star,
  Users,
  HelpCircle,
  MessageCircle,
  ThumbsUp,
} from 'react-feather';
import { POST_FILTER_TYPE } from '../entity/post/postConstant';

export const PROFILE_FILTER_TYPE = {
  OPINIONS: 'opinions',
  ANSWERED_BY: 'answered-by',
  ASKED_BY: 'asked-by',
  REPLIED_BY: 'replied-by',
  REVIEWS: 'reviews',
};

export const PROFILE_EDIT_TYPE = {
  BASIC: 'basicInformation',
  PICTURE: 'picture',
  MOBILE: 'mobile',
};

export const CREDENTIAL_TYPE = {
  EMPLOYMENT: 'employment',
  EDUCATION: 'education',
};

export const CREDENTIAL_TYPE_LIST = [
  CREDENTIAL_TYPE.EMPLOYMENT,
  CREDENTIAL_TYPE.EDUCATION,
];

export const YEAR_OPTIONS = [];
for (let i = new Date().getFullYear(); i > 1960; i -= 1) {
  YEAR_OPTIONS.push(i);
}

export const CREDENTIAL_LINK_TYPE = {
  FACEBOOK: 'facebookLink',
  LINKEDIN: 'linkedinLink',
  INSTAGRAM: 'instagramLink',
  YOUTUBE: 'youtubeLink',
  TWITTER: 'twitterLink',
  WEBSITE: 'websiteLink',
};

export const CREDENTIAL_LINK_TYPE_LIST = values(CREDENTIAL_LINK_TYPE);

export const CREDENTIAL_LINK_MAP = {
  [CREDENTIAL_LINK_TYPE.FACEBOOK]: 'www.facebook.com/',
  [CREDENTIAL_LINK_TYPE.LINKEDIN]: 'www.linkedin.com/',
  [CREDENTIAL_LINK_TYPE.INSTAGRAM]: 'www.instagram.com/',
  [CREDENTIAL_LINK_TYPE.YOUTUBE]: 'www.youtube.com/',
  [CREDENTIAL_LINK_TYPE.TWITTER]: 'www.twitter.com/',
  [CREDENTIAL_LINK_TYPE.WEBSITE]: '',
};

export const CREDENTIAL_TITLE_MAP = {
  [CREDENTIAL_LINK_TYPE.FACEBOOK]: 'Facebook',
  [CREDENTIAL_LINK_TYPE.LINKEDIN]: 'Linkedin',
  [CREDENTIAL_LINK_TYPE.INSTAGRAM]: 'Instagram',
  [CREDENTIAL_LINK_TYPE.YOUTUBE]: 'Youtube',
  [CREDENTIAL_LINK_TYPE.TWITTER]: 'Twitter',
  [CREDENTIAL_LINK_TYPE.WEBSITE]: 'Website',
};

export const PROFILE_FILTER_TYPE_MAP = {
  [POST_FILTER_TYPE.ANSWERED_BY]: 'Comments',
  [POST_FILTER_TYPE.REPLIED_BY]: 'Replies',
  [POST_FILTER_TYPE.ASKED_BY]: 'Posts',
  [PROFILE_FILTER_TYPE.REVIEWS]: 'Reviews',
  [PROFILE_FILTER_TYPE.OPINIONS]: 'Opinions',
};

export const PROFILE_FILTER_TYPE_LIST = [
  PROFILE_FILTER_TYPE.OPINIONS,
  POST_FILTER_TYPE.ASKED_BY,
  POST_FILTER_TYPE.ANSWERED_BY,
  POST_FILTER_TYPE.REPLIED_BY,
  PROFILE_FILTER_TYPE.REVIEWS,
];

export const MILESTONE_TYPE = {
  ABOUT: 'about',
  CREDENTIAL: 'credential',
  SOCIAL: 'social',
  QUESTION_ASKED: 'question-asked',
  QUESTION_ANSWERED: 'question-answered',
  UPVOTES_GIVEN: 'upvotes-given',
};

export const MILESTONE_TRACKER_LIST_DETAILS = {
  [MILESTONE_TYPE.ABOUT]: {
    icon: Smile,
    title: 'Let us know you',
    description: 'Fill in the about section',
  },
  [MILESTONE_TYPE.CREDENTIAL]: {
    icon: Star,
    title: 'Where are you from?',
    description: 'Add your credentials',
  },
  [MILESTONE_TYPE.SOCIAL]: {
    icon: Users,
    title: 'Social creature',
    description: 'Add 1 social link',
  },
  [MILESTONE_TYPE.QUESTION_ASKED]: {
    icon: HelpCircle,
    title: 'Hear from others',
    description: 'Start a discussion',
  },
  [MILESTONE_TYPE.QUESTION_ANSWERED]: {
    icon: MessageCircle,
    title: 'Share your opinions',
    description: 'Comment in a discussion',
  },
  [MILESTONE_TYPE.UPVOTES_GIVEN]: {
    icon: ThumbsUp,
    title: 'Be a nice guy',
    description: 'Upvote 5 comments',
  },
};

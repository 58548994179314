import get from 'lodash/get';

class ApiError {
  createError(response) {
    const errorMessage = get(response, 'data.message', '');
    const errorCode = get(response, 'status', 500);
    const problemCode = get(response, 'problem', '');
    const apiParam = get(response, 'config.params', '');
    const apiUrl = get(response, 'config.url', '');

    return {
      errorMessage,
      errorCode,
      problemCode,
      apiUrl,
      apiParam,
    };
  }

  constructor(response) {
    const {
      errorMessage,
      errorCode,
      apiUrl,
      apiParam,
      problemCode,
    } = this.createError(response);

    this.message = errorMessage;
    this.statusCode = errorCode;
    this.problemCode = problemCode;
    this.url = apiUrl;
    this.params = apiParam;
  }
}

export default ApiError;

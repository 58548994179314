import styled from 'styled-components';
import { TextVariantTheme } from './TextTheme';
import colors from '../themes/colors';

interface StyledTextProps {
  whiteSpace?: string;
  wordBreak?: string;
  colorProp?: string;
  textAlign?: string;
  hoverable?: boolean;
  underline?: boolean;
  variant: TextVariantTheme;
  fontFamily: 'Open Sans' | 'Mont';
  textTransform: 'none' | 'uppercase';
}

export const Text = styled.p<StyledTextProps>`
  font-weight: ${props => props.variant.fontWeight};
  font-size: ${props => props.variant.fontSize};
  font-family: ${props => props.variant.fontFamily};
  text-transform: ${props => props.variant.textTransform};
  line-height: ${props => props.variant.lineHeight};
  white-space: ${props => props.whiteSpace};
  word-break: ${props => props.wordBreak || 'normal'};
  color: ${props => props.colorProp};
  text-align: ${props => props.textAlign || 'left'};

  &:hover {
    cursor: ${props =>
    props.underline || props.hoverable ? 'pointer' : 'inherit'};
    color: ${props => (props.hoverable ? colors.seedlyBlue : props.colorProp)};
    text-decoration: ${props => (props.underline ? 'underline' : 'unset')};
  }

  &:link,
  &:visited,
  &:focus,
  &:active {
    color: ${props => props.colorProp};
  }
`;

import styled from 'styled-components';
import colors from '../themes/colors';
import { VariantTheme, SizeTheme } from './ButtonTypes';

interface StyledButtonProp {
  variant: VariantTheme;
  size: SizeTheme;
  borderRadius: string;
  isBlock?: boolean;
}

export const StyledButton = styled.button<StyledButtonProp>`
  border-radius: ${props => props.borderRadius};
  background-color: ${props => props.variant.backgroundColor};
  border: ${props => props.variant.border};
  color: ${props => props.variant.color};
  box-shadow: ${props => props.variant.boxShadow};

  padding: ${props => props.size.padding};
  width: ${props => (props.isBlock ? '100%' : 'unset')};
  height: fit-content;
  min-width: ${props => props.size.minWidth || '38px'};

  font-size: ${props => props.size.fontSize};
  font-family: Open Sans, Helvetica, Arial;
  font-weight: ${props => props.size.fontWeight};
  line-height: ${props => props.size.lineHeight};

  text-align: center;
  white-space: nowrap;
  cursor: pointer;
  outline: none;
  display: inline-block;

  &:hover {
    background-color: ${props => props.variant.backgroundHoveredColor};
    border: ${props => props.variant.boderHovered};

    svg {
      stroke: ${props => props.variant.hoveredColor || props.variant.color};
    }
  }

  &:active {
    background-color: ${props => props.variant.backgroundPressedColor};
    color: ${props => props.variant.pressedColor};
  }

  &:disabled {
    color: ${props => props.variant.colorDisabled || colors.neutral4};
    background-color: ${props => props.variant.backgroundDisabledColor};
    border: ${props => props.variant.borderDisabled};
    cursor: default;

    &:hover {
      svg {
        stroke: ${colors.neutral7};
      }
    }
  }

  &:link,
  &:visited {
    color: ${props => props.variant.color};
  }
`;

import api from './base';

const getOpinion = (params = {}) => {
  const { opinionSlug } = params;
  const published = true;
  return api.get(`v4/opinions/${opinionSlug}`, { published });
};

const getOpinions = (params = {}) => {
  return api.get(`v4/opinions`, params);
};

const getRecentOpinions = (params = {}) => {
  return api.get(`v4/opinions/recent`, params);
};

const getTrendingOpinions = (params = {}) => {
  return api.get(`v4/opinions/trending?days=7`, params);
};

const getUserOpinions = (params = {}) => {
  const { slug } = params;
  return api.get(`v4/users/${slug}/opinions`, params);
};

const getBookmarkedOpinions = (params = {}) => {
  const { slug } = params;
  return api.get(`v4/users/${slug}/bookmarked_opinions`, params);
};

const postOpinion = (params = {}) => {
  const published = true;
  return api.post('v4/opinions', { published, ...params });
};

const updateOpinion = (params = {}) => {
  const { id } = params;
  const published = true;
  return api.put(`v4/opinions/${id}`, { published, ...params });
};

const deleteOpinion = (params = {}) => {
  const { id } = params;
  const published = true;
  return api.delete(`v4/opinions/${id}`, { published });
};

const voteOpinion = (params = {}) => {
  const { id, voteType } = params;
  return api.put(`v4/opinions/${id}/vote`, { vote_type: voteType });
};

const bookmarkOpinion = (params = {}) => {
  const { id } = params;
  return api.put(`v4/opinions/${id}/bookmark`, {});
};

const updateOpinionPageView = (params = {}) => {
  const { id } = params;
  return api.patch(`v4/opinions/${id}/count`, params);
};

export default {
  getOpinion,
  getOpinions,
  getUserOpinions,
  getBookmarkedOpinions,
  getRecentOpinions,
  getTrendingOpinions,
  postOpinion,
  updateOpinion,
  deleteOpinion,
  voteOpinion,
  bookmarkOpinion,
  updateOpinionPageView,
};

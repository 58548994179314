import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import api from 'app/entity/auth/authApi';
import noop from 'lodash/noop';

const checkVerificationExpiry = dateISO => {
  if (!dateISO) return false;

  const expiryDate = new Date(dateISO);
  const currentDate = new Date();

  if (currentDate > expiryDate) {
    return false;
  }
  return true;
};

const useShowResendEmail = toggleEmailVerifiedModal => {
  const [showResendEmail, setShowResendEmail] = useState(false);

  const confirmedAt = useSelector(state => state.auth.user.confirmedAt);
  const isSignedIn = useSelector(state => state.auth.session.isSignedIn);
  const userEmail = useSelector(state => state.profile.user.email);
  const unverifiedEmail = useSelector(state => state.auth.unverifiedEmail);

  const onResendEmail = () => {
    api
      .resendVerificationEmail(userEmail)
      .then(() => {
        setShowResendEmail(false);
        toggleEmailVerifiedModal(true);
      })
      .catch(() => {
        setShowResendEmail(false);
      });
    const tomorrowDate = new Date();
    tomorrowDate.setDate(tomorrowDate.getDate() + 1);
    localStorage.setItem(
      'stop_verification_message_until',
      tomorrowDate.toISOString(),
    );
  };

  useEffect(() => {
    if (unverifiedEmail) {
      api.resendVerificationEmail(unverifiedEmail);
    }
  }, [unverifiedEmail]);

  useEffect(() => {
    let isVerificationExpire = true;
    try {
      isVerificationExpire = checkVerificationExpiry(
        localStorage.getItem('stop_verification_message_until'),
      );
    } catch (e) {
      noop();
    }
    if (isSignedIn && !confirmedAt && userEmail && !isVerificationExpire) {
      setShowResendEmail(true);
      localStorage.removeItem('stop_verification_message_until');
    }
  }, []);

  return [showResendEmail, onResendEmail];
};

export default useShowResendEmail;

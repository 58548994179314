import { call, put, takeLatest } from 'redux-saga/effects';
import get from 'lodash/get';

import api from 'app/api/opinionApi';
import searchApi from 'app/api/searchApi';
import { PROFILE_FILTER_TYPE } from 'app/constants/profile';
import ProfileAction from 'app/reducer/profileRedux';
import OpinionAction, { OpinionTypes } from 'app/reducer/opinionRedux';
import { OPINION_FITLER } from 'app/constants/opinion';
import { rewardsSlice } from 'entity/reward/rewardRedux';

import { generateOpinionLink } from 'app/utils/generateShareLink';
import { handleResponse } from 'app/utils/sagaHelper';
import { SearchTypes } from 'app/reducer/searchRedux';
import { commentsSlice } from '../entity/comment/commentRedux';
import { constructRewardCreditPayload } from '../entity/reward/rewardUtils';

const formatter = response => {
  return {
    opinions: response.data.data,
    pagination: response.data.meta.pagination,
  };
};

const getOpinionApiByFilter = opinionFilter => {
  switch (opinionFilter) {
    case OPINION_FITLER.TRENDING:
      return api.getTrendingOpinions;
    case OPINION_FITLER.RECENT:
      return api.getRecentOpinions;
    default:
      return api.getOpinions;
  }
};

function* getOpinion(action) {
  const { payload, meta } = action;

  const response = yield call(api.getOpinion, payload);
  const successAction = OpinionAction.getOpinionSuccess;
  yield call(handleResponse, { successAction, meta, response });
}

function* getOpinions(action) {
  const { payload, meta } = action;

  const opinionApi = getOpinionApiByFilter(payload.opinionFilter);
  const response = yield call(opinionApi, { page: payload.page });
  const successAction = OpinionAction.getOpinionsSuccess;
  yield call(handleResponse, {
    successAction,
    meta,
    response,
    format: formatter,
  });
}

function* getUserOpinions(action) {
  const { payload, meta } = action;

  yield put(
    ProfileAction.updateProfileMeta({
      filter: PROFILE_FILTER_TYPE.OPINIONS,
    }),
  );

  const response = yield call(api.getUserOpinions, payload);
  const successAction = OpinionAction.getOpinionsSuccess;
  yield call(handleResponse, {
    successAction,
    meta,
    response,
    format: formatter,
  });
}

function* getBookmarkedOpinions(action) {
  const { payload, meta } = action;

  const response = yield call(api.getBookmarkedOpinions, payload);
  const successAction = OpinionAction.getOpinionsSuccess;
  yield call(handleResponse, {
    successAction,
    meta,
    response,
    format: formatter,
  });
}

function* postOpinion(action) {
  const { payload, meta } = action;

  const response = yield call(api.postOpinion, payload);
  const successAction = OpinionAction.postOpinionSuccess;
  const failureAction = OpinionAction.postOpinionFailure;
  meta.successMsg = 'Opinion posted.';
  meta.failureMsg =
    response.data?.message || 'An error occured. Failed to post opinion.';

  // For profanity error, current is like [["profanity#1", "profanity#2"]]
  // Will relook into it when refactor
  meta.profanityErrors = get(response.data, 'errors.profanity[0]', []);

  yield call(handleResponse, { successAction, failureAction, meta, response });

  if (response.ok) {
    const shareLink = generateOpinionLink(response.data.data.slug);
    const successPayload = constructRewardCreditPayload(response, shareLink);

    yield put(rewardsSlice.actions.handleEarnCredit(successPayload));
  }
}

function* updateOpinion(action) {
  const { payload, meta } = action;

  const response = yield call(api.updateOpinion, payload);
  const successAction = OpinionAction.updateOpinionSuccess;
  const failureAction = OpinionAction.updateOpinionFailure;
  meta.successMsg = 'Opinion updated.';
  meta.failureMsg =
    response.data?.message || 'An error occured. Failed to update opinion.';

  yield call(handleResponse, { successAction, failureAction, meta, response });
}

function* deleteOpinion(action) {
  const { payload, meta } = action;

  const response = yield call(api.deleteOpinion, payload);
  const successAction = OpinionAction.deleteOpinionSuccess;
  meta.successMsg = 'Opinion deleted.';
  meta.failureMsg = 'An error occured. Failed to delete opinion.';
  const format = () => payload;

  yield call(handleResponse, { successAction, meta, response, format });
}

function* voteOpinion(action) {
  const { payload, meta = {} } = action;
  const response = yield call(api.voteOpinion, payload);
  const successAction = OpinionAction.voteOpinionSuccess;
  meta.failureMsg = get(response, 'data.message', null);
  yield call(handleResponse, { successAction, meta, response });
}

function* bookmarkOpinion(action) {
  const { payload, metadata } = action;

  const response = yield call(api.bookmarkOpinion, payload);
  const successAction = OpinionAction.bookmarkOpinionSuccess;
  if (response.ok) {
    metadata.successMsg = get(response, 'data.message', null);
  } else {
    metadata.failureMsg = get(response, 'data.message', null);
  }
  yield call(handleResponse, { successAction, meta: metadata, response });
}

function* getOpinionsSuccess(action) {
  const { opinions } = action.payload;
  const opinionComments = opinions
    .filter(opinion => !!opinion.featuredComment)
    .map(opinion => {
      return {
        pagination: {
          currentPage: 0, // trivia because initial
          per: 1,
          totalPage: opinion.commentsCount,
          totalCount: opinion.commentsCount,
        },
        commentableId: opinion.id,
        comments: [opinion.featuredComment],
      };
    });

  yield put(
    commentsSlice.actions.getFeaturedCommentsSuccess({
      commentsByCommentable: opinionComments,
      commentableType: 'Opinion',
    }),
  );
}

function* updateOpinionPageView(action) {
  const { payload } = action;

  yield call(api.updateOpinionPageView, payload);
}

function* searchOpinions(action) {
  const { payload, meta } = action;
  const response = yield call(searchApi.searchOpinionWithTerm, payload);
  const successAction = OpinionAction.getOpinionsSuccess;
  yield call(handleResponse, {
    successAction,
    meta,
    response,
    format: formatter,
  });
}

export const opinionSagas = [
  takeLatest(OpinionTypes.GET_OPINION_REQUEST, getOpinion),
  takeLatest(OpinionTypes.GET_OPINIONS_REQUEST, getOpinions),
  takeLatest(OpinionTypes.GET_USER_OPINIONS_REQUEST, getUserOpinions),
  takeLatest(OpinionTypes.GET_USER_OPINIONS_REQUEST, getUserOpinions),
  takeLatest(
    OpinionTypes.GET_BOOKMARKED_OPINIONS_REQUEST,
    getBookmarkedOpinions,
  ),
  takeLatest(OpinionTypes.GET_OPINIONS_SUCCESS, getOpinionsSuccess),
  takeLatest(OpinionTypes.POST_OPINION_REQUEST, postOpinion),
  takeLatest(OpinionTypes.UPDATE_OPINION_REQUEST, updateOpinion),
  takeLatest(OpinionTypes.DELETE_OPINION_REQUEST, deleteOpinion),
  takeLatest(OpinionTypes.VOTE_OPINION_REQUEST, voteOpinion),
  takeLatest(OpinionTypes.BOOKMARK_OPINION_REQUEST, bookmarkOpinion),
  takeLatest(
    OpinionTypes.UPDATE_OPINION_PAGE_VIEW_REQUEST,
    updateOpinionPageView,
  ),
  takeLatest(SearchTypes.SEARCH_OPINION_REQUEST, searchOpinions),
];

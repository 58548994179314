export const TYPES = {
  ANSWER: 'answer',
  REVIEW: 'review',
  COMMENT: 'comment',
  OPINION: 'opinion',
  POST: 'post',
};

export const REPORT_MODAL = {
  [TYPES.ANSWER]: {
    name: 'Answer',
    type: TYPES.ANSWER,
    options: [
      { title: 'Promotional Spam' },
      { title: 'Harassment' },
      { title: 'Nudity' },
      { title: 'Profanity' },
      { title: 'Private Information' },
      { title: 'Suspicious' },
      { title: "Doesn't Answer the Question" },
      { title: 'Plagiarism' },
      { title: 'Unsubstantial' },
      { title: 'Poorly Written' },
      { title: 'Joke Answer' },
      { title: 'Factually Incorrect' },
    ],
  },
  [TYPES.REVIEW]: {
    name: 'Review',
    type: TYPES.REVIEW,
    options: [
      { title: 'Promotional Spam' },
      { title: 'Harassment' },
      { title: 'Wrong Product' },
      { title: 'Inconsistent' },
      { title: 'Plagiarism' },
      { title: 'Unsubstantial' },
      { title: 'Poorly Written' },
      { title: 'Profanity' },
      { title: 'Private Information' },
      { title: 'Suspicious' },
      { title: 'Fraudulent' },
    ],
  },
  [TYPES.COMMENT]: {
    name: 'Comment',
    type: TYPES.COMMENT,
    options: [
      { title: 'Promotional Spam' },
      { title: 'Harassment' },
      { title: 'Private Information' },
    ],
  },
  [TYPES.OPINION]: {
    name: 'Opinion',
    type: TYPES.OPINION,
    options: [
      { title: 'Promotional Spam' },
      { title: 'Harassment' },
      { title: 'Nudity' },
      { title: 'Profanity' },
      { title: 'Suspicious' },
      { title: 'Plagiarism' },
      { title: 'Unsubstantial' },
      { title: 'Poorly Written' },
      { title: 'Factually Incorrect' },
    ],
  },
  [TYPES.POST]: {
    name: 'post',
    type: TYPES.POST,
    options: [
      { title: 'Promotional Spam' },
      { title: 'Harassment' },
      { title: 'Poorly Written' },
      { title: 'Incorrect Topics' },
      { title: 'Private Information' },
    ],
  },
};

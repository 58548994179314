import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import isEmpty from 'lodash/isEmpty';

import { authUserToken, setAuthStatus } from 'app/entity/auth/authDispatch';
import { getUserProfileWithSlug } from 'app/dispatcher/profileDispatcher';
import getAuthHeaders from 'app/utils/getAuthHeaders';

const useAuth = () => {
  const dispatch = useDispatch();
  const { isSignedIn } = useSelector(state => state.auth.session);
  const authUser = useSelector(state => state.auth.user);

  useEffect(() => {
    if (isSignedIn) {
      dispatch(getUserProfileWithSlug({ slug: authUser.slug }));
    }
  }, [authUser.slug, dispatch, isSignedIn]);

  useEffect(() => {
    const authHeaders = getAuthHeaders();

    if (!isEmpty(authHeaders) && !isSignedIn) {
      dispatch(
        authUserToken({
          headers: {
            'access-token': authHeaders['access-token'],
            client: authHeaders.client,
            uid: authHeaders.uid,
          },
        }),
      );
    } else if (isEmpty(authHeaders)) {
      dispatch(setAuthStatus({ isIdle: false }));
    }
  }, [dispatch, isSignedIn]);
};

export default useAuth;

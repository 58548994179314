import { createReducer, createActions } from 'reduxsauce';
import produce from 'immer';
import get from 'lodash/get';
import find from 'lodash/find';
import { CREDIT_CARD_FILTER } from 'app/constants/product';

const { Types, Creators } = createActions({
  getProductCategoriesRequest: ['payload', 'meta'],
  getProductCategoriesSuccess: ['payload', 'meta'],
  getProductCategoriesFailure: null,

  getProductsRequest: ['payload', 'meta'],
  getProductsWithTermRequest: ['payload', 'meta'],
  getProductsSuccess: ['payload', 'meta'],
  getProductsFailure: null,

  getProductRequest: ['payload', 'meta'],
  getProductSuccess: ['payload', 'meta'],
  getProductFailure: null,

  getCategoryPrismicRequest: ['payload', 'meta'],
  getCategoryPrismicSuccess: ['payload'],
  getCategoryPrismicFailure: null,

  getPlanRequest: ['payload', 'meta'],
  getPlanSuccess: ['payload', 'meta'],
  getPlanFailure: null,

  getPlanFaqPrismicRequest: ['payload', 'meta'],
  getPlanFaqPrismicSuccess: ['payload'],
  getPlanFaqPrismicFailure: null,

  getSavingsAccountsRequest: ['payload', 'meta'],
  getSavingsAccountsSuccess: ['payload'],
  getSavingsAccountsFailure: null,

  getTopQuestionReviewRequest: ['payload', 'meta'],
  getTopQuestionReviewSuccess: ['payload'],
  getTopQuestionReviewFailure: null,

  setProductFilterParams: ['payload'],
  resetSearchProducts: null,
});

const INITIAL_STATE = {
  productCategories: [],
  productCategoriesById: {},
  products: [],
  productFilterParams: {
    filter: {},
  },
  byId: {},
  api: {
    isFetching: false,
    hasError: false,
  },
  meta: {
    pagination: {},
  },
  prismicBanner: {},
  plan: null,
  planFaq: null,
  planMeta: {},
};

export const ProductTypes = Types;
export default Creators;

const resetSearchProducts = state => {
  return produce(state, draft => {
    draft.products = [];
    draft.meta.pagination = {};
  });
};

const getProductCategoriesSuccess = (state, action) => {
  const productCategories = action.payload;

  return produce(state, draft => {
    draft.api.hasError = false;
    draft.productCategories = productCategories;

    productCategories.forEach(parentCat => {
      draft.productCategoriesById[parentCat.id] = parentCat;

      if (parentCat.children.length > 0) {
        parentCat.children.forEach(childCat => {
          draft.productCategoriesById[childCat.id] = childCat;
        });
      }
    });
  });
};

const getProductsRequest = state => {
  return produce(state, draft => {
    draft.api.isFetching = true;
  });
};

const getProductsSuccess = (state, action) => {
  const {
    products,
    pagination,
    sortType,
    categorySlug,
    term,
    productFilter,
    isSignUpBonus,
  } = action.payload;

  // if credit card product param fulfil
  let title;
  let description;
  let descriptionMap = {};

  if (productFilter) {
    const cardTypes = get(productFilter, CREDIT_CARD_FILTER.CARD_TYPES, []);
    const bankProviders = get(
      productFilter,
      CREDIT_CARD_FILTER.BANK_PROVIDERS,
      [],
    );

    const categoriesArray = Object.values(state.productCategoriesById);
    const currentCategory = find(categoriesArray, ['slug', categorySlug]);
    const metaDescription = get(currentCategory, 'metaDescription', null);

    if (metaDescription) {
      try {
        descriptionMap = JSON.parse(metaDescription);
      } catch (error) {
        descriptionMap = {};
      }
    }

    if (cardTypes.length === 1 && bankProviders.length === 0) {
      title = `Best Credit Cards for ${cardTypes[0]} in Singapore`;
      if (descriptionMap[cardTypes[0]]) {
        description = descriptionMap[cardTypes[0]];
      }
    }
    if (cardTypes.length === 0 && bankProviders.length === 1) {
      title = `Best Credit Cards for ${bankProviders[0]} in Singapore`;
      description = `Find the best ${bankProviders[0]} credit card.`;
    }
    if (cardTypes.length === 1 && bankProviders.length === 1) {
      title = `Best ${bankProviders[0]} Credit Cards for ${cardTypes[0]} in Singapore`;
      if (descriptionMap[cardTypes[0]]) {
        description = `${
          descriptionMap[cardTypes[0]]
        } Compare and find the best ${bankProviders[0]} credit card.`;
      }
    }
  }

  return produce(state, draft => {
    draft.products = products;
    draft.api.isFetching = false;
    draft.api.hasError = false;
    draft.meta.pagination = pagination || {};
    draft.meta.categorySlug = categorySlug;
    draft.meta.sortType = sortType;
    draft.meta.term = term || null;
    draft.meta.title = title || '';
    draft.meta.description = description || '';
    draft.meta.isSignUpBonus = isSignUpBonus || false;
    draft.productFilterParams.filter = productFilter || {};
  });
};

const setProductFilterParams = (state, action) => {
  const { type, value } = action.payload;
  return produce(state, draft => {
    draft.productFilterParams.filter[type] = value;
  });
};

const setApiFailure = state => {
  return produce(state, draft => {
    draft.api.isFetching = false;
    draft.api.hasError = true;
  });
};

const getProductSuccess = (state, action) => {
  const { payload } = action;
  return produce(state, draft => {
    draft.api.hasError = false;
    draft.byId[payload.product.id] = payload.product;
  });
};

const getCategoryPrismicSuccess = (state, action) => {
  const { payload } = action;

  return produce(state, draft => {
    draft.prismicBanner = payload;
  });
};

const getPlanSuccess = (state, action) => {
  const { payload } = action;
  const { plan, meta } = payload;

  return produce(state, draft => {
    draft.api.hasError = false;
    draft.plan = plan;
    draft.planMeta = meta;
  });
};

const getPlanFaqPrismicSuccess = (state, action) => {
  const { payload } = action;

  return produce(state, draft => {
    draft.planFaq = payload;
  });
};

const getTopQuestionReviewSuccess = (state, action) => {
  const { itemId, review, question } = action.payload;
  const index = state.products.findIndex(prod => prod.id === itemId);
  return produce(state, draft => {
    draft.products[index].reviewId = get(review, 'id');
    draft.products[index].review = review;
    draft.products[index].questionId = get(question, 'id');
  });
};

export const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_PRODUCT_CATEGORIES_SUCCESS]: getProductCategoriesSuccess,
  [Types.GET_PRODUCT_CATEGORIES_FAILURE]: setApiFailure,
  [Types.GET_PRODUCTS_WITH_TERM_REQUEST]: getProductsRequest,
  [Types.GET_PRODUCTS_REQUEST]: getProductsRequest,
  [Types.GET_PRODUCTS_SUCCESS]: getProductsSuccess,
  [Types.GET_PRODUCTS_FAILURE]: setApiFailure,
  [Types.GET_PRODUCT_SUCCESS]: getProductSuccess,
  [Types.GET_PRODUCT_FAILURE]: setApiFailure,
  [Types.GET_CATEGORY_PRISMIC_SUCCESS]: getCategoryPrismicSuccess,
  [Types.GET_PLAN_SUCCESS]: getPlanSuccess,
  [Types.GET_PLAN_FAILURE]: setApiFailure,
  [Types.GET_PLAN_FAQ_PRISMIC_SUCCESS]: getPlanFaqPrismicSuccess,
  [Types.GET_SAVINGS_ACCOUNTS_SUCCESS]: getProductsSuccess,
  [Types.GET_TOP_QUESTION_REVIEW_SUCCESS]: getTopQuestionReviewSuccess,
  [Types.GET_TOP_QUESTION_REVIEW_FAILURE]: setApiFailure,
  [Types.SET_PRODUCT_FILTER_PARAMS]: setProductFilterParams,
  [Types.RESET_SEARCH_PRODUCTS]: resetSearchProducts,
});

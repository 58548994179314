const shadow = {
  carousel: '0px 1px 5px 2px rgba(0, 0, 0, 0.1)',
  dropdownMenu: '0px 2px 5px 0px rgba(0, 0, 0, 0.1)',
  likeButton: '0px 2px 5px 0px rgba(0, 0, 0, 0.1)',
  modal: '0px 12px 28px 0px rgba(0, 0, 0, 0.15)',
  navBar: '0px 1px 2px 0px rgba(0, 0, 0, 0.1)',
  card: '0px 1px 2px 0px rgba(64, 72, 90, 0.1)',
};

export default shadow;

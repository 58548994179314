import React from 'react';
import noop from 'lodash/noop';

export const PathContext = React.createContext({ path: '/', host: '' });
export const PageContext = React.createContext({
  showHeader: false,
  scrollable: true,
  setScrollable: noop,
  scrollTo: '',
  setScrollTo: noop,
  isOpen: false,
  toggleMenu: noop,
});
export const PlanContext = React.createContext();
export const RewardModalContext = React.createContext();
export const SystemInfoContext = React.createContext({
  isMobile: false,
});

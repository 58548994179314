import { useEffect } from 'react';
import mixpanel, { trackedEvents } from 'app/utils/mixpanel';

const usePageViewTracker = () => {
  useEffect(() => {
    mixpanel.track(trackedEvents.PageView);
  }, []);
};

export default usePageViewTracker;

import apisauce from 'apisauce';
import paramsSerializer from 'app/utils/paramSerializer';

import buildConfig from 'utils/buildConfig';
import api, { ResponseData } from '../../api/base';
import {
  GetRewardParams,
  Reward,
  CreditHistory,
  RewardBenefitByTier,
  RewardVoucher,
} from './rewardTypes';
import { AuthenticatedUser } from '../user/userTypes';
import { Pagination } from '../comment/commentTypes';

const s3Api = apisauce.create({
  baseURL: buildConfig.s3BucketEndpoint,
  timeout: 15000,
});

const getReward = (rewardId: number) => {
  return api.get<ResponseData<Reward>>(`v4/rewards/${rewardId}`);
};

const getAllRewards = (params: GetRewardParams) => {
  return api.get<ResponseData<Reward[]>>('v4/rewards', params, {
    paramsSerializer,
  });
};

const getPersonalRewards = () => {
  return api.get<ResponseData<Reward[]>>('v4/rewards?personal=true');
};

const getCreditHistory = (params: {
  state: 'debit' | 'credit';
  per: number;
  page: number;
}) => {
  return api.get<ResponseData<CreditHistory[], { pagination: Pagination }>>(
    'v4/rewards/transaction_history',
    params,
  );
};

const getRewardCategoriesFilter = () => {
  return api.get<ResponseData<{ name: string; slug: string }[]>>(
    '/v4/rewards/categories',
  );
};

const claimReward = (rewardId: number) => {
  return api.post<ResponseData<Reward>>(`/v4/rewards/${rewardId}/claim`);
};

const getTierBenefitList = () => {
  return api.get<ResponseData<RewardBenefitByTier>>('/v4/rewards/benefits');
};

const getVoucherDetail = (rewardId: number, claimId: number) => {
  return api.get<ResponseData<RewardVoucher>>(
    `/v4/rewards/${rewardId}/claims/${claimId}/voucher`,
  );
};

const getRewardFaq = () => {
  return s3Api.get<{ question: string; answer: string; section: string }[]>(
    'reward/faq.json',
  );
};

const dailyCheckIn = (userId: number) => {
  return api.post<ResponseData<AuthenticatedUser>>(
    `/v4/users/${userId}/check_in`,
  );
};

const downloadReward = (rewardId: number) => {
  return api.get<ArrayBuffer>(
    `/v4/rewards/${rewardId}/download`,
    {},
    { responseType: 'arraybuffer' },
  );
};

const markUseReward = (rewardId: number, claimId: number) => {
  return api.put<ResponseData<Reward>>(`/v4/rewards/${rewardId}/use`, {
    claim_id: claimId,
  });
};

export default {
  getReward,
  getAllRewards,
  getPersonalRewards,
  getCreditHistory,
  getVoucherDetail,
  claimReward,
  getRewardCategoriesFilter,
  getTierBenefitList,
  markUseReward,
  getRewardFaq,
  dailyCheckIn,
  downloadReward,
};

/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import isEmpty from 'lodash/isEmpty';
import { Pagination } from '../comment/commentTypes';
import { RewardState, Reward, GetRewardParams } from './rewardTypes';

const DefaultPagination: Pagination = {
  per: 0,
  currentPage: 0,
  totalCount: 0,
  totalPage: 0,
};

const initialState: RewardState = {
  ids: [],
  levelUpRewardId: null,
  claimedReward: null,
  userRewardIds: [],
  shareLink: '',
  byId: {},
  pagination: {
    per: 0,
    currentPage: 0,
    totalCount: 0,
    totalPage: 0,
  },
  api: {
    isFetching: false,
    isClaiming: false,
  },
};

export const rewardsSlice = createSlice({
  name: 'rewards',
  initialState,
  reducers: {
    getRewardRequest(
      state,
      action: PayloadAction<{ rewardId: number; onSuccess: () => void }>,
    ) {
      state.api.isFetching = true;
    },
    getRewardSuccess(state, action: PayloadAction<Reward>) {
      const reward = action.payload;
      state.byId[reward.id] = reward;
      state.api.isFetching = false;
    },
    getRewardsRequest(state, action: PayloadAction<GetRewardParams>) {
      state.api.isFetching = true;
    },
    getRewardsSuccess(
      state,
      action: PayloadAction<{ rewards: Reward[]; pagination: Pagination }>,
    ) {
      const { rewards, pagination } = action.payload;
      state.ids = [];
      rewards.forEach(reward => {
        state.ids.push(reward.id);
        state.byId[reward.id] = reward;
      });
      state.pagination = isEmpty(pagination) ? DefaultPagination : pagination;
      state.api.isFetching = false;
    },
    getPersonalRewardsRequest(state) {
      state.api.isFetching = true;
    },
    getPersonalRewardsSuccess(state, action: PayloadAction<Reward[]>) {
      state.userRewardIds = [];
      action.payload.forEach(reward => {
        state.userRewardIds.push(reward.id);
        state.byId[reward.id] = reward;
      });
      state.api.isFetching = false;
    },
    getRewardFailure(state) {
      state.api.isFetching = false;
    },
    claimRewardRequest(state, action: PayloadAction<number>) {
      state.api.isClaiming = true;
    },
    claimRewardSuccess(state, action: PayloadAction<Reward>) {
      const reward = action.payload;
      state.claimedReward = {
        reward,
        rewardId: reward.id,
        claimId: reward.claims[0].id,
      };
      state.api.isClaiming = false;
    },
    claimRewardFailure(state) {
      state.api.isClaiming = false;
    },
    setRewardAsClaimed(state, action: PayloadAction<Reward>) {
      const reward = action.payload;
      state.byId[reward.id] = reward;
    },
    handleEarnCredit(
      state,
      action: PayloadAction<{
        creditsEarned: number;
        userLeveledUp: boolean;
        shareLink: string;
        user: any;
      }>,
    ) {
      state.shareLink = action.payload.shareLink;
    },
    dailyCheckInRequest(
      state,
      action: PayloadAction<{ openRewardModal: boolean }>,
    ) {},
    markRewardUseRequest(
      state,
      action: PayloadAction<{ rewardId: number; claimId: number }>,
    ) {},
    markRewardUseSuccess(state, action: PayloadAction<Reward>) {
      const reward = action.payload;
      state.byId[reward.id] = reward;
    },
  },
});

export const {
  getRewardRequest,
  getRewardSuccess,
  getRewardsRequest,
  getPersonalRewardsRequest,
  claimRewardRequest,
  dailyCheckInRequest,
  markRewardUseRequest,
  markRewardUseSuccess,
  claimRewardFailure,
  setRewardAsClaimed,
} = rewardsSlice.actions;

export default rewardsSlice.reducer;

import { call, takeLatest, put, select } from 'redux-saga/effects';
import * as Sentry from '@sentry/nextjs';
import ProfileAction from 'reducer/profileRedux';
import ModalActions from 'reducer/modalRedux';
import { handleResponse } from 'utils/sagaHelper';
import { Message } from 'seedly-component-library';
import {
  ClaimRewardApiResponse,
  GetRewardApiResponse,
  GetRewardParams,
} from './rewardTypes';
import { rewardsSlice } from './rewardRedux';
import profileApi from '../../api/profileApi';
import api from './rewardApi';

export function* getReward(action: {
  payload: { rewardId: number; onSuccess: () => void };
}) {
  const { rewardId, onSuccess } = action.payload;
  const response = yield call(api.getReward, rewardId);
  if (response.ok) {
    yield put(rewardsSlice.actions.getRewardSuccess(response.data.data));
    yield call(onSuccess);
  } else {
    yield put(rewardsSlice.actions.getRewardFailure());
  }
}

export function* getRewards(action: { payload: GetRewardParams }) {
  const { payload } = action;
  const response: GetRewardApiResponse = yield call(api.getAllRewards, payload);
  if (response.ok) {
    yield put(
      rewardsSlice.actions.getRewardsSuccess({
        rewards: response.data.data,
        pagination: response.data.meta.pagination,
      }),
    );
  } else {
    yield put(rewardsSlice.actions.getRewardFailure());
  }
}

export function* getPersonalRewards() {
  const response: GetRewardApiResponse = yield call(api.getPersonalRewards);
  if (response.ok) {
    yield put(
      rewardsSlice.actions.getPersonalRewardsSuccess(response.data.data),
    );
  } else {
    yield put(rewardsSlice.actions.getRewardFailure());
  }
}

export function* claimReward(action) {
  const rewardId = action.payload;
  const response: ClaimRewardApiResponse = yield call(
    api.claimReward,
    rewardId,
  );
  const user = yield select(state => state.auth.user);

  if (response.ok) {
    // fetch and updated profile
    const profileResponse = yield call(profileApi.getProfile, {
      slug: user.slug,
    });
    const successAction = ProfileAction.getUserProfileSuccess;
    yield call(handleResponse, { successAction, response: profileResponse });

    // update reward + profile
    const reward = response.data.data;
    yield put(rewardsSlice.actions.claimRewardSuccess(reward));

    // open claim reward success modal
    yield put(ModalActions.setClaimRewardSuccessModal(true));
  } else {
    // open claim reward failure modal
    yield put(ModalActions.setClaimRewardFailureModal(true));
    yield put(rewardsSlice.actions.claimRewardFailure());
  }
}

export function* markUseReward(action) {
  const { rewardId, claimId } = action.payload;
  const response = yield call(api.markUseReward, rewardId, claimId);
  if (response.ok) {
    const reward = response.data.data;
    yield put(rewardsSlice.actions.markRewardUseSuccess(reward));
  } else {
    Sentry.captureMessage('User failed to mark reward as used');
  }
}

export function* handleEarnCredit(action) {
  const {
    user,
    userLeveledUp,
    shareLink,
    creditsEarned,
    maxMonthlyCreditReached,
  } = action.payload;

  // update
  if (user) {
    yield put(ProfileAction.updateProfilePoints(user));
  }

  // user tier up
  if (userLeveledUp) {
    yield put(ModalActions.setTierUpModal(true));
    return;
  }

  // user earned credit
  yield put(
    ModalActions.setCreditEarnModal(true, {
      shareLink,
      creditsEarned,
      maxMonthlyCreditReached,
    }),
  );
}

export function* dailyCheckIn(action) {
  const openRewardModal = action.payload?.openRewardModal;
  const user = yield select(state => state.profile.user);
  const response = yield call(api.dailyCheckIn, user.id);
  if (response.ok) {
    const { data, meta } = response.data;

    Message.success('Checked In');
    yield put(ProfileAction.dailyCheckIn(data));
    if (openRewardModal) {
      yield put(ModalActions.setDailyCheckInRewardModal(true));
    }
    if (data.awardWeeklyLoginStreak) {
      yield put(
        ModalActions.setDailyCheckInPointsModal(true, {
          maxMonthlyCreditReached: meta?.maxMonthlyCreditReached,
        }),
      );

      if (meta.userLeveledUp) {
        yield put(ModalActions.setTierUpModal(true));
      }
    } else {
      yield put(ModalActions.setDailyCheckInSuccessModal(true));
    }
  } else {
    Message.error('Checked In Unsuccessful');
    Sentry.captureMessage('User failed to check in daily');
  }
}

export const rewardSagas = [
  takeLatest(rewardsSlice.actions.getRewardRequest, getReward),
  takeLatest(rewardsSlice.actions.getRewardsRequest, getRewards),
  takeLatest(
    rewardsSlice.actions.getPersonalRewardsRequest,
    getPersonalRewards,
  ),
  takeLatest(rewardsSlice.actions.claimRewardRequest, claimReward),
  takeLatest(rewardsSlice.actions.handleEarnCredit, handleEarnCredit),
  takeLatest(rewardsSlice.actions.dailyCheckInRequest, dailyCheckIn),
  takeLatest(rewardsSlice.actions.markRewardUseRequest, markUseReward),
];

import React from 'react';
import TextTheme from './TextTheme';
import colors from '../themes/colors';
import * as S from './styles';

const Intent = {
  primary: colors.seedlyBlue,
  secondary: colors.neutral5,
  tertiary: colors.neutral6,
  error: colors.red5,
  light: colors.neutral4,
  success: colors.green6,
  inverted: colors.neutral1,
  default: colors.neutral7,
};

interface TextProps extends React.HTMLAttributes<HTMLParagraphElement> {
  renderAs?: any;
  ariaLabel?: string;
  variant?: keyof typeof TextTheme;
  intent?: keyof typeof Intent;
  underline?: boolean;
  hoverable?: boolean;
  whiteSpace?: string;
  wordBreak?: string;
  textAlign?: string;
  href?: string;
  target?: string;
  rel?: string;
  textColor?: string;
  children: React.ReactNode;
}

const Text = React.forwardRef<HTMLParagraphElement, TextProps>((props, ref) => {
  const {
    renderAs = 'p',
    ariaLabel,
    variant = 'body',
    intent = 'default',
    underline,
    hoverable,
    whiteSpace = 'normal',
    wordBreak = 'normal',
    textAlign,
    href,
    target,
    rel,
    textColor,
    ...otherProps
  } = props;

  const textVariant = TextTheme[variant];
  const color = textColor || Intent[intent];

  return (
    <S.Text
      variant={textVariant}
      underline={underline}
      hoverable={hoverable}
      as={renderAs}
      colorProp={color}
      aria-label={ariaLabel}
      ref={ref}
      whiteSpace={whiteSpace}
      wordBreak={wordBreak}
      textAlign={textAlign}
      href={href}
      target={target}
      rel={rel}
      {...otherProps}
    >
      {props.children}
    </S.Text>
  );
});

export default Text;

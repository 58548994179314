import styled, { createGlobalStyle } from 'styled-components';
import { media } from 'theme';
import { themes } from '../themes';
import type { IGlobalStyle } from './index';

export const GlobalStyle = createGlobalStyle<{
  globalStyle: IGlobalStyle;
  label: string;
  size: string;
}>`
  .ReactModal__Body--open {
    overflow: hidden;
  }

  .overlay {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: ${themes.colors.bgDarkGrey};
    z-index: 19;
    display: flex;
    justify-content: center;
    align-items: center;

    ${media.largeAndDown`
      overflow: hidden;
    `}
  }

  .content-${props => (props.label ? props.label : 'default')} {
    top: auto;
    bottom: auto;
    left: auto;
    right: auto;
    border: none;
    border-radius: ${themes.borderRadius.xxl};
    box-shadow: ${themes.shadow.modal};
    margin: 0 auto;
    max-width: ${themes.sizing.modalMaxWidth};
    padding: 0;
    max-height: ${props => props.globalStyle?.maxHeight || '95vh'};
    position: relative;
    background: #fff;
    overflow: hidden;
    overflow-y: auto;
    outline: none;
    min-height: ${props => props.globalStyle?.minHeight};
    width: ${props => themes.sizing[props.size]};

    @media (max-height: 639px) {
      min-height: 0;
      height: 100%;
    }

    ${props => media.largeAndDown`
      border: none;
      z-index: 20;
      height: ${props.globalStyle?.mobileHeight || '100%'};
      width: ${props.globalStyle?.mobileWidth || '100%'};
      border-radius: ${
  props.globalStyle?.isMobileRounded ? themes.borderRadius.lg : 0
};
      top: 0;
      left: 0;
      padding: 0;
      max-height: 100%;
      min-height: 0;
      display: flex;
      flex-flow: column;
    `}
  }
`;

export const closeIconStyles = {
  fill: 'grey',
  height: '24px',
  width: '24px',
  cursor: 'pointer',
};

export const ModalHeader = styled.div<{
  padding: string;
  mobileFullHeight: boolean;
}>`
  text-align: right;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background: ${themes.colors.bgWhiteMinorTranslucent};
  z-index: 5;
  position: sticky;
  top: 0;
  padding: ${props => props.padding};

  ${props =>
    props.mobileFullHeight &&
    media.largeAndDown`
      flex: 0 1 auto;
  `}
`;

export const Title = styled.span`
  font: ${themes.fonts.default};
`;

export const ModalBody = styled.div<{ mobileFullHeight: boolean }>`
  padding: ${themes.sizing.default};
  overflow-y: auto;

  ${props =>
    props.mobileFullHeight &&
    media.largeAndDown`
    display: flex;
    flex-flow: column;
    flex: 1 1 auto;
  `}
`;

export const ModalFooter = styled.div<{ mobileFullHeight: boolean }>`
  height: auto;
  padding-bottom: ${themes.spacing.md};
  position: sticky;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: ${themes.colors.neutral1};
  z-index: 5;

  ${props =>
    props.mobileFullHeight &&
    media.largeAndDown`
    flex: 0 1 40px;
  `}
`;

import styled from 'styled-components';

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding-top: 15px;
  font-size: 14px;
  color: ${props => props.theme.colors.neutral5};
  > * {
    margin-bottom: 24px;
  }
`;

export const IllustrationWrapper = styled.div`
  img {
    height: 105px;
  }
`;

export const Header = styled.div`
  font-size: 18px;
  font-weight: bold;
  width: auto;
  color: ${props => props.theme.colors.neutral7};
`;
export const SubHeader = styled.div`
  width: 305px;
`;

export const ReasonWrapper = styled.div`
  padding: 16px 0 32px;
  > div:first-of-type {
    text-transform: uppercase;
    font-size: 12px;
    font-weight: bold;
    margin-bottom: 8px;
  }

  > div:nth-of-type(2) {
    color: ${props => props.theme.colors.neutral7};
  }
`;

export const SupportMessage = styled.div`
  width: 306px;
  padding: 16px 0;
  a {
    font-weight: bold;
    color: ${props => props.theme.colors.seedlyBlue};
  }
`;

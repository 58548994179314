import { REVIEW_FILTER_TYPE } from 'app/constants/product';
import ReviewActions from '../reducer/reviewRedux';
import { makeCallbacks } from './helper';

const getSortParam = sortType => {
  switch (sortType) {
    case REVIEW_FILTER_TYPE.MOST_UPVOTE:
      return {
        by: 'cached_votes_up',
        dir: 'desc',
      };
    case REVIEW_FILTER_TYPE.MOST_RECENT:
      return {
        by: 'updated_at',
        dir: 'desc',
      };
    case REVIEW_FILTER_TYPE.LOW_TO_HIGH:
      return {
        by: 'rating',
        dir: 'asc',
      };
    case REVIEW_FILTER_TYPE.HIGH_TO_LOW:
      return {
        by: 'rating',
        dir: 'desc',
      };
    default:
      return {
        by: 'cached_votes_up',
        dir: 'desc',
      };
  }
};

const getUserReviews = ({ profileSlug }, meta = {}) => {
  const params = {
    slug: profileSlug,
    include_latest_comment: true,
    per: 8,
  };
  meta.includeLatestComment = true;
  return ReviewActions.getUserReviewsRequest(params, meta);
};

const getUserReviewsNextPage = ({ profileSlug, page }) => {
  const params = {
    slug: profileSlug,
    include_latest_comment: true,
    per: 8,
    page,
  };
  const meta = { isFetchingMoreReviews: true, includeLatestComment: true };
  return ReviewActions.getUserReviewsRequest(params, meta);
};

const getProductReviews = (
  { productId, rid, sortType, tag, filterText },
  meta = {},
) => {
  const sort = getSortParam(sortType);
  const params = {
    productId,
    rid,
    sort,
    include_latest_comment: true,
    per: 10,
  };

  if (tag) params.tag = tag;

  meta.includeLatestComment = true;
  meta.sortType = sortType;
  meta.tag = tag || null;
  meta.filterText = filterText || [];

  return ReviewActions.getProductReviewsRequest(params, meta);
};

const getProductReviewsNextPage = ({ productId, page, tag }, meta) => {
  const sort = getSortParam(meta.sortType);
  const params = {
    page,
    productId,
    sort,
    include_latest_comment: true,
    per: 10,
  };

  if (tag) params.tag = tag;

  const newMeta = {
    ...meta,
    isFetchingMoreReviews: true,
    includeLatestComment: true,
  };
  return ReviewActions.getProductReviewsRequest(params, newMeta);
};

const postReview = (formValues, { onSuccess, onFailure } = {}) => {
  const meta = {};
  meta.callbacks = makeCallbacks({ onSuccess, onFailure });
  return ReviewActions.postReviewRequest(formValues, meta);
};

const updateReview = (
  { formValues, reviewId },
  { onSuccess, onFailure } = {},
) => {
  const meta = {};
  meta.callbacks = makeCallbacks({ onSuccess, onFailure });
  return ReviewActions.updateReviewRequest({ formValues, reviewId }, meta);
};

const deleteReview = ({ reviewId }, { onSuccess, onFailure } = {}) => {
  const meta = {};
  meta.callbacks = makeCallbacks({ onSuccess, onFailure });
  return ReviewActions.deleteReviewRequest({ reviewId }, meta);
};

const voteReview = ({ id, voteType }, { onSuccess, onFailure } = {}) => {
  const params = { id, type: voteType };
  const meta = {};
  meta.callbacks = makeCallbacks({ onSuccess, onFailure });
  return ReviewActions.voteReviewRequest(params, meta);
};

const getPrismic = (params, meta) => {
  return ReviewActions.getProductPrismicRequest(params, meta);
};

const getReviewFilter = (params, meta) => {
  return ReviewActions.getReviewFilterRequest(params, meta);
};

export {
  getUserReviews,
  getUserReviewsNextPage,
  getProductReviews,
  getProductReviewsNextPage,
  getReviewFilter,
  postReview,
  updateReview,
  deleteReview,
  voteReview,
  getPrismic,
};

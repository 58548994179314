import Fuse from 'fuse.js';

const LOCAL_STORAGE_SEARCH_KEY = 'search_history';

const defaultOptions = Object.freeze({
  shouldSort: true,
  tokenize: true,
  findAllMatches: true,
  includeScore: false,
  threshold: 0,
  location: 0,
  distance: 100,
  maxPatternLength: 32,
  minMatchCharLength: 1,
  keys: ['title', 'name', 'description', 'tags', 'keywords'],
});

const createInitialState = () => ({
  products: [],
  profiles: [],
  opinions: [],
  groups: [],
  questions: [],
  blogs: [],
});

export const createLocalSearch = (data, options = {}) => {
  const fuse = new Fuse(data, { ...defaultOptions, ...options });

  const load = () => {
    try {
      if (!window) {
        return createInitialState();
      }

      const historyData = window.localStorage.getItem(LOCAL_STORAGE_SEARCH_KEY);
      return { blogs: [], ...JSON.parse(historyData) } || createInitialState();
    } catch (error) {
      return createInitialState();
    }
  };

  let histories = load();

  const duplicate = (type, result) => {
    const results = histories[type];
    const valid = results && Array.isArray(results);
    return valid && results.find(item => item.id === result.id);
  };

  const size = () =>
    Object.values(histories || {})
      .map(collection => collection.length)
      .reduce((left, right) => left + right, 0);

  const add = (type, result) => {
    if (!histories[type]) histories[type] = [];

    // Store the last recentAt date.
    // Don't override existing ones.
    if (!result.recentAt) result.recentAt = new Date();

    // Recent items appears first.
    histories[type].unshift(result);
  };

  const clear = () => {
    histories = createInitialState();
  };

  const truncate = () => {
    // Keep only the last 5 items.
    if (size() > 5) {
      const items = Object.values(histories).reduce(
        (left, right) => left.concat(right),
        [],
      );

      const latestItems = items
        .sort(
          (left, right) => new Date(right.recentAt) - new Date(left.recentAt),
        )
        .slice(0, 5);

      clear();

      latestItems.forEach(item => {
        add(item.type, item);
      });
    }
  };

  const save = () => {
    window.localStorage.setItem(
      LOCAL_STORAGE_SEARCH_KEY,
      JSON.stringify(histories),
    );
  };

  return {
    // Returns the items that matches the search comparison.
    searchLocal(term) {
      return fuse.search(term);
    },

    // Save the search result when user tap on the result, and before
    // navigating to the result page.
    save(item) {
      const { type } = item;
      const present = item && type;
      if (!present || duplicate(type, item)) return;
      add(type, item);
      truncate();
      save();
    },

    // Display the search history when the user first tap on the search input.
    load() {
      try {
        if (!window) {
          return createInitialState();
        }

        const historyData = window.localStorage.getItem(
          LOCAL_STORAGE_SEARCH_KEY,
        );
        return JSON.parse(historyData) || createInitialState();
      } catch (error) {
        return createInitialState();
      }
    },
  };
};

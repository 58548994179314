import { createSelector } from 'reselect';
import get from 'lodash/get';
import {
  CREDIT_CARD_DEFAULT_META,
  getOnlineBrokerageMetaTitle,
  ONLINE_BROKERAGES_DEFAULT_META,
  CAR_INSURANCE_DEFAULT_META,
  PRIORITY_BANKING_DEFAULT_META,
} from 'app/constants/product';

export const getCategories = state => state.product.productCategories;
export const getCategoriesById = state => state.product.productCategoriesById;
export const getCurrentCategorySlug = state => {
  return get(state, 'product.meta.categorySlug', '');
};
export const getProducts = state => state.product.products;
export const getProductMeta = state => state.product.meta;
export const getFilterParams = state =>
  state.product.productFilterParams.filter;

export const getChildCategoryBySlug = createSelector(
  [getCategories],
  categories => {
    const categoryBySlug = {};
    categories.forEach(parentCat => {
      // categories for sign up bonus don't have children, hence the check
      if (parentCat.children && parentCat.children.length > 0) {
        parentCat.children.forEach(childCat => {
          categoryBySlug[childCat.slug] = childCat;
        });
      } else {
        categoryBySlug[parentCat.slug] = parentCat;
      }
    });
    return categoryBySlug;
  },
);

export const getCurrentCategory = createSelector(
  [getChildCategoryBySlug, getCurrentCategorySlug],
  (childCategoryBySlug, currentCategorySlug) => {
    return get(childCategoryBySlug, currentCategorySlug, null);
  },
);

export const getReviewPageProps = createSelector(
  [getCurrentCategory, getProductMeta],
  (currentCategory, productMeta) => {
    let title;
    let description;
    let seoMeta;

    let metaTitle = get(productMeta, 'title', '');
    const metaDescription = get(productMeta, 'description', '');
    const isSignUpBonus = get(productMeta, 'isSignUpBonus', '');

    const today = new Date();
    const year = today.toLocaleDateString('en-US', { year: 'numeric' });

    const isPageTitlePresent = Boolean(currentCategory?.extra.pageTitle);
    const isPageDescriptionPresent = Boolean(
      currentCategory?.extra.pageDescription,
    );
    const isSEOMetaTitlePresent = Boolean(
      currentCategory?.extra.seoMeta?.title,
    );
    const isSEOMetaDescriptionPresent = Boolean(
      currentCategory?.extra.seoMeta?.description,
    );

    if (currentCategory && isSignUpBonus) {
      title = `Sign Up Bonus & Promotions for ${currentCategory.name} in Singapore ${year} `;
      description =
        currentCategory.extra.signUpBonus?.description ||
        'Read reviews from other users on Robo-Advisors, investment products and more';

      seoMeta = {
        category: currentCategory,
        metaDescription:
          currentCategory.extra.signUpBonus?.metaDescription ||
          currentCategory.extra.signUpBonus?.description ||
          description,
        metaTitle,
      };
    } else if (isSignUpBonus) {
      title = `Sign Up Bonus & Promotions in Singapore ${year}`;
      description =
        'Maximize your savings with sign-up bonuses and welcome gifts on your new credit card, travel insurance sign-ups, and more! Find the latest credit card promotions, travel insurance deals, and other offers on financial products here on Seedly';
      seoMeta = null;
    } else if (!currentCategory || !currentCategory.isLeaf) {
      title = 'All Products';
      description =
        'Read reviews from other users on Robo-Advisors, investment products and more';
      seoMeta = null;
    } else {
      let overrideMetaDescription = '';

      title =
        metaTitle.length > 0
          ? metaTitle
          : `Best ${metaTitle} ${
              currentCategory.name
            } in Singapore ${new Date().getFullYear()}`;
      description =
        metaDescription.length > 0
          ? metaDescription
          : `Compare and read real user reviews on ${currentCategory.name} in Singapore`;

      if (
        currentCategory.slug === 'credit-cards' &&
        metaDescription.length === 0
      ) {
        description = CREDIT_CARD_DEFAULT_META;
      }

      if (currentCategory.slug === 'online-brokerages') {
        metaTitle = getOnlineBrokerageMetaTitle();
        description = ONLINE_BROKERAGES_DEFAULT_META;
      }

      if (currentCategory.slug === 'car-insurance') {
        description = CAR_INSURANCE_DEFAULT_META;
      }

      if (currentCategory.slug === 'priority-banking') {
        overrideMetaDescription = PRIORITY_BANKING_DEFAULT_META;
        metaTitle = `Best Priority Banking in Singapore ${new Date().getFullYear()} | Premier & Privilege Banking`;
      }

      seoMeta = {
        ...currentCategory,
        metaDescription: overrideMetaDescription || description,
        metaTitle,
      };
    }

    if (currentCategory && !isSignUpBonus && isPageTitlePresent) {
      title = currentCategory.extra.pageTitle;
    }

    if (currentCategory && !isSignUpBonus && isPageDescriptionPresent) {
      description = currentCategory.extra.pageDescription;
    }

    if (currentCategory && !isSignUpBonus && isSEOMetaTitlePresent) {
      seoMeta = {
        ...seoMeta,
        metaTitle: currentCategory.extra.seoMeta.title,
      };
    }

    if (currentCategory && !isSignUpBonus && isSEOMetaDescriptionPresent) {
      seoMeta = {
        ...seoMeta,
        metaDescription: currentCategory.extra.seoMeta.description,
      };
    }

    return { title, description, seoMeta };
  },
);

export const getProductBySlug = createSelector([getProducts], products => {
  const productBySlug = products.reduce((bySlug, product) => {
    bySlug[product.slug] = product;
    return bySlug;
  }, {});

  return productBySlug;
});

export const getCategoryNameAndId = createSelector(
  [getCategoriesById],
  productCategories => {
    const categoryIds = Object.keys(productCategories);
    const categorySearchList = categoryIds
      .filter(catId => {
        const category = productCategories[catId];
        return category.isLeaf;
      })
      .map(catId => {
        const category = productCategories[catId];
        const { name, id, slug } = category;
        return {
          id,
          name,
          slug,
        };
      });
    return categorySearchList;
  },
);

import { createReducer, createActions } from 'reduxsauce';
import produce from 'immer';

const { Types, Creators } = createActions({
  setShareForm: ['payload'],
});

const INITIAL_STATE = {
  link: '',
  type: null,
  name: null,
  data: {},
};

export const ShareTypes = Types;
export default Creators;

const setShareForm = (state, action) => {
  const { type, link, name, data } = action.payload;

  return produce(state, draft => {
    draft.type = type;
    draft.link = link;
    draft.name = name;
    draft.data = data;
  });
};

export const reducer = createReducer(INITIAL_STATE, {
  [Types.SET_SHARE_FORM]: setShareForm,
});

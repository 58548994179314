import { createReducer, createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  postReportRequest: ['payload', 'meta'],
  postReportSuccess: ['payload, meta'],
  postReportFailure: null,
});

const INITIAL_STATE = {};

export const ReportTypes = Types;
export default Creators;

export const reducer = createReducer(INITIAL_STATE, {});

// try catch to prevent security issue on safari when block cookies was turned on.
const getSessionStorage = (key: string) => {
  try {
    return sessionStorage.getItem(key);
  } catch (e) {
    return null;
  }
};

export default getSessionStorage;

import AuthActions from './authRedux';

const fbSignIn = () => {
  return AuthActions.fbSignInRequest();
};

const signOut = payload => {
  return AuthActions.signOutRequest(payload);
};

const signIn = (payload, meta) => {
  return AuthActions.signInRequest(payload, meta);
};

const signUp = (payload, meta) => {
  return AuthActions.signUpRequest(payload, meta);
};

const authUserToken = (payload, meta) => {
  return AuthActions.authUserTokenRequest(payload, meta);
};

const forgetPassword = (payload, meta) => {
  return AuthActions.forgetPassword(payload, meta);
};

const updateEmail = (payload, meta) => {
  return AuthActions.updateEmailRequest(payload, meta);
};

const updatePassword = (payload, meta) => {
  return AuthActions.updatePasswordRequest(payload, meta);
};

const setAuthErrorMessage = payload => {
  return AuthActions.setAuthErrorMessage(payload);
};

const setAuthStatus = payload => {
  return AuthActions.setAuthStatus(payload);
};

export {
  fbSignIn,
  signOut,
  signIn,
  signUp,
  authUserToken,
  forgetPassword,
  updateEmail,
  updatePassword,
  setAuthErrorMessage,
  setAuthStatus,
};

import styled, { css, keyframes } from 'styled-components';

const load = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const loadRule = css`
  animation: ${load} 1.1s infinite linear;
`;

interface LoaderProps {
  size: number;
  bgColor: string;
  fgColor: string;
}

export const Loader = styled.div<LoaderProps>`
  border-radius: 50%;
  display: inline-block;
  position: relative;
  text-indent: -9999em;
  transform: translateZ(0);
  ${loadRule};
  width: ${props => `${props.size}px`};
  height: ${props => `${props.size}px`};
  border-top: ${({ size, bgColor }) => `${size * 0.11}px solid ${bgColor}`};
  border-right: ${({ size, bgColor }) => `${size * 0.11}px solid ${bgColor}`};
  border-bottom: ${({ size, bgColor }) => `${size * 0.11}px solid ${bgColor}`};
  border-left: ${({ size, fgColor }) => `${size * 0.11}px solid ${fgColor}`};

  &:after {
    border-radius: 50%;
    width: ${props => `${props.size}px`};
    height: ${props => `${props.size}px`};
  }
`;

export const SpinnerWrapper = styled.div<{
  isCenter: boolean;
  margin: string;
}>`
  display: ${props => (props.isCenter ? 'flex' : 'inline-block')};
  justify-content: ${props => (props.isCenter ? 'center' : 'unset')};
  align-items: ${props => (props.isCenter ? 'center' : 'unset')};
  margin: ${props => props.margin || '0'};
`;

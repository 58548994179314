import React, { createContext, useState, useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { RootState } from 'reducer';

export type LoginScene =
  | 'main-sign-up'
  | 'main-log-in'
  | 'sign-up'
  | 'log-in'
  | 'verify-email'
  | 'forget-password';

interface ILoginContext {
  scene: LoginScene;
  navigate: (scene: LoginScene) => void;
  isBackAvailable: boolean;
  goBack: () => void;
  reset: () => void;
}

export const LoginContext = createContext<ILoginContext>({
  scene: 'main-log-in',
  isBackAvailable: false,
  navigate: () => {},
  goBack: () => {},
  reset: () => {},
});

interface OwnProps {
  children: React.ReactNode;
}

const DEFAULT_SCENE: LoginScene = 'main-sign-up';

const LoginContextProvider = (props: OwnProps) => {
  const { children } = props;
  const [history, setHistory] = useState<LoginScene[]>([DEFAULT_SCENE]);
  const loginModalMeta = useSelector(
    (state: RootState) => state.modal.loginModalMeta,
  );

  useEffect(() => {
    if (loginModalMeta.scene) {
      setHistory([loginModalMeta.scene as LoginScene]);
    }
  }, [loginModalMeta.scene]);

  const navigate = (newScene: LoginScene) => {
    setHistory([...history, newScene]);
  };

  const goBack = useCallback(() => {
    const newHistory = history.slice(0, -1);
    setHistory(newHistory);
  }, [history]);

  const reset = useCallback(() => {
    setHistory([DEFAULT_SCENE]);
  }, []);

  return (
    <LoginContext.Provider
      value={{
        scene: history[history.length - 1],
        isBackAvailable: history.length > 1,
        navigate,
        goBack,
        reset,
      }}
    >
      {children}
    </LoginContext.Provider>
  );
};

export default LoginContextProvider;

import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Lottie from 'lottie-react';
import Link from 'components/misc/Link';
import { RootState } from 'reducer';
import { Button } from 'seedly-component-library';
import Dialog from 'seedly-component-library/dialog';
import {
  closeDailyCheckInRewardModal,
  closeDailyCheckInSuccessModal,
  closeDailyCheckInPointsModal,
} from 'dispatcher/modalDispatcher';
import { imageUrl } from 'app/theme';
import checkInAnimation from './animation/checkin.json';
import giftBox from './animation/giftBox.json';
import CheckInDate from './CheckInDate';
import EarnMore from './EarnMore';
import DiscoverRewards from './DiscoverRewards';
import ChallengesConfirmation from './icons/ChallengesConfirmation';

const CheckInSuccessModal = () => {
  const isSuccessModalOpen = useSelector(
    (state: RootState) => state.modal.isDailyCheckInSuccessModalOpen,
  );
  const dispatch = useDispatch();

  return (
    <Dialog
      isOpen={isSuccessModalOpen}
      onCloseClick={() => {
        dispatch(closeDailyCheckInSuccessModal());
      }}
      widthSize="xs"
      isMobileFullHeight={false}
    >
      <div className="flex flex-col items-center gap-4">
        <Lottie
          animationData={checkInAnimation}
          loop
          autoplay
          style={{ width: 172, height: 172 }}
        />
        <p className="text-base font-bold text-success text-center">
          Check in successful! <br /> Come back tomorrow to keep your streak
          going!
        </p>
      </div>
    </Dialog>
  );
};

const OpenRewardModal = () => {
  const [openReward, setOpenReward] = useState(false);
  const isPointsModalOpen = useSelector(
    (state: RootState) => state.modal.isDailyCheckInPointsModalOpen,
  );
  const { maxMonthlyCreditReached } = useSelector(
    (state: RootState) => state.modal.dailyCheckInModalMeta,
  );
  const dispatch = useDispatch();

  return (
    <Dialog
      isOpen={isPointsModalOpen}
      onCloseClick={() => {
        if (openReward) {
          dispatch(closeDailyCheckInPointsModal());
        }
      }}
      isMobileFullHeight={false}
      widthSize="xs"
    >
      <div className="flex flex-col gap-2 w-full items-center">
        {openReward ? (
          <>
            <p className="text-sm font-bold text-center text-neutral-500">
              {maxMonthlyCreditReached
                ? 'You’re one of our top earners!'
                : 'You’ve earned'}
            </p>
            <p className="text-[32px] font-bold text-center text-success">
              {maxMonthlyCreditReached ? 'Fantastic' : '200 Credits'}
            </p>
            <ChallengesConfirmation />
            {maxMonthlyCreditReached && (
              <p className="text-xs text-neutral-500 text-center">
                Great to see you&#39;re on a roll! You&#39;ve hit your credit
                earning limit and we might have to raise the limits now{' '}
                <span role="img" aria-label="wink">
                  😉
                </span>
              </p>
            )}
            <Link passHref href="/rewards">
              <Button size="lg" variant="outlineGrey" isBlock>
                View My Rewards
              </Button>
            </Link>
          </>
        ) : (
          <>
            <Lottie
              animationData={giftBox}
              loop
              autoplay
              onClick={() => {
                setOpenReward(true);
              }}
            />
            <p className="text-base font-bold text-center">
              Your 7 days reward is here! <br /> Tap to open it now!
            </p>
          </>
        )}
      </div>
    </Dialog>
  );
};

const DailyCheckInModal = () => {
  const isOpen = useSelector(
    (state: RootState) => state.modal.isDailyCheckInRewardModalOpen,
  );

  const dispatch = useDispatch();

  return (
    <>
      <Dialog
        isOpen={isOpen}
        onCloseClick={() => {
          dispatch(closeDailyCheckInRewardModal());
        }}
        widthSize="sm"
        contentClassName="bg-blue-500"
      >
        <img
          src={imageUrl.greenCoin}
          alt="green-coin"
          className="absolute w-[108px] h-[108px] right-10 top-0 opacity-75 rotate-180"
        />
        <img
          src={imageUrl.yellowCoin}
          alt="green-coin"
          className="absolute w-[108px] h-[108px] left-0 top-0 opacity-75 rotate-180"
        />
        <div className="flex flex-col w-full gap-10 items-center mt-12">
          <div className="flex flex-col gap-1 items-center w-[342px]">
            <p className="text-white text-center text-2xl">
              Daily Check-in Reward
            </p>
            <p className="text-center text-white">
              Check in now and continue doing so for 7 consecutive days to earn
              some bonus credits!
            </p>
          </div>
          <div className="flex flex-col items-center w-full gap-4">
            <CheckInDate variant="modal" />
            <EarnMore />
            <DiscoverRewards />
          </div>
        </div>
      </Dialog>
      <CheckInSuccessModal />
      <OpenRewardModal />
    </>
  );
};

export default DailyCheckInModal;

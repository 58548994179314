import Cookies from 'js-cookie';
import { sessionIdentifier } from 'app/constants/auth';

const getAuthHeaders = () => {
  try {
    const authStr = Cookies.get(sessionIdentifier);
    const authHeaders = JSON.parse(authStr);

    const isPresent =
      authHeaders['access-token'] && authHeaders.client && authHeaders.uid;

    if (isPresent) {
      return authHeaders;
    }

    return {};
  } catch (e) {
    return {};
  }
};

export default getAuthHeaders;

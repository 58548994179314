import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import * as Sentry from '@sentry/react';

import mixpanel from 'app/utils/mixpanel';

const useInitialiseApp = () => {
  const auth = useSelector(state => state.auth);

  useEffect(() => {
    if (auth.session.isSignedIn) {
      // Only track logged-in users on Mixpanel
      const userId = auth.user.id;
      const userName = auth.user.name;
      const userProfile = auth.user.slug;
      const isUserBanned = auth.user.banned;

      if (userId) {
        // Sentry context to identify user when error occurs
        Sentry.setContext('Logged In User', {
          id: userId,
          name: userName,
          profile: userProfile,
          isBanned: isUserBanned,
        });

        // User-ID Tracking on GA via GTM
        if (window && window.dataLayer) {
          window.dataLayer = window.dataLayer || [];
          window.dataLayer.push({
            userId,
          });
        }

        mixpanel.identify(userId); // Identify the user once
      }
    }
  }, [
    auth.session.isSignedIn,
    auth.user.banned,
    auth.user.id,
    auth.user.name,
    auth.user.slug,
  ]);
};

export default useInitialiseApp;

import { Article, ArticleFromWp, ArticleWithLink } from './blogTypes';

export const addLinksToArticle = (article: Article): ArticleWithLink => {
  const { author, categories } = article;
  const authorWithLinks = {
    ...author,
    link: {
      as: `/author/${author.slug}/`,
      href: `/blog/author/${author.slug}/`,
    },
  };

  const categoriesWithLinks = categories.map(c => {
    let categoryParsed;
    if (typeof c === 'string') {
      categoryParsed = JSON.parse(c);
    }
    return {
      ...(categoryParsed || c),
      link: {
        href: `/blog/category/?categorySlug=${categoryParsed?.slug || c.slug}`,
        as: `/category/${categoryParsed?.slug || c.slug}/`,
      },
    };
  });

  return {
    ...article,
    author: authorWithLinks,
    categories: categoriesWithLinks,
    link: {
      as: `/${article.slug}/`,
      href: `/blog/article/?articleSlug=${article.slug}`,
    },
  };
};

export const mapBlogData = (articleFromWp: ArticleFromWp): ArticleWithLink => {
  const {
    id,
    date_gmt: dateGmt,
    title,
    slug,
    author,
    excerpt,
    categories,
    modified_gmt: modifiedGmt,
    content,
  } = articleFromWp;

  const article = {
    objectID: '#',
    id,
    articleId: null,
    title: title.rendered,
    slug,
    imageUrl: '',
    imageAlt: '',
    meta: {
      description: '',
    },
    author: {
      id: author,
      name: '',
      slug: '',
      imageUrl: '',
      description: '',
    },
    excerpt: (excerpt && excerpt.rendered) || '',
    categories:
      categories.map(categoryId => {
        return {
          id: categoryId,
          name: '',
          slug: '',
        };
      }) || [],
    publishDate: new Date(dateGmt).toISOString(),
    publishDateTimestamp: Math.floor(new Date(dateGmt).getTime() / 1000),
    modifiedDate: new Date(modifiedGmt).toISOString(),
    views: 0,
    content: content && content.rendered,
    shares: {
      facebook: {
        reactionCount: 0,
        commentCount: 0,
        shareCount: 0,
        commentPlugincount: 0,
      },
    },
  };
  return addLinksToArticle(article);
};

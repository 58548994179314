import React from 'react';
import clsx from 'clsx';
import Spinner from '../icons/spinner.svg';

type ButtonVariant =
  | 'primary'
  | 'primaryWhite'
  | 'ghost'
  | 'outline'
  | 'outlineGrey'
  | 'outlineWhite'
  | 'outlineGreyHighlight';

export interface ButtonProps {
  children?: React.ReactNode;
  className?: string;
  disabled?: boolean;
  loading?: boolean;
  icon?: React.ReactElement;
  type?: 'submit' | 'reset' | 'button';
  variant?: ButtonVariant;
  size?: string;
  shape?: string;
  form?: string;
  isBlock?: boolean;
  title?: string;
  isLoading?: boolean;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
}

export const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  function Button(
    {
      children,
      loading,
      variant = 'primary',
      className,
      icon,
      disabled,
      isBlock,
      size,
      shape,
      isLoading,
      type = 'button',
      ...passThroughProps
    },
    ref,
  ) {
    return (
      <button
        type={type}
        ref={ref}
        className={clsx(
          {
            'btn-primary': variant === 'primary',
            'btn-primary-white': variant === 'primaryWhite',
            'btn-ghost': variant === 'ghost',
            'btn-outline': variant === 'outline',
            'btn-outline-grey': variant === 'outlineGrey',
            'btn-outline-white': variant === 'outlineWhite',
            'btn-outline-grey-highlight': variant === 'outlineGreyHighlight',
            'btn-disabled': disabled,
          },
          {
            'btn-size-large': size === 'lg',
            'btn-size-small': size === 'sm',
          },
          isBlock && 'w-full',
          className,
        )}
        disabled={disabled || loading}
        {...passThroughProps}
      >
        {isLoading && (
          <Spinner className="inline mr-2 w-4 h-4 animate-spin text-blue-300 fill-blue-500" />
        )}
        {children}
      </button>
    );
  },
);

export default Button;

import React from 'react';
import ReactModal from 'react-modal';
import { X as CloseIcon } from 'react-feather';
import { CSSProperties } from 'styled-components';
import {
  GlobalStyle,
  ModalBody,
  ModalHeader,
  ModalFooter,
  closeIconStyles,
  Title,
} from './styles';
import { themes } from '../themes';

export interface IGlobalStyle {
  maxHeight?: string;
  minHeight?: string;
  mobileHeight?: string;
  mobileWidth?: string;
  isMobileRounded?: boolean;
}

interface ModalProps {
  bodyStyle?: CSSProperties;
  closeButtonStyle?: CSSProperties;
  renderHeaderLeft?: () => {};
  isFullScreen?: boolean;
  withCloseButton?: boolean;
  footer?: React.ReactNode;
  label?: string;
  size?: string;
  headerPadding?: string;
  mobileFullHeight?: boolean;
  children: React.ReactNode;
  onRequestClose?: (e) => void;
  headerTitle?: string;
  globalStyle?: IGlobalStyle;
  footerStyle?: CSSProperties;
  ariaHideApp?: boolean;
  isOpen: boolean;
  testId?: string;
}

export const Content = (props: Partial<ModalProps>) => {
  const {
    closeButtonStyle,
    headerPadding,
    mobileFullHeight,
    renderHeaderLeft,
    isFullScreen,
    withCloseButton,
    bodyStyle,
    footer,
  } = props;

  return (
    <>
      <ModalHeader
        style={closeButtonStyle}
        padding={headerPadding}
        mobileFullHeight={mobileFullHeight}
      >
        {renderHeaderLeft ? (
          renderHeaderLeft()
        ) : (
          <Title>{props.headerTitle}</Title>
        )}
        {isFullScreen || withCloseButton ? (
          <CloseIcon
            onClick={props.onRequestClose}
            style={closeIconStyles}
            data-testid="button-close"
          />
        ) : null}
      </ModalHeader>
      <ModalBody style={bodyStyle} mobileFullHeight={mobileFullHeight}>
        {props.children}
      </ModalBody>
      {footer && (
        <ModalFooter
          style={props.footerStyle}
          mobileFullHeight={mobileFullHeight}
        >
          {footer}
        </ModalFooter>
      )}
    </>
  );
};

const Modal = (props: ModalProps) => {
  const {
    bodyStyle,
    closeButtonStyle,
    renderHeaderLeft,
    isFullScreen = false,
    withCloseButton = false,
    footer = false,
    label = 'default', // ! use dash separated value for this e.g. 'level-up' and not 'level up'
    size = 'lg',
    headerPadding = themes.sizing.default,
    mobileFullHeight = false,
  } = props;

  return (
    <>
      <GlobalStyle globalStyle={props.globalStyle} label={label} size={size} />
      <ReactModal
        className={`content-${label}`}
        overlayClassName="overlay"
        {...props}
      >
        <Content
          bodyStyle={bodyStyle}
          closeButtonStyle={closeButtonStyle}
          renderHeaderLeft={renderHeaderLeft}
          isFullScreen={isFullScreen}
          withCloseButton={withCloseButton}
          footer={footer}
          headerPadding={headerPadding}
          mobileFullHeight={mobileFullHeight}
          {...props}
        />
      </ReactModal>
    </>
  );
};

export default Modal;
